import React from "react";
import {
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { AppConstant } from "appConstant";
import { useHistory } from "react-router-dom";

const PrivacyPolicy = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const history = useHistory();

  const goToHomePage = () => {
    history.push("/");
  };

  return (
    <div
      className={classes.root}
      style={{
        marginBottom: 60,
        paddingLeft: isMobile ? 10 : "6%",
        paddingRight: isMobile && 10,
      }}
    >
      <Grid
        container
        spacing={isMobile ? 0 : 3}
        style={{ width: "100%", marginTop: 30 }}
        justifyContent="center"
      >
        {/* <Grid item xs={12} md={10} style={{ paddingTop: "50px" }}>
                    <a onClick={goToHomePage} className={classes.breadcrumbLink}>
                        <i className="fa fa-home"></i> Home
                    </a>
                </Grid> */}
        <Grid item style={{ width: "100%" }}>
          <div className={classes.titleHeader}>Privacy Policy:</div>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            1. Agreement
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            This is your agreement with tickat.com ("Website"). By joining the Website and continuing to use it you agree to be bound by all the terms and conditions of this agreement and any changes we may make from time to time. Each time you use or access the Website, you agree to be bound by these Terms of Use, as amended from time to time, with or without notice.
          </Typography>
        </Grid>{" "}
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            2. Services
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            The content and services provided to you via the Website are on an "As Is" basis. You agree that the Website reserves the right to modify or discontinue provision of this Website and its services and to remove the data you provide at any time, without notice and without any liability.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            3. Registration & Password
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            To use the Website or certain parts of it, you may be required to register for a user account. In such case, you agree to provide truthful information when requested and if a minimum age is required for eligibility for a user account, you undertake that you are at least the required age. You are responsible for maintaining confidentiality of your password and usage of your account. You agree to immediately notify the administrator of any unauthorized use of your account, username or password.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            4. Third-party Services, PayPal, Credit/Debit Card & Net Banking Transactions
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Goods and services of third parties may be advertised and may be made available on or through this Website. Representations made regarding products and services provided by third parties are governed by the policies of these third parties. Some services on the website require a payment, including subscription fees. tickat.com has collaborated with preferred third-party vendors such as EBS etc. to process all payments and transactions. As such, any and all transactions are bound by user agreements and regulations governing appropriate credit card, debit card, Net banking and other relevant electronic payment transactions as listed on their respective websites. tickat.com will not in any manner be liable for or responsible for any of your dealings or interaction with third parties, except as required by law.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            5. Membership Dues & Automatic Renewal
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            When you register for Monthly, Quarterly or Annual membership, you agree for automatic renewal of the membership through recurring payments unless you cancel your membership (see Cancellation Policy). You agree to pay membership dues on time and make available sufficient funds in your account necessary for successful withdrawal through appropriate automatic electronic transfer of funds (recurring payments) on or before the due date every month for monthly renewal (or every 3 months for quarterly renewal and every year for annual renewal) as agreed by you during registration. We will notify you if sufficient funds are not available to complete withdrawal of monthly or quarterly or annual subscription fees and may suspend or cancel your membership, if you do not take corrective action within 48 hours of receiving the notification. You may also be liable for paying any fees as required by our third-party vendors for such failed transactions that are a result of insufficient funds in your account or any other reason on your part.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            6. Cancellation Policy
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            You can cancel your membership at any time. In order to so, you need to notify the administrator of the Website by using the Cancel membership option on your MY ACCOUNT page. No refund will be given once funds have been withdrawn from your account unless decided otherwise, which is solely based on the administrator's discretion.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            7. Privacy Policy
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Registration data and other personally identifiable information that the site may collect is subject to the Website's Privacy Policy. We place high priority in maintaining users privacy on the data shared with us. Where applicable, we always ensure to take all precautionary steps to ensure confidentiality of all personal data and transactions. We do NOT sell any personally identifiable information to any third-parties.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            8. Your conduct
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            By using this Website or any service provided, you explicitly agree that: You will not provide any content or conduct yourself in any way that may be construed as: illegal; threatening; abusive; stalking; defamatory; objectionable; pornographic; designed to interfere with or disrupt operation of this Website; infecting with a virus or other destructive programming routine; in violation of an applicable local, national or international law; You will not collect or harvest any information about other users and will not provide or promote any content or service in any commercial manner or in any manner that would involve junk mail, spam, chain letters, pyramid schemes, or any other form of unauthorized advertising without the Website's prior written consent; You acknowledge and agree that the Website will have the right to refuse to publish or to remove and block access to any content you provide, at any time and for any reason, with or without notice.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            9. Indemnification
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            You agree to indemnify and hold harmless the Website and its representatives, subsidiaries, affiliates, related parties, employees, agents, advertisers, partners and co-branders from any claim or demand, including reasonable legal fees that may be filed by any third party, arising out of your conduct on this Website.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            10. Disclaimer of Warranties
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Services and Content are provided to you "As Is" and while we strive to provide top rate service to our members, the Website makes no warranty, either implied or express, that any part of the service will be uninterrupted, error-free, virus-free, timely, secure, accurate, reliable or of any quality, nor is it warranted, either implicitly or expressly that any content is safe in any manner for download.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            11. Limitation of Liability
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            You expressly understand and agree that the Website will not be liable for any direct, indirect, special, incidental, consequential or exemplary damages. This includes but is not limited to damages for loss of profits, goodwill, use, data or other intangible losses resulting from the use or inability to use services, the cost of obtaining substitute goods or services resulting from any transaction entered on through services, unauthorized access to or alteration of your data transmissions, statements by any third party or conduct of any third party services or any other matter relating to services.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            12. Reservation of Rights
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            The Website reserves all copyright, trademarks, patents, trade secrets and any other proprietary rights in respect of this Website, its content, and goods and services that may be provided. By making services available to you, tickat.com is not providing you with any implied or express licenses or rights to make any commercial use of provided services without prior written consent.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            13. Notice of Copyright Infringement
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            The Website reserves all copyright, trademarks, patents, trade secrets and any other proprietary rights in respect of this Website, its content, and goods and services that may be provided. By making services available to you, tickat.com is not providing you with any implied or express licenses or rights to make any commercial use of provided services without prior written consent.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            The Website reserves all copyright, trademarks, patents, trade secrets and any other proprietary rights in respect of this Website, its content, and goods and services that may be provided. By making services available to you, tickat.com is not providing you with any implied or express licenses or rights to make any commercial use of provided services without prior written consent.
          </Typography>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            14. Applicable Law
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            By registering for a user account on this Website or by using this Website and the services it provides, you accept that jurisdiction is granted to the courts on the Website's domicile and that any disputes will be heard by the said courts.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default PrivacyPolicy;

const Gap10 = () => {
  return <div className="gap-10" style={{ margin: 10, width: "100%" }}></div>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .highlight": {
      color: theme.palette.primary.darkOrange,
    },
  },
  titleHeader: {
    fontSize: 40,
    fontWeight: 500,
    textDecoration: "underline",
  },
  Grid: {
    background: theme.palette.background.default,
    marginTop: "20px",
    boxShadow: "0px 53px 99px -81px rgba(0,0,0,0.75)",
    padding: 30,
    borderRadius: 20,
  },
  sectionHeader: {
    color: `${theme.palette.primary.darkFadedText} !important`,
    fontWeight: 500,
    margin: "5px 0 30px",
  },
  sectionSubhead: {
    color: `${theme.palette.primary.defaultText} !important`,
    fontWeight: 400,
  },
  sectionText: {
    color: `${theme.palette.primary.davyGray} !important`,
    fontWeight: 400,
    lineHeight: "27px",
  },
  sectionTextGrid: {
    margin: "15px 0",
    flexDirection: "column",
  },
  listText: {
    "& li": {
      color: theme.palette.primary.davyGray,
      fontWeight: 400,
      lineHeight: "27px",
    },
  },
  sectionTextGrid: {
    margin: "15px 0",
    flexDirection: "column",
  },
  listText: {
    "& li": {
      color: theme.palette.primary.darkFadedText,
      fontWeight: 400,
      lineHeight: "27px",
    },
  },
  breadcrumbLink: {
    color: `${theme.palette.primary.active} !important`,
    cursor: "pointer",
    "&:hover": {
      color: `${theme.palette.primary.pomegranate} !important`,
    },
  },
}));
