export const colors = {
  palette: {
    primary: {
      main: "#00B6A6",
      contrastText: "#fff",
      defaultText: "#000",
      darkText: "#3c4858",
      lightGreenSec: "#c2d87e",
      LightGraySec: "#eaeaea",
      lightText: "#FFF",
      lightGray: "#7E7979",
      shadedLightText: "#CDCDCD",
      darkFadedText: "#665",
      sub: "#666666",
      disabled: "#D8D8D8",
      active: "#00b7a6",
      danger: "#E31352",
      success: "#48AB64",
      info: "#4887AB",
      warning: "#E46800",
      reddishgray: "#464646",
      yellow: "#F1A625",
      blue: "#34404b",
      darkGray: "#666",
      golden: "#E3A133",
      blackPearl: "#060F26",
      peach: "#f76b6b",
      faddedGray: "#848383",
      sapphire: "#2174ED",
      redAlert: "#D0342C",
      french: "#2F71AF",
      honey: "#ffc037",
      violetBlue: "#5863F8",
      shadow: "#262626",
      iron: "#2D2D2D",
      fadedLight: "#EFEFEF",
      lightGreen: "#155724",
    },
    secondary: {
      main: "#00B6A6",
      // main: "#E2BE69",
      darkOrange: "#ef6614",
      contrastText: "#fff",
      defaultText: "#000",
      darkText: "#333",
      lightText: "#FFF",
      darkFadedText: "#665",
      sub: "#da5200",
      disabled: "#D8D8D8",
      active: "#F17509",
      danger: "#E31352",
      success: "#48AB64",
      info: "#4887AB",
      warning: "#E46800",
      mustardYellow: "#f89605",
      gray: "#666",
      lightBlue: "#1d79d5",
      darkBlue: "#007A92ee",
      black: "#0c0c0c",
      aegean: "#004C7E",
      sharkGray: "#9B9B9B",
      charcoal: "#5E5E5E",
      pebble: "#333333",
      metalGray: "#949799",
      charcoalGray: "#494949",
      dimGray: "#666666",
      grandmaGray: "#bcbcbc",
      fordGray: "#979797",
      darkShadedGray: "#444444",
      darkBlack: "#000000",
      medallion: "#E3A130",
      neon: "#2CE843",
      steel: "#808080",
      crimson: "#DF3049",
      koalaGray: "#908c8c",
      orange: "#F4783B",
      parakeet: "#43B253",
    },
    tertiary: {
      main: "#00B6A6",
      contrastText: "#FFF",
      darkText: "#333",
      lightText: "#FFF",
    },
    quarternary: {
      main: "#9d48ab",
      contrastText: "#fff",
      darkText: "#333",
      lightText: "#FFF",
      darkGrayText: "#666",
    },
    background: {
      default: "#FFF",
      primary: "#00B6A6",
      defaultContrast: "#000",
      lightGrayDefault: "#eeeeee",
      lightGrayDefaultContrast: "#333333",
      disabled: "#dddddd",
      disabledContrast: "#333333",
      lightYellowGreen: "#c2d87e",
      mobileSearch: "#F6F6F6",
      mobileSearchBorder: "#DF3049",
      lightOrange: "#ef661445",
      whiteGreen: "#f2fbd7",
      light: "#FFF",
      dark: "#383838",
      darkDefault: "#000000",
      wappbg: "#25D366",
      wappbgContrastColor: "#ffffff",
      lightFadedGray: "#F5F0f0",
      darkerFadedGray: "#777777",
      lightGray: "#eee",
      red: "#d52731",
      selago: "#FAF9FA",
      carulean: "#1374B3",
      peach: "#EC6161",
      smokeWhite: "#f5f5f5",
      silverWhite: "#EFEFEF",
      cultured: "#F4F4F4",
      green: "#38B449",
      primaryContrast: "#ffffff",
      secondary: "#00B6A6",
      secondaryContrast: "#FFFFFF",
      tertiary: "#00B6A6",
      overlay: "#eeeeee",
      tertiaryContrast: "#FFFFFF",
      rgba_primary: "rgba(0, 182, 166, 0.6)",
      rgba_secondary: "rgba(0, 80, 137, 0.15)",
      rgba_tertiary: "rgba(2, 47, 80, 0.3)",
      rgba_quarternary: "rgba(221, 221, 221, 0.9)",
      rgba_primary_active: "rgba(0, 182, 166, 0.6)",
      rgba_secondary_active: "rgba(0, 80, 137, 0.6)",
      rgba_tertiary_active: "rgba(2, 47, 80, 0.6)",
      rgba_quarternary_active: "rgba(157, 72, 171, 0.6)",
    },
    text: {
      main: "#00B6A6",
      primaryContrast: "#fff",
      secondary: "#00B6A6",
      secondaryContrast: "#ffffff",
      teritiary: "#00B6A6",
      tertiaryContrast: "#ffffff",
      quarternary: "#9d48ab",
      quarternaryContrast: "#ffffff",
      default: "#000000",
      defaultContrast: "#ffffff",
      dark: "#333333",
      light: "#ffffff",
      danger: "#E31352",
      success: "#48AB64",
      info: "#4887AB",
      warning: "#E46800",
    },
    buttons: {
      default: "#FFF",
      defaultContrast: "#333",
      primary: "#00B6A6",
      primaryContrastText: "#FFF",
      secondary: "#00B6A6",
      secondaryContrastText: "#fff",
      tertiary: "#022F50",
      tertiaryContrastText: "#FFF",
      quarternary: "#9d48ab",
      quarternaryContrastText: "#ffffff",
      disabled: "#7E7979",
      disabledContrastText: "#fff",
      bookNowBtn: "#00b6a6",
    },
    rgb: {
      primary: "rgb(0, 182, 166)",
      secondary: "rgb(0, 80, 137)",
      teritiary: "rgb(2, 47, 80)",
    },
    font: {
      primary: "'Poppins', sans-serif",
      secondary: "'Poppins', sans-serif",
      tertiary: "'Roboto', sans-serif",
    },
  },
};
