import React from 'react'
import LeftAdornedAutoComplete from './LeftAdornedAutoComplete';
import { Popper, TextField } from '@material-ui/core';
import WebApi from "api/ApiConstants";


const FlightLocationInput = ({props, index, type}) => {


    const renderOptionsComp = (optionsData, propes) => {
        return (
          <div selected={propes.selected} className="ac-options-list">
            <span>
              <p className="ac-option-left">
                {optionsData.cityName} ({optionsData.airportCode})
              </p>
              <p className="ac-option-right">{optionsData.countryCode}</p>
            </span>
            <p>{optionsData.airportDesc}</p>
          </div>
        );
      };

    const [airportList, setAirportList] = React.useState(popularCities);


    const [fetchingAirports, setFetchingAirports] = React.useState(false);

    React.useEffect(() => {
        // console.log("fetchingAirports",fetchingAirports)
        // console.log("airportList",airportList)
        if (airportList && airportList.length > 0) {
          setFetchingAirports(false);
        }
      }, [airportList]);

    const fetchAirports = (searchParams) => {
        setFetchingAirports(true);
        if (searchParams.length >= 3) {
          WebApi.fetchAirportList(
            {
              airportCode: searchParams.trim(),
            },
            (response) => {
              if (response.success == true) {
                setAirportList(response.data.airports);
              }
            },
            (error) => console.log("fetchAirportList error", error)
          );
        }
      };

      const customPopperTo = function(props) {
        return <Popper {...props} placement="bottom" style={{}} />;
      };

  return (
    <LeftAdornedAutoComplete
    loading={fetchingAirports}
    className="form-control airport-input"
    name={`sectors[${index}][${type}Obj]`}
    PopperComponent={customPopperTo}
    options={airportList || []}
    getOptionLabel={(o) => (o ? `${o.cityName}` : "")}
    value={props.values.sectors[index][`${type}Obj`]}
    openOnFocus
    getOptionSelected={(option, value) =>
      option.cityName === value.cityName
    }
    onKeyUp={(event) => {
      fetchAirports(event.target.value.trim());
    }}
    onChange={(event, newValue, reason) => {
      if (reason === "clear") {
        setAirportList(props.popularcities);
      } else {
        props.setFieldValue(`sectors[${index}][${type}Obj]`, newValue);
        props.setFieldValue(
          `sectors[${index}].${type}`,
          newValue.airportCode
        );
      }
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        fullWidth
        color="secondary"
        inputProps={{
          ...params.inputProps,
          autoComplete: "new-password",
        }}
      />
    )}
    renderOption={(optionsData, propes) =>
      renderOptionsComp(optionsData, propes)
    }
    disablePortal={true}
    // adnicon={locationPinIcon}
  />
  )
}

const popularCities = [
    {
      airportId: 5669,
      cityName: "Mumbai",
      airportCode: "BOM",
      countryCode: "IN",
      citySearch: "MUMBAI,BOMBAY",
      airportDesc: "Chhatrapati Shivaji International Airport",
    },
    {
      airportId: 5877,
      cityName: "New Delhi",
      airportCode: "DEL",
      countryCode: "IN",
      citySearch: "NEW DELHI,DELHI",
      airportDesc: "Indira Gandhi International Airport",
    },
    {
      airportId: 682,
      cityName: "Bangkok",
      airportCode: "BKK",
      countryCode: "TH",
      citySearch: "",
      airportDesc: "Bangkok",
    },
    {
      airportId: 679,
      cityName: "Bangalore",
      airportCode: "BLR",
      countryCode: "IN",
      citySearch: "BANGALORE,BANGLURU,BANGLORE,BENGALURU",
      airportDesc: "Bengaluru International Airport",
    },
    {
      airportId: 6926,
      cityName: "Pune",
      airportCode: "PNQ",
      countryCode: "IN",
      citySearch: "PUNE,PUNA",
      airportDesc: "Pune Airport",
    },
    {
      airportId: 3454,
      cityName: "Hyderabad",
      airportCode: "HYD",
      countryCode: "IN",
      citySearch: "HYDERABAD,HYDRABAD",
      airportDesc: "Rajiv Gandhi International Airport",
    },
    {
      airportId: 4229,
      cityName: "Kolkata",
      airportCode: "CCU",
      countryCode: "IN",
      citySearch: "KOLKATA,CALCUTTA,CALCUTA",
      airportDesc: "Netaji Subhash Chandra Bose International Airport",
    },
    {
      airportId: 1585,
      cityName: "Chennai",
      airportCode: "MAA",
      countryCode: "IN",
      citySearch: "CHENNAI,MADRAS",
      airportDesc: "Chennai International Airport",
    },
    {
      airportId: 2935,
      cityName: "Goa",
      airportCode: "GOI",
      countryCode: "IN",
      citySearch: "GOA",
      airportDesc: "Dabolim Goa International Airport",
    },
    {
      airportId: 2257,
      cityName: "Dubai",
      airportCode: "DXB",
      countryCode: "AE",
      citySearch: "",
      airportDesc: "Dubai International",
    },
  ];

export default FlightLocationInput
