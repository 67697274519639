import React, { useEffect, useMemo } from "react";
import { useTheme, Tab, Grid, Avatar } from "@material-ui/core";
import styled from "styled-components";
import FlightSection from "./forms/FlightSection";

import HotelSection from "./forms/HotelSection";
import BusSection from "./forms/BusSection";
import WebApi from "api/ApiConstants";
import { useDispatch } from "react-redux";
import { refreshUserSession } from "oautils/oaAuthUtils";

import { localforageGetItem } from "oautils/oaForageUtils";
import HomePageNavbar from "components/LandingPage/Tickat/HomePageNavbar";
import { AppStateContext } from "layouts/AppStateProvider";
import { Switch, Route } from "react-router-dom";
import ExplorePackage from "./ExplorePackage";
import DomesticPackage from "./DomesticPackage";
import WhyChooseUs from "./WhyChooseUs";
import flightIcon from "assets/Tickat/images/home_pix/flight_icon.png";
import blackflightIcon from "assets/Tickat/images/home_pix/flight_black_icon.png";
import hotelIcon from "assets/Tickat/images/home_pix/hotel_icon.png";
import blackhotelIcon from "assets/Tickat/images/home_pix/hotel_black_icon.png";

import { TabContext, TabList, TabPanel } from "@material-ui/lab";

import SuitServices from "./SuitServices";
// import ExclusiveOffers from "./ExclusiveOffers";
import HomepageContactUs from "./HomepageContactUs";
import PlanYourTripMobile from "components/LandingPage/Tickat/forms/PlanYourTripMobile";
import OfferSection from "./OfferSection";
import { useHistory } from "react-router-dom";
import MobileFlightSection from "../for-mobile/MobileFlightSection";
import MobileHotelSection from "../for-mobile/MobileHotelSection";
import Carousel from "react-elastic-carousel";
import bg1 from "assets/Tickat/images/home/carousel/dubai_city_night.avif";
import bg2 from "assets/Tickat/images/home/carousel/dubai_city.avif";
import bg3 from "assets/Tickat/images/home/carousel/indonesia.avif";
import bg4 from "assets/Tickat/images/home/carousel/maldives.avif";
import bg5 from "assets/Tickat/images/home/carousel/norway_bridge.avif";
import bg6 from "assets/Tickat/images/home/carousel/norway.avif";
import bg7 from "assets/Tickat/images/home/carousel/venice_Provincia_Veneția_Italia.avif";

const HomePage = (props) => {
  const carouselImages = useMemo(() => [bg1, bg2, bg3, bg4, bg5, bg6, bg7], []);
  let resetTimeout;
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMounted = React.useRef(false);
  const carouselRef = React.useRef(null);
  const [value, setValue] = React.useState("1");
  const history = useHistory();
  const [currentBgImage, setCurrentBgImage] = React.useState(0);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [usertype, setUsertype] = React.useState(null);
  const { setUserTypeb2c } = React.useContext(AppStateContext);
  const [corporateType, setCorporateType] = React.useState(null);
  const [serviceOptions, setServiceOptions] = React.useState([]);
  const [showServices, setShowServices] = React.useState(
    props.hideServices ? props.hideServices : props.showServices
  );
  const [routesItem, setRoutesItem] = React.useState([]);
  const [offers, setOffers] = React.useState([]);
  const [packageCategory, setPackageCategory] = React.useState();
  const [
    domesticPackageCategory,
    setDomesticPackageCategory,
  ] = React.useState();
  const [
    internationalPackageCategory,
    setInternationalPackageCategory,
  ] = React.useState();

  const [LeadListData, SetLeadListData] = React.useState([]);
  const isHotels = window.location.toString().indexOf("hotels") != -1;

  // React.useEffect(() => {
  //   setCurrentBgImage(carouselImages[Math.floor(Math.random() * 3)]);
  // }, []);

  const handleChange = (event, newValue) => {
    if (+newValue === 1) {
      history.push("/flights");
    } else {
      history.push("/hotels");
    }

    setValue(newValue);
  };

  useEffect(() => {
    if (isHotels) {
      setValue("2");
    } else {
      setValue("1");
    }
  }, [isHotels]);

  const FlightSearch = () => {
    return (
      <div className="section-container mainsearch-container">
        <div className="search__box">
          {width < breakpoint ? <MobileFlightSection /> : <FlightSection />}
          {/* <FlightSection /> */}
        </div>

        <div className="bannerText">
          <p className="bannerTextTitle">Embark on Dreams</p>
          <p className="bannerTextSubText">Your Journey, Our Expertise</p>
        </div>
      </div>
    );
  };

  const HotelSearch = () => {
    return (
      <div className="section-container mainsearch-container">
        <div className="search__box">
          {width < breakpoint ? <MobileHotelSection /> : <HotelSection />}
          {/* <HotelSection /> */}
        </div>
        <div className="bannerText">
          <p className="bannerTextTitle">Embark on Dreams</p>
          <p className="bannerTextSubText">Your Journey, Our Expertise</p>
        </div>
      </div>
    );
  };

  const BusSearch = () => {
    return (
      <div className="section-container">
        <div className="bannerText">
          <p className="bannerTextTitle">Embark on Dreams</p>
          <p className="bannerTextSubText">Your Journey, Our Expertise</p>
        </div>
        <div className="search__box">
          {/* {width < breakpoint ? <MobileBusSection /> : <BusSection />} */}
          <BusSection />
        </div>
      </div>
    );
  };

  const callbackRefreshToken = (flag) => {
    setIsLoading(flag);
  };

  const getUserDetails = (mountStatus) => {
    localforageGetItem("user-details", function (err, value) {
      if (mountStatus && value) {
        if (value.userTypeAbv == "S") {
          setIsLoggedIn(true);
        }

        setShowServices(
          value.userType == "distributor"
            ? false
            : showServices != null
              ? showServices
              : true
        );

        setServiceOptions(value.serviceOption);

        setUsertype(value.userType);
        setUserTypeb2c(value.userType);
        setCorporateType(value.corporateType);
        setIsLoading(false);
      } else {
        console.error("error getting user details", err);
      }
    });
  };

  const width = window.innerWidth;
  const breakpoint = 980;
  const currentURL = window.location.href; // returns the absolute URL of a page
  const currentTab = currentURL.split("/");

  let d = new Date();
  const date =
    ("0" + (d.getDate() + 0)).slice(-2) +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    d.getFullYear();

  React.useEffect(() => {
    isMounted.current = true;
    refreshUserSession(dispatch, callbackRefreshToken);
    let today = new Date();
    WebApi.getPackageCategory(
      { date: today },
      (response) => {
        if (response.data) {
          //console.log(response.data);
          setPackageCategory(response.data);

          for (var i = 0; i < response.data.length; i++) {
            // console.log("response.data[i].packageType", response.data[i].packageType);

            if (response.data[i].packageType === "D") {
              setDomesticPackageCategory(response.data[i]);
            } else if (response.data[i].packageType === "I") {
              setInternationalPackageCategory(response.data[i]);
            }
          }
          // console.log(response.data)
        }
      },
      (error) => console.error("error fetching package category", error)
    );
    WebApi.getFlightLeadList(
      {
        agentId: 0,
        dateFrom: date,
        dateTo: date,
        status: 1,
      },
      (resp) => {
        // console.log("res", resp);
        SetLeadListData(resp.data);
      },
      (error) => console.error("error", error)
    );
    return () => (isMounted.current = false);
  }, []);

  useEffect(() => {
    // getUserDetails(isMounted.current);
    WebApi.getExclusiveOffer(
      {
        callFrom: "web",
        offerCategory: "",
      },
      (resp) => {
        if (resp != null && resp.success) {
          // console.log(resp.data, "get pop");
          setOffers(resp.data);
          // setDataIs(true);
        } else {
          console.log("error resp null", resp);
        }
      },
      (error) => console.error("error", error)
    );
  }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     // console.log("wohoo");
  //     // setCurrentBgImage((prevSlide) => (prevSlide + 1) % carouselImages.length);
  //     setCurrentBgImage((prevSlide) => (prevSlide + 1) % carouselImages.length);
  //     // setCurrentBgImage(carouselImages[Math.floor(Math.random() * 3)]);
  //   }, 3000); // Change this to 3000 for 3-second intervals

  //   return () => clearInterval(interval);
  // }, []);

  // const nextSlide = () => {
  //   setCurrentBgImage((prevSlide) => (prevSlide + 1) % carouselImages.length);
  //   setTimeout(nextSlide, 3000); // Change this to 3000 for 3-second intervals
  // };

  // // Start the automatic slide change
  // setTimeout(nextSlide, 3000); // Change this to 3000 for 3-second intervals

  return (
    <Container style={{ width: "100%" }} theme={theme}>
      <div className="top-bg-container">
        {/* <Carousel
          // itemPadding={[0, 10, 0, 0]}
          itemsToShow={1}
          itemsToScroll={1}
          showArrows={false}
          autoPlaySpeed={2000}
          enableAutoPlay
          loop={true}
          // verticalMode=
          // itemsToShow={}
          pagination={false}
        >
          {carouselImages?.map((img) => <img src={img} />)}
        </Carousel> */}
      </div>
      <HomePageNavbar />
      {width < breakpoint ? (
        <div style={{ marginTop: 80 }}>
          <PlanYourTripMobile />
        </div>
      ) : (
        <div className="section-container search-section">
          <div className="carousel-container">
            <Carousel
              ref={carouselRef}
              enableAutoPlay
              autoPlaySpeed={3500}
              showArrows={false}
              onNextEnd={({ index }) => {
                clearTimeout(resetTimeout);
                if (index + 1 === carouselImages.length) {
                  resetTimeout = setTimeout(() => {
                    carouselRef.current.goTo(0);
                  }, 1500); // same time
                }
              }}
              itemsToShow={1}
            >
              {carouselImages.length > 0 &&
                carouselImages.map((carImage, index) => (
                  <div
                    className="carousel-image-component"
                    style={{
                      backgroundImage: `url(${carImage})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                    key={index}
                  >
                    <div className="bg-overlay"></div>
                  </div>
                ))}
            </Carousel>
          </div>
          <div className="tab-container zi">
            <Switch>
              <Route
                path="/flights"
                render={() => {
                  // return <FlightSearchSection {...props} />;
                  return (
                    <TabContext value={value}>
                      <div>
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                        >
                          <Tab
                            icon={
                              <Avatar
                                alt="test avatar"
                                src={
                                  value === "1" ? flightIcon : blackflightIcon
                                }
                              />
                            }
                            label="Flights"
                            value="1"
                          />
                          <Tab
                            icon={
                              <Avatar
                                alt="test avatar"
                                src={value === "2" ? hotelIcon : blackhotelIcon}
                              />
                            }
                            label="Hotels"
                            value="2"
                          />
                        </TabList>
                      </div>
                      <TabPanel value="1">
                        <FlightSearch {...props} />
                      </TabPanel>
                      <TabPanel value="2">
                        <HotelSearch {...props} />
                      </TabPanel>
                    </TabContext>
                  );
                }}
              />
              <Route
                path="/hotels"
                render={() => {
                  return (
                    <TabContext value={value}>
                      <div
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                          marginTop: "36px",
                        }}
                      >
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                        >
                          <Tab
                            icon={
                              <Avatar
                                alt="test avatar"
                                src={
                                  value === "1" ? flightIcon : blackflightIcon
                                }
                              />
                            }
                            label="Flights"
                            value="1"
                          />
                          <Tab
                            icon={
                              <Avatar
                                alt="test avatar"
                                src={value === "2" ? hotelIcon : blackhotelIcon}
                              />
                            }
                            label="Hotels"
                            value="2"
                          />
                        </TabList>
                      </div>

                      <TabPanel value="1">
                        <FlightSearch {...props} />
                      </TabPanel>
                      <TabPanel value="2">
                        <HotelSearch {...props} />
                      </TabPanel>
                    </TabContext>
                  );
                }}
              />
              <Route
                render={() => {
                  // return <FlightSearchSection {...props} />;
                  return (
                    <TabContext value={value}>
                      <div
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                          marginTop: "36px",
                        }}
                      >
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                        >
                          <Tab
                            label="Flights"
                            value="1"
                            icon={
                              <Avatar
                                alt="test avatar"
                                src={
                                  value === "1" ? flightIcon : blackflightIcon
                                }
                              />
                            }
                          />
                          <Tab
                            label="Hotels"
                            value="2"
                            icon={
                              <Avatar
                                alt="test avatar"
                                src={value === "2" ? hotelIcon : blackhotelIcon}
                              />
                            }
                          />
                        </TabList>
                      </div>
                      <TabPanel value="1">
                        <FlightSearch {...props} />
                      </TabPanel>
                      <TabPanel value="2">
                        <HotelSearch {...props} />
                      </TabPanel>
                    </TabContext>
                  );
                }}
              />
            </Switch>
          </div>
        </div>
      )}
      <div className="container-1280" style={{ zIndex: 0 }}>
        <div className="section-container zi">
          <OfferSection />
        </div>


        <div className="section-container zi" style={{ zIndex: 0 }}>
          <DomesticPackage data={domesticPackageCategory} />
        </div>
        {internationalPackageCategory && (
          <div className="section-container zi">
            <ExplorePackage data={internationalPackageCategory} />
          </div>
        )}
        <div className="section-container zi" style={{ zIndex: 0 }}>
          <SuitServices />
        </div>

        <div className="section-container zi">
          <HomepageContactUs />
        </div>
        <div className="section-container zi">
          <WhyChooseUs />
        </div>
      </div>
    </Container>
  );
};

export default HomePage;

const Container = styled.div`
  min-height: 80vh;
  position: relative;
  background-color: #F8F5F5;
  width: 100%;
  .MuiTabs-root {
    position: absolute;
    top: 175px;
    // top: 15.5vh;
    z-index: 2;
    left: 44px;
    background-color: #fff;
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 10px;
  }
  .tab-container {
    max-width: 1400px;
    & .MuiTab-wrapper{
      font-weight: 700;
      font-family: poppins;
      text-transform: capitalize;
      display: flex;
      flex-direction: row;
    }
    & .MuiAvatar-root{
      height: 20px;
      width: 20px;
      margin-right: 10px;
      overflow: visible;
    }
    & .MuiTab-root {
      min-height: 30px;
    }
    & .Mui-selected {
      background-color: #33B3A6;
      color: #fff;
      border-radius: 10px;
    }
    & .MuiTabs-indicator {
      background-color: #fff;
    }
  }

  
//   background-color: ${(prop) => prop.theme.palette.background.secondary};
  display: flex;
  flex-direction: column;
  align-items: center;
  .top-bg-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    & .rec-item-wrapper {
      // height: 230px;
      width: 100vw !important;
      & img {
        width: 100vw !important;
      }
    }
    & .rec-slider-container {
 margin-left: 0px;
 margin-right: 0px;
    }
  }
  .vector-top-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    & img {
      height: 8.6rem;
    }
  }
  .container-1280 {
    max-width: 1280px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    &   .section-container {
      width: 90%;
    }
  }
  .mainsearch-container {
    display: flex;
    // align-items: center;
    margin-top: 200px !important;
    // margin-top: 18vh !important;
    justify-content: space-between;
    & .search__box {
      z-index: 3;
    }
  }
  .section-container {
    padding: 0;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    // max-width: 1280px;
    // width: 80%;
    & .MuiTabs-scroller {
      height: 50px;
      & .MuiAvatar-root{
          margin-bottom: 0px !important;
      }
    }

    & .bannerText {
      // right: 20px;
      // top: 360px;
      // position: absolute;
      // -webkit-text-stroke: .7px #000;
      width: 600px;
      margin-top: 70px;
      @media all and (max-width: 1300px) {
        width: 400px;
      }
      & p {
        text-align: center;
        // font-size: 48px;
        // font-family: ${(prop) => prop.theme.palette.font.secondary};
        font-family: Garamond;
        font-weight: 300;
        color: ${(prop) => prop.theme.palette.text.light};
      }
    }
    & .bannerTextTitle {
      text-transform: uppercase;
      font-size: 50px;
      @media all and (max-width: 1400px) {
        font-size: 40px;
      }
      @media all and (max-width: 1300px) {
        font-size: 38px;
      }
      @media all and (max-width: 1130px) {
        font-size: 33px;
      }
    }
    & .bannerTextSubText{
      font-size: 45px;
      @media all and (max-width: 1400px) {
        font-size: 35px;
      }
      @media all and (max-width: 1300px) {
        font-size: 35px;
      }
      @media all and (max-width: 1130px) {
        font-size: 30px;
      }
    }
    &.search-section {
      padding: 50px 0;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      // margiin
      // -height: 762px;
      // height: 85vh;

      height: 800px;
      margin-top: 0px;

      & .search-bg-container {
        position: absolute;
    
        top: -63px;
        left: 0;
        right: 0;
        z-index: 0;
        background-color: red;
        & img {
          width: 100%;
        }
      }
      & .bottom-right-frame-container {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
        & img {
          width: 615px;
        }
      }
      & .frame-vector-top-container {
        position: absolute;
        top: -104px;
        right: 0;
        left: 0;
        z-index: 2;
        & img {
          width: 100%;
        }
      }
      & .frame-vector-bottom-container {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        & img {
          width: 100%;
        }
      }
      & .frame-pix-container {
        position: absolute;
        top: -35px;
        right: -1px;
        z-index: 3;
        & img {
          height: 658px;
        }
      }
      & .bottom-edge-vector {
        position: absolute;
        z-index: 0;
        bottom: -34px;
        & img {
          width: 100%;
        }
      }
      & .form-wrapper {
        margin: 0 0 50px;
      }
      & .carousel-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        & .rec-carousel-wrapper, .rec-carousel, .rec-slider-container, .rec-slider, .rec-swipable, .rec-item-wrapper {
          width: 100%;
          height: 800px;
        }
        & .rec-carousel-wrapper {
          position: relative;
        }
        & .rec-slider-container {
          margin: 0 !important;
        }
        & .carousel-image-component {
          width: 100%;
          // height: 85vh;
          height: 800px;
          position: relative;
          & .bg-overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0,0,0,0.3);
          }
        }
        & .rec-pagination {
          position: absolute;
          bottom: 5px;
          display: flex;
          align-items: center;
        }
        & .rec-dot {
          box-shadow: 0 0 5px 5px rgba(0,0,0,0.3);
          height: 6px;
          width: 6px;
          &.rec-dot_active {
            background-color: rgba(255,255,255,.5)
          }
        }
      }
    }
  }
  .zi {
    z-index: 1;
    position: relative;
  }
  .zi-4 {
    z-index: 4;
    position: relative;
  }
  .section-content {
    & .search__box {
      // z-index: 11;
      position: relative;
    }
  }
  .MuiFormControl-root {
    margin-top: 0;
  }
  .explore-package-section {
    margin-top: 170px;
  }
  // @media all and (max-width: 1279px) {
  //   .top-bg-container {
  //     display: none;
  //   }
  // }
  // @media all and (max-width: 1228px) {
  //   .min-1228 {
  //     display: none !important;
  //   }
  //   .section-container {
  //     display: flex;
  //     flex-direction: column;
  //     align-items: center;
  //     &.search-section {
  //       min-height: 873px;
  //       & .frame-vector-top-container {
  //         top: 3px;
  //       }
  //       & .frame-pix-container {
  //         top: 215px;
  //         right: -70px;
  //       }
  //     }
  //   }
  //   .bannerText {
  //     width: 100%;
   
  //   }
  // }
  @media all and (max-width: 991px) {
    .section-container {
      // &.search-section {
      //   & .frame-vector-top-container {
      //     top: 0;
      //   }
      // }
    }
    .section-container .bannerText p {
      font-size: 1.7rem;
    }
  }
  // @media all and (max-width: 959px) {
  //   .section-container {
  //     background: ${(prop) => prop.theme.palette.background.default};
  //   }
  // }
  @media all and (max-width: 768px) {
    .search__box {
      width: 100%;
      padding: 0 5px;
    }
  }
  @media all and (max-width: 630px) {
    .section-container {
      &.search-section {
        & .frame-pix-container {
          display: none;
        }
        & .frame-vector-top-container {
          top: -3px;
        }
        & .bottom-right-frame-container img {
          width: 260px;
        }
      }
    }
  }
`;
