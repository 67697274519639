import React from "react";
import { Autocomplete } from "@material-ui/lab";
import styled from "styled-components";
import { useTheme } from "@material-ui/core";

const LeftAdornedAutoComplete = (props) => {
  const theme = useTheme();
  return (
    <RootContainer className="location-input-container" theme={theme}>
      <div className="adornment-icon">
        <img src={props.adnicon} />
      </div>
      <div className="input-wrapper">
        <Autocomplete {...props} />
      </div>
    </RootContainer>
  );
};

export default LeftAdornedAutoComplete;

const RootContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    background: ${(prop) => prop.theme.palette.background.default};
    border-radius: 8px;
    padding: 6px 8px;
    height: 45px;
    .adornment-icon {
        width: 40px;
        text-align: center;
        & img {
            height: 27px;
        }
    }
    .input-wrapper {
        flex-grow: 1;
    }
    .MuiInput-input {
        color: ${(prop) => prop.theme.palette.background.defaultContrast};
        font-family: ${(prop) => prop.theme.palette.font.secondary};
        font-weight: 600;
    }
    .MuiInput-underline:after, & .MuiInput-underline:hover:not(.Mui-disabled):before, .MuiInput-underline:before {
        border-bottom: none;
    }
    .MuiInput-colorSecondary.MuiInput-underline:after {
        border-bottom-color: unset;
    }
    // @media all and (max-width: 1279px) {
    //     border-radius: 25px;
    //     border: solid 1px ${(prop) => prop.theme.palette.background.primary};
    // }
`;
