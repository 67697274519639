import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { useState } from 'react'

const FlightFareType = ({values, setFieldValue}) => {
    const [fareType, setFareType] = useState("Regular");
  return (
    <FormControl component="fieldset">
    <RadioGroup
      aria-label="method"
      name="method"
      value={values.fareType}
      // value={"Regular"}
      onChange={
        (event) => {
          setFareType(event.target.value);
          setFieldValue("fareType", event.target.value);

          // console.log(event.target.value, "VALUE");
        }
        // props.setselectedpaymentmethod(event.target.value)
        // console.log("first")
      }
    >
      <FormControlLabel
        value="Regular"
        control={<Radio />}
        label={`Regular Fare`}
      />
      <FormControlLabel
        value="Student"
        control={<Radio />}
        label={`Student Fare`}
      />
      <FormControlLabel
        value="SeniorCitizen"
        control={<Radio />}
        label={`SeniorCitizen Fare`}
      />
    </RadioGroup>
  </FormControl>
  )
}

export default FlightFareType
