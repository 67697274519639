import { useTheme, Grid } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import companyLogo from "assets/Tickat/images/logo-footer.png";
import addressIcon from "assets/Tickat/images/icons/addressIcon.png";
import phoneIcon from "assets/Tickat/images/icons/phoneIcon.png";
import emailIcon from "assets/Tickat/images/icons/emailIcon.png";
import facebookIcon from "assets/Tickat/images/icons/Icon-awesome-facebook-f.png";
import instagramIcon from "assets/Tickat/images/icons/Icon-awesome-instagram.png";
import twitterIcon from "assets/Tickat/images/icons/Icon-awesome-twitter.png";
import linkedinIcon from "assets/Tickat/images/icons/Icon-awesome-linkedin-in.png";

import masterCard from "assets/payments/master.png";
import american from "assets/payments/american.png";

import visa from "assets/payments/visa.png";
import rupay from "assets/payments/rupay.png";
import IATA from "assets/iata.png";
//import TAAFI from "assets/taafi_logo.png";

import { AppConstant } from "appConstant";
import travlogy_logo from "assets/travlogy/images/travlogy_logo_white.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PageFooter = (props) => {
  const theme = useTheme();
  const history = useHistory();

  return (
    <RootContainer theme={theme}>
      <div className="footer-container">
        <div className="footer-column company-info">
          <img className="company-brand" src={companyLogo} />
          <div className="company-info-item address-item">
            <img src={addressIcon} className="address-icon" />
            <p>{AppConstant.address.office}</p>
          </div>
          <div className="company-info-item">
            <img src={phoneIcon} />
            <p>{AppConstant.supportContact}</p>
          </div>
          <div className="company-info-item">
            <img src={emailIcon} />
            <p>{AppConstant.supportEmail}</p>
          </div>
        </div>
        <div className="rightfooter-container">
          <div className="top-footer">
            <div className="top-footer-containers" style={{ display: "flex" }}>
              <div className="footer-column website-links">
                <p className="website-links-header">Services</p>
                <ul className="website-links-list">
                  <li>
                    <a onClick={() => history.push("/flights")}>Flight</a>
                  </li>
                  <li>
                    <a onClick={() => history.push("/hotels")}>Hotels</a>
                  </li>
                  {/* <li>
                    <a onClick={() => history.push("/buses")}>Bus</a>
                  </li> */}
                  <li>
                    <a onClick={() => history.push("/packages")}>Holidays</a>
                  </li>
                  {/* <li>
                            <a>
                                DMC
                            </a>
                        </li>
                        <li>
                            <a>
                                Visa
                            </a>
                        </li> */}
                </ul>
              </div>
              <div className="footer-column website-links">
                <p className="website-links-header">About</p>
                <ul className="website-links-list">
                  {/* <li>
                            <a>
                                How to book
                            </a>
                        </li>
                        <li>
                            <a>
                                Help Center
                            </a>
                        </li> */}
                  <li>
                    <a onClick={() => history.push("/contactus")}>Contact Us</a>
                  </li>
                  {/* <li>
                            <a>
                                Careers
                            </a>
                        </li> */}
                  <li>
                    <a onClick={() => history.push("/aboutus")}>About Us</a>
                  </li>
                </ul>
              </div>

              <div className="footer-column website-links">
                <div className="website-links-header">Payment Mode</div>
                <div className="card-lists">
                  <img src={visa} />
                  <img src={masterCard} />
                  <img src={american} />
                  <img style={{marginLeft: 4}} src={rupay} />
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-footer">
            <div
              className="bottom-footer-containers"
              style={{ display: "flex" }}
            >
              <div className="footer-column website-links">
                <p className="website-links-header">Support</p>
                <ul className="website-links-list">
                  <li>
                    <a onClick={() => history.push("/privacypolicy")}>
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a onClick={() => history.push("/termsandconditions")}>
                      Terms and Conditions
                    </a>
                  </li>
                  <li>
                    <a onClick={() => history.push("/refundandcancellation")}>
                      Refund and Cancellation Policy
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer-column website-links">
                <div className="iata-icons">
                  <img src={IATA} className="iataIcon" />
                  {/* <img src={TAAFI} className="iataIcon" /> */}

                  {/* <img src={IATA} /> */}
                </div>
              </div>
              <div className="footer-column website-links footer-column get-in-touch">
                {/* <p>
                        Question or feedback?<br/>
                        We'd love to hear from you
                    </p>
                    <div className="your-email-form">
                        <EmailForm/>
                    </div> */}
                <div className="follow-us">
                  <span className="website-links-header">Follow us</span>
                  <span className="social-icons">
                    <span>
                      <img src={facebookIcon} />
                    </span>
                    <span>
                      <img src={instagramIcon} />
                    </span>
                    <span>
                      <img src={twitterIcon} />
                    </span>
                    <span>
                      <img src={linkedinIcon} />
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-container copyright">
        <div className="col-md-4 col-sm-12">
          {/* Mydonetrip © 2022 | */}
          <p className="handcrafted-text">
            Hand Crafted by
            <a href="http://travlogy.co" target="_blank">
              <img src={travlogy_logo} style={{ height: 15 }} />
            </a>
          </p>
        </div>
        <div className="col-md-8 col-sm-12">
          <p className="copyright-text">
            ©2024 {AppConstant.name} All rights reserved.
          </p>
        </div>
      </div>
    </RootContainer>
  );
};

export default PageFooter;

const RootContainer = styled.div`
    width: 100%;
    background: #11263B;
    border-top: 1px solid ;#005089
    z-index: 1;
    font-family: ${(prop) => prop.theme.palette.font.primary};
    .website-links-list {
      min-width: 150px;
      // @media all and (max-width: 700px) {
      //   width: 100%;
      // }
    }
    .website-links{
      @media all and (max-width:900px) {
          width: 30% !important;
        }
        @media all and (max-width: 560px) {
          width: 100% !important;
          display: flex;
          flex-direction: column;
          align-items: center;

        }
    }
    .website-links-list {
      @media all and (max-width: 560px) {
        min-width: fit-content;
        text-align: center;
      }
    }
    .footer-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        & .rightfooter-container {
            width: 70%;
            @media all and (max-width: 959px) {
              width: 100%;
            }
        }
        & .website-links {

          width: fit-content;
            // width: 20%
            // width: 200px;
        }
        & .top-footer {
            display: flex;
            border-bottom: 1px solid #ddd;
            justify-content: space-between;
            padding-right: 20px;
            @media all and (max-width: 959px) {
              justify-content: center;
            }
        }
        & .iata-icons { 
            display: flex;
            align-items: center;
            // width: 300px;
        }
        & .iataIcon {
            height: 60px;
            width: 60px;
            border-radius: 20%;
            margin: 5px;
        }
        & .bottom-footer {
            display: flex;
            justify-content: space-between;
            padding-right: 20px;
            @media all and (max-width: 959px) {
              justify-content: center;
            }
        }
    }
    .footer-column {
        padding: 25px 30px;
        & .
    }
    .company-info {
        width: 30%;
        border-right: 1px solid #888;
        & .card-lists {
            & img {
              margin-right: 4px;
            }
        }

    }
    .company-info-item {
        display: flex;
        gap: 10px;
        margin: 8px 0 0;
        & p {
            /*line-height: 1.7;*/
            color: #fff;
        }
        & img {
            height: 20px;
            &.address-icon {
                height: 25px;
            }
        }
    }
    .website-links {
        & .website-links-header {
            color: ${(prop) => prop.theme.palette.text.primaryContrast};
            font-weight: 600;
        }
        & ul {
            list-style: none;
            padding-inline-start: 0;

            & a {
                cursor: pointer;
                color: ${(prop) => prop.theme.palette.text.primaryContrast};
                line-height: 1.7;
            }
        }
    }
    .get-in-touch {
        width: 25%;
        & p {
            line-height: 1.9;
        }
        & .your-email-form {
            margin: 15px 0 25px;
        }
        & .follow-us {
           /* display: flex;
            align-items: center;*/
            gap: 10px;
            margin: 0 0 15px;
            & .label {
                color: ${(prop) => prop.theme.palette.text.main};
            }
            & .social-icons {
                width:100px;
                display: flex;
                align-items: center;
                margin-top: 10px;
                justify-content: space-evenly;
                gap: 10px;
                & a {
                    cursor: pointer;
                }
            }
        }
    }
    .copyright {
        // marginTop: "2rem",
        display: "flex",
        //   border: "1px solid #000",
        justifyContent: "space-between",
        //  marginTop: 40,
        // backgroundColor: "#13161d ",
        [theme.breakpoints.down(960)]: {
            display: "block",
            
          },
    }
    .handcrafted-text{
        padding: 5px;
        font-size: 10px;
        border-radius: 5px;
        & a img {
            padding-left: 5px;
            font-size: 14px;
            font-weight: 300;
            text-transform: none;
        }
    }
    .copyright-text {
        padding: 5px;
        font-size: 12px;
        border-radius: 5px;
        padding-right:60px;
        @media all and (max-width: 560px) {
          padding-right:0px;
        }
    }
    @media all and (max-width: 959px) {
      .rightfooter-container{
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .top-footer{
        width: 90%;
        justify-content: space-between;
      }
      .bottom-footer{
        width: 90%;
        justify-content: space-between;
      }
      .top-footer-containers{
        // width: 80%
        justify-content: space-between;
        @media all and (max-width: 959px) {
          width: 100%
        }
        @media all and (max-width: 560px) {
          flex-direction: column;
          align-items: center;
        }
      }
      .bottom-footer-containers{
        // width: 80%
        justify-content: space-between;
        @media all and (max-width: 959px) {
          width: 100%
        }
        @media all and (max-width: 560px) {
          flex-direction: column;
          align-items: center;
        }
      }
        .footer-container {
            flex-wrap: wrap;
            & .company-info {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                border-bottom: solid 2px ${(prop) =>
                  prop.theme.palette.background.primary};
                // margin: 0 20px;
                & .company-info-item {
                    justify-content: center;
                    padding: 0 10px;
                    gap: 0;
                    margin: 0;
                    &:last-child {
                        border-left: solid 1px #000;
                    }
                    & img {
                        margin-right: 5px;
                        height: 20px;
                        &.address-icon {
                            height: 25px;
                        }
                    }
                    & p {
                        line-height: unset;
                    }
                    &.address-item {
                        p {
                            padding: 3px 0 0;
                        }
                    }
                }
                & .company-brand {
                    text-align: center;
                    width: unset;
                }
                & .address-item {
                    width: 100%;
                }
            }
            & .company-brand {
                width: 100%;
            }
            & .get-in-touch {
                width: 100%;
                & .follow-us {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media all and (max-width: 500px) {
                      flex-direction: column;
                  }
                    & .social-icons {
                        margin-top: 0;
                    }
                
                }
            }
        }
    }
    @media all and (max-width: 796px) {
        .footer-container {
            & .company-info {
                & .company-info-item.address-item p {
                    
                    text-align: center;
                }
            }
            &.copyright {
                background: ${(prop) =>
                  prop.theme.palette.background.secondary};
                & div {
                    width: 100%;
                    text-align: center;
                    & p {
                        color: ${(prop) =>
                          prop.theme.palette.background.secondaryContrast};
                    }
                }
            }
        }
    }
    @media all and (max-width: 538px) {
        .website-links {
            padding: 15px 20px;
            // width: 33.3%;
        }
        
    }
`;
