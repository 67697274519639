import React, { useState } from 'react'
import {

    TextField,

  } from "@material-ui/core";
import RoomModal from '../RoomModal';
  

const RoomsGuests = ({values, setFieldValue}) => {
    const [roomModalOpen, setRoomModalOpen] = useState(false);
    const [childError, setChildError] = useState(false);


    const validateChildAge = (rooms) => {
        return rooms.every((room) => {
          if (room.noOfChild > 0) {
            return room.childAge.every((age) => age > 0);
          } else return true;
        });
      };

  return (
    <>
    <TextField
    className="room-modal-text"
    // label="Rooms"
    color="secondary"
    value={`${values.roomGuests.length} ${
      values.roomGuests.length == 1 ? "Room" : "Rooms"
    },  ${values.roomGuests.reduce(function(a, b) {
      return (
        Number(a) + Number(b.noOfAdults) + Number(b.noOfChild)
      );
    }, 0)} Guests`}
    fullWidth
    onClick={() => setRoomModalOpen(true)}
  />
  {roomModalOpen && (
    <div className="room-modal-open-dev">
      <div className="modal-paper">
        <RoomModal
          values={values}
          setFieldValue={setFieldValue}
          rooms={values.roomGuests}
          childError={childError}
          setChildError={setChildError}
          closeModal={() => {
            if (validateChildAge(values.roomGuests)) {
              setRoomModalOpen(false);
              setChildError(false);
            } else {
              setChildError(true);
            }
          }}
        />
      </div>
    </div>
  )}
   </>
  )
}

export default RoomsGuests
