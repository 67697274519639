import React, { useEffect, useState, useRef, useContext } from "react";
import styled from "styled-components";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  useTheme,
} from "@material-ui/core";
import {
  Box,
  Grid,
  TextField,
  Button,
  Fade,
  Backdrop,
  Modal,
  withWidth,
  Typography,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import RoomModal from "./RoomModal";
import OaFormAlerts from "pages/components/OaFormAlerts";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  setHotelCountry,
  setHotelCity,
  setHotelSearch,
  setHotelBookingInitialTime,
} from "redux/action";
import _ from "lodash";
import differenceInDays from "date-fns/differenceInDays";
import dateFnsFormat from "date-fns/format";
import { AppStateContext } from "layouts/AppStateProvider";
import { COLORS, FONTS } from "assets/css/CssConstants";
import { localforageGetItem } from "oautils/oaForageUtils";
import OaOutlinedDatePicker from "oahoc/OaOutlinedDatepicker";
import OaOutlinedAutoComplete from "oahoc/OaOutlinedAutoComplete";
import cx from "classnames";
import locationPinIcon from "assets/Tickat/images/home_pix/location_pin_icon.png";
import calendarIcon from "assets/Tickat/images/home_pix/calendar_icon.png";
import LeftAdornedAutoComplete from "./formControls/LeftAdornedAutoComplete";
import LeftAdornedDatePicker from "./formControls/LeftAdornedDatePicker"
import HotelLocationCityInput from "./formControls/HotelLocationCityInput"

import HotelLocationCountryInput from "./formControls/HotelLocationCountryInput"
import HotelDateInput from "./formControls/HotelDateInput"
import { useHistory } from "react-router-dom";
import RoomsGuests from "./formControls/RoomsGuests";

export class RoomObj {
  constructor() {
    this.noOfAdults = 1;
    this.noOfChild = 0;
    this.childAge = [];
  }
}

const HotelSection = (props) => {
  const today = new Date();
  const theme = useTheme();
  const history = useHistory();

  const locationAutocompleteRef = useRef(null);
  const [departureDateDialogOpen, setDepartureDateDialogOpen] = useState(false);
  const [arrivalDateDialogOpen, setArrivalDateDialogOpen] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [childError, setChildError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [roomModalOpen, setRoomModalOpen] = useState(false);
  const formikRef = React.useRef();
  const { setSecLogo } = useContext(AppStateContext);
  const [refreshLocation, setRefreshLocation] = useState(false);
  const [userType, setUserType] = useState(null);
  const [corporateType, setCorporateType] = useState(null);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [callingApi, setCallingApi] = useState(false);
  const isMounted = React.useRef(false);

  let baseUrl = "/";

  let optionsHotel = [
    {
      name: "searchHotel",
      label: <Typography className="typo1">Search Hotel</Typography>,
      url: baseUrl + "hotels",
      icon: <i className="fas fa-search"></i>,
    },
    {
      name: "hotelReport",
      label: <Typography className="typo1">Hotel Report</Typography>,
      url: "/admin/booking-report/hotel",
      icon: <i className="fas fa-dollar-sign"></i>,
    },
  ];

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const initialValues = {
    countryCodeObj: {
      name: "India",
      code: "IN",
    },
    countryCode: "IN",
    cityObj: popularCities[0],
    cityId: popularCities[0].destinationId,
    checkInDate: dateFnsFormat(new Date(), "dd/MM/yyyy"),
    checkInDateDisplay: new Date(),
    isIslandHopper: "false",
    checkOutDate: dateFnsFormat(
      new Date(new Date().setDate(new Date().getDate() + 1)),
      "dd/MM/yyyy"
    ),
    checkOutDateDisplay: new Date(new Date().setDate(new Date().getDate() + 1)),

    preferredCurrency: "INR",
    roomGuests: [new RoomObj()],
    guestNationality: "IN",
    guestNationalityObj: {
      name: "India",
      code: "IN",
    },
  };

  const validationSchema = Yup.object().shape({
    countryCode: Yup.string().required("country Field is Required"),
    cityId: Yup.string().required("Location Field is Required"),
    checkInDate: Yup.string().required("Check In Date Field is Required"),
    checkOutDate: Yup.string().required("Check Out Date Field is Required"),
    // preferredCurrency: Yup.string().required(
    //   "Preferred Currency Field is Required"
    // ),
    guestNationality: Yup.string().required(
      "Guest Nationality Field is Required"
    ),
  });

  const cityHotelChangeHandler = (searchType, obj) => {
    let countryCode = "";
    if (obj != null) {
      countryCode = obj.code;
    } else {
      const countryCodeObj = formikRef.current.values.countryCode;
      countryCode = countryCodeObj?.code;
    }
  };

  const fetchMatchingCities = (searchType, searchParam, countryCode) => {
    // console.log("hotel city search");

    if (searchParam.length < 3) {
      return;
    }

    setCallingApi(true);
    WebApi.getMatchingHotelCity(
      {
        searchType: searchType,
        countryCode: countryCode || "IN",
        hotelCity: searchParam,
      },
      (response) => {
        if (response.success === true) {
          // console.log("hotel city search", searchParam);
          // console.log("matching cities response", response);
          props.setHotelCity(response.data.destinations);
        } else {
          setIsError(!response.success);
          setInfoText(response.message);
        }
        setCallingApi(false);
      },
      (error) => {
        setCallingApi(false);
        console.log("getMatchingHotelCity error", error);
      }
    );
  };

  const openQuickLink = (url) => {
    // console.log("quicklink", url);
    history.push({
      pathname: url,
    });
  };

  const validateChildAge = (rooms) => {
    return rooms.every((room) => {
      if (room.noOfChild > 0) {
        return room.childAge.every((age) => age > 0);
      } else return true;
    });
  };

  const onSubmitHotelSearch = (values) => {
    // history.push('/hotel/payment-status/146331')
    // return;
    // values.countryCode = values.countryCode.code;
    // const cityObj = values.cityId;
    // values.cityId = cityObj.destinationId;
    if (validateChildAge(values.roomGuests)) {
      setChildError(false);
      values.city = values.cityObj.cityName;
      // values.guestNationality = values.guestNationality.code;

      values.noOfNights = differenceInDays(
        values.checkOutDateDisplay,
        values.checkInDateDisplay
      );
      values.noOfNights = values.noOfNights > 0 ? values.noOfNights : 1;
      // values.checkInDate = dateFnsFormat(values.checkInDate, "dd/MM/yyyy");
      // values.checkOutDate = dateFnsFormat(
      //   values.checkOutDate,
      //   "dd/MM/yyyy"
      // );
      values.noOfRooms = values.roomGuests.length;

      //  searchParams.resultCount = 10;
      values.maxRating = 5;
      values.minRating = 0;
      values.isTBOMapped = true;
      //  searchParams.isTBOMapped = 1;
      //  searchParams.isCompactdata = 1;
      props.setHotelSearch(values);

      props.setHotelBookingInitialTime(null);
      window.sessionStorage.removeItem("service");
      // setSecLogo(true);
      history.push("/hotel/search");
    } else {
      setChildError(true);
    }
  };

  React.useEffect(() => {
    isMounted.current = true;
    props.setHotelCity(popularCities);
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (isMounted.current) {
      localforageGetItem("user-details", function(err, details) {
        if (details) {
          setUserType(details.userType);
          setCorporateType(details.corporateType);
        }
      });
    }
  }, [isMounted.current]);

  React.useEffect(() => {
    if (isLoading) {
      // console.log(0);
      const length = props.hotelCountry
        ? Object.keys(props.hotelCountry).length
        : 0;

      const lengthCity = props.hotelCity
        ? Object.keys(props.hotelCity).length
        : 0;

      if (length > 0 && lengthCity > 0) {
        const countryCodeObj = {
          name: props.hotelCountry?.filter(
            (country) => country.code == props.hotelCity[0].countryCode
          )[0].name,
          code: props.hotelCity[0].countryCode,
        };

        formikRef.current.setFieldValue("countryCode", countryCodeObj.code);
        formikRef.current.setFieldValue("countryCodeObj", countryCodeObj);
        formikRef.current.setFieldValue("guestNationality", "IN");
        formikRef.current.setFieldValue("guestNationalityObj", countryCodeObj);

        setIsLoading(false);
      } else {
        // console.log(1);
        WebApi.getHotelCountry(
          {},
          (response) => {
            if (response.success === true) {
              setIsLoading(false);
              props.setHotelCountry(response.data.countryList.country);
              // setHotelCountry(response.data.countryList.country);
              // formikRef.current.setFieldValue("countryCode", {
              //   name: "India",
              //   code: "IN",
              // });
              // formikRef.current.setFieldValue("guestNationality", {
              //   name: "India",
              //   code: "IN",
              // });
              cityHotelChangeHandler(1, {
                name: "India",
                code: response.data.countryList.country, //"IN",
              });
            } else {
              setIsError(!response.success);
              setInfoText(response.message);
            }
          },
          (error) => console.log("getHotelCountry error", error)
        );
      }
    }
  }, [isLoading]);

  return (
    <RootContainer theme={theme}>
      <div className="notification-container">
        <OaFormAlerts
          isSaving={isSaving}
          isSuccess={isSuccess}
          isError={isError}
          infoText={infoText}
          setIsError={setIsError}
          setIsSuccess={setIsSuccess}
        />
      </div>
      <div className="formik-wrapper">
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmitHotelSearch}
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            <Form>
              <div className="form-wrapper">
                <div className="flex-row">
                  <div className="form-group-wrapper w50p">
                    <label>Country</label>
                    <HotelLocationCountryInput setFieldValue={setFieldValue} values={values} props={props} formikRef={formikRef} />
                    {/* <LeftAdornedAutoComplete
                      className="form-control"
                      name={`countryCodeObj`}
                      options={props.hotelCountry || []}
                      getOptionLabel={(a) => a.name || ""}
                      value={values.countryCodeObj}
                      getOptionSelected={(option, value) =>
                        option.value == value.code
                      }
                      disableClearable
                      onChange={
                        (_, newValue) => {
                          setFieldValue("countryCodeObj", newValue);
                          setFieldValue("countryCode", newValue.code);
                          cityHotelChangeHandler(1, newValue);
                          props.setHotelCity([]);
                          setFieldValue("cityId", "");
                          //   setFieldValue("guestNationalityObj", newValue);
                          //   setFieldValue("guestNationality", newValue.code);

                          WebApi.getHotelCityListForCountry(
                            {
                              clientId: "",
                              countryCode: newValue.code,
                              endUserIp: "",
                              hotelCity: "",
                              searchType: "",
                              tokenId: "",
                            },

                            (response) => {
                              if (response.success === true) {
                                // console.log(response.data.destinations);
                                props.setHotelCity(
                                  response?.data?.destinations ?? []
                                );
                                // console.log(
                                //   response.data.destinations[0].destinationId
                                // );
                                setFieldValue(
                                  "cityObj",
                                  response.data.destinations[0]
                                );
                                setFieldValue(
                                  "cityId",
                                  response.data.destinations[0].destinationId
                                );
                                setIsLoading(false);
                              } else {
                              }
                            },
                            (error) =>
                              console.log("getHotelCountry error", error)
                          );
                        }
                        // setData((prevState) => ({
                        //   ...prevState,
                        //   country: newValue,
                        // }))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          color="secondary"
                          // label="Country"
                        />
                      )}
                      disablePortal={true}
                      // adnicon={locationPinIcon}
                    /> */}
                    <ErrorMessage
                      name="countryCode"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="form-group-wrapper w50p">
                    <label>Where</label>
                    {/* <LeftAdornedAutoComplete
                      className="form-control"
                      name={`cityObj`}
                      options={props.hotelCity || []}
                      getOptionLabel={(a) => (a && a.cityName) || ""}
                      value={values.cityObj}
                      loading={callingApi}
                      getOptionSelected={(option, value) =>
                        option.value == value.destinationId
                      }
                      onKeyUp={(event) =>
                        fetchMatchingCities(
                          1,
                          event.target.value,
                          values.countryCode
                        )
                      }
                      onChange={(_, newValue) => {
                        setFieldValue("cityObj", newValue);
                        setFieldValue("cityId", newValue.destinationId);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          color="secondary"
                          // label="Location"
                          inputRef={locationAutocompleteRef}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                          placeholder="Enter City"
                        />
                      )}
                      // adnicon={locationPinIcon}
                    /> */}
                    <HotelLocationCityInput setFieldValue={setFieldValue} values={values} props={props} locationAutocompleteRef={locationAutocompleteRef} />
                    <ErrorMessage
                      name="cityId"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>

                <div className="flex-row">
                  <div className="form-group-wrapper w50p">
                    <label>Check-In</label>
                    {/* <LeftAdornedDatePicker
                      showDisabledMonthNavigation
                      name="checkInDateDisplay"
                      className="date-picker"
                      minDate={startDate}
                      customInput={
                        <TextField id="standard-basic" variant="standard" />
                      }
                      value={values.checkInDateDisplay}
                      onChange={(e, v) => {
                        setFieldValue("checkInDateDisplay", e);
                        setFieldValue(
                          "checkInDate",
                          dateFnsFormat(e, "dd/MM/yyyy")
                        );
                        setFieldValue(
                          "checkOutDateDisplay",
                          new Date(e.getTime() + 86400000)
                        );
                        setFieldValue(
                          "checkOutDate",
                          dateFnsFormat(
                            new Date(e.getTime() + 86400000),
                            "dd/MM/yyyy"
                          )
                        );
                        setDepartureDateDialogOpen(false);
                        setArrivalDateDialogOpen(true);
                        // setEndDate(dateFnsFormat(e, "dd/MM/yyyy"));
                      }}
                      KeyboardButtonProps={{
                        onFocus: (e) => {
                          setDepartureDateDialogOpen(true);
                        },
                      }}
                      PopoverProps={{
                        disableRestoreFocus: true,
                        onClose: () => {
                          setDepartureDateDialogOpen(false);
                        },
                      }}
                      InputProps={{
                        onFocus: () => {
                          setDepartureDateDialogOpen(true);
                        },
                      }}
                      monthsShown={2}
                      dateFormat="dd MMM yyyy"
                      labeltxt="CHECK-IN"
                      selected={values.checkInDateDisplay}
                      // adnicon={calendarIcon}
                    /> */}
                    <HotelDateInput type="checkIn" values={values} setFieldValue={setFieldValue} setDepartureDateDialogOpen={setDepartureDateDialogOpen} setArrivalDateDialogOpen={setArrivalDateDialogOpen} />
                    <ErrorMessage
                      name={`checkInDate`}
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="form-group-wrapper w50p">
                    <label>Check-Out</label>
                       <HotelDateInput type="checkOut" values={values} setFieldValue={setFieldValue} setDepartureDateDialogOpen={setDepartureDateDialogOpen} setArrivalDateDialogOpen={setArrivalDateDialogOpen} />
                    {/* <LeftAdornedDatePicker
                      showDisabledMonthNavigation
                      name="checkOutDateDisplay"
                      className="date-picker"
                      minDate={values.checkInDateDisplay}
                      customInput={
                        <TextField id="standard-basic" variant="standard" />
                      }
                      value={values.checkOutDateDisplay}
                      onChange={(e, v) => {
                        setFieldValue("checkOutDateDisplay", e);
                        setFieldValue(
                          "checkOutDate",
                          dateFnsFormat(e, "dd/MM/yyyy")
                        );
                        setArrivalDateDialogOpen(false);
                        setEndDate(e);
                      }}
                      KeyboardButtonProps={{
                        onFocus: (e) => {
                          setArrivalDateDialogOpen(true);
                        },
                      }}
                      PopoverProps={{
                        disableRestoreFocus: true,
                        onClose: () => {
                          setArrivalDateDialogOpen(false);
                        },
                      }}
                      InputProps={{
                        onFocus: () => {
                          setArrivalDateDialogOpen(true);
                        },
                      }}
                      monthsShown={2}
                      dateFormat="dd MMM yyyy"
                      labeltxt="CHECK-OUT"
                      selected={values.checkOutDateDisplay}
                      // adnicon={calendarIcon}
                    /> */}
                    <ErrorMessage
                      name={`checkOutDate`}
                      component="div"
                      className="error"
                    />
                  </div>
                </div>

                <div className="flex-row">
                  <div className="form-group-wrapper flex-grow-1 room-modal-wrapper">
                    <label>Rooms & Guests</label>
                    {/* <TextField
                      className="room-modal-text"
                      // label="Rooms"
                      color="secondary"
                      value={`${values.roomGuests.length} ${
                        values.roomGuests.length == 1 ? "Room" : "Rooms"
                      },  ${values.roomGuests.reduce(function(a, b) {
                        return (
                          Number(a) + Number(b.noOfAdults) + Number(b.noOfChild)
                        );
                      }, 0)} Guests`}
                      fullWidth
                      onClick={() => setRoomModalOpen(true)}
                    />
                    {roomModalOpen && (
                      <div className="room-modal-open-dev">
                        <div className="modal-paper">
                          <RoomModal
                            values={values}
                            setFieldValue={setFieldValue}
                            rooms={values.roomGuests}
                            childError={childError}
                            setChildError={setChildError}
                            closeModal={() => {
                              if (validateChildAge(values.roomGuests)) {
                                setRoomModalOpen(false);
                                setChildError(false);
                              } else {
                                setChildError(true);
                              }
                            }}
                          />
                        </div>
                      </div>
                    )} */}
                    <RoomsGuests setFieldValue={setFieldValue} values={values} />
                  </div>
                  <div className="form-group-wrapper submit-wrapper">
                    <Button type="submit">Book Hotel</Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </RootContainer>
  );
};

function mapStateToProps(state, props) {
  return {
    hotelCountry: state.hotel.hotelCountry,
    hotelCity: state.hotel.hotelCity,
    hotelSearch: state.hotel.hotelSearch,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setHotelCountry: (hotelCountry) => dispatch(setHotelCountry(hotelCountry)),
    setHotelCity: (hotelCity) => dispatch(setHotelCity(hotelCity)),
    setHotelSearch: (hotelSearch) => dispatch(setHotelSearch(hotelSearch)),

    setHotelBookingInitialTime: (hotelBookingInitialTime) =>
      dispatch(setHotelBookingInitialTime(hotelBookingInitialTime)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(HotelSection));

const popularCities = [
  {
    cityName: "Delhi",
    countryCode: "IN",
    countryName: "India",
    destinationId: "130443",
    hotelName: null,
    stateProvince: "DL",
    type: null,
  },
  {
    cityName: "Navi Mumbai",
    countryCode: "IN",
    countryName: "India",
    destinationId: "128734",
    hotelName: null,
    stateProvince: "MH",
    type: null,
  },
  {
    cityName: "Bengaluru",
    countryCode: "IN",
    countryName: "India",
    destinationId: "111124",
    hotelName: null,
    stateProvince: "KA",
    type: null,
  },
  {
    cityName: "Goa",
    countryCode: "IN",
    countryName: "India",
    destinationId: "119805",
    hotelName: null,
    stateProvince: "GA",
    type: null,
  },
  {
    cityName: "Chennai",
    countryCode: "IN",
    countryName: "India",
    destinationId: "127343",
    hotelName: null,
    stateProvince: "TN",
    type: null,
  },
  {
    cityName: "Jaipur",
    countryCode: "IN",
    countryName: "India",
    destinationId: "122175",
    hotelName: null,
    stateProvince: "RJ",
    type: null,
  },
];

const RootContainer = styled.div`
  margin: 10px 0 0 20px;
  border-radius: 18px;
  border-top-left-radius: 0px;
  // margin-top: 220px;

  @media all and (max-width: 1400px) {
    width: 700px;
  }
  @media all and (max-width: 1200px) {
    width: 650px;
  }
  @media all and (max-width: 1200px) {
    width: 650px;
  }
  @media all and (max-width: 1080px) {
    width: 600px;
  }
  @media all and (max-width: 1020px) {
    width: 570px;
  }
  width: 800px;
  background: #e9f5f4;
  webkit-box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.6);
  -moz-box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.6);
  box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.6);
  .adornment-icon {
    width: 0px;
  }
  .MuiInput-input {
    color: #838383 !important;
  }
  .form-wrapper {
    padding: 10px 35px;
  }
  .form-group-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 5px 0;
    & label {
      color: ${(prop) => prop.theme.palette.secondary.defaultText};
      font-family: ${(prop) => prop.theme.palette.font.secondary};
      font-weight: 700;
      @media all and (max-width: 1280px) {
        font-size: 12px;
      }
    }
  }
  .form-control {
    &.radio-control {
      flex-direction: row;
      & .MuiRadio-root {
        & .MuiSvgIcon-root {
          color: ${(prop) => prop.theme.palette.secondary.defaultText};
        }
        &.Mui-checked {
          & .MuiSvgIcon-root {
            color: ${(prop) => prop.theme.palette.primary.main};
          }
        }
      }
      & .MuiTypography-root {
        font-size: 14px;
        color: ${(prop) => prop.theme.palette.text.light};
      }
    }
  }
  .swap-icon-wrapper {
    position: absolute;
    top: 94px;
    left: 330px;
    & .swap-button {
      background: ${(prop) => prop.theme.palette.primary.main};
      height: 56px;
      width: 56px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      & img {
        height: 55px;
      }
    }
  }
  .flex-row {
    display: flex;
    gap: 25px;
    margin: 13px 0;
  }
  .w50p {
    width: 50%;
    max-width: 312px;
  }
  .w100p {
    width: 100%;
  }
  .pl15 {
    padding-left: 15px;
  }
  .flight-itinerary-root {
    & label {
      font-weight: 500;
    }
  }
  .p-rtv {
    position: relative;
  }
  .MuiAutocomplete-popper.MuiAutocomplete-popperDisablePortal {
    width: 252px !important;
    & .ac-options-list {
      width: 222px;
      overflow-x: hidden;
      padding: 0 2px;
      & span {
        padding: 0;
        display: flex;
        gap: 10px;
        justify-content: space-between;
        width: 100%;
        & .ac-option-right {
          padding: 0;
        }
      }
      & p {
        white-space: nowrap;
      }
    }
  }
  .submit-wrapper {
    padding-top: 25px;
    & button {
      background: ${(prop) => prop.theme.palette.buttons.primary};
      color: ${(prop) => prop.theme.palette.buttons.primaryContrastText};
      text-transform: uppercase;
      height: 45px;
      border-radius: 8px;
      width: 200px;
      font-weight: 600;
    }
  }
  .align-items-center {
    align-items: center;
  }
  .pos-rel {
    position: relative;
  }
  .flex-grow-1 {
    flex-grow: 1;
  }
  & .room-modal-wrapper {
    position: relative;
    & .MuiTextField-root {
      background: ${(prop) => prop.theme.palette.background.default};
      border-radius: 8px;
      padding: 6px 8px;
      height: 45px;
    }
    & .MuiInputBase-input {
      padding: 5px 10px;
    }
  }
  & .room-modal-open-dev {
    position: absolute;
    top: 70px;
    left: 10px;
    right: 20px;
    background: ${(prop) => prop.theme.palette.background.default};
    border-radius: 8px;
    padding: 6px 8px;
    webkit-box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.6);
    -moz-box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.6);
    box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.6);
    & label {
      color: ${(prop) => prop.theme.palette.background.defaultContrast};
      font-weight: bold;
      font-family: ${(prop) => prop.theme.palette.font.secondary};
    }
    & .MuiSelect-root {
      padding: 5px 10px 5px 80px;
    }
  }
  & .MuiInput-input {
    color: ${(prop) => prop.theme.palette.background.defaultContrast};
    font-family: ${(prop) => prop.theme.palette.font.secondary};
    font-weight: 600;
    font-size: 14px;
    @media all and (max-width: 1280px) {
      font-size: 12px;   
    }
  }
  & .MuiInput-underline:after,
  & .MuiInput-underline:hover:not(.Mui-disabled):before,
  .MuiInput-underline:before {
    border-bottom: none;
  }
  & .MuiInput-colorSecondary.MuiInput-underline:after {
    border-bottom-color: unset;
  }
  // @media all and (max-width: 1279px) {
  //   .room-modal-wrapper {
  //     & .room-modal-text {
  //       border-radius: 25px;
  //       border: solid 1px ${(prop) => prop.theme.palette.background.primary};
  //     }
  //   }
  //   .submit-wrapper {
  //     & button {
  //       border-radius: 25px;
  //       border: solid 1px ${(prop) => prop.theme.palette.background.primary};
  //     }
  //   }
  // }
  // @media all and (max-width: 1228px) {
  //   margin: 10px 0;
  // }
  @media all and (max-width: 768px) {
    width: 100%;
    .flex-row {
      flex-direction: column;
    }
    .w50p {
      width: 100%;
      max-width: 100%;
    }
  }
`;
