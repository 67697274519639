import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import dateFnsFormat from "date-fns/format";
import WebApi from "api/ApiConstants";
import image1 from "assets/rive/hotelanimation.riv";
import Rive from "rive-react";
import OaFormAlerts from "pages/components/OaFormAlerts";
import { connect } from "react-redux";
import {
  setHotelSearchResult,
  setTimerInitialMinute,
  setTimerInitialSecond,
  setHotelBookingInitialTime,
  setHotelFilters,
} from "redux/action";
import { apiCall } from "oautils/oaDataUtils";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const HotelSearchProgressPage = (props) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [infoText, setInfoText] = React.useState("");
  const [guestCount, setGuestCount] = React.useState({
    adult: 0,
    child: 0,
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [isLoading, setIsLoading] = React.useState(true);
  const history = useHistory();
  const classes = useStyles();

  const redirectToSearchEngine = () => {
    setTimeout(() => {
      history.push("/hotels");
    }, 1500);
  };

  // React.useEffect(() => {
  //   WebApi.getHotelSearch(
  //     props.hotelSearch,
  //     (response) => {
  //       if (response.success) {
  //         props.setHotelSearchResult(response.data.hotelSearchResult);
  //         props.setHotelFilters(response.data.filter);
  //         props.setTimerInitialMinute(0);
  //         props.setTimerInitialSecond(0);
  //         //  props.setInitialSessionTime(new Date().getTime() + 15 * 60 * 1000);
  //         props.setHotelBookingInitialTime(
  //           new Date().getTime() + 15 * 60 * 1000
  //         );
  //         window.sessionStorage.setItem("service", "hotel");
  //         history.push("/hotel/searchResult");
  //       } else {
  //         setIsError(!response.success);
  //         setInfoText(response.message + ". redirecting home ...");
  //         redirectToSearchEngine();
  //       }
  //     },
  //     (error) => {
  //       console.log("internal error", error);
  //       setInfoText("internal error! please contact admin");
  //       setIsError(true);
  //       redirectToSearchEngine();
  //     }
  //   );
  // }, [isLoading]);

  React.useEffect(() => {
    let adult = 0;
    let child = 0;
    for (let i = 0; i < props.hotelSearch.roomGuests.length; i++) {
      let guestObj = props.hotelSearch.roomGuests[i];
      if (guestObj.noOfAdults > 0) {
        adult += guestObj.noOfAdults;
      }
      if (guestObj.noOfChild > 0) {
        child += guestObj.noOfChild;
      }
    }
    setGuestCount({
      adult: adult,
      child: child,
    });
  }, []);

  return (
    <div className={classes.baseContainer}>
      <GridContainer className={classes.root}>
        <GridItem md={12}>
          <OaFormAlerts
            isSaving={props.isSaving}
            isSuccess={props.isSuccess}
            isError={props.isError}
            infoText={props.infoText}
            setIsError={props.setIsError}
            setIsSuccess={props.setIsSuccess}
          />
        </GridItem>
        <GridItem
          md={12}
          className="section-row justify-content-center align-items-flex-end wt-100p"
        >
          {/* <img src={ajaxLoader}/> */}
          <Rive src={image1} width="315" height="165" />
        </GridItem>

        <GridItem className="section-row justify-content-center align-items-center wt-100p">
          <GridContainer>
            <GridItem
              md={12}
              xs={12}
              align={"center"}
              // flexDirection={size < 950 ? "row" : "column"}
              style={{
                paddingTop: 0,
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className={"destination"}
            >
              Please Wait.....
              <br />
              While we're searching for the best Hotel Deals for you.
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem
          md={12}
          className="section-row justify-content-center align-items-center wt-100p"
        >
          <GridContainer className="section-row justify-content-center align-items-center wt-100p">
            {/* <GridItem md={4} align={isMobile ? "center" : "right"} xs={12}>
            Checkin:{" "}
            {dateFnsFormat(
              new Date(
                props.hotelSearch.checkInDate.split("/")[2],
                props.hotelSearch.checkInDate.split("/")[1] - 1,
                props.hotelSearch.checkInDate.split("/")[0]
              ),
              "do MMM yyyy"
            )}
          </GridItem> */}
            <GridItem md={4} align="center" xs={12}>
              {/* <div className="arrow-container" style={{ marginTop: 10 }}>
              <span className="arrow-right"></span>
            </div> */}
              <div style={{ color: theme.palette.secondary.grayDove }}>
                <span style={{ fontWeight: 600, fontSize: 18 }}>
                  Location: {props.hotelSearch.city}
                </span>
              </div>
            </GridItem>
            {/* <GridItem md={4} xs={12} align={isMobile ? "center" : "left"}>
            Checkout:{" "}
            {dateFnsFormat(
              new Date(
                props.hotelSearch.checkOutDate.split("/")[2],
                props.hotelSearch.checkOutDate.split("/")[1] - 1,
                props.hotelSearch.checkOutDate.split("/")[0]
              ),
              "do MMM yyyy"
            )}
          </GridItem> */}
          </GridContainer>
        </GridItem>
        {/* {props.hotelSearch.departureCityId} {props.hotelSearch.destinationCityId} */}
        <GridItem
          md={12}
          className="section-row align-items-flex-start wt-100p itin-grid"
        >
          <div className="section-row itin-wrapper flex-wrap wt-100p">
            <div className="section-row itin-item wt-50p">
              <span className="itin-label">Check In:</span>
              <span className="itin-content">
                {dateFnsFormat(
                  new Date(
                    props.hotelSearch.checkInDate.split("/")[2],
                    props.hotelSearch.checkInDate.split("/")[1] - 1,
                    props.hotelSearch.checkInDate.split("/")[0]
                  ),
                  "do MMM yyyy"
                )}
              </span>
            </div>
            <div className="section-row itin-item wt-50p">
              <span className="itin-label">Check Out:</span>
              <span className="itin-content">
                {dateFnsFormat(
                  new Date(
                    props.hotelSearch.checkOutDate.split("/")[2],
                    props.hotelSearch.checkOutDate.split("/")[1] - 1,
                    props.hotelSearch.checkOutDate.split("/")[0]
                  ),
                  "do MMM yyyy"
                )}
              </span>
            </div>
            <div className="section-row itin-item wt-50p">
              <span className="itin-label">Room(s):</span>
              <span className="itin-content">
                {props.hotelSearch?.noOfRooms}
              </span>
            </div>
            <div className="section-row itin-item wt-50p">
              <span className="itin-label">Guest(s):</span>
              <span className="itin-content">
                {guestCount?.adult} Adult(s)
                {guestCount?.child > 0 &&
                  ", " + guestCount?.child + " Children"}
              </span>
            </div>
            <div className="section-row itin-item wt-50p">
              <span className="itin-label">Max Rating:</span>
              <span className="itin-content">
                {props.hotelSearch.maxRating}
              </span>
            </div>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    hotelSearch: state.hotel.hotelSearch,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setHotelSearchResult: (hotelSearchResult) =>
      dispatch(setHotelSearchResult(hotelSearchResult)),
    setHotelFilters: (hotelFilters) => dispatch(setHotelFilters(hotelFilters)),
    setTimerInitialMinute: (timerInitialMinute) =>
      dispatch(setTimerInitialMinute(timerInitialMinute)),
    setTimerInitialSecond: (timerInitialSecond) =>
      dispatch(setTimerInitialSecond(timerInitialSecond)),

    setHotelBookingInitialTime: (hotelBookingInitialTime) =>
      dispatch(setHotelBookingInitialTime(hotelBookingInitialTime)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HotelSearchProgressPage);

const useStyles = makeStyles((theme) => ({
  baseContainer: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },
  root: {
    // minHeight:
    height: "100%",
    maxWidth: 768,
    boxShadow: `0px 0px 3px 0px rgba(107,107,107,0.75)`,
    marginTop: 30,
    "& .section-row": {
      display: "flex",
      backgroundColor: "transparent",
    },
    "& .justify-content-center": {
      justifyContent: "center",
    },
    "& .align-items-flex-end": {
      alignItems: "flex-end",
    },
    "& .align-items-flex-start": {
      alignItems: "flex-start",
    },
    "& .align-items-center": {
      alignItems: "center",
    },
    "& .ht-20-vh": {
      height: "20vh",
    },
    "& .ht-10-vh": {
      height: "10vh",
    },
    "& .wt-100p": {
      width: "100%",
    },
    "& .wt-50p": {
      width: "50%",
      [theme.breakpoints.down(520)]: {
        width: "100%",
      },
    },
    "& .flex-wrap": {
      flexWrap: "wrap",
    },
    "& .itin-grid": {
      background: "#e2e6f2",
      height: 80,
      paddingTop: 10,
      [theme.breakpoints.down(520)]: {
        height: "auto",
      },
    },
    "& .itin-wrapper": {
      [theme.breakpoints.down(960)]: {
        margin: "0 15px",
      },
    },
    "& .itin-label": {
      width: "35%",
      fontWeight: 600,
    },
    "& .itin-content": {
      width: "65%",
      fontWeight: 600,
    },
  },
}));
