import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import StarRating from "components/HotelPage/HotelComponents/StarRating/StarRating";
import image1 from "assets/img/Hotelassets/Rectangle Copy 57.png";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";
import Button from "components/CustomButtons/Button";
import { useHistory } from "react-router-dom";
import { AppStateContext } from "layouts/AppStateProvider";
import moment from "moment";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { localforageGetItem } from "oautils/oaForageUtils";

const HotelBookingReview = ({ location, ...props }) => {
  const [paymentOptions, setPaymentOptions] = React.useState([]);

  const [value, setValue] = React.useState(null);

  const {
    setConfirmation,
    isConfirmed,
    setIsConfirmed,
    setOpen,
  } = React.useContext(AppStateContext);
  const classes = useStyles();
  const {
    values,
    hotelInfo,
    hotelSearch,
    totalFare,
    selectedRooms,
    hotelCode,
    traceId,
    couponObj,
    totalPayable,
    discountApplied
  } = {
    ...location.state,
  };
  // console.log(location.state)
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  const dateChange = (date) => {
    let newArrayDate = date.split("/");
    return newArrayDate[1] + "/" + newArrayDate[0] + "/" + newArrayDate[2];
  };

  const TitleComp = (props) => {
    return (
      <div className="section-title-wrapper">
        <span className="heading-icon">
          <i className={props.fa} />
        </span>
        <Typography className="heading-text">{props.title}</Typography>
      </div>
    );
  };

  useEffect(() => {
    if (isConfirmed) {
      setIsConfirmed(false);

      history.push("/hotel/hotelpaymentprogress", {
        values,
        hotelInfo,
        hotelSearch,
        totalFare,
        selectedRooms,
        hotelCode,
        traceId, //trace ID added
        couponObj,
        totalPayable,
        discountApplied
      });
    }
  }, [isConfirmed]);

  useEffect(() => {
    localforageGetItem("user-details", function (err, details) {
      if (details != null) {
        apiCall(
          WebApi.getCompanyPGOptions,
          {
            partnerId: details.partnerId,
            serviceId: 2,
          },
          (response) => {
            response.data.map((value) => {
              if (value.selected === true) {
                // console.log("  True Entered");
                setValue(value.method);
              }
            });
            setPaymentOptions(response.data);
          }
        );
      }
    });
  }, []);

  const handleChange = (event) => {
    const method = event.target.value;
    setValue(method);
  };

  // const proceedbutton = () => {

  //   if (window.confirm("Are you sure want to proceed?")) {
  //     history.push("/hotel/hotelpaymentprogress", {
  //       values,
  //       hotelInfo,
  //       hotelSearch,
  //       totalFare,
  //       selectedRooms,
  //       hotelCode,
  //     });
  //   }

  // };

  return (
    <Grid
      container
      style={{ padding: "0 6px", justifyContent: "space-around" }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid Item md={12} className={classes.Lgrid1}>
          <Typography className={classes.Ltypo1}>Review Booking</Typography>
        </Grid>
      </Grid>

      <Grid
        container
        md={8}
        style={{ marginTop: "20px" }}
        className={classes.sectionContainer}
      >
        <Grid container className={classes.grid} justifyContent="flex-start">
          <Grid item className={classes.text1}>
            <Typography className={classes.text}>Hotel Details</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          style={{ marginTop: "5%", padding: 15 }}
          className={classes.sectionContainer}
        >
          <Grid item md={6}>
            <Grid container direction="row">
              <Typography variant="h6" component="h1" className={classes.text2}>
                {hotelInfo.hotelDetails.hotelName}
              </Typography>
              <Grid item style={{ marginLeft: "20px", marginTop: "4px" }}>
                <StarRating rating={hotelInfo.hotelDetails.starRating} />
              </Grid>
            </Grid>
            <Typography style={{ fontSize: "11px", marginTop: "10px" }}>
              {hotelInfo.hotelDetails.address}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Grid container justifyContent="flex-end">
              <Grid item style={{ marginRight: "20px" }}>
                <img src={image1} width="76px" height="53px" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: "20px" }} md={12}>
            <Grid container direction="row">
              <Grid item md={6} xs={6}>
                <Grid container justifyContent="flex-start">
                  <Typography className={classes.text3}>CheckIn</Typography>
                </Grid>
              </Grid>

              <Grid item md={6} xs={6}>
                <Grid container justifyContent="flex-end">
                  <Typography className={classes.text3}>CheckOut</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid item md={4} xs={6}>
                <Grid container justifyContent="flex-start">
                  <Typography className={classes.date}>
                    {hotelSearch.checkInDate}
                  </Typography>
                </Grid>
              </Grid>
              {isMobile && (
                <Grid item md={4} xs={6}>
                  <Grid container justifyContent="flex-end">
                    <Typography className={classes.date}>
                      {hotelSearch.checkOutDate}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid item md={4} xs={12}>
                <Grid justifyContent="center">
                  {hotelSearch.roomGuests.reduce((total, guest) => {
                    return total + parseInt(guest.noOfAdults);
                  }, 0) +
                    " " +
                    "Adult, " +
                    hotelSearch.roomGuests.reduce((total, guest) => {
                      return total + parseInt(guest.noOfChild);
                    }, 0) +
                    " Child - " +
                    hotelSearch.noOfNights +
                    " Nights"}
                </Grid>
              </Grid>

              {!isMobile && (
                <Grid item md={4}>
                  <Grid container justifyContent="flex-end">
                    <Typography className={classes.date}>
                      {hotelSearch.checkOutDate}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {!isMobile && (
              <Grid container direction="row">
                <Grid item md={6}>
                  <Grid container justifyContent="flex-start">
                    <Typography className={classes.days}>
                      {moment(dateChange(hotelSearch.checkInDate)).format(
                        "dddd"
                      )}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item md={6}>
                  <Grid container justifyContent="flex-end">
                    <Typography className={classes.days}>
                      {moment(dateChange(hotelSearch.checkOutDate)).format(
                        "dddd"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {hotelInfo.hotelDetails.hotelFacilities &&
          hotelInfo.hotelDetails.hotelFacilities.length > 0 &&
          <Grid className={classes.sectionContainer}>
            <Grid item md={12} /*style={{ marginTop: "20px" }}*/>
              <Typography component="h1" style={{ fontWeight: 400 }}>
                Includes:
              </Typography>
            </Grid>
            <Grid item md={12} className={classes.amminities}>
              <Grid container md={12}>
                {hotelInfo.hotelDetails.hotelFacilities.length === 1
                  ? hotelInfo.hotelDetails.hotelFacilities[0]?.split(",")
                  : hotelInfo.hotelDetails.hotelFacilities.map((value) => {
                    return (
                      <Grid
                        item
                        md={4}
                        style={{
                          //background: theme.palette.primary.aqua,
                          //padding: 4,
                          //borderRadius: "10px",
                          ///margin: 10,
                          marginBottom: 10,
                          marginTop: 10,
                          color: "white",
                          //justifyContent: "space-evenly",
                        }}
                      >
                        <Typography className={classes.facilites}>
                          {value}
                        </Typography>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        }

        <Grid item style={{ marginTop: "25px" }}>
          <Typography
            style={{
              fontSize: "14px",
              color: theme.palette.background.fortressGray,
              fontWeight: "500",
            }}
          >
            Selected rooms
          </Typography>
          {selectedRooms.map((room) => {
            // console.log(room?.roomInfo?.amenities);
            return (
              <Grid item md={12}>
                <Card>
                  <CardBody>
                    <Typography className={classes.cardtypo}>
                      {room.name}
                    </Typography>
                    <hr />
                    <Typography className={classes.cardtypo1}>
                      This Includes:
                    </Typography>

                    {room?.roomInfo?.amenities?.length > 0 &&
                      room?.roomInfo?.amenities?.[0]
                        ?.split(",")
                        ?.map((value, index) => {
                          return (
                            <Typography className={classes.cardtypo2}>
                              {index + 1} {value}
                            </Typography>
                          );
                        })}
                  </CardBody>
                </Card>
              </Grid>
            );
          })}
        </Grid>

        {/* <Grid item md={12}>
          <Card style={{ marginLeft: "1%", height: "258px" }}>
            <CardBody>
              <Typography className={classes.btmcard}>
                Please let us know if you have any special requirement
              </Typography>

              <Card style={{ height: "173px" }}>
                <CardBody>
                  <Typography className={classes.btmcard1}>
                    Write Here
                  </Typography>
                </CardBody>
              </Card>
            </CardBody>
          </Card>
        </Grid> */}
        {!isMobile && (
          <Grid item md={12} xs={12} style={{ marginTop: 20 }}>
            <Button
              fullWidth
              color="primary"
              // onClick={proceedbutton}

              onClick={() => {
                setConfirmation("Are you sure want to proceed?");
                setOpen(true);
              }}
              style={{
                background: theme.palette.buttons.bookNowBtn,
                color: theme.palette.primary.lightText,
                width: "160px",
                height: "45px",

              }}
            >
              Proceed
            </Button>
            <Button
              fullWidth
              color="primary"
              // onClick={proceedbutton}

              onClick={() => {
                props.history.goBack();
              }}
              style={{
                marginLeft: 50,
                background: theme.palette.background.smokeWhite,
                color: theme.palette.primary.defaultText,
                width: "160px",
                height: "45px",
              }}
            >
              Cancel
            </Button>
          </Grid>
        )}
      </Grid>

      {/* <p> Right Side code</p> */}

      <Grid
        container
        className={classes.sectionContainer}
        md={3}
        xs={12}
        style={{ height: "100%", marginTop: 20 }}
      >
        <div
          className="section-header"
          style={{ width: "100%" }}
        // style={{
        //   borderBottom: "1px solid rgba(0,0,0,0.125)",
        // }}
        >
          <TitleComp fa="fa fa-building" title={`Fare Details`} />
        </div>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          style={{ padding: 20, paddingTop: 5, paddingBottom: 2 }}
        // className={classes.sectionContainer}
        >
          {/* <Grid item className={classes.Rgrid1}>
            <Typography className={classes.grid2text} style={{ fontSize: 18 }}>
              Price Breakup
            </Typography>
          </Grid> */}
          <Grid container>
            <Grid item md={6} xs={6}>
              <Typography
                style={{
                  fontSize: "16px",
                  color: theme.palette.secondary.charcoalGray,
                }}
              >
                {hotelSearch.noOfRooms} Room {hotelSearch.noOfNights} Night
              </Typography>
            </Grid>

            <Grid item md={6} xs={6}>
              <Typography
                align="right"
                style={{
                  color: theme.palette.primary.fadedDarkGray,
                  fontSize: "16px",
                }}
              >
                {hotelSearch.preferredCurrency
                  ? "₹"
                  : hotelSearch.preferredCurrency}{" "}
                {parseFloat(totalFare).toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "4%" }}>
            <Grid item md={6} xs={6}>
              <Typography
                style={{
                  fontSize: "16px",
                  color: theme.palette.secondary.charcoalGray,
                  fontWeight: "500",
                }}
              >
                Total Discount
              </Typography>
            </Grid>

            <Grid item md={6} xs={6}>
              <Typography
                align="right"
                style={{
                  color: theme.palette.secondary.charcoalGray,
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                {hotelSearch.preferredCurrency
                  ? "₹"
                  : hotelSearch.preferredCurrency}{" "}
                {/* {selectedRooms.reduce((total, room) => {
                  return total + parseInt(room.roomInfo.price.discount);
                }, 0)} */}
                {discountApplied && couponObj?.voucher_value ? couponObj.voucher_value : 0}
              </Typography>
            </Grid>
          </Grid>
          <hr style={{ width: "100%" }}></hr>
          <Grid container style={{ marginTop: "2%" }}>
            <Grid item md={6} xs={6}>
              <Typography
                style={{
                  fontSize: "16px",
                  color: theme.palette.secondary.charcoalGray,
                }}
              >
                Discounted Price
              </Typography>
            </Grid>

            <Grid item md={6} xs={6}>
              <Typography
                align="right"
                style={{
                  color: theme.palette.primary.fadedDarkGray,
                  fontSize: "16px",
                }}
              >
                {hotelSearch.preferredCurrency
                  ? "₹"
                  : hotelSearch.preferredCurrency}{" "}
                {/* {totalFare -
                  selectedRooms.reduce((total, room) => {
                    return total + parseInt(room.roomInfo.price.discount);
                  }, 0)} */}
                {totalPayable}
              </Typography>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "4%" }}>
            <Grid item md={6} xs={6}>
              <Typography
                style={{
                  fontSize: "16px",
                  color: theme.palette.secondary.charcoalGray,
                  fontWeight: "500",
                }}
              >
                Total Discount
              </Typography>
            </Grid>

            <Grid item md={6} xs={6}>
              <Typography
                align="right"
                style={{
                  color: theme.palette.secondary.charcoalGray,
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                {/* ₹ 0/- */}
                ₹ {discountApplied && couponObj?.voucher_value ? couponObj.voucher_value : 0}
              </Typography>
            </Grid>
          </Grid>
          <hr style={{ width: "100%" }}></hr>
        </Grid>
        <Grid
          container
          style={{
            background: theme.palette.background.disabled,
            padding: "10px 15px",
            borderBottom: `none`,
            flexDirection: "row",
            display: "flex",
            width: "100%",
          }}
        >
          <Grid item md={8} xs={8}>
            <Typography
              style={{
                fontSize: "16px",
                color: theme.palette.secondary.charcoalGray,
                fontWeight: "500",
              }}
            >
              Total Amount
            </Typography>
          </Grid>

          <Grid item md={4} xs={4}>
            <Typography className={classes.paymoney} align="right">
              {hotelSearch.preferredCurrency
                ? "₹"
                : hotelSearch.preferredCurrency}{" "}
              {/* {totalFare} */} {totalPayable}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {isMobile && (
        <Grid item md={12} xs={12}>
          <Button
            fullWidth
            // color="primary"
            // onClick={proceedbutton}

            onClick={() => {
              setConfirmation("Are you sure want to proceed?");
              setOpen(true);
            }}
            className={classes.paymentbtn}
          >
            Proceed To Payment Option
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default HotelBookingReview;

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    "& hr": {
      marginTop: 0,
      marginBottom: 0,
    },
    background: theme.palette.background.light,
    borderRadius: "0.25rem",
    border: "1px solid rgba(0,0,0,0.125)",
    boxShadow: "0 .125rem .25rem rgba(0,0,0,0.075)!important",
    marginBottom: 25,
    overflow: "scroll",
    minHeight: "200px",
    padding: 10,
    [theme.breakpoints.down(980)]: {
      padding: "10px 0px 0px 10px",
    },
    // [theme.breakpoints.down(600)]: {
    //   padding: "5px !important",
    // },
    "& .section-header": {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.125)",
      marginBottom: 5,
    },
    "& .section-title-wrapper": {
      display: "flex",
      alignItems: "center",
    },
    "& .heading-icon": {
      borderRadius: "50%",
      background: theme.palette.background.disabled,
      width: 30,
      height: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: 10,
      "& i": {
        fontSize: 16,
        color: theme.palette.text.secondary,
      },
    },
    "& .heading-text": {
      textTransform: "uppercase",
      fontWeight: 500,
      color: theme.palette.text.secondary,
    },
  },
  text: {
    fontSize: "18px",
    color: theme.palette.secondary.black,
  },
  Lgrid1: { marginTop: "20px" },

  grid: {
    backgroundColor: theme.palette.background.silverWhite,
    // width: "736px",
    height: "63px",
  },
  text1: {
    marginTop: "20px",
    marginLeft: "10px",
  },
  text2: {
    fontSize: "18px",
    color: theme.palette.background.carulean,
  },
  text3: {
    color: theme.palette.secondary.metalGray,
    fontSize: "20px",
    fontWeight: "500",
  },
  input: {
    width: "264px",
    height: "35px",
    borderRadius: "40px",
    backgroundColor: theme.palette.background.cultured,
    border: "none",
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      paddingLeft: "40px",
    },
  },
  date: {
    color: theme.palette.secondary.charcoalGray,
    fontSize: "16px",
    fontWeight: "400",
  },
  days: {
    color: theme.palette.secondary.metalGray,
    fontSize: "16px",
  },
  cardtypo: {
    fontSize: "16px",
    fontWeight: "500",
    color: theme.palette.secondary.charcoalGray,
  },
  cardtypo1: {
    fontSize: "14px",
    color: theme.palette.background.peach,
  },
  cardtypo2: {
    fontSize: "14px",
    color: theme.palette.secondary.sharkGray,
  },
  btmcard: {
    fontSize: "14px",
    color: theme.palette.secondary.sharkGray,
    fontWeight: "500",
  },
  btmcard1: {
    fontSize: "14px",
    color: theme.palette.secondary.sharkGray,
  },
  grid2text: {
    marginTop: "18px",
    marginLeft: "10px",
    fontSize: "22px",
    color: theme.palette.secondary.charcoalGray,
  },
  facilites: {
    fontSize: "11px",
    fontWeight: "400",
    color: theme.palette.primary.defaultText,
  },
  paymentbtn: {
    marginTop: "5%",
    fontSize: "16px",
    fontWeight: "500",
    backgroundColor: theme.palette.buttons.bookNowBtn,
    color: theme.palette.primary.contrastText,
  },
  paymoney: {
    marginLeft: "10%",
    color: theme.palette.secondary.charcoalGray,
    fontSize: "16px",
    fontWeight: "500",
  },
  Ltypo1: {
    color: theme.palette.background.peach,
    fontSize: "20px",
  },

  Rmncontnr: {
    marginLeft: "5%",
    marginTop: "2%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  Rgrid1: {
    backgroundColor: theme.palette.background.silverWhite,
    height: "63px",
  },
  amminities: {
    marginTop: "10px",
    overflow: "scroll",
    maxHeight: 200,

    "&.MuiGrid-spacing-xs-3": {
      padding: 4,
    },
  },
}));
