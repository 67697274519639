import React from "react";
import styled from "styled-components";
import { useTheme } from "@material-ui/core";
import {
  Box,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Button,
  withWidth,
  Popper,
} from "@material-ui/core";
import { localforageGetItem } from "oautils/oaForageUtils";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import { connect } from "react-redux";
import { setBusCities, setBusBookingInitialTime } from "redux/action";

import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import OaFormAlerts from "pages/components/OaFormAlerts";
import _ from "lodash";
import dateFnsFormat from "date-fns/format";
import { useHistory } from "react-router-dom";
import locationPinIcon from "assets/Tickat/images/home_pix/location_pin_icon.png";
import calendarIcon from "assets/Tickat/images/home_pix/calendar_icon.png";
import swapIcon from "assets/Tickat/images/home_pix/swap_icon_vertical.png";
import LeftAdornedAutoComplete from "./formControls/LeftAdornedAutoComplete";
import LeftAdornedDatePicker from "./formControls/LeftAdornedDatePicker";

const BusSection = props => {

    const theme = useTheme();
    const history = useHistory();
    const busDepartureAutocompleteRef = React.useRef(null);
    const busDestinationAutocompleteRef = React.useRef(null);

    const today = new Date();
    const [startDate, setStartDate] = React.useState(today);
    const [departureDateDialogOpen, setDepartureDateDialogOpen] = React.useState(false);

    const [isSaving, setIsSaving] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [infoText, setInfoText] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(true);
    const [userType, setUserType] = React.useState(null);
    const formikRef = React.useRef();
    const [corporateType, setCorporateType] = React.useState(null);

    let baseUrl = "/";

    let optionsBus = [
        {
            name: "busSearch",
            label: "SEARCH BUS",
            url: baseUrl + "buses",
            icon: <i className="fas fa-search"></i>,
        },
        {
            name: "busMarkup",
            label: "MARK UP",
            url: "/admin/commission/bus",
            icon: <i className="material-icons">attach_money</i>,
        },
        // { name: 'busBank', label: 'BANK DETAILS', url: '/admin/management/bank-account', icon: (<i className="material-icons">%</i>) },
        {
            name: "busMoney",
            label: "ADD MONEY",
            url: "/admin/management/balance/master",
            icon: <i className="material-icons">post_add</i>,
        },
        {
            name: "busCancellation",
            label: "CANCELLATION",
            url: "/admin/booking-report/bus",
            icon: <i className="fas fa-ban"></i>,
        },
        // { name: 'searchHotel', label: 'DISCOUNTS', url: "#", disabled: true, icon: (<LocalOfferSharp style={{ fontSize: "27px" }} />) },
        // { name: 'searchHotel', label: 'COMMISSION', url: "#", disabled: true, icon: (<AccountBalanceWalletSharp style={{ fontSize: "27px" }} />) },
        {
            name: "busLedger",
            label: "LEDGER",
            url: "/admin/ledger",
            icon: <i className="material-icons">receipt_long</i>,
        },
        // { name: 'searchBus', label: 'MANAGE AGENT', url: "#", disabled: true, icon: (<FaceSharp style={{ fontSize: "27px" }} />) }
        {
            name: "busReport",
            label: "BUS REPORT",
            url: "/admin/booking-report/bus",
            icon: <i className="fas fa-bus"></i>,
        },
    ];

    const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];

    const initialValues = {
        OriginId: "",
        DestinationId: "",
        DateOfJourney: new Date(),
        BusType: "economy",
    };

    const validationSchema = Yup.object().shape({
        OriginId: Yup.string().required("Origin is required"),
        DestinationId: Yup.string().required("Destination is required"),
    });

    const fetchMatchingCities = (searchParams) => {
        if (searchParams.length >= 3) {
            apiCall(
                WebApi.getMatchingBusCity,
                {
                    cityId: "0",
                    cityName: searchParams,
                },
                (response) => {
                    if (response.success === true) {
                        props.setBusCities(response.data.BusCities);
                        // console.log("props", props);
                        // console.log(response.data.BusCities);
                        // setIsLoading(false);
                    } else {
                        setIsError(response.success);
                        setInfoText(response.message);
                    }
                }
            );
        }
    };

    React.useEffect(() => {
        localforageGetItem("user-details", function (err, details) {
            if (details != null) {
                setUserType(details.userType);
                setCorporateType(details.corporateType);
            }
        });
    }, []);

    const customPopper = function (props) {
        // console.log("props", props);
        return (
            <Popper
                {...props}
                // anchorEl={departAutocompleteEl.current.children[0]}
                placement="bottom-start"
                style={{}}
            />
        );
    };

    const swapDepartureDestination = () => {
        // console.log("swapping ");
        const temp = formikRef.current.values.DestinationId;
        formikRef.current.setFieldValue(
            "DestinationId",
            formikRef.current.values.OriginId
        );
        formikRef.current.setFieldValue("OriginId", temp);
    };

    const onSubmitBusSearch = (values) => {
        if (values.OriginId.CityId > 0 && values.DestinationId.CityId > 0) {
            var params = {
                departureCityId: values.OriginId.CityId,
                departureCityName: values.OriginId.CityName,
                destinationCityId: values.DestinationId.CityId,
                destinationCityName: values.DestinationId.CityName,
                selectedDate: dateFnsFormat(values.DateOfJourney, "yyyy-MM-dd"),
            };
            props.setBusBookingInitialTime(null);
            window.sessionStorage.removeItem("service");
            console.log("values", params);
            history.push({
                pathname: "/bus/search-progress",
                state: params,
            });
        }
    };

    React.useEffect(() => {
        if (isLoading) {
            const length = props.busCities ? Object.keys(props.busCities).length : 0;
            // console.log(length);
            if (length > 0) {
                setIsLoading(false);
            } else {
                apiCall(WebApi.getBusCity, {}, (response) => {
                    if (response.success === true) {
                        props.setBusCities(response.data.BusCities);
                        // console.log(response.data.BusCities,"BusCities");
                        setIsLoading(false);
                    } else {
                        setIsError(response.success);
                        setInfoText(response.message);
                    }
                });
            }
        }
    }, [isLoading]);

    const openQuickLink = (url) => {
        // console.log("quicklink", url);
        history.push({
            pathname: url,
        });
    };

    return(
        <RootContainer theme={theme}>
            <div className="notification-container">
                <OaFormAlerts
                    isSaving={isSaving}
                    isSuccess={isSuccess}
                    isError={isError}
                    infoText={infoText}
                    setIsError={setIsError}
                    setIsSuccess={setIsSuccess}
                />
            </div>
            <div className="formik-wrapper">
                <Formik
                    innerRef={formikRef}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmitBusSearch}
                >
                    {({ values, errors, touched, handleChange, setFieldValue }) => (
                        <Form>
                            <div className="form-wrapper pos-rel">
                                <div className="flex-row">
                                    <div className="form-group-wrapper w100p">
                                        <label className="pl15">Source City Name</label>
                                        <LeftAdornedAutoComplete
                                            className="form-control"
                                            fullWidth
                                            PopperComponent={customPopper}
                                            name="OriginId"
                                            options={props.busCities || []}
                                            getOptionLabel={(option) => option.CityName || ""}
                                            // options={busDepartureList}
                                            // getOptionLabel={(o) => o.title || ""}
                                            value={values.OriginId}
                                            openOnFocus
                                            getOptionSelected={(option, value) => {
                                                return option.CityName == value.CityName;
                                            }}
                                            onKeyUp={(event) =>
                                                fetchMatchingCities(event.target.value)
                                            }
                                            onChange={(event, newValue) => {
                                                // busDestinationAutocompleteRef.current.focus();
                                                // setData({ ...data, departure: newValue });
                                                setFieldValue("OriginId", newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    color="secondary"
                                                    placeholder="Source City"
                                                    inputRef={busDepartureAutocompleteRef}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: "new-password",
                                                    }}
                                                />
                                            )}
                                            disablePortal={true}
                                            adnicon={locationPinIcon}
                                        />
                                        <ErrorMessage
                                            name="OriginId"
                                            component="div"
                                            className="error"
                                        />
                                    </div>
                                </div>
                                <div className="swap-icon-wrapper">
                                    <IconButton className="swap-button" onClick={swapDepartureDestination}>
                                        <img src={swapIcon} />
                                    </IconButton>
                                </div>
                                <div className="flex-row">
                                    <div className="form-group-wrapper w100p">
                                        <label className="pl15">Destination City Name</label>
                                        <LeftAdornedAutoComplete
                                            className="form-control"
                                            fullWidth
                                            PopperComponent={customPopper}
                                            name="DestinationId"
                                            // options={busDestinationList}
                                            // getOptionLabel={(o) => o.title || ""}
                                            options={props.busCities || []}
                                            getOptionLabel={(option) => option.CityName || ""}
                                            value={values.DestinationId}
                                            getOptionSelected={(option, value) => {
                                                return option.CityName == value.CityName;
                                            }}
                                            onKeyUp={(event) =>
                                                fetchMatchingCities(event.target.value)
                                            }
                                            onChange={(event, newValue) => {
                                                // setDepartureDateDialogOpen(true);
                                                // setData({ ...data, destination: newValue });
                                                setFieldValue("DestinationId", newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    color="secondary"
                                                    // label="Arrival"
                                                    inputRef={busDestinationAutocompleteRef}
                                                    placeholder="Destination City"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autocomplete: "new-password",
                                                    }}
                                                />
                                            )}
                                            disablePortal={true}
                                            adnicon={locationPinIcon}
                                        />
                                        <ErrorMessage
                                            name="DestinationId"
                                            component="div"
                                            className="error"
                                        />
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="form-group-wrapper flex-grow-1">
                                        <label className="pl15">Travel Date</label>
                                        <LeftAdornedDatePicker
                                            showDisabledMonthNavigation
                                            name="DateOfJourney"
                                            className="date-picker"
                                            minDate={startDate}
                                            customInput={
                                                <TextField id="standard-basic" variant="standard" />
                                            }
                                            value={values.DateOfJourney}
                                            onChange={(e, v) => {
                                                busDestinationAutocompleteRef.current.blur();
                                                setDepartureDateDialogOpen(false);
                                                // console.log(
                                                //   document.getElementById("searchActionButton")
                                                // );
                                                // document.getElementById("searchActionButton").focus();
                                                // setData({ ...data, departureDate: e });
                                                setFieldValue("DateOfJourney", e);
                                            }}
                                            KeyboardButtonProps={{
                                                onFocus: (e) => {
                                                    setDepartureDateDialogOpen(true);
                                                },
                                            }}
                                            PopoverProps={{
                                                disableRestoreFocus: true,
                                                onClose: () => {
                                                    setDepartureDateDialogOpen(false);
                                                },
                                            }}
                                            InputProps={{
                                                onFocus: () => {
                                                    setDepartureDateDialogOpen(true);
                                                },
                                            }}
                                            monthsShown={2}
                                            dateFormat="dd MMM yyyy"
                                            //labeltxt="DEPARTURE DATE"
                                            selected={values.DateOfJourney}
                                            adnicon={calendarIcon}
                                        />
                                    </div>
                                    <div className="form-group-wrapper seat-input">
                                        <label className="pl15">Seat(s)</label>
                                        <TextField
                                            fullWidth
                                            defaultValue={1}
                                            type="number"
                                            inputProps={{
                                                min: "1"
                                            }}
                                            className="seat-input-text"
                                        />
                                    </div>
                                </div>
                                <div className="flex-row align-items-center">
                                    <div className="form-group-wrapper submit-wrapper">
                                        <Button type="submit">
                                            Search Bus
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
                {userType &&
                    userType == "agent" &&
                    corporateType &&
                    corporateType != "corporate" && (
                        <Box className="quick-links-wrapper">
                            {optionsBus &&
                                optionsBus.map((val, ind) => (
                                    <div
                                        item
                                        className="quick-link-item"
                                        key={ind}
                                        onClick={() => openQuickLink(val.url)}
                                    >
                                        <div className="qlink-icon-wrapper">{val.icon}</div>
                                        <div className="qlink-label-wrapper">{val.label}</div>
                                    </div>
                                ))}
                        </Box>
                    )
                }
            </div>
        </RootContainer>
    );
};

const mapDispatchToProp = (dispatch) => {
    return {
        setBusCities: (busCities) => dispatch(setBusCities(busCities)),
        setBusBookingInitialTime: (busBookingInitialTime) =>
            dispatch(setBusBookingInitialTime(busBookingInitialTime)),
    };
};

const mapStateToProp = (state, prop) => {
    return {
        busCities: state.bus.busCities,
    };
};

export default connect(
    mapStateToProp,
    mapDispatchToProp
)(withWidth()(BusSection));

const busDepartureList = [
    { title: "Mumbai", value: "mumbai" },
    { title: "Hyderabad", value: "hyderabad" },
    { title: "Bangalore", value: "bangalore" },
    { title: "Chennai", value: "chennai" },
];

const busDestinationList = [
    { title: "Mumbai", value: "mumbai" },
    { title: "Hyderabad", value: "hyderabad" },
    { title: "Bangalore", value: "bangalore" },
    { title: "Chennai", value: "chennai" },
];

const RootContainer = styled.div`
    margin: 10px 0 0 50px;
    border-radius: 18px;
    width: 720px;
    background-color: rgba(255, 255, 255, 0.15);
    webkit-box-shadow: 0px 1px 2px 0px rgba(56,56,56,0.6);
    -moz-box-shadow: 0px 1px 2px 0px rgba(56,56,56,0.6);
    box-shadow: 0px 1px 2px 0px rgba(56,56,56,0.6);
    .form-wrapper {
        padding: 10px 35px;
    }
    .form-group-wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin: 5px 0;
        & label {
            color: ${prop => prop.theme.palette.secondary.contrastText};
            font-family: ${prop => prop.theme.palette.font.secondary};
        }
    }
    .form-control {
        &.radio-control {
            flex-direction: row;
            & .MuiRadio-root {
                & .MuiSvgIcon-root {
                    color: ${prop => prop.theme.palette.secondary.contrastText};
                }
                &.Mui-checked {
                    & .MuiSvgIcon-root {
                        color: ${prop => prop.theme.palette.primary.main};
                    }
                }
            }
            & .MuiTypography-root {
                font-size: 14px;
                color: ${prop => prop.theme.palette.text.light};
            }
        }
    }
    .swap-icon-wrapper {
        position: absolute;
        top: 94px;
        left: 330px;
        & .swap-button {
            background: ${prop => prop.theme.palette.primary.main};
            height: 56px;
            width: 56px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            & img {
                height: 55px;
            }
        }
    }
    .flex-row {
        display: flex;
        gap: 25px;
        margin: 13px 0;
    }
    .w50p {
        width: 50%;
        max-width: 300px;
    }
    .w100p {
        width: 100%;
    }
    .pl15 {
        padding-left: 15px;
    }
    .flight-itinerary-root {
        & label {
            font-weight: 500;
        }
    }
    .p-rtv {
        position: relative;
    }
    .MuiAutocomplete-popper.MuiAutocomplete-popperDisablePortal {
        width: 252px !important;
        & .ac-options-list {
        width: 222px;
        overflow-x: hidden;
        padding: 0 2px;
        & span {
            padding: 0;
            display: flex;
            gap: 10px;
            justify-content: space-between;
            width: 100%;
            & .ac-option-right {
                padding: 0;
            }
        }
        & p {
            white-space: nowrap;
        }
        }
    }
    .submit-wrapper {
        & button {
            background: ${prop => prop.theme.palette.buttons.primary};
            color: ${prop => prop.theme.palette.buttons.primaryContrastText};
            text-transform: uppercase;
            height: 45px;
            border-radius: 8px;
            width: 200px;
            font-weight: 600;
        }
    }
    .align-items-center {
        align-items: center;
    }
    .pos-rel {
        position: relative;
    }
    .flex-grow-1 {
        flex-grow: 1;
    }
    .seat-input {
        width: 200px;
        & .MuiTextField-root {
            background: ${prop => prop.theme.palette.background.default};
            border-radius: 8px;
            padding: 6px 8px;
            height: 45px;
        }
        & .MuiInputBase-input {
            padding: 5px 10px;
        }
    }
    & .MuiInput-input {
        color: ${prop => prop.theme.palette.background.defaultContrast};
        font-family: ${prop => prop.theme.palette.font.secondary};
        font-weight: 600;
    }
    & .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before, .MuiInput-underline:before {
        border-bottom: none;
    }
    & .MuiInput-colorSecondary.MuiInput-underline:after {
        border-bottom-color: unset;
    }
    @media all and (max-width: 1279px) {
        .form-group-wrapper {
            &.seat-input {
                & .seat-input-text {
                    border-radius: 25px;
                    border: solid 1px ${prop => prop.theme.palette.background.primary};
                }
                & .MuiInputBase-input {
                    padding: 5px 10px 5px 15px;
                }
            }
        }
        .submit-wrapper {
            & button {
                border-radius: 25px;
                border: solid 1px ${prop => prop.theme.palette.background.primary};
            }
        }
    }
    @media all and (max-width: 1228px) {
        margin: 10px 0;
    }
    @media all and (max-width: 768px) {
        width: 100%;
        .swap-icon-wrapper {
            left: 46%;
        }
    }
    @media all and (max-width: 504px) {
        .swap-icon-wrapper {
            top: 99.3px;
            left: 60%;
        }
        .swap-icon-wrapper {
            & .swap-button {
                height: 45px;
                width: 45px;
                & img {
                    height: 40px;
                }
            }
        }
    }
    @media all and (max-width: 418px) {
        .flex-row {
            flex-direction: column;
            gap: 10px;
        }
        .seat-input {
            width: 100%;
        }
        .submit-wrapper {
            width: 100%;
            padding: 10px 0 0;
            & button {
                width: 100%;
            }
        }
    }
`;
