const airParadise = {
  name: "Air Paradise",
  title: "Air Paradise",
  ccName: "AirParadise", //this is for pascal case usage
  countryDialCode: "91",
  country: "IN",
  domainName: "ap.tours",
  domainAlias: "www.ap.tours",
  supportEmail: "support@ap.tours",
  infoEmail: "info@ap.tours",
  supportContact: "9811955552",
  supportContactView: "9811 955 552",
  infoContact: "13323334100",
  infoContactView: "+1 (332) 333-4100",
  o2Contact: "971505876816", //please don't insert any space in this
  o2ContactView: "971 505876816",
  wacontact: "9713265000", //please don't insert any space in this
  assetFolder: "airParadise",
  address: {
    office: "E-175, Naraina Vihar, New Delhi 110028",
    register: "E-175, Naraina Vihar, New Delhi 110028",
    office2Name: "Kanz Al Dar",
    office2:
      "304E 3rd flr. Aspin Commercial Tower Sheikh Zayed Road Dubai UAE, PO Box - 63413",
  },
  latitude: 28.629880701394402,
  longitude: 77.13842411534078,
  latitudeO2: 25.20903,
  longitudeO2: 55.272602,
  shareNow: true,
  hotelFilterOpen: false,
  brandUrl: "https://www.ap.tours/br/static/media/logo.22549bd5.png",
  hotelPriceRoundOff: false,
  flightConvenienceFeeSectorwise: false,
  serviceAvailed: "1,2",
  hotelInfoCarouselButtonLarge: true,
  hotelRatingsLowToHigh: false,
  holidaysFormShow: false,
};

const triumphTravel = {
  name: "Triumphh Travel N More Pvt Ltd",
  title: "Triumphh Travel N More Pvt Ltd",
  ccName: "TriumphhTravel", //this is for pascal case usage
  countryDialCode: "91",
  country: "IN",
  domainName: "triumphhtravels.com",
  domainAlias: "www.triumphhtravels.com",
  supportEmail: "online@triumphhtravel.com",
  infoEmail: "info@triumphhtravel.com",
  supportContact: "1141611366", //please don't insert any space in this
  supportContactView: "1141 611 366",
  infoContact: "1141611367", //please don't insert any space in this
  infoContactView: "1141 611 367",
  wacontact: "",
  assetFolder: "triumphhTravel",
  address: {
    office:
      "609, Vishwa Sadan Building, Janakpuri District Centre, New Delhi - 110058",
    register:
      "609, Vishwa Sadan Building, Janakpuri District Centre, New Delhi - 110058",
  },
  latitude: 28.629335887150404,
  longitude: 77.08047991830229,
  shareNow: true,
  hotelFilterOpen: false,
  brandUrl:
    "https://www.triumphhtravel.com/static/media/company_logo.efd5340b.png",
  hotelPriceRoundOff: false,
  flightConvenienceFeeSectorwise: false,
  serviceAvailed: "1,2,4,6",
  hotelInfoCarouselButtonLarge: true,
  hotelRatingsLowToHigh: true,
  holidaysFormShow: false,
};

const easyMyTravels = {
  name: "Fly Easy Forever",
  title: "Fly Easy Forever",
  ccName: "FlyEasyForever", //this is for pascal case usage
  countryDialCode: "91",
  country: "IN",
  domainName: "flyeasyforever.com",
  domainAlias: "www.flyeasyforever.com",
  supportEmail: "support@flyeasyforever.com",
  infoEmail: "info@flyeasyforever.com",
  supportContact: "1142690477", //please don't insert any space in this
  supportContactView: "1142 690 477",
  infoContact: "1141725478", //please don't insert any space in this
  infoContactView: "1141 725 478",
  wacontact: "9748816884",
  assetFolder: "easyTravels",
  address: {
    office: "906, VV Building, Noida District Centre, Noida - 123456",
    register: "906, VV Building, Noida District Centre, Noida - 123456",
  },
  latitude: 18.984274,
  longitude: 73.08717,
  shareNow: true,
  hotelFilterOpen: false,
  brandUrl: "https://i.imgur.com/R4v3rRp.png",
  hotelPriceRoundOff: false,
  flightConvenienceFeeSectorwise: false,
  serviceAvailed: "1,2,4,6",
  hotelInfoCarouselButtonLarge: true,
  hotelRatingsLowToHigh: true,
  holidaysFormShow: false,
};

const lookMyTicket = {
  name: "Lookmyticket Tours and Travels Pvt. Ltd.",
  title: "Book Flights, Hotels, Bus and Insurance",
  ccName: "LookMyTicket", //this is for pascal case usage
  countryDialCode: "91",
  country: "IN",
  domainName: "lookmyticket.com",
  domainAlias: "www.lookmyticket.com",
  supportEmail: "support@lookmyticket.com",
  infoEmail: "info@lookmyticket.com",
  supportContact: "8699665757", //please don't insert any space in this
  supportContactView: "86996 65757",
  infoContact: "8699665858", //please don't insert any space in this
  infoContactView: "86996 65858",
  wacontact: "",
  assetFolder: "lookMyTicket",
  address: {
    office:
      "Global Business Park Opposite Metro Mall,OFFICE NO-322,3rd Floor, Zirakpur, Punjab 140603",
    register:
      "Global Business Park Opposite Metro Mall,OFFICE NO-322,3rd Floor, Zirakpur, Punjab 140603",
  },
  latitude: 30.63975,
  longitude: 76.82317,
  shareNow: true,
  hotelFilterOpen: true,
  brandUrl: "https://i.imgur.com/uto3bRl.png",
  hotelPriceRoundOff: true,
  flightConvenienceFeeSectorwise: false,
  serviceAvailed: "1,2,4,6",
  hotelInfoCarouselButtonLarge: true,
  hotelRatingsLowToHigh: true,
  holidaysFormShow: false,
};

const afaTours = {
  name: "AFA Tourism",
  title: "AFA Tourism",
  ccName: "AfaTourism", //this is for pascal case usage
  countryDialCode: "91",
  country: "IN",
  domainName: "afatour.in",
  domainAlias: "www.afatour.in",
  supportEmail: "support@afatour.in",
  infoEmail: "afaquery@gmail.com",
  // supportContact: "9237301601/02/03/04", //please don't insert any space in this
  supportContact: "18008900159", //please don't insert any space in this
  supportContactView: "1800 8900 159",
  // infoContact: "9237301601/02/03/04", //please don't insert any space in this
  // infoContactView: "9237301601/02/03/04",
  infoContact: "9237301600", //please don't insert any space in this
  infoContactView: "92373 01600",
  // wacontact: "9237301601/02/03/04",
  assetFolder: "afaTours",
  address: {
    // office: "Plot no-327,Hi-tech Annex,Sundarpada,Bhubaneswar, Odisha-751002, India",
    // register: "Plot no-327,Hi-tech Annex,Sundarpada,Bhubaneswar, Odisha-751002, India",
    office:
      "At- Sundarpada, Po. Kuha, Bhubaneswar, Dist. Khurda, Odisha 751002, India",
    register:
      "At- Sundarpada, Po. Kuha, Bhubaneswar, Dist. Khurda, Odisha 751002, India",
  },
  latitude: 20.204758326841826,
  longitude: 85.79564629909754,
  shareNow: true,
  hotelFilterOpen: false,
  brandUrl: "https://i.imgur.com/iK8fFp1.png",
  hotelPriceRoundOff: false,
  flightConvenienceFeeSectorwise: false,
  serviceAvailed: "1,2",
  hotelInfoCarouselButtonLarge: true,
  hotelRatingsLowToHigh: true,
  holidaysFormShow: false,
};

const dwc = {
  name: "DWC (staging)",
  title: "DWC (staging)",
  ccName: "DigitalWorld", //this is for pascal case usage
  countryDialCode: "91",
  country: "IN",
  domainName: "dwcegov.com",
  domainAlias: "www.dwcegov.com",
  supportEmail: "customercare@dwcegov.com",
  infoEmail: "info@dwcegov.com",
  supportContact: "03228291200", //please don't insert any space in this
  supportContactView: "03228291200",
  infoContact: "9933067262", //please don't insert any space in this
  infoContactView: "9933067262",
  wacontact: "9933067262",
  assetFolder: "dwc",
  address: {
    office: "Padumbasan , Holding no-394, Ward no-10, (Beside Bazar Kolkata)",
    register: "Tamluk Purba Medinipur West Bengal 721636",
  },
  latitude: 18.984274,
  longitude: 73.08717,
  shareNow: true,
  hotelFilterOpen: false,
  brandUrl: "https://i.imgur.com/LwOLlOq.png",
  hotelPriceRoundOff: false,
  flightConvenienceFeeSectorwise: false,
  serviceAvailed: "1,2,4,6",
  hotelInfoCarouselButtonLarge: true,
  hotelRatingsLowToHigh: true,
  holidaysFormShow: false,
};

const doneTrip = {
  name: "My Done Trip (staging)",
  title: "My Done Trip (staging)",
  ccName: "MyDoneTrip", //this is for pascal case usage
  countryDialCode: "91",
  country: "IN",
  domainName: "mydonetrip.com",
  domainAlias: "www.mydonetrip.com",
  supportEmail: "support@mydonetrip.com",
  infoEmail: "info@mydonetrip.com",
  supportContact: "9672912008", //please don't insert any space in this
  supportContactView: "9672912008",
  infoContact: "9672912008", //please don't insert any space in this
  infoContactView: "9672912008",
  wacontact: "9672912008",
  assetFolder: "doneTrip",
  address: {
    office:
      "Midas International-www.mydonetrip.com, 18/786, Chopasani Housing Board, Jodhpur( Raj.), PIN-342008",
    register:
      "Midas International-www.mydonetrip.com, 18/786, Chopasani Housing Board, Jodhpur( Raj.), PIN-342008",
    register: "Jodhpur( Raj.), PIN-342008",
  },
  latitude: 18.984274,
  longitude: 73.08717,
  shareNow: false,
  hotelFilterOpen: false,
  brandUrl: "https://i.imgur.com/FiEfPKh.png",
  hotelPriceRoundOff: false,
  flightConvenienceFeeSectorwise: false,
  serviceAvailed: "1,2",
  hotelInfoCarouselButtonLarge: true,
  hotelRatingsLowToHigh: true,
  holidaysFormShow: false,
};

const easyTravelsOnline = {
  name: "Easy Travels Online",
  title: "Easy Travels Online",
  ccName: "EasyTravelsOnline", //this is for pascal case usage
  countryDialCode: "91",
  country: "IN",
  domainName: "easytravelsonline.com",
  domainAlias: "www.easytravelsonline.com",
  supportEmail: "support@easytravelsonline.com",
  infoEmail: "support@easytravelsonline.com",
  supportContact: "7415665000", //please don't insert any space in this
  supportContactView: "74156 65000",
  infoContact: "7415665000", //please don't insert any space in this
  infoContactView: "74156 65000",
  wacontact: "74156 65000",
  assetFolder: "EasyTravelsOnline",
  address: {
    office:
      "Guru Shakti Sadan,Geeta Nagar (Choubey Colony),near City Hospital, Raipur,Chhattisgarh, 492001",
    register:
      "Guru Shakti Sadan,Geeta Nagar (Choubey Colony),near City Hospital, Raipur,Chhattisgarh, 492001",
  },
  latitude: 28.7041,
  longitude: 77.7041,
  shareNow: true,
  hotelFilterOpen: true,
  brandUrl:
    "https://www.easytravelsonline.com/static/media/company_logo.ee58d58f.png",
  hotelPriceRoundOff: false,
  flightConvenienceFeeSectorwise: false,
  serviceAvailed: "1,2,4,6",
  hotelInfoCarouselButtonLarge: true,
  hotelRatingsLowToHigh: true,
  holidaysFormShow: false,
};

const tripBouquet = {
  name: "TripBouquet",
  title: "TripBouquet",
  ccName: "TripBouquet", //this is for pascal case usage
  countryDialCode: "91",
  country: "IN",
  domainName: "tripBouquet.com",
  domainAlias: "www.tripBouquet.com",
  supportEmail: "support@tripbouquet.com",
  infoEmail: "support@tripBouquet.com",
  supportContact: "9518221549", //please don't insert any space in this
  supportContactView: "95182 21549",
  infoContact: "01244240151", //please don't insert any space in this
  infoContactView: "0124-4240151",
  wacontact: "95182 21549",
  assetFolder: "tripBouquet",
  address: {
    office: "241 Tower B Spazedge building, Sector 47 Gurugram, 492001",
    register: "241 Tower B Spazedge building, Sector 47 Gurugram, 492001",
  },
  latitude: 28.427890438377013,
  longitude: 77.05043406127602,
  shareNow: true,
  hotelFilterOpen: true,
  brandUrl: "https://i.imgur.com/AD5VJFW.png",
  hotelPriceRoundOff: false,
  flightConvenienceFeeSectorwise: false,
  serviceAvailed: "1,2,4,6",
  hotelInfoCarouselButtonLarge: true,
  hotelRatingsLowToHigh: true,
  holidaysFormShow: true,
};

const buzzHolidays = {
  name: "STK FOREX & LEISURE PRIVATE LIMITED (Staging)",
  title: "STK FOREX & LEISURE PRIVATE LIMITED (Staging)",
  ccName: "STK FOREX & LEISURE PRIVATE LIMITED (Staging)", //this is for pascal case usage
  countryDialCode: "91",
  country: "IN",
  domainName: "buzzholidayz.com",
  domainAlias: "www.buzzholidayz.com",
  supportEmail: "sales@buzzholidayz.com",
  infoEmail: "info@buzzholidayz.com",
  supportContact: "9160895608", //please don't insert any space in this
  supportContactView: "91608 95608",
  infoContact: "9032720700", //please don't insert any space in this
  infoContactView: "90327 20700",
  wacontact: "90327 20700",
  assetFolder: "BuzzHolidays",
  address: {
    office:
      "D.NO.6-3-1111/B 5 6, BABUKHAN MALL, GREENLAND ROADS, SOMAJIGUDA, Hyderabad, Telangana, 500016",
    register:
      "D.NO.6-3-1111/B 5 6, BABUKHAN MALL, GREENLAND ROADS, SOMAJIGUDA, Hyderabad, Telangana, 500016",
  },
  latitude: 28.7041,
  longitude: 77.7041,
  shareNow: true,
  hotelFilterOpen: true,
  brandUrl: "https://i.imgur.com/ZlLLyG1.png",
  hotelPriceRoundOff: false,
  flightConvenienceFeeSectorwise: false,
  serviceAvailed: "1,2,4,6",
  hotelInfoCarouselButtonLarge: true,
  hotelRatingsLowToHigh: true,
  holidaysFormShow: false,
};

const tickat = {
  name: "Tickat.com",
  title: "Tickat.com",
  ccName: "Tickat.com", //this is for pascal case usage
  countryDialCode: "91",
  country: "IN",
  domainName: "tickat.com",
  domainAlias: "www.tickat.com",
  supportEmail: "support@tickat.com",
  infoEmail: "info@tickat.com",
  supportContact: "8879111166", //please don't insert any space in this
  supportContactView: "88791 11166",
  infoContact: "", //please don't insert any space in this
  infoContactView: "",
  wacontact: "",
  assetFolder: "Tickat",
  address: {
    office:
      "Hallmark Business Plaza 11th Floor, Office No 1106, Bandra East, Mumbai, Maharashtra 400051",
    register:
      "Hallmark Business Plaza 11th Floor, Office No 1106, Bandra East, Mumbai, Maharashtra 400051",
  },
  latitude: 19.06085,
  longitude: 72.85201,
  shareNow: true,
  hotelFilterOpen: true,
  brandUrl: "https://i.imgur.com/MH4QOXJ.png",
  hotelPriceRoundOff: false,
  flightConvenienceFeeSectorwise: false,
  serviceAvailed: "1,2,4,6",
  hotelInfoCarouselButtonLarge: true,
  hotelRatingsLowToHigh: true,
  holidaysFormShow: false,
};

export const AppConstant = tickat;
