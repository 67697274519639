import React, { useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import thumb1 from "assets/img/Hotelassets/thumb1.jpg";

import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import OaFormAlerts from "pages/components/OaFormAlerts";
import HotelInfo from "./hotelinfo/HotelInfo";

import { Rooms } from "./hotelinfo/Rooms";
import CardBody from "components/Card/CardBody";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SelectedRoom from "./hotelinfo/selectedRooms";
import Card from "components/Card/Card";
import Scheduler from "components/HotelPage/HotelComponents/Scheduler/Scheduler";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useMediaQuery,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

const HotelDetailPage = ({ history, ...props }) => {
  const classes = useStyles();
  const [hotelRoomInfo, setHotelRoomInfo] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [selectedRooms, setSelectedRooms] = useState({ room: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [roomCombination, setRoomCombination] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // room index,

  const bookRoom = () => {
    // console.log("selectedRooms");
    // console.log(selectedRooms);
    console.log(props);
    // console.log("categoryId: ", selectedRooms.room[0].categoryId);
    history.push("/hotel/bookingForm", {
      resultIndex: props.hotelRoomParam.resultIndex,
      hotelCode: props.hotelRoomParam.hotelCode,
      traceId: props.hotelRoomParam.traceId, //trace ID added
      selectedRooms: selectedRooms.room,
      hotelInfo: props.hotelRoomParam.hotelInfo,
      categoryId: selectedRooms.room[0].categoryId,
      isIslandHopper: props.hotelSearch.isIslandHopper,
    });
  };

  useEffect(() => {
    apiCall(
      WebApi.getHotelRoom,
      {
        resultIndex: props.hotelRoomParam.resultIndex,
        hotelCode: props.hotelRoomParam.hotelCode,
        traceId: props.hotelRoomParam.traceId,
        isIslandHopper: props.hotelSearch.isIslandHopper,
      },
      (response) => {
        if (response.success === true) {
          setHotelRoomInfo(response.data.hotelRoomsResult);
        } else {
          setIsError(!response.success);
          setInfoText(response.message);
        }
      }
    );
  }, [isLoading]);

  return (
    <div>
      <OaFormAlerts
        isSaving={isSaving}
        isSuccess={isSuccess}
        isError={isError}
        infoText={infoText}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
      />

      <Scheduler selectedData={props.hotelSearch} history={history} />
      <AccordionComponent
        expandPanel="panel1"
        title="Image Gallery and Hotel Map"
      >
        <HotelInfo
          hotelInfo={props.hotelRoomParam.hotelInfo}
          hotel={props.hotelRoomParam.hotel}
          hotelSearch={props.hotelSearch}
          hotelCode={props.hotelRoomParam.hotelCode}
          images={props.hotelRoomParam.images}
        />
      </AccordionComponent>
      <AccordionComponent
        expandPanel="panel2"
        // expanded={true}
        title="Rooms"
        bodyStyle={
          {
            maxHeight: 250,
            overflowY: "auto",
            scrollbarWidth: "thin",
          }
        }
      >
        <Rooms
          selectedRooms={selectedRooms}
          setSelectedRooms={setSelectedRooms}
          hotelSearch={props.hotelSearch}
          roomInfo={hotelRoomInfo}
          roomCombination={roomCombination}
          setRoomCombination={setRoomCombination}
          hotelCode={props.hotelRoomParam.hotelCode}
        />
      </AccordionComponent>
      <AccordionComponent
        // expanded={true}
        expandPanel="panel3"
        title="Hotel Description"
        bodyStyle={{
          maxHeight: 320,
          overflowY: "auto",
          scrollbarWidth: "thin",
        }}
      >
        <Grid container style={{ padding: 5 }}>
          <Card>
            <CardBody>
              {props.hotelRoomParam.hotelInfo.hotelDetails.description && (
                <Grid item style={{ marginTop: 10 }}>
                  <span className={classes.headText}>About</span>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: new DOMParser().parseFromString(
                        `<!doctype html><body>${props.hotelRoomParam.hotelInfo.hotelDetails.description}`,
                        "text/html"
                      ).body.textContent,
                    }}
                    className={classes.aboutHotel}
                  />
                  {/* <Typography>{props.hotelRoomParam.hotelInfo.hotelDetails.description}</Typography> */}
                </Grid>
              )}
              {props.hotelRoomParam.hotelInfo.hotelDetails.attractions && (
                <Grid item style={{ marginTop: 10 }}>
                  <span className={classes.headText}>Attractions</span>
                  {props.hotelRoomParam.hotelInfo.hotelDetails.attractions.map(
                    (attraction, idex) => (
                      <div
                        dangerouslySetInnerHTML={{ __html: attraction.value }}
                        key={idex}
                        className={classes.attractsHotel}
                      />
                    )
                  )}
                </Grid>
              )}
              {props.hotelRoomParam.hotelInfo.hotelDetails.hotelFacilities && (
                <Grid item style={{ marginTop: 10 }}>
                  <span className={classes.headText}>Facilities</span>
                  {props.hotelRoomParam.hotelInfo.hotelDetails.hotelFacilities.map(
                    (hotelFacility, indx) => (
                      <div
                        dangerouslySetInnerHTML={{ __html: hotelFacility }}
                        key={indx}
                        className={classes.facilititesHotel}
                      />
                    )
                  )}
                </Grid>
              )}
            </CardBody>
          </Card>
        </Grid>
      </AccordionComponent>

      {selectedRooms.room.length === 0 ? null : (
        <SelectedRoom
          setSelectedRooms={setSelectedRooms}
          selectedRooms={selectedRooms}
          hotelSearch={props.hotelSearch}
          bookRoom={bookRoom}
          roomCombination={roomCombination}
          hotelCode={props.hotelRoomParam.hotelCode}
          themeobj={theme}
        />
      )}
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    hotelRoomParam: state.hotel.hotelRoomParam,
    hotelSearch: state.hotel.hotelSearch,
  };
}

export default withRouter(connect(mapStateToProps)(HotelDetailPage));

const reviewBody = `Perched on a hilltop, experience the
 vivacious charm of Goa in the serene comfort of this chic Ginger 
 Dona Paula Hotel. Overlooking the Arabian Sea, spacious modern rooms, 
 with an option of private terrace or balconies, and a refreshing pool, 
 relax and soak in the pleasures of Goa at this one of a kind destination.`;

const hotelData = {
  images: [thumb1, thumb1, thumb1, thumb1, thumb1, thumb1, thumb1],
  hotelFacilities: ["good food", "good food", "good food", "good food"],
  hotelName: "GINGER HOTEL GURGON",
  starRating: 4.8,
  hotelAddress: "Gurgoan, Haryana",
  description: `Perched on a hilltop, experience the
  vivacious charm of Goa in the serene comfort of this chic Ginger 
  Dona Paula Hotel. Overlooking the Arabian Sea, spacious modern rooms, 
  with an option of private terrace or balconies, and a refreshing pool, 
  relax and soak in the pleasures of Goa at this one of a kind destination.`,
};

const AccordionComponent = (props) => {
  const { children, expandPanel, title, bodyStyle } = props;
  const theme = useTheme();
  const [expanded, setExpanded] = useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    //   console.log(panel, newExpanded, "LINE 228");
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Accordion
      // expanded={expanded === expandPanel}
      // expanded={
      //   // expandPanel === "panel2" ||
      //   // expandPanel === "panel3" ||
      //   expanded === expandPanel
      // }
      onChange={handleChange(expandPanel)}
      style={{ margin: "8px 4px" }}
      defaultExpanded={true}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls={`${expandPanel}bh-content`}
        id={`${expandPanel}bh-header`}
        style={{
          backgroundColor: theme.palette.background.light,
          color: theme.palette.background.carulean,
        }}
      >
        <Typography style={{ width: "90%", flexShrink: 0, fontWeight: 500 }}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={bodyStyle}>{children}</AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.background.selago,
  },
  // root: {
  //   // margin: theme.spacing(2, 0),
  //   padding: theme.spacing(4, 2),
  //   backgroundColor: "white",
  // },

  heading: {
    textTransform: "uppercase",
    fontWeight: "500",
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(22),
    letterSpacing: theme.typography.pxToRem(2),
    color: theme.palette.background.carulean,
    margin: theme.spacing(1.5, 0, 1),
  },
  stars: {
    // marginTop: theme.spacing(1),
  },

  subHeading: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(15),
    letterSpacing: theme.typography.pxToRem(0.4),
    color: theme.palette.secondary.sharkGray,
  },
  image: {
    width: "100%",
    height: "100%",
  },
  priceGroup: {
    margin: theme.spacing(1, 0),
  },
  originalPrice: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(18),
    letterSpacing: theme.typography.pxToRem(0.3575),
    textDecoration: "line-through",
    color: theme.palette.secondary.grandmaGray,
  },
  tagline: {
    fontWeight: "500",
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(18),
    color: theme.palette.secondary.darkBlack,
  },
  inr: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(22),
    lineHeight: theme.typography.pxToRem(37),
    letterSpacing: theme.typography.pxToRem(1.9),
    color: theme.palette.background.peach,
    margin: theme.spacing(0),
  },
  commission: {
    fontSize: theme.typography.pxToRem(10),
    lineHeight: theme.typography.pxToRem(12),
    letterSpacing: theme.typography.pxToRem(0.4),
    color: theme.palette.secondary.sharkGray,
  },
  benefits: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(12),
    letterSpacing: theme.typography.pxToRem(0.2026),
    color: theme.palette.background.carulean,
    margin: theme.spacing(0.5),
  },
  paper: {
    padding: theme.spacing(1),
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(8),
    lineHeight: theme.typography.pxToRem(10),
    letterSpacing: theme.typography.pxToRem(0.664),
    color: theme.palette.background.carulean,
    textTransform: "uppercase",
  },

  costInfo: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(12),
    letterSpacing: theme.typography.pxToRem(1),
    color: theme.palette.secondary.darkBlack,
    // marginBottom: theme.spacing(1),
    padding: theme.spacing(2, 2, 0),
    display: "flex",
    justifyContent: "space-between",
    "&:last-of-type": {
      padding: theme.spacing(2, 2),
    },
  },
  total: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(12),
    letterSpacing: theme.typography.pxToRem(1.5),
    color: theme.palette.primary.lightText,
    borderTop: `1px solid ${theme.palette.secondary.fordGray}`,
    borderBottom: `1px solid ${theme.palette.secondary.fordGray}`,
    padding: theme.spacing(1.5, 0),
    margin: theme.spacing(1.5, 0),
    backgroundColor: theme.palette.background.peach,
    "& span": {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  btn: {
    backgroundColor: theme.palette.background.peach,
    color: theme.palette.primary.lightText,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(15),
    textAlign: "center",
    letterSpacing: theme.typography.pxToRem(0.76),
    padding: theme.spacing(1.2, 2.5),
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  about: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    letterSpacing: theme.typography.pxToRem(0.3575),
    color: theme.palette.background.carulean,
  },
  des: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    letterSpacing: theme.typography.pxToRem(-0.06),
    color: theme.palette.secondary.charcoal,
    marginBottom: theme.spacing(1.5),
  },
  options: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 500,
    letterSpacing: theme.typography.pxToRem(0.3),
    color: theme.palette.secondary.darkShadedGray,
    margin: theme.spacing(1, 0),
  },
  map: {
    width: "100%",
    height: theme.typography.pxToRem(300),
    border: "1px solid black",
    margin: theme.spacing(1.5, 0),
  },
  starRating: {
    color: theme.palette.secondary.medallion,
    fontSize: theme.typography.pxToRem(16),
    marginLeft: theme.spacing(1),
  },
  priceContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textAlign: "right",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
  headText: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.background.carulean,
  },
  // accordionTitle: {
  //   [theme.breakpoints.down(650)]: {
  //     width: "80% !important",
  //   },
  // },
  aboutHotel: {
    fontFamily: theme.palette.font.secondary,
    lineHeight: 1.6
  },
  attractsHotel: {
    fontFamily: theme.palette.font.secondary,
    lineHeight: 1.6
  },
  facilititesHotel: {
    fontFamily: theme.palette.font.secondary,
    lineHeight: 1.6
  }
}));
