import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import StarRating from "components/HotelPage/HotelComponents/StarRating/StarRating";

import { useHistory, withRouter } from "react-router-dom";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import "components/HotelPage/HotelComponents/HotelListView/HotelListView.css";

import OaCard from "oahoc/OaCard";
import Viewer from "react-viewer";
import OaFormAlerts from "pages/components/OaFormAlerts";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";

import { connect } from "react-redux";
import { setHotelRoomParam } from "redux/action";
import { Box, Checkbox, Icon } from "@material-ui/core";
import Link from "@material-ui/core/Link";

import TripAdvisorIcon from "assets/icons/TripAdviser.jpeg";
import HotelMoreDetails from "./HotelMoreDetailsModal";
import HotelErrorImg from "assets/img/HotelNA.jpg";
import { basename } from "api/ApiConstants";
import { AppConstant } from "appConstant";

const HotelListView = ({
  searchData,
  hnf,
  hotel,
  resultIndex,
  hotelCode,
  traceId,
  categoryId,
  ...props
}) => {
  const [visible, setVisible] = React.useState(false);console.log("hotel 42", hotel)
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const hotelPriceRoundOff = AppConstant.hotelPriceRoundOff;
  //console.log("hotelPriceRoundOff ", hotelPriceRoundOff);

  const [collapse, setCollapse] = useState(true);
  const [hotelInfo, setHotelInfo] = useState(null);
  const [facilities, setFacilities] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hotelInfoDetails, setHotelInfoDetails] = useState();
  const [openMoreDetails, setOpenMoreDetails] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const importantFacilities = [
    "Breakfast available (surcharge)",
    "Free WiFi",
    "Laundry facilities",
  ];

  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setOpenMoreDetails(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMoreDetails(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [images, setImages] = useState([{ src: hotel.hotelPicture, alt: "" }]);

  const [fullImages, setFullImages] = useState([
    { src: hotel.hotelPicture, alt: "" },
  ]);

  const classes = useStyles();

  useEffect(() => {
    // console.log("HOTEL LIST VIEW TRACEID", traceId);
    return;
    apiCall(
      WebApi.getHotelInfo,
      {
        resultIndex: resultIndex,
        hotelCode: hotelCode,
        traceId: traceId,
        categoryId: categoryId,
      },
      (response) => {
        if (response.success === true) {
          // console.log(
          //   response.data.hotelSearchResult.hotelDetails.hotelName,
          //   "RESPONSE"
          // );
          setHotelInfoDetails(response.data.hotelSearchResult.hotelDetails);
          let images = [];
          response.data.hotelSearchResult.hotelDetails.images &&
            response.data.hotelSearchResult.hotelDetails.images.map(
              (url, index) => {
                images.push({ src: url, alt: "" });
              }
            );

          setImages(images);
          //  console.log("%%%%%%%%",response.data)
          let hotelFacilities =
            (response.data.hotelSearchResult.hotelDetails.hotelFacilities &&
              response.data.hotelSearchResult.hotelDetails.hotelFacilities
                .length == 1) ||
              0
              ? response.data.hotelSearchResult.hotelDetails.hotelFacilities[0]?.split(
                ","
              )
              : response.data.hotelSearchResult.hotelDetails.hotelFacilities;
          setFacilities(hotelFacilities);

          setHotelInfo(response.data.hotelSearchResult);
        } else {
          setIsError(!response.success);
          setInfoText(response.message);
        }
      }
    );
  }, [isLoading]);

  const getFacilityIcon = (description) => {
    switch (description) {
      case "Dry cleaning/laundry service":
        return "dry_cleaning";

      case "Covered parking":
        return "local_parking";

      case "Wheelchair accessible (may have limitations)":
        return "accessible";

      case "Luggage storage":
        return "luggage";

      case "24-hour front desk":
        return "supervisor_account";

      case "Wheelchair-accessible registration desk":
        return "accessible";

      case "Wheelchair-accessible concierge desk":
        return "accessible";

      case "Breakfast available (surcharge)":
        return "free_breakfast";

      case "Free WiFi":
        return "wifi";

      case "Library":
        return "local_library";

      case "Designated smoking areas":
        return "smoking_rooms";

      case "Barbecue grill(s)":
        return "outdoor_grill";

      case "Garden":
        return "yard";

      case "In-room accessibility":
        return "room_service";

      case "Designated smoking areas (fines apply)":
        return "smoking_rooms";

      case "Stair-free path to entrance":
        return "stairs";

      case "Laundry facilities":
        return "dry_cleaning";

      case "Free newspapers in lobby":
        return "feed";

      case "Tours/ticket assistance":
        return "confirmation_number";

      case "Elevator":
        return "elevator";

      case "Free self parking":
        return "local_parking";

      case "Terrace":
        return "deck";

      case "Concierge services":
        return "child_care";

      case "Newspapers in lobby (surcharge)":
        return "feed";

      case "Bar":
        return "local_bar";

      case "gym":
        return "fitness_center";

      case "restaurant":
        return "hotel";

      case "Reception hall":
        return "meeting_room";

      case "meeting_room":
        return "meeting_room";

      case "Free breakfast":
        return "free_breakfast";

      case "Smoke-free property":
        return "smoke_free";

      case "Accessible bathroom":
        return "wc";

      case "Coffee/tea in common areas":
        return "local_cafe";

      case "Shopping mall on site":
        return "add_shopping_cart";

      case "Safe-deposit box at front desk":
        return "account_balance_wallet";

      case "Television in common areas":
        return "tv";

      default:
        return "error_outline";
    }
  };

  const getFacilityText = (description) => {
    switch (description) {
      case "Dry cleaning/laundry service":
        return "Dry Clean";

      case "Covered parking":
        return "Covered Parking";

      case "Wheelchair accessible (may have limitations)":
        return "Wheelchair";

      case "Luggage storage":
        return "Luggage";

      case "24-hour front desk":
        return "Front Desk always";

      case "Wheelchair-accessible registration desk":
        return "Wheelchair";

      case "Wheelchair-accessible concierge desk":
        return "Wheelchair";

      case "Breakfast available (surcharge)":
        return "Breakfast";

      case "Free WiFi":
        return "WiFi";

      case "Library":
        return "Library";

      case "Designated smoking areas":
        return "Smoking Areas";

      case "Barbecue grill(s)":
        return "Barbecue";

      case "Garden":
        return "Garden";

      case "In-room accessibility":
        return "Room Service";

      case "Designated smoking areas (fines apply)":
        return "Smoking Areas";

      case "Stair-free path to entrance":
        return "Stair Free";

      case "Laundry facilities":
        return "Laundry";

      case "Free newspapers in lobby":
        return "Newspapers";

      case "Tours/ticket assistance":
        return "Tours Assistance";

      case "Elevator":
        return "Elevator";

      case "Free self parking":
        return "Free Parking";

      case "Terrace":
        return "Terrace";

      case "Concierge services":
        return "Concierge services";

      case "Newspapers in lobby (surcharge)":
        return "Newspapers";

      case "Bar":
        return "Bar";

      case "gym":
        return "Gym";

      case "restaurant":
        return "Restaurant";

      case "Reception hall":
        return "Reception hall";

      case "meeting_room":
        return "Meeting Room";

      case "Free breakfast":
        return "Free Breakfast";

      case "Smoke-free property":
        return "Smoke Free";

      case "Accessible bathroom":
        return "Accessible Bathroom";

      case "Shopping mall on site":
        return "Shopping Mall";

      case "Safe-deposit box at front desk":
        return "Safe Deposite";

      case "Coffee/tea in common areas":
        return "Coffee/tea in common areas";

      case "Television in common areas":
        return "Common Television";

      default:
        return "NA";
    }
  };

  const preventDefault = (event) => {
    setCollapse(!collapse);
    event.preventDefault();
  };

  const hotelDetailPage = () => {
    // console.log(
    //   hotelInfo,
    //   resultIndex,
    //   hotel,
    //   traceId,
    //   hotelCode,
    //   images,
    //   searchData
    // );
    apiCall(
      WebApi.getHotelInfo,
      {
        resultIndex: resultIndex,
        hotelCode: hotelCode,
        traceId: traceId,
        categoryId: categoryId,
      },
      (response) => {
        if (response.success === true) {
          let images = [];
          response.data.hotelSearchResult.hotelDetails.images &&
            response.data.hotelSearchResult.hotelDetails.images.map(
              (url, index) => {
                images.push({ src: url, alt: "" });
              }
            );

          setImages(images);
          //  console.log("%%%%%%%%",response.data)
          let hotelFacilities =
            (response.data.hotelSearchResult.hotelDetails.hotelFacilities &&
              response.data.hotelSearchResult.hotelDetails.hotelFacilities
                .length == 1) ||
              0
              ? response.data.hotelSearchResult.hotelDetails.hotelFacilities[0]?.split(
                ","
              )
              : response.data.hotelSearchResult.hotelDetails.hotelFacilities;
          setFacilities(hotelFacilities);

          setHotelInfo(response.data.hotelSearchResult);

          props.setHotelRoomParam({
            hotelInfo: response.data.hotelSearchResult,
            resultIndex,
            hotel,
            traceId,
            hotelCode,
            images,
            searchData,
          });

          window.open(basename + "/hotel/HotelDetail", "_blank");
        } else {
          setIsError(!response.success);
          setInfoText(response.message);
        }
      }
    );
    return;
    const int = setInterval(() => {
      if (hotelInfo) {
        //  console.log("#######hotelinfo set inteval #######",hotelInfo)
        //  console.log("######interval time ",int)
        clearInterval(int);

        apiCall(
          WebApi.getHotelInfo,
          {
            resultIndex: resultIndex,
            hotelCode: hotelCode,
            traceId: traceId,
            categoryId: categoryId,
          },
          (response) => {
            if (response.success === true) {
              let images = [];
              response.data.hotelSearchResult.hotelDetails.images &&
                response.data.hotelSearchResult.hotelDetails.images.map(
                  (url, index) => {
                    images.push({ src: url, alt: "" });
                  }
                );

              setImages(images);
              //  console.log("%%%%%%%%",response.data)
              let hotelFacilities =
                (response.data.hotelSearchResult.hotelDetails.hotelFacilities &&
                  response.data.hotelSearchResult.hotelDetails.hotelFacilities
                    .length == 1) ||
                  0
                  ? response.data.hotelSearchResult.hotelDetails.hotelFacilities[0]?.split(
                    ","
                  )
                  : response.data.hotelSearchResult.hotelDetails
                    .hotelFacilities;
              setFacilities(hotelFacilities);

              setHotelInfo(response.data.hotelSearchResult);

              props.setHotelRoomParam({
                hotelInfo: response.data.hotelSearchResult,
                resultIndex,
                hotel,
                traceId,
                hotelCode,
                images,
                searchData,
              });

              window.open(basename + "/hotel/HotelDetail", "_blank");
            } else {
              setIsError(!response.success);
              setInfoText(response.message);
            }
          }
        );
      }
    }, 1000);
  };

  // console.log(
  //   hotel.price.commissionCharge.grossProductPrice.toString().split(".")[0]
  // );

  // console.log(hotel.starRating, hotel.hotelName);

  return (
    <OaCard>
      <OaFormAlerts
        isSaving={isSaving}
        isSuccess={isSuccess}
        isError={isError}
        infoText={infoText}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
      />
      <Grid container spacing={2} style={{ padding: "5px 0px" }}>
        <Grid
          container
          spacing={2}
          //style={{ cursor: "pointer" }}
          className={classes.carouselGrid}
        >
          <Grid container justifyContent="space-between" xs={12} md={3}>
            {/* <Carousel
            autoPlay
            infiniteLoop
            useKeyboardArrows
            showThumbs={false}
            onClickItem={(index, event) => {
              // console.log(event);
              setCurrentIndex(index);
              setVisible(true);
            }}
            showIndicators={false}
            stopOnHover={true}
          >
            {images &&
              images.map((value, index) => {
                return (
                  <div>
                    <img src={value.src} height="200" width="auto" />
                  </div>
                );
              })}
          </Carousel> */}
            <div className={classes.hotelThumbnailContainer}>
              <img
                className="hotel-thumbnail-image"
                src={images[0]?.src}
                onError={(e) => (
                  (e.target.onerror = null), (e.target.src = HotelErrorImg)
                )}
              />
            </div>

            {/* <Offer discount={hotel.price.discount} /> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                justify: "flex-end",
              }}
              className="pricing-top"
            >
              <span className="heading">
                {hotel.price.currencyCode === "INR"
                  ? "₹"
                  : hotel.price.currencyCode}{" "}
                {/* {hotel.price.commissionCharge.grossProductPrice} */}
                {hotelPriceRoundOff
                  ? hotel.price.commissionCharge.grossProductPrice.toFixed(0).toLocaleString('en-IN')
                  : hotel.price.commissionCharge.grossProductPrice.toLocaleString('en-IN')
                  // hotel.price.commissionCharge.grossProductPrice
                  //   .toString()
                  //   .split(".")[0]
                }
              </span>
              {/* <ArrowDropUp /> */}
              <div className={classes.lowestRate}>
                Lowest Total Rate <br />
                (Incl. Taxes)
              </div>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={6}
          // onClick={hotelDetailPage}
          >
            <Grid item xs={12}>
              <span
                style={{ cursor: "pointer" }}
                onClick={hotelDetailPage}
                className={classes.heading}
              >
                {hotel.hotelName ?? hotelInfoDetails?.hotelName}
                {/* {hotelInfoDetails?.hotelName} */}
              </span>
              <Grid
                item
                xs={12}
                style={{
                  [theme.breakpoints.up(400)]: {
                    display: "flex",
                  },
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <StarRating rating={hotel.starRating} />
                {/* <div>
                  <span
                    style={{
                      [theme.breakpoints.up(400)]: {
                        marginLeft: 20,
                      },
                      marginBottom: 4,
                      verticalAlign: "top",
                    }}
                  >
                    Guest Rating
                  </span>
                  <StarRating rating={hotel.starRating} />
                </div> */}
                {/* <StarIcon className={classes.starIcon}></StarIcon> */}
              </Grid>
              {/* </Grid> */}
              {hotel.hotelAddress && (
                <Grid item xs={12}>
                  <span className={classes.subHeading}>
                    {hotel.hotelAddress}
                  </span>
                </Grid>
              )}
              {/* <Grid item container xs={12}> */}
              {/* {images && images.length >0 &&
              [...Array(images.length > 4 ? 4 : images.length - 1)].map(
                (x, i) => (
                  <Grid item md={2}>
                    <img
                      src={images[i].src}
                      width="50"
                      height="50"
                      onClick={(e) => {
                        e.stopPropagation();
                        setCurrentIndex(`${i}`);
                        setVisible(true);
                      }}
                    />

                    {images.length > 4 && i === 3 && (
                      <div
                        style={{
                          marginTop: -35,
                          marginLeft: 10,
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        {images.length - 4} +
                      </div>
                    )} */}
              {/* <p className="legend">{images[i].alt}</p> */}
              {/* </Grid>
                )
              )} */}
              <Typography variant="body">
                {/* {hotel?.hotelDescription ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: hotel?.hotelDescription.substring(0, 50),
                    }}
                  />
                ) : (
                  ""
                )} */}
                {hotel?.description || ""}
              </Typography>
              <Link
                href="#"
                // aria-describedby={id}
                variant="contained"
                color="primary"
                onClick={handleClick}
                className={classes.moreButton}
              >
                <u>more details</u>
              </Link>
              {/* <Popover
                // id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                {/* {console.log({facilities})} */}
              {/* <Grid
                  item
                  container
                  xs={12}
                  style={{ width: 700, padding: 50 }}
                >
                  {facilities &&
                    facilities.map((facility, index) => {
                      return (
                        <Grid
                          container
                          item
                          xs={4}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              border: "solid #fbfaf8 2px",
                              padding: 5,
                              margin: 5,
                            }}
                          >
                            <Icon>{getFacilityIcon(facility)}</Icon>
                          </Grid>
                          <Grid
                            xs={12}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <p className={classes.para}>{facility}</p>
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
              </Popover>  */}
            </Grid>
            <Grid container xs={12}>
              <Link style={{ alignSelf: "center", marginRight: 2 }}>
                <img src={TripAdvisorIcon} alt="" height={18} width={24} />
              </Link>
              {hotel?.tripAdvisor?.rating !== "0" && (
                <div style={{ marginTop: 6 }}>
                  <StarRating
                    rating={hotel?.tripAdvisor?.rating}
                    tripAdviserRating
                  />
                </div>
              )}
              {/* <span style={{ margin: "5px 10px" }}>|</span>
              <Link className={classes.viewMapButton}>
                <u>View Map</u>
              </Link> */}
              {/* <Grid item container xs={12} style={{ marginTop: 25 }}>
              {facilities && facilities.length > 0 &&
                [...Array(facilities.length > 3 ? 3 : facilities.length)].map(
                  (value, index) => {
                    return (
                      <Grid item container xs={4}>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Icon>{getFacilityIcon(facilities[index])}</Icon>
                        </Grid>
                    {!isMobile &&    <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p className={classes.para}>
                            {getFacilityText(facilities[index]) === "NA"
                              ? facilities[index]
                              : getFacilityText(facilities[index])}
                          </p> */}
              {/* toTitleCase(getFacilityIcon(facilities[index]).replace('_', ' ')) */}
              {/* </Grid> }
                      </Grid>
                    );
                  }
                )}
            </Grid> */}
            </Grid>
          </Grid>
          <Grid
            item
            container
            md={3}
            xs={12}
            // style={{ background: "#FAFAFA" }}
            justifyContent="flex-end"
            alignItems="center"
          // justifyContent="flex-end"
          //
          // onClick={hotelDetailPage}
          >
            <Grid
              item
              container
              md={12}
              xs={4}
              // justifyContent="flex-end"
              alignItems="center"
              justifyContent="flex-end"
              className="pricing-bottom"
            >
              {/* <Offer discount={hotel.price.discount} /> */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // alignItems: "center",
                  justify: "flex-end",
                }}
                className="pricing-low"
              >
                <span className="heading">
                  {hotel.price.currencyCode === "INR"
                    ? "₹"
                    : hotel.price.currencyCode}{" "}
                  {/* {hotel.price.commissionCharge.grossProductPrice} */}
                  {// hotel.price.commissionCharge.grossProductPrice
                    //   .toString()
                    //   .split(".")[0]
                    hotelPriceRoundOff
                      ? (hotel.price.commissionCharge.grossProductPrice.toFixed(0)).toLocaleString('en-IN')
                      : (hotel.price.commissionCharge.grossProductPrice).toLocaleString('en-IN')}
                </span>
                {/* <ArrowDropUp /> */}
                <div className={classes.lowestRate}>
                  Lowest Total Rate <br />
                  (Incl. Taxes)
                </div>
              </Box>
            </Grid>
            {/* <Grid
            item
            container
            md={12}
            xs={8}
            justifyContent={isMobile ? "flex-end" : "center"}
            alignItems="center"
          >
            <span
              className="hotel-price-exclude"
              style={{
                textDecorationLine:
                  hotel.price.discount == 0 ? "none" : "line-through",
              }}
            >
              Original Price : {hotel.price.currencyCode}{" "}
              {hotel.price.commissionCharge.grossProductPrice}
            </span>
          </Grid> */}
            {/* <Grid
              item
              container
              xs={12}
              justifyContent="flex-end"
            >
              <span
              className={classes.inr}
              style={{ justifyContent: "center", display: "flex" }}
            >
              {hotel.price.currencyCode}
              {hotel.price.commissionCharge.grossProductPrice}
            </span>
              <Checkbox
                className={classes.Checkbox}
                edge="end"
                // onChange={props.handleTimingToggle(value)}
                // checked={props.timing.indexOf(value) !== -1}
              />
              <span className={classes.checkboxLabel}>Email</span>
            </Grid> */}

            {/* <Grid
              item
              container
              xs={12}
            justifyContent={isMobile ? "flex-end" : "center"}
            >
              <span className="hotel-price">
              Per Night for 1 Room - {hotel.price.currencyCode}{" "}
              {(
                hotel.price.commissionCharge.grossProductPrice /
                searchData.noOfNights
              ).toFixed(0)}
            </span>
            </Grid> */}
            {hnf && (
              <Grid item>
                <span className="hotel-price">
                  Net Commission + Markup:{" "}
                  {hotel.price.currencyCode === "INR"
                    ? "₹"
                    : hotel.price.currencyCode}
                  {hotel.price.commissionCharge.agentIncome +
                    hotel.price.commissionCharge.agentMarkup}
                </span>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          md={12}
          className={classes.bookNowContainer}
          justifyContent="flex-end"
        >
          <Button
            className={classes.bookButton}
            variant="contained"
            color="primary"
            onClick={hotelDetailPage}
          >
            Book Now
          </Button>
        </Grid>
      </Grid>

      <div>
        <Viewer
          onMaskClick={(e) => {
            setVisible(false);
          }}
          activeIndex={currentIndex}
          visible={visible}
          onClose={() => {
            setVisible(false);
          }}
          images={images}
          noClose={false}
        />
      </div>
      {openMoreDetails && (
        <HotelMoreDetails
          open={openMoreDetails}
          anchorEl={anchorEl}
          handleClose={handleClose}
          hotel={hotel}
          resultIndex={resultIndex}
          hotelCode={hotelCode}
          traceId={traceId}
          categoryId={categoryId}
        />
      )}
    </OaCard>
  );
};

function mapStateToProps(state, props) {
  return {
    //   hotelInfo: state.hotel.hotelInfo
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setHotelRoomParam: (data) => dispatch(setHotelRoomParam(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HotelListView);

const useStyles = makeStyles((theme) => ({
  bookButton: {
    padding: "unset",
    width: 85,
    height: 40,
    background: theme.palette.buttons.bookNowBtn,
    // cursor: "pointer",
    color: theme.palette.primary.lightText,
    // border: `solid 1px ${theme.palette.secondary.main}`,
    "&:hover": {
      //background: theme.palette.secondary.main,
    },
    [theme.breakpoints.down(650)]: {
      position: "relative",
      bottom: 50,
    },
  },
  bookNowContainer: {
    [theme.breakpoints.down(650)]: {
      height: 0,
    },
  },
  lowestRate: {
    fontSize: 12,
  },
  heading: {
    color: theme.palette.primary.info,
    fontSize: 20,
    fontWeight: 500,
  },
  carouselGrid: {
    [theme.breakpoints.down(600)]: {
      padding: 5,
    },
    "& .pricing-top": {
      display: "none",

      [theme.breakpoints.down(960)]: {
        display: "unset",
        marginTop: 15,
      },
      "& .heading": {
        fontSize: 20,
        fontWeight: 500,
      },
      "& .lowestRate": {
        fontSize: 15,
      },
    },
    "& .pricing-bottom": {
      // display: "none",
      [theme.breakpoints.down(960)]: {
        display: "none",
      },
      fontSize: 20,
      "& .heading": {
        fontSize: 20,
        fontWeight: 500,
      },
    },
  },
  hotelThumbnailContainer: {
    display: "flex",
    justifyContent: "center",
    width: 200,
    height: 150,
    borderRadius: 10,
    background: theme.palette.background.dark,
    overflow: "hidden",
    [theme.breakpoints.down(450)]: {
      width: 130,
    },
    "& .hotel-thumbnail-image": {
      // borderRadius: 10,
      width: 150,
      height: 150,
    },
  },
  moreButton: {
    color: theme.palette.buttons.defaultContrast,
  },
}));
