import React from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Grid,
  Divider,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Formik, Form, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
// import _ from "lodash";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import { localforageGetItem } from "oautils/oaForageUtils";
import OaFormDateField from "oahoc/OaFormDateField";
import dateFnsFormat from "date-fns/format";

import { useMediaQuery } from "@material-ui/core";
import { AppConstant } from "appConstant";
import HotelPaymentOptions from "pages/module/hotel/HotelPaymentOptions";
import { Close } from "@material-ui/icons";
import OaFormikErrorAlert from "oahoc/OaFormikErrorAlert";
import DatePickerWithCustomHeader from "oahoc/DatePickerCustomHeader ";
import OaAlert from "oahoc/OaAlert";

export class GuestObj {
  constructor(name, age, gender, nationality) {
    this.name = name;
    this.age = age;
    this.gender = gender;
    this.nationality = nationality;
  }
}

const ContactCard = ({
  hotelSearch,
  totalFare,
  hotelInfo,
  selectedRooms,
  hotelCode,
  traceId,
  locationProps,
  hotelInfoProps,
  couponObj,
  totalPayable,
  discountApplied,
  ...props
}) => {
  const classes = useStyles();
  const initialValues = {
    room: [],
    mobile: "",
    email: "",
    pan: "",
    acceptTNC: false,
  };
  const [data, setData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  const formikRef = React.useRef();
  const [showTNC, setShowTNC] = React.useState(false);
  const [showFareRates, setShowFareRates] = React.useState(false);
  const [userType, setUserType] = React.useState(null);
  const [paymentModes, setPaymentModes] = React.useState([]);
  const [selectedPaymentMode, setSelectedPaymentMode] = React.useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(
    null
  );
  const [isErrorOpen, setIsErrorOpen] = React.useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [payModeTabIndex, setPayModeTabIndex] = React.useState(0);
  const [openTNC, setOpenTNC] = React.useState(false);
  const [openFareRules, setOpenFareRules] = React.useState(false);
  const [isPayable, setIsPayable] = React.useState(false);

  const [walletCriteria, setWalletCriteria] = React.useState(false);
  const [odExpired, setOdExpired] = React.useState(false);

  const [balanceStr, setBalanceStr] = React.useState(false);
  const [isAlertPrompt, setIsAlertPrompt] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState("");
  const [primaryButtonText, setPrimaryButtonText] = React.useState("");
  const [secondaryButtonText, setSecondaryButtonText] = React.useState("");

  const [alertTitle, setAlertTitle] = React.useState("");

  console.log("selectedRooms", selectedRooms);
  const history = useHistory();


  const TitleComp = (props) => {
    return (
      <div className="section-title-wrapper">
        <span className="heading-icon">
          <i className={props.fa} />
        </span>
        <Typography className="heading-text">{props.title}</Typography>
      </div>
    );
  };

  hotelSearch.roomGuests.map((guest, index) => {
    const temp = { adult: [], child: [] };
    for (let i = 0; i < parseInt(guest.noOfAdults); i++) {
      temp.adult.push({
        title: "",
        firstName: "",
        lastName: "",
        age: "",
        gender: "",
        nationality: hotelSearch.guestNationality,
        passportNo: "",
        issueDate: new Date(),
        expiryDate: new Date(),
        dobDisplay: new Date(),
      });
    }
    for (let i = 0; i < parseInt(guest.noOfChild); i++) {
      temp.child.push({
        title: "",
        firstName: "",
        lastName: "",
        age: guest.childAge[i],
        gender: "",
        nationality: hotelSearch.guestNationality,
        passportNo: "",
        issueDate: new Date(),
        expiryDate: new Date(),
        dobDisplay: new Date(),
      });
    }
    initialValues.room[index] = temp;
  });


  const validatePaxDetails = (values) => {
    let noErrorFound = true;

    // console.log("values",values);
    let room = values.room;

    for (let rm = 0; rm < room.length; rm++) {
      ///console.log("room[rm].adults ", room[rm].adult);
      //console.log("room[rm].child ", room[rm].child);

      if (noErrorFound) {
        noErrorFound = validateIndividualPaxDetails(
          room[rm].adult,
          room[rm].adult.length,
          "Adult",
          rm + 1
        );
      }
      //setAlertMsg("noErrorFound ", noErrorFound);
      if (noErrorFound) {
        if (room[rm].child.length > 0) {
          noErrorFound = validateIndividualPaxDetails(
            room[rm].child,
            room[rm].child.length,
            "Child",
            rm + 1
          );
        }
      }
    }
    if (noErrorFound) {
      if (values.mobile === "" || values.mobile === null) {
        setAlertMsg("Mobile Number should not be empty ");
        noErrorFound = false;
      }
      if (noErrorFound) {
        if (!/^[0-9]*$/.test(values.mobile)) {
          setAlertMsg("Mobile Number should contains Numbers only ");
          noErrorFound = false;
        }
      }
      if (noErrorFound) {
        if (values.mobile.length !== 10) {
          setAlertMsg("Mobile Number should be 10 digits Numbers only ");
          noErrorFound = false;
        }
      }

      if (noErrorFound) {
        if (values.email === "" || values.email === null) {
          setAlertMsg("Email Id should not be empty ");
          noErrorFound = false;
        }
      }
      if (noErrorFound) {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
          setAlertMsg("Invalid Email Id ");
          noErrorFound = false;
        }
      }
      if (noErrorFound) {
        if (values.pan === "") {
          setAlertMsg("PAN Number should not be empty ");
          noErrorFound = false;
        }
      }
      if (noErrorFound) {
        if (!values.acceptTNC) {
          setAlertMsg("Please accept Terms and Conditions");
          noErrorFound = false;
        }
      }
    }

    //    noErrorFound = false;

    return noErrorFound;
  };

  const validateIndividualPaxDetails = (paxInfo, totalPax, paxType, room) => {
    let allFieldNotOk = true;

    for (let k = 0; k < totalPax; k++) {
      // console.log("values.isDomestic" , paxInfo[k]);

      let paxTitle = paxInfo[k].title;
      let paxFname = paxInfo[k].firstName;
      let paxLname = paxInfo[k].lastName;
      let age = paxInfo[k].age;
      let gender = paxInfo[k].gender;

      // console.log("paxTitle" ,paxInfo[k]);
      if (paxTitle === "") {
        setAlertMsg(
          "Please select the title of the room :" +
          room +
          " ," +
          paxType +
          " " +
          (k + 1)
        );
        allFieldNotOk = false;
        // adultPax[k]
        break;
      }
      if (paxFname === "") {
        setAlertMsg(
          "Please Enter the firstname of the room :" +
          room +
          " ," +
          paxType +
          " " +
          (k + 1)
        );
        allFieldNotOk = false;
        // adultPax[k]
        break;
      }
      if (!/^[A-Za-z ]*$/.test(paxFname)) {
        setAlertMsg(
          "Firstname should contains Alphabates and space only of the room :" +
          room +
          " ," +
          paxType +
          " " +
          (k + 1)
        );
        allFieldNotOk = false;
        break;
      }
      if (!/^[^\s][A-Za-z ]*$/.test(paxFname)) {
        setAlertMsg(
          "Firstname cant be start with space of the room :" +
          room +
          " ," +
          paxType +
          " " +
          (k + 1)
        );
        allFieldNotOk = false;
        break;
      }

      if (paxFname.length < 2) {
        setAlertMsg(
          "Firstname should not be less than 2 char of the room :" +
          room +
          " ," +
          paxType +
          " " +
          (k + 1)
        );
        allFieldNotOk = false;
        // adultPax[k]
        break;
      }
      if (paxLname === "") {
        setAlertMsg(
          "Please select the lastname of the room :" +
          room +
          " ," +
          paxType +
          " " +
          (k + 1)
        );
        allFieldNotOk = false;
        // adultPax[k]
        break;
      }
      if (!/^[A-Za-z ]*$/.test(paxLname)) {
        setAlertMsg(
          "Lastname should contains Alphabates and space only of the room :" +
          room +
          " ," +
          paxType +
          " " +
          (k + 1)
        );
        allFieldNotOk = false;
        break;
      }
      if (!/^[^\s][A-Za-z ]*$/.test(paxLname)) {
        setAlertMsg(
          "Lastname cant be start with space of the room :" +
          room +
          " ," +
          paxType +
          " " +
          (k + 1)
        );

        allFieldNotOk = false;
        break;
      }

      if (age === "") {
        setAlertMsg(
          "Age should not be empty of the room :" +
          room +
          " ," +
          paxType +
          " " +
          (k + 1)
        );
        allFieldNotOk = false;
        break;
      }

      if (!/^[0-9]*$/.test(age)) {
        setAlertMsg(
          "Age should contains Numbers only of the room :" +
          room +
          " ," +
          paxType +
          " " +
          (k + 1)
        );
        allFieldNotOk = false;
        break;
      }

      // console.log("parseFloat(age) " +"," + parseFloat(age) + ", paxType"+ paxType);
      if (paxType === "Adult") {
        if (parseFloat(age) < 12) {
          setAlertMsg(
            "Age should be more than 12 Years of the room :" +
            room +
            " ," +
            paxType +
            " " +
            (k + 1)
          );
          allFieldNotOk = false;
          break;
        }
      } else {
        if (parseFloat(age) > 12) {
          setAlertMsg(
            "Age should be less than 12 Years of the room :" +
            room +
            " ," +
            paxType +
            " " +
            (k + 1)
          );
          allFieldNotOk = false;
          break;
        }
      }

      if (gender === "0" || gender === "") {
        setAlertMsg(
          "Please select Gender of the room :" +
          room +
          " ," +
          paxType +
          " " +
          (k + 1)
        );
        allFieldNotOk = false;
        break;
      }
    }
    return allFieldNotOk;
  };

  const totalCost = (selectedRooms) => {
    let total = 0;
    selectedRooms.map((room) => {
      total = total + room.price;
    });
    return total.toFixed(2);
  };

  const handleTncOpen = () => {
    setOpenTNC(true);
  };

  const handleCloseTNC = () => {
    setOpenTNC(false);
  };

  const handleOpenFareRules = () => {
    setOpenFareRules(true);
  };

  const handleCloseFareRules = () => {
    setOpenFareRules(false);
  };

  const handleOnSubmit = (values) => {
    if (validatePaxDetails(values)) {
      paymentModes.map((payMode) => {
        if (payMode.method == "cash") {
          values.pgOption = {
            method: "cashPayment",
            selected: payMode.selected,
          };
          values.payMode = "cash";
        } else {
          payMode.gateway.map((gatew) => {
            if (gatew.method === selectedPaymentMethod) {
              values.pgOption = gatew;
              values.payMode = payMode.method;
            }
          });
        }
      });
      history.push("/hotel/reviewbooking", {
        values,
        hotelInfo,
        hotelSearch,
        totalFare,
        selectedRooms,
        hotelCode,
        traceId, //trace ID added
        couponObj,
        totalPayable,
        discountApplied
      });
    } else {
      setAlertTitle("Fields Mandatory");
      setIsError(true);
      setPrimaryButtonText("OK");
      setIsAlertPrompt(true);
    }
  };

  React.useEffect(() => {
    if (isLoading) {
      localforageGetItem("user-details", function (err, value) {
        if (value) {
          props.setUserId(value.userId);
          setUserType(value.userType ? value.userType : "b2c_user");
          // console.log(' ### value.userType ##  ' + value.userType);

          WebApi.getCompanyPGOptions(
            {
              partnerId: value.partnerId,
              serviceId: 2,
            },
            (response) => {
              let paymentModeList = response.data;

              for (let [ind, pgObj] of paymentModeList.entries()) {
                if (pgObj.selected) {
                  setPayModeTabIndex(ind);
                  setSelectedPaymentMode(pgObj);
                  for (let payGate of pgObj.gateway) {
                    if (payGate.selected) {
                      setSelectedPaymentMethod(payGate.method);
                    }
                  }
                }

                // if(pgObj.method == 'paygoal') {
                //   pgObj.gateway.push({
                //     "charge": 1,
                //     "charge_type": "P",
                //     "method_label": "Online Payment",
                //     "method": "card",
                //     "selected": true,
                //     "methodOwnChargeHandle": true,
                //   });
                // }
              }
              setPaymentModes(paymentModeList);
            },
            (error) => console.log("getCompanyPGOptions error", error)
          );
        }
      });

      localforageGetItem("user-id", function (err, value) {
        apiCall(
          WebApi.getAgentExtendedDetails,
          { agentId: value },
          (response) => {
            setIsLoading(false);

            if (response.success === true) {
              if (
                response.data?.isBaseCreditSet &&
                new Date() < new Date(response.data?.creditValidity)
              ) {
                if (response.data.availableCreditAmount >= totalFare) {
                  setWalletCriteria(true);
                  setBalanceStr(
                    "Current Balance is : " +
                    response.data.balance +
                    ", Available OD : " +
                    response.data.availableCreditAmount
                  );
                } else {
                  setWalletCriteria(response.data.balance >= totalFare);
                  setBalanceStr(
                    "Current Balance is : " + response.data.balance
                  );
                }
              } else {
                setWalletCriteria(response.data.balance >= totalFare);
                setBalanceStr("Current Balance is : " + response.data.balance);
              }
              setOdExpired(
                new Date() > new Date(response.data?.creditValidity)
              );
              setData(response.data);
              formikRef.current.setFieldValue(
                "mobile",
                response.data.mobilenumber
              );
              formikRef.current.setFieldValue("email", response.data.email);
            } else {
              setIsError(true);
            }
          }
        );
      });
    }
  }, [isLoading]);

  return (
    <>
      {isError && (
        <Grid item md={12} xs={12}>
          <OaAlert
            isPrompt={isAlertPrompt}
            msg={alertMsg}
            title={alertTitle}
            isError={isError}
            primaryButtonText={primaryButtonText}
            secondaryButtonText={secondaryButtonText}
            togglePrompt={() => {
              setIsAlertPrompt(!isAlertPrompt);
            }}
          ></OaAlert>
        </Grid>
      )}

      <div className={classes.root}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
        // validationSchema={schema}
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            <Form className={classes.formRoot}>
              {/* --------------------pax start---------------- */}
              <Grid
                className={classes.sectionContainer}
                style={{ marginTop: 15 }}
              >
                <FieldArray
                  name="room"
                  render={() => (
                    <div>
                      {values.room &&
                        values.room.map((value, index) => {
                          return (
                            <Grid container key={index}>
                              <Grid item xs={12}>
                                {/* <Typography
                                component="h6"
                                variant="h6"
                                className={classes.sectionHeading}
                                style={{ color: "#007A92", fontSize: 20 }}
                              >
                                Guest Details ( Room {index + 1})
                              </Typography> */}

                                <div
                                  className="section-header"
                                // style={{
                                //   borderBottom: "1px solid rgba(0,0,0,0.125)",
                                // }}
                                >
                                  <TitleComp
                                    fa="fa fa-users"
                                    title={` Guest Details ( Room ${index +
                                      1})`}
                                  />
                                </div>
                              </Grid>
                              <Grid
                                style={{
                                  backgroundColor:
                                    theme.palette.background.lightGrayDefault,
                                  padding: 15,
                                  margin: 15,
                                  // marginBottom: 0,
                                  borderRadius: 5,
                                  width: "100%",
                                }}
                              >
                                {value.adult && (
                                  <Grid item xs={12}>
                                    <Typography
                                      component="h6"
                                      variant="h6"
                                      className={classes.sectionHeading}
                                    >
                                      Adult Info
                                    </Typography>
                                  </Grid>
                                )}
                                {value.adult &&
                                  value.adult.map((adult, secIndex) => (
                                    <Grid item container spacing={1}>
                                      <Grid item xs={12} md={2}>
                                        <TextField
                                          select
                                          fullWidth
                                          label="Title"
                                          name={`room[${index}].adult[${secIndex}].title`}
                                          onChange={handleChange}
                                          variant="outlined"
                                        // className="select"
                                        // style={{
                                        //   width: isMobile?"100%":"80px",
                                        //   background:"red"
                                        // }}
                                        >
                                          <MenuItem value="Mr">Mr</MenuItem>
                                          <MenuItem value="Mrs">Mrs</MenuItem>
                                          <MenuItem value="Miss">Miss</MenuItem>
                                          <MenuItem value="Ms">Ms</MenuItem>
                                        </TextField>
                                        <ErrorMessage
                                          name={`room[${index}].adult[${secIndex}].title`}
                                          component="div"
                                          className="error"
                                        />

                                        <OaFormikErrorAlert
                                          name={`room[${index}].adult[${secIndex}].title`}
                                          error={props.error}
                                          touched={props.touched}
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={2}>
                                        <TextField
                                          fullWidth
                                          label="First Name"
                                          name={`room[${index}].adult[${secIndex}].firstName`}
                                          onChange={handleChange}
                                          variant="outlined"
                                        // className={classes.inputLabel}
                                        />
                                        <ErrorMessage
                                          name={`room[${index}].adult[${secIndex}].firstName`}
                                          component="div"
                                          className="error"
                                        />
                                        <OaFormikErrorAlert
                                          name={`room[${index}].adult[${secIndex}].firstName`}
                                          error={props.error}
                                          touched={props.touched}
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={2}>
                                        <TextField
                                          fullWidth
                                          label="Last Name"
                                          name={`room[${index}].adult[${secIndex}].lastName`}
                                          onChange={handleChange}
                                          variant="outlined"
                                          className={classes.inputLabel}
                                        />
                                        <ErrorMessage
                                          name={`room[${index}].adult[${secIndex}].lastName`}
                                          component="div"
                                          className="error"
                                        />

                                        <OaFormikErrorAlert
                                          name={`room[${index}].adult[${secIndex}].lastName`}
                                          error={props.error}
                                          touched={props.touched}
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={1}>
                                        <TextField
                                          fullWidth
                                          label="Age"
                                          name={`room[${index}].adult[${secIndex}].age`}
                                          //type="number"
                                          onChange={handleChange}
                                          variant="outlined"
                                        // className={classes.inputLabel1}
                                        />
                                        <ErrorMessage
                                          name={`room[${index}].adult[${secIndex}].age`}
                                          component="div"
                                          className="error"
                                        />

                                        <OaFormikErrorAlert
                                          name={`room[${index}].adult[${secIndex}].age`}
                                          error={props.error}
                                          touched={props.touched}
                                        />
                                      </Grid>

                                      <Grid item xs={12} md={2}>
                                        <TextField
                                          fullWidth
                                          select
                                          label="Gender"
                                          name={`room[${index}].adult[${secIndex}].gender`}
                                          onChange={handleChange}
                                          variant="outlined"
                                          className="select"
                                          style={{
                                            width: isMobile ? "100%" : "100%",
                                          }}
                                        >
                                          <MenuItem value="0" disabled>
                                            Not Specified
                                          </MenuItem>
                                          <MenuItem value="male">Male</MenuItem>
                                          <MenuItem value="female">
                                            Female
                                          </MenuItem>
                                          <MenuItem value="transgender">
                                            Transgender
                                          </MenuItem>
                                        </TextField>
                                        <ErrorMessage
                                          name={`room[${index}].adult[${secIndex}].gender`}
                                          component="div"
                                          className="error"
                                        />

                                        <OaFormikErrorAlert
                                          name={`room[${index}].adult[${secIndex}].gender`}
                                          error={props.error}
                                          touched={props.touched}
                                        />
                                      </Grid>
                                      {/* <Grid
                                      item
                                      xs={12}
                                      md={2}
                                      className={classes.lpad30}
                                    >
                                      <TextField
                                        select
                                        label="Nationality"
                                        name={`room[${index}].adult[${secIndex}].nationality`}
                                        onChange={handleChange}
                                        variant="outlined"
                                        // className="select"
                                        // style={{ width:isMobile?"100%": "135px" }}
                                        disabled
                                        value={
                                          value.adult[secIndex].nationality
                                        }
                                        style={{
                                          width: isMobile ? "100%" : "100%",
                                        }}
                                      >
                                        <MenuItem
                                          value={
                                            value.adult[secIndex].nationality
                                          }
                                        >
                                          {value.adult[secIndex].nationality}
                                        </MenuItem>
                                      </TextField>
                                    </Grid> */}

                                      {selectedRooms[index].roomInfo
                                        .isPassportMandatory === "true" ? (
                                        <>
                                          <Grid item xs={12} md={2}>
                                            <Grid
                                              item
                                              xs={12}
                                              md={12}
                                              className={classes.dob}
                                              style={{ marginTop: 0 }}
                                            >
                                              <OaFormDateField
                                                value={
                                                  value.adult[secIndex]
                                                    .dobDisplay
                                                }
                                                selected={
                                                  value.adult[secIndex]
                                                    .dobDisplay
                                                }
                                                onChange={(date) =>
                                                  setFieldValue(
                                                    `room[${index}].adult[${secIndex}].dobDisplay`,
                                                    date
                                                  )
                                                }
                                                label="DOB"
                                                name={`room[${index}].adult[${secIndex}].dobDisplay`}
                                                style={{ marginTop: 0 }}
                                              />
                                              <ErrorMessage
                                                name={`room[${index}].adult[${secIndex}].dobDisplay`}
                                                component="div"
                                                className="error"
                                              />
                                              <OaFormikErrorAlert
                                                name={`room[${index}].adult[${secIndex}].dobDisplay`}
                                                error={props.error}
                                                touched={props.touched}
                                              />
                                            </Grid>
                                          </Grid>
                                          <Grid item xs={12} md={3}>
                                            <TextField
                                              fullWidth
                                              label="Passport No"
                                              name={`room[${index}].adult[${secIndex}].passportNo`}
                                              onChange={handleChange}
                                              variant="outlined"
                                              className={classes.inputLabel}
                                              style={{ width: "100%" }}
                                            />
                                            <ErrorMessage
                                              name={`room[${index}].adult[${secIndex}].passportNo`}
                                              component="div"
                                              className="error"
                                            />

                                            <OaFormikErrorAlert
                                              name={`room[${index}].adult[${secIndex}].passportNo`}
                                              error={props.error}
                                              touched={props.touched}
                                            />
                                          </Grid>
                                          <Grid item xs={12} md={3}>
                                            <OaFormDateField
                                              value={
                                                value.adult[secIndex].issueDate
                                              }
                                              selected={
                                                value.adult[secIndex].issueDate
                                              }
                                              onChange={(date) =>
                                                setFieldValue(
                                                  `room[${index}].adult[${secIndex}].issueDate`,
                                                  date
                                                )
                                              }
                                              label="Issue Date"
                                              name={`room[${index}].adult[${secIndex}].issueDate`}
                                              style={{ marginTop: 0 }}
                                            />
                                            <ErrorMessage
                                              name={`room[${index}].adult[${secIndex}].issueDate`}
                                              component="div"
                                              className="error"
                                            />
                                            <OaFormikErrorAlert
                                              name={`room[${index}].adult[${secIndex}].issueDate`}
                                              error={props.error}
                                              touched={props.touched}
                                            />
                                          </Grid>
                                          <Grid
                                            item
                                            xs={12}
                                            md={3}
                                            style={{ marginTop: 0 }}
                                          >
                                            <OaFormDateField
                                              value={
                                                value.adult[secIndex].expiryDate
                                              }
                                              selected={
                                                value.adult[secIndex].expiryDate
                                              }
                                              onChange={(date) =>
                                                setFieldValue(
                                                  `room[${index}].adult[${secIndex}].expiryDate`,
                                                  date
                                                )
                                              }
                                              minDate={
                                                value.adult[secIndex].issueDate
                                              }
                                              label="Expiry Date"
                                              name={`room[${index}].adult[${secIndex}].expiryDate`}
                                              style={{ marginTop: 0 }}
                                            />
                                            <ErrorMessage
                                              name={`room[${index}].adult[${secIndex}].expiryDate`}
                                              component="div"
                                              className="error"
                                            />

                                            <OaFormikErrorAlert
                                              name={`room[${index}].adult[${secIndex}].expiryDate`}
                                              error={props.error}
                                              touched={props.touched}
                                            />
                                          </Grid>
                                        </>
                                      ) : null}
                                    </Grid>
                                  ))}

                                {value.child.length > 0 && (
                                  <Grid item xs={12}>
                                    <Typography
                                      component="h6"
                                      variant="h6"
                                      className={classes.sectionHeading}
                                      style={{ marginTop: 10 }}
                                    >
                                      Child Info
                                    </Typography>
                                  </Grid>
                                )}
                                {value.child &&
                                  value.child.map((child, secIndex) => (
                                    <Grid item container spacing={1}>
                                      <Grid item xs={12} md={2}>
                                        <TextField
                                          fullWidth
                                          select
                                          label="Title"
                                          name={`room[${index}].child[${secIndex}].title`}
                                          onChange={handleChange}
                                          variant="outlined"
                                          className="select"
                                        // style={{
                                        //   width: isMobile?"100%":"80px",
                                        // }}
                                        >
                                          <MenuItem value="Mr">Mr</MenuItem>
                                          <MenuItem value="Mrs">Mrs</MenuItem>
                                          <MenuItem value="Miss">Miss</MenuItem>
                                          <MenuItem value="Ms">Ms</MenuItem>
                                        </TextField>
                                        <ErrorMessage
                                          name={`room[${index}].child[${secIndex}].title`}
                                          component="div"
                                          className="error"
                                        />
                                        <OaFormikErrorAlert
                                          name={`room[${index}].child[${secIndex}].title`}
                                          error={props.error}
                                          touched={props.touched}
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={2}>
                                        <TextField
                                          fullWidth
                                          label="First Name"
                                          name={`room[${index}].child[${secIndex}].firstName`}
                                          onChange={handleChange}
                                          variant="outlined"
                                        // className={classes.inputLabel}
                                        />
                                        <ErrorMessage
                                          name={`room[${index}].child[${secIndex}].firstName`}
                                          component="div"
                                          className="error"
                                        />

                                        <OaFormikErrorAlert
                                          name={`room[${index}].child[${secIndex}].firstName`}
                                          error={props.error}
                                          touched={props.touched}
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={2}>
                                        <TextField
                                          fullWidth
                                          label="Last Name"
                                          name={`room[${index}].child[${secIndex}].lastName`}
                                          onChange={handleChange}
                                          variant="outlined"
                                        // className={classes.inputLabel}
                                        />
                                        <ErrorMessage
                                          name={`room[${index}].child[${secIndex}].lastName`}
                                          component="div"
                                          className="error"
                                        />
                                        <OaFormikErrorAlert
                                          name={`room[${index}].child[${secIndex}].lastName`}
                                          error={props.error}
                                          touched={props.touched}
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={1}>
                                        <TextField
                                          fullWidth
                                          label="Age"
                                          name={`room[${index}].child[${secIndex}].age`}
                                          onChange={handleChange}
                                          //type="number"
                                          value={value.child[secIndex].age}
                                          variant="outlined"
                                          // className={classes.inputLabel1}

                                          disabled
                                        />
                                        <ErrorMessage
                                          name={`room[${index}].child[${secIndex}].age`}
                                          component="div"
                                          className="error"
                                        />
                                        <OaFormikErrorAlert
                                          name={`room[${index}].child[${secIndex}].age`}
                                          error={props.error}
                                          touched={props.touched}
                                        />
                                      </Grid>

                                      <Grid item xs={12} md={2}>
                                        <TextField
                                          fullWidth
                                          select
                                          label="Gender"
                                          name={`room[${index}].child[${secIndex}].gender`}
                                          onChange={handleChange}
                                          variant="outlined"
                                          className="select"
                                          style={
                                            {
                                              // width: isMobile ? "100%" : "90px",
                                            }
                                          }
                                        >
                                          <MenuItem value="0" disabled>
                                            Not Specified
                                          </MenuItem>
                                          <MenuItem value="male">Male</MenuItem>
                                          <MenuItem value="female">
                                            Female
                                          </MenuItem>
                                          <MenuItem value="transgender">
                                            Transgender
                                          </MenuItem>
                                        </TextField>
                                        <ErrorMessage
                                          name={`room[${index}].child[${secIndex}].gender`}
                                          component="div"
                                          className="error"
                                        />
                                        <OaFormikErrorAlert
                                          name={`room[${index}].child[${secIndex}].gender`}
                                          error={props.error}
                                          touched={props.touched}
                                        />
                                      </Grid>
                                      {/* <Grid
                                      item
                                      xs={12}
                                      md={2}
                                      //style={{ marginLeft: 30 }}
                                    >
                                      <TextField
                                        fullWidth
                                        select
                                        label="Nationality"
                                        name={`room[${index}].child[${secIndex}].nationality`}
                                        onChange={handleChange}
                                        variant="outlined"
                                        // className="select"
                                        // style={{ width: isMobile?"100%":"135px" }}
                                        disabled
                                        value={
                                          value.child[secIndex].nationality
                                        }
                                      >
                                        <MenuItem
                                          value={
                                            value.child[secIndex].nationality
                                          }
                                        >
                                          {value.child[secIndex].nationality}
                                        </MenuItem>
                                      </TextField>
                                    </Grid> */}
                                      {selectedRooms[index].roomInfo
                                        .isPassportMandatory === "true" ? (
                                        <>
                                          <Grid item xs={12} md={2}>
                                            <Grid
                                              item
                                              xs={12}
                                              md={12}
                                              className={classes.dob}
                                              style={{ marginTop: 0 }}
                                            >
                                              <OaFormDateField
                                                value={
                                                  value.child[secIndex]
                                                    .dobDisplay
                                                }
                                                selected={
                                                  value.child[secIndex]
                                                    .dobDisplay
                                                }
                                                onChange={(date) =>
                                                  setFieldValue(
                                                    `room[${index}].child[${secIndex}].dobDisplay`,
                                                    date
                                                  )
                                                }
                                                label="DOB"
                                                name={`room[${index}].child[${secIndex}].dobDisplay`}
                                                style={{ marginTop: 0 }}
                                              />
                                              <ErrorMessage
                                                name={`room[${index}].child[${secIndex}].dobDisplay`}
                                                component="div"
                                                className="error"
                                              />
                                              <OaFormikErrorAlert
                                                name={`room[${index}].child[${secIndex}].dobDisplay`}
                                                error={props.error}
                                                touched={props.touched}
                                              />
                                            </Grid>
                                          </Grid>
                                          <Grid item xs={12} md={3}>
                                            <TextField
                                              fullWidth
                                              label="Passport No"
                                              name={`room[${index}].child[${secIndex}].passportNo`}
                                              onChange={handleChange}
                                              variant="outlined"
                                              className={classes.inputLabel}
                                              style={{ width: "100%" }}
                                            />
                                            <ErrorMessage
                                              name={`room[${index}].child[${secIndex}].passportNo`}
                                              component="div"
                                              className="error"
                                            />

                                            <OaFormikErrorAlert
                                              name={`room[${index}].child[${secIndex}].passportNo`}
                                              error={props.error}
                                              touched={props.touched}
                                            />
                                          </Grid>
                                          <Grid item xs={12} md={3}>
                                            <OaFormDateField
                                              value={
                                                value.child[secIndex].issueDate
                                              }
                                              selected={
                                                value.child[secIndex].issueDate
                                              }
                                              onChange={(date) =>
                                                setFieldValue(
                                                  `room[${index}].child[${secIndex}].issueDate`,
                                                  date
                                                )
                                              }
                                              label="Issue Date"
                                              name={`room[${index}].child[${secIndex}].issueDate`}
                                              style={{ marginTop: 0 }}
                                            />
                                            <ErrorMessage
                                              name={`room[${index}].child[${secIndex}].issueDate`}
                                              component="div"
                                              className="error"
                                            />
                                            <OaFormikErrorAlert
                                              name={`room[${index}].child[${secIndex}].issueDate`}
                                              error={props.error}
                                              touched={props.touched}
                                            />
                                          </Grid>
                                          <Grid
                                            item
                                            xs={12}
                                            md={3}
                                            style={{ marginTop: 0 }}
                                          >
                                            <OaFormDateField
                                              value={
                                                value.child[secIndex].expiryDate
                                              }
                                              selected={
                                                value.child[secIndex].expiryDate
                                              }
                                              onChange={(date) =>
                                                setFieldValue(
                                                  `room[${index}].child[${secIndex}].expiryDate`,
                                                  date
                                                )
                                              }
                                              minDate={
                                                value.child[secIndex].issueDate
                                              }
                                              label="Expiry Date"
                                              name={`room[${index}].child[${secIndex}].expiryDate`}
                                              style={{ marginTop: 0 }}
                                            />
                                            <ErrorMessage
                                              name={`room[${index}].child[${secIndex}].expiryDate`}
                                              component="div"
                                              className="error"
                                            />

                                            <OaFormikErrorAlert
                                              name={`room[${index}].child[${secIndex}].expiryDate`}
                                              error={props.error}
                                              touched={props.touched}
                                            />
                                          </Grid>
                                        </>
                                      ) : null}
                                    </Grid>
                                  ))}
                              </Grid>

                              <Divider />
                            </Grid>
                          );
                        })}
                    </div>
                  )}
                />
              </Grid>
              {/* --------------------pax end---------------- */}

              {/* --------------------contact details start---------------- */}
              <Grid
                className={classes.sectionContainer}
                style={{ marginBottom: 5 }}
              >
                <div
                  className="section-header"
                // style={{
                //   borderBottom: "1px solid rgba(0,0,0,0.125)",
                // }}
                >
                  <TitleComp fa="fa fa-phone" title={` Contact Details`} />
                </div>
                <Grid style={{ padding: 15, paddingTop: 0 }}>
                  <Grid
                    container
                    // spacing={1}
                    style={{
                      marginTop: 20,
                      backgroundColor:
                        theme.palette.background.lightGrayDefault,
                      borderRadius: 5,
                      display: "flex",
                      // padding: 10,
                      padding: 15,
                      // margin: 15,
                    }}
                  >
                    <Grid item xs={12} md={3} style={{ paddingRight: 5 }}>
                      <Grid item md={12}>
                        <Typography
                          component="h6"
                          variant="h6"
                          className={classes.sectionHeading}
                          style={{ fontSize: ".875rem" }}
                        >
                          Mobile No
                        </Typography>
                        <TextField
                          fullWidth
                          // label="Mobile No. "
                          name="mobile"
                          // className={classes.inputLabel}
                          onChange={handleChange}
                          value={values.mobile}
                          variant="outlined"
                        // style={{ width:"100%"}}
                        />

                        <ErrorMessage
                          name="mobile"
                          component="div"
                          className="error"
                        />

                        <OaFormikErrorAlert
                          name="mobile"
                          error={props.error}
                          touched={props.touched}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ paddingRight: 5 }}>
                      <Grid item xs={12}>
                        <Typography
                          component="h6"
                          variant="h6"
                          className={classes.sectionHeading}
                          style={{ fontSize: ".875rem" }}
                        >
                          Email
                        </Typography>
                      </Grid>
                      <TextField
                        // label="Email ID "
                        variant="outlined"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        type="email"
                        fullWidth
                        style={{ width: "100%" }}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error"
                      />

                      <OaFormikErrorAlert
                        name="email"
                        error={props.error}
                        touched={props.touched}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} style={{ paddingRight: 5 }}>
                      <Grid item xs={12}>
                        <Typography
                          component="h6"
                          variant="h6"
                          className={classes.sectionHeading}
                          style={{ fontSize: ".875rem" }}
                        >
                          Pan No
                        </Typography>
                        <TextField
                          fullWidth
                          // label="Pan Number"
                          name="pan"
                          className={classes.inputLabel}
                          onChange={handleChange}
                          variant="outlined"
                          style={{ width: isMobile ? "100%" : "100%" }}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                        <ErrorMessage
                          name="pan"
                          className="error"
                          component="div"
                          style={{ marginLeft: 20 }}
                        />

                        <OaFormikErrorAlert
                          name="pan"
                          error={props.error}
                          touched={props.touched}
                        />
                      </Grid>
                    </Grid>

                    {/* <Grid item xs={12}>
                  <Typography
                    component="p"
                    variant="caption"
                    className={classes.caption}
                    style={{ marginTop: 5 }}
                  >
                    ( Your ticket and travel information will be sent here )
                  </Typography>
                </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
              {/* --------------------contact details end---------------- */}

              <Grid
                container
                alignItems="center"
                style={{ paddingTop: 10, paddingBottom: 10 }}
              ></Grid>
              {/* HOTEL */}
              <Grid
                // className={classes.container}
                className={`${classes.container} ${classes.sectionContainer}`}
                item
                container
                xs={12}
              // spacing={2}
              >
                {/* --------------------mobile hotel details start---------------- */}
                <Grid
                  item
                  md={4}
                  sm={12}
                  xs={12}
                  className={classes.infoGrid}
                  style={{
                    padding: 15,
                    border: "1px solid rgba(0,0,0,0.125)",
                    boxShadow: "0 .125rem .25rem rgba(0,0,0,0.075)!important",
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                    // className={classes.sectionContainer}
                    // style={{ padding: 15 }}
                    >
                      <div
                        className="section-header"
                      >
                        <TitleComp fa="fa fa-plane" title={` Hotel Details `} />
                      </div>
                      <Typography className={classes.hotelName}>
                        {" "}
                        {locationProps.state.hotelInfo.hotelDetails.hotelName}
                      </Typography>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Typography
                          style={{ color: theme.palette.primary.blackPearl }}
                        >
                          {" "}
                          Check-In:
                        </Typography>
                        <Typography
                          style={{ color: theme.palette.primary.blackPearl }}
                        >
                          {hotelInfoProps.hotelSearch.checkInDate}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{ color: theme.palette.primary.blackPearl }}
                        >
                          {" "}
                          Check-Out:
                        </Typography>
                        <Typography
                          style={{ color: theme.palette.primary.blackPearl }}
                        >
                          {hotelInfoProps.hotelSearch.checkOutDate}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12} style={{ marginTop: 2 }}>
                      <Divider />
                    </Grid>
                    <Grid container>
                      <Grid item md={12} sm={12} xs={12}>
                        {/* <Typography className={classes.hotelInfo}>
                        Price
                      </Typography> */}
                      </Grid>
                      <Grid item md={6} sm={6} xs={6}>
                        <Typography
                          className={classes.hotelName}
                          style={{
                            fontSize: 20,
                            // marginBottom: 15,
                            fontWeight: 500,
                          }}
                        >
                          Total Cost
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={6} xs={6}>
                        <Typography
                          className={classes.hotelName}
                          align="right"
                          style={{
                            fontSize: 20,
                            // marginBottom: 15,
                            fontWeight: 500,
                          }}
                        >{`${hotelInfoProps.hotelSearch.preferredCurrency === "INR"
                          ? "₹"
                          : hotelInfoProps.hotelSearch.preferredCurrency
                          } ${totalCost(
                            locationProps.state.selectedRooms
                          )}`}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* --------------------mobile hotel details end---------------- */}

                {/* --------------------payment details start---------------- */}
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <div
                    className="section-header"
                  // style={{
                  //   borderBottom: "1px solid rgba(0,0,0,0.125)",
                  // }}
                  >
                    <TitleComp
                      fa="fa fa-credit-card"
                      title={` Payment Details`}
                    />
                  </div>
                </Grid>

                <HotelPaymentOptions
                  paymentmodes={paymentModes}
                  setselectedpaymentmode={setSelectedPaymentMode}
                  isloading={isLoading}
                  data={data}
                  totalfare={totalFare}
                  errors={errors}
                  touched={touched}
                  setisloading={setIsLoading}
                  selectedpaymentmethod={selectedPaymentMethod}
                  setselectedpaymentmethod={setSelectedPaymentMethod}
                  paymodetabindex={payModeTabIndex}
                  setpaymodetabindex={setPayModeTabIndex}
                  setIsPayable={setIsPayable}
                />
                {/* --------------------payment details end---------------- */}
                <div style={{ padding: 16, display: "none" }}>
                  <Grid item md={12}>
                    <Typography
                      component="h2"
                      variant="h6"
                      className={classes.sectionHeading1}
                    >
                      Note
                    </Typography>
                  </Grid>

                  <Grid item md={12} style={{ display: "none" }}>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                  </Grid>

                  <Grid item md={12} style={{ display: "none" }}>
                    <span className="payment-info-title">
                      Hotel rooms may change their prices any minute. This fare
                      is not guaranteed until the Hotel Voucher is generated.
                      Generation of a Transaction ID (TID) is the confirmation
                      of booking request and payment received by{" "}
                      {AppConstant.domainName} and under no circumstances, is it
                      equivalent to {AppConstant.domainName}' final purchase
                      price of respective Hotel voucher. There might be
                      situations, where the fare displayed may increase by the
                      time purchase is made with the respective Hotel. In this
                      event, {AppConstant.domainName} will revert to you with
                      the new fare or alternate options regarding your flight
                      booking.
                    </span>
                  </Grid>
                  <Grid item md={12} style={{ display: "none" }}>
                    <span
                      className="payment-info-title "
                      style={{ color: "#D0342C" }}
                    >
                      *There will be a TDS on the discounts / commissions on
                      every transaction @ 5% for Permanent Account Number (PAN)
                      holders and 20% if the agent is not having a PAN. Kindly
                      update your PAN to save 15% on TDS.
                    </span>
                  </Grid>
                </div>
                
              </Grid>

              <Grid>
                <Grid
                  item
                  xs={12}
                  className={classes.sectionContainer}
                  style={{ padding: 10 }}
                >
                  <div className="checkbox-group">
                    <Checkbox
                      style={{ color: theme.palette.background.carulean }}
                      value={values.acceptTNC}
                      onChange={() =>
                        setFieldValue("acceptTNC", !values.acceptTNC)
                      }
                    />
                    <label className="form-check-label payment-info-title">
                      I have read and accepted{" "}
                      <Link
                        style={{ color: theme.palette.primary.redAlert }}
                        onClick={handleTncOpen}
                      >
                        terms and conditions
                      </Link>{" "}
                      and{" "}
                      <Link
                        style={{ color: theme.palette.primary.redAlert }}
                        onClick={handleOpenFareRules}
                      >
                        Fare Rules
                      </Link>
                    </label>
                  </div>
                  <ErrorMessage
                    component="div"
                    className="error"
                    name="acceptTNC"
                  />
                  <OaFormikErrorAlert
                    name="acceptTNC"
                    errors={props.errors}
                    touched={props.touched}
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.reviewButtonContainer}
                >
                  {!isLoading && (walletCriteria || userType === "b2c_user") && (
                    <Button
                      className={classes.reviewButton}
                      align="center"
                      type="submit"
                    >
                      Review
                    </Button>
                  )}
                </Grid>
              </Grid>

              {/* {errors && _.isString(errors) && touched && _.isArray(touched) && (
              <div className="field-error">{errors}</div>
            )}
            <div className={"row"}>
              <div className={"col-12"}>
                <code>
                  <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                </code>
              </div>
              <div className={"col-12"}>
                <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
              </div>
              <div className={"col-12"}>
                <pre>Touched: {JSON.stringify(touched, null, 2)}</pre>
              </div>
            </div>
            <Button type="submit">Submit</Button> */}
            </Form>
          )}
        </Formik>

        <Dialog
          fullWidth
          maxWidth={"lg"}
          open={openTNC}
          onClose={handleCloseTNC}
        >
          <DialogTitle>
            <div className="modal-close">
              <span className="icon-holder" onClick={handleCloseTNC}>
                <Close />
              </span>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="content-container-inner">
              <props.tnc />
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          fullWidth
          maxWidth={"lg"}
          open={openFareRules}
          onClose={handleCloseFareRules}
        >
          <DialogTitle>
            <div className="modal-close">
              <span className="icon-holder" onClick={handleCloseFareRules}>
                <Close />
              </span>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="content-container-inner">
              <props.ppc />
            </div>
          </DialogContent>
        </Dialog>
        <style>{`
      .modal-close {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
      }
      .modal-close .icon-holder {
        cursor: pointer;
      }
      `}</style>
      </div>
    </>
  );
};

export default ContactCard;

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: "0 15px",
    width: "100%",
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.background.light,
    },
    "& .MuiInputBase-root": {
      lineHeight: "0.1876em",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      lineHeight: "17px",
    },
    "& .checkbox-group": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    "& .error-msgBox": {
      width: "60%",
      // height:'100vh',
      bottom: 0,
      position: "fixed",
      // position:'absolute',
      top: "10%",
      left: "20%",
      padding: "6px 13px",
      zIndex: 999,
      margin: "10px 0",
      "& .error-container": {
        position: "relative",
        width: "100%",
        padding: "6px 13px",
        margin: "10px 0",
      },
    },
  },
  sectionContainer: {
    background: theme.palette.background.light,
    borderRadius: "0.25rem",
    border: "1px solid rgba(0,0,0,0.125)",
    boxShadow: "0 .125rem .25rem rgba(0,0,0,0.075)!important",
    marginBottom: 25,
    "& .section-header": {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.125)",
      marginBottom: 5,
    },
    "& .section-title-wrapper": {
      display: "flex",
      alignItems: "center",
    },
    "& .heading-icon": {
      borderRadius: "50%",
      background: theme.palette.background.disabled,
      width: 30,
      height: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: 10,
      "& i": {
        fontSize: 16,
        color: theme.palette.text.secondary,
      },
    },
    "& .heading-text": {
      textTransform: "uppercase",
      fontWeight: 500,
      color: theme.palette.text.secondary,
    },
  },
  container_mobile: {
    [theme.breakpoints.down("sm")]: {
      boxShadow: "0px 4px 21px rgba(195, 195, 195, 0.25)",
      background: theme.palette.background.default,
      borderRadius: "5px",
      marginLeft: 0,
      marginRight: 0,
    },
  },
  sectionHeading: {
    fontWeight: "500",
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: theme.typography.pxToRem(1.2366),
    color: theme.palette.primary.blackPearl,
  },
  sectionHeading1: {
    fontWeight: "500",
    fontSize: theme.typography.pxToRem(18),
    letterSpacing: theme.typography.pxToRem(1.2366),
    color: theme.palette.primary.active,
    textTransform: "uppercase",
    marginBottom: theme.typography.pxToRem(4),
  },
  // inputLabel: {
  //   width: "319px",
  // },
  inputLabel1: {
    width: "70px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  inputLabel2: {
    fontSize: theme.typography.pxToRem(11),
    letterSpacing: theme.typography.pxToRem(0.97),
    color: theme.palette.secondary.charcoal,
    textTransform: "uppercase",
  },
  grid: {
    width: "150%",
  },
  payment__coupon: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    letterSpacing: theme.typography.pxToRem(1.06),
    color: theme.palette.background.carulean,
    textTransform: "uppercase",
  },
  payment__info: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    letterSpacing: theme.typography.pxToRem(0.46),
    color: theme.palette.secondary.charcoal,
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(1),
      // marginBottom: theme.spacing(0.5),
    },
    select: {
      height: 40,
    },
  },
  btn: {
    width: "100%",
    backgroundColor: theme.palette.background.peach,
    color: "white",
    textTransform: "uppercase",
    padding: theme.spacing(1, 4),
    fontSize: theme.typography.pxToRem(12),
    letterSpacing: theme.typography.pxToRem(1.0333),
  },
  termsandCond: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
    },
  },
  reviewButtonContainer: {
    [theme.breakpoints.down(600)]: {
      textAlign: "center",
    },
  },
  reviewButton: {
    fontWeight: "500 !important",
    fontSize: "18px !important",
    lineHeight: "20px !important" /* identical to box height */,
    letterSpacing: "1px !important",
    background: theme.palette.buttons.bookNowBtn,
    // cursor: "pointer",
    color: theme.palette.primary.lightText,
    border: `solid 1px ${theme.palette.buttons.bookNowBtn}`,
    borderRadius: "4px !important",
    textTransform: "capitalize !important",
    cursor: "pointer",
    minWidth: "240px !important",
    height: "47px",
    padding: "6px 15px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    },
    [theme.breakpoints.down(600)]: {
      textAlign: "center",
    },
    "&:hover": {
      // background: theme.palette.buttons.secondaryContrastText,
      background: theme.palette.buttons.secondary,
    },
  },
  tabDiv: {
    "& .MuiTab-textColorInherit": {
      background: `${theme.palette.primary.disabled} !important`,
      color: `${theme.palette.primary.darkText} !important`,
      borderRadius: "8px 8px 0 0",
      "&.Mui-selected": {
        background: `${theme.palette.primary.main} !important`,
        color: `${theme.palette.primary.contrastText} !important`,
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  infoGrid: {
    display: "none",
    [theme.breakpoints.down(960)]: {
      display: "unset",
    },
  },
  tabContDiv: {
    marginLeft: 15,
    "& .MuiBox-root": {
      padding: 0,
    },
    "& .paymentMethods": {
      marginLeft: 15,
    },
  },
  lpad30: {
    // marginLeft: 30,
    [theme.breakpoints.down(768)]: {
      marginLeft: 0,
    },
  },
  dob: {
    "& .MuiInputAdornment-positionStart": {
      display: "none",
    },
    " & .MuiOutlinedInput-adornedStart": {
      paddingLeft: 0,
    },
    "& .MuiInputBase-input": {
      fontSize: 13,
    },
  },
}));
