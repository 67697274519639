import React from 'react'
import LeftAdornedDatePicker from "./LeftAdornedDatePicker";
import dateFnsFormat from "date-fns/format";
import calendarIcon from "assets/Tickat/images/home_pix/calendar_icon.png";
import {
    TextField,

  } from "@material-ui/core";

const FlightDateInput = ({props,index, type}) => {

  const onChangeReturnDateDisplay = (e, v) => {
 
  };
  

  return (
    <LeftAdornedDatePicker
    showDisabledMonthNavigation
    className="date-picker"
    name={`sectors[${index}].${type}DateDisplay`}
    minDate={type === 'depart' ? new Date() : props.values.sectors[0].departDateDisplay}
    // minDate={props.values.sectors[0].departDateDisplay}
    customInput={
      <TextField id="standard-basic" variant="standard" fullWidth />
    }
    selected={type === "depart" ? props.values.sectors[index][`${type}DateDisplay`] : props.values.returnDateDisplay}

    onChange={(e, v) => {
      //   console.log("props.values.mode " + props.values.mode);
      if(type === "depart") {
        if (props.values.mode === "MULTICITY") {
          for (let i = 0; i < props.values.sectors.length; i++) {
            const t1 = new Date(e).getTime();
            const t2 = new Date(
              props.values.sectors[i][`${type}DateDisplay`]
            ).getTime();
            if (t2 < t1) {
              props.setFieldValue(
                `sectors[${i}][${type}DateDisplay]`,
                e
              );
              props.setFieldValue(
                `sectors[${i}][${type}Date]`,
                dateFnsFormat(e, "yyyy-MM-dd")
              );
            }
          }
        } else {
          props.setFieldValue(`sectors[${index}][${type}DateDisplay]`, e);
          props.setFieldValue(
            `sectors[${index}][${type}Date]`,
            dateFnsFormat(e, "yyyy-MM-dd")
          );
        }
        if (Math.abs(props.values.returnDateDisplay) < Math.abs(e)) {
          props.setFieldValue(`returnDateDisplay`, e);
          props.setFieldValue(
            `returnDate`,
            dateFnsFormat(e, "yyyy-MM-dd")
          );
        }
      } else {
        props.setFieldValue(`returnDateDisplay`, e);
        props.setFieldValue(`returnDate`, dateFnsFormat(e, "yyyy-MM-dd"));
      }
  
    }}
    monthsShown={2}
    hidedate={type === 'return' && props.values.mode !== "ROUND"}
    dateFormat="dd MMM yy"
    // adnicon={calendarIcon}
  />
 
  )
}

export default FlightDateInput
