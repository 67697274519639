import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  getQueryVar,
  getCurrentTab,
  getAgencyId,
} from "oautils/oaCommonUtils.js";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import PostLoginNavBar from "components/Navbars/PostLoginNavBar.Tickat";
import PageFooter from "components/Footer/PageFooter.Tickat";
import clsx from "clsx";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import Container from "@material-ui/core/Container";
import BusSearchProgressPage from "pages/module/bus/BusSearchProgressPage";
import HotelBookingFormPage from "pages/module/hotel/HotelBookingFormPage";
import BusSearchResult from "pages/module/bus/BusSearchResult";
import BusPassengerDetails from "pages/module/bus/BusPassengerDetails";
import BusPassengerReview from "pages/module/bus/BusPassengerReview";
import BusPriceChanged from "pages/module/bus/BusPriceChanged";
import BusPaymentProgressPage from "pages/module/bus/BusPaymentProgressPage";
import BusTicket from "pages/module/bus/BusTicket";
import companylogo from "assets/Tickat/images/logo/logo.png";
import BusPaymentStatus from "pages/module/bus/BusPaymentStatus";
import ExtRedirBusBookProgress from "pages/module/bus/ExtRedirBusBookProgress";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import PricingPolicy from "components/LandingPage/Tickat/siteinfo/PricingPolicy";
import TermsAndConditions from "components/LandingPage/Tickat/siteinfo/TermsAndConditions";

const useStyles = makeStyles(styles);

export default function HotelLayout(props) {
  const { ...rest } = props;
  const classes = useStyles();
  const addClasses = additionalStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const sessionStartTime = useSelector(
    (state) => state.hotel.busBookingInitialTime
  );

  useEffect(() => {
    if (sessionStartTime) {
      let now = new Date().getTime();
      let distance = sessionStartTime - now;

      let minute = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let second = Math.floor((distance % (1000 * 60)) / 1000);
      if (minute <= 0 && second <= 0) {
        history.push("/");
      }
    }
  });

  React.useEffect(() => {
    document.body.style.overflow = "unset";
    let qAgencyId = getAgencyId(props);
    setAgencyId(qAgencyId);
    return function cleanup() {};
  }, [agencyId, props]);

  const [agencyId, setAgencyId] = useState(undefined);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const newWidth = window.innerWidth;
    setWidth(newWidth);
    // console.log("meow",newHeight)
  });

  // let defaultTab = 'hotel';
  // let queryVar = getQueryVar({ ...props, q: 'priTab' });
  // let currentTab = getCurrentTab(queryVar, defaultTab);

  // console.log("hell");

  return (
    <div className={clsx(classes.wrapper, addClasses.rootWrapper)}>
      <PostLoginNavBar
        {...rest}
        isSearchPage={true}
        showServices={true}
        isTimerShow={true}
      />
      <div
        className={"inner-wrapper"}
        style={{
          padding: isMobile && "0px 5px",
        }}
      >
        <div className="inner-container">
          <Switch>
            <Route
              path="/:priTab/search-progress"
              exact
              component={BusSearchProgressPage}
            />
            <Route
              path="/:priTab/bookingForm"
              exact
              component={HotelBookingFormPage}
            />
            <Route
              path="/:priTab/bus-result"
              exact
              component={BusSearchResult}
            />
            <Route
              path="/:priTab/passenger-details"
              exact
            >
              <BusPassengerDetails tnc={TermsAndConditions} ppc={PricingPolicy} {...props}/>
            </Route>
            <Route
              path="/:priTab/passenger-review"
              exact
              component={BusPassengerReview}
            />
            {/* <Route path="/bus/bus-price-changed" exact component={BusPriceChanged}/> */}
            <Route
              path="/:priTab/bus-payment-progress/:transactionId"
              exact
              component={ExtRedirBusBookProgress}
            />
            <Route
              path="/:priTab/bus-payment-progress"
              exact
              component={BusPaymentProgressPage}
            />
            <Route path="/:priTab/bus-ticket" exact component={BusTicket} />
            <Route
              path="/:priTab/bus-ticket"
              render={(prop) => (
                <BusTicket companylogo={companylogo} {...props} />
              )}
            />
            <Route
              path="/bus/payment-status/:tid/:status"
              component={BusPaymentStatus}
            />
          </Switch>
        </div>
      </div>
      {/* <PageFooter white {...props} /> */}
      <PageFooter />
    </div>
  );
}

const additionalStyles = makeStyles((theme) => ({
  rootWrapper: {
    // backgroundColor: theme.palette.background.default,
    width: `100%`,
    height: `100%`,
    "& .inner-wrapper": {
      display: "flex",
      justifyContent: "center",
      width: `100%`,
      //backgroundColor: theme.palette.background.lightGray,
    },
    "& .inner-container": {
      marginTop: 10,
      marginBottom: 30,
      minHeight: "70vh",
      maxWidth: "1440px",
    },
  },
}));
