import React, { useState } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ContactBanner from "assets/BuzzHolidays/images/contact-banner.jpg";
import { AppConstant } from "appConstant";
import LeafletMap from "pages/module/hotel/hotelinfo/HotelLeafletMap";
import ContactUsForm from "../forms/ContactUsForm";
import emailIcon from "assets/BuzzHolidays/images/icons/mail-light.svg";
import phoneIcon from "assets/BuzzHolidays/images/icons/phone-light.svg";
import locationIcon from "assets/BuzzHolidays/images/icons/map-pin-light.svg";
import { useHistory } from "react-router-dom";
import OfficeLocationMap from "./OfficeLocationMap";

const ContactUs = () => {
  const classes = useStyles();
  const [partnerEnquiry, setPartnerEnquiry] = useState(false);

  const history = useHistory();

  const goToUrlLandingpage = () => {
    history.push("/flights");
  };

  return (
    <div className={classes.contactUsRoot}>
      <div className="section-container">
        <div className="banner-section">
          <div className="section-title-container">
            <div className="section-title">
              <h2>Contact Us</h2>
            </div>
            <div className="section-bread-crumbs">
              <a onClick={goToUrlLandingpage}>Home</a> |{" "}
              <a href="">Contact Us</a>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="section-container grid-container justify-center">
                <div className="md-1280 padding-tb100">
                    <div className="map-container">
                        <LeafletMap hotels={[
                            { latitude: AppConstant.latitude, longitude: AppConstant.longitude, hotelName: AppConstant.name }
                        ]} />
                        <OfficeLocationMap />
                    </div>
                </div>
            </div> */}

      <div className="section-container grid-container justify-center">
        <div className="md-1280">
          <div className="section-contact-header">
            <div className="section-header">
              <p> </p>
            </div>
            <div className="section-caption">
              <p>
                Contact and <br />
                Support Information
              </p>
            </div>
          </div>
          <div className="section-contact-form">
            <ContactUsForm />
          </div>
          <div className="section-contact-info grid-container even-space">
            <div className="contact-info-item">
              <div className="contact-info-icon">
                <img src={emailIcon} />
              </div>
              <div className="contact-info-title">Email</div>
              <div className="contact-info">
                <p>{AppConstant.infoEmail}</p>
                <p>{AppConstant.supportEmail}</p>
              </div>
            </div>

            <div className="contact-info-item">
              <div className="contact-info-icon">
                <img src={phoneIcon} />
              </div>
              <div className="contact-info-title">Phone</div>
              <div className="contact-info">
                {AppConstant.supportContact !== "" && (
                  <p>
                    +{AppConstant.countryDialCode} {AppConstant.supportContact}
                  </p>
                )}
                {AppConstant.infoContact != "" && (
                  <p>
                    +{AppConstant.countryDialCode} {AppConstant.infoContact}
                  </p>
                )}
              </div>
            </div>

            <div className="contact-info-item">
              <div className="contact-info-icon">
                <img src={locationIcon} />
              </div>
              <div className="contact-info-title">Location</div>
              <div className="contact-info">
                <p>{AppConstant.address.office}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

const useStyles = makeStyles((theme) => ({
  contactUsRoot: {
    // display: "flex",
    // justifyContent: "center",
    fontFamily: theme.palette.font.primary,
    boxSizing: "border-box",
    "& .section-container": {
      margin: 0,
      padding: 0,
      width: "100%",
    },
    "& .grid-container": {
      display: "flex",
    },
    "& .justify-center": {
      justifyContent: "center",
    },
    "& .even-space": {
      justifyContent: "space-evenly",
    },
    "& .padding-tb100": {
      padding: "100px 0",
    },
    "& .md-1280": {
      maxWidth: 1280,
      width: "100%",
      padding: "0 0 100px 0",
      [theme.breakpoints.down(1290)]: {
        padding: "0 20px 100px 20px",
      },
    },
    "& .section-title-container": {
      padding: "15px 0 0 60px",
      [theme.breakpoints.down(500)]: {
        padding: "75px 0 0 60px",
      },
    },
    "& .section-title": {
      "& h2": {
        fontWeight: 700,
        color: theme.palette.primary.lightText,
        fontSize: 42,
        margin: 0,
      },
    },
    "& .section-bread-crumbs": {
      color: theme.palette.primary.lightText,
      "& a": {
        color: theme.palette.primary.lightText,
        cursor: "pointer",
      },
    },
    "& .banner-section": {
      padding: 20,
      height: 380,
      backgroundImage: `url(${ContactBanner})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "norepeat",
      [theme.breakpoints.down(500)]: {
        height: 250,
      },
    },
    "& .map-container": {
      width: "100%",
    },
    "& .content-container": {
      "& button": {
        background: theme.palette.buttons.tertiary,
        color: theme.palette.buttons.tertiaryContrastText,
        fontSize: 14,
        padding: "10px 20px",
        textTransform: "lowercase",
        "& .MuiSvgIcon-root": {
          marginLeft: 4,
          fontSize: 16,
        },
      },
    },
    "& .section-contact-form": {
      marginBottom: 35,
    },
    "& .section-contact-info": {
      [theme.breakpoints.down(959)]: {
        flexWrap: "wrap",
      },
      "& .contact-info-item": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 20,
        width: 315,
      },
      "& .contact-info-icon": {
        backgroundColor: theme.palette.buttons.tertiary,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 70,
        height: 70,
        borderRadius: "50%",
        "& img": {
          height: 18,
        },
      },
      "& .contact-info-title": {
        fontSize: 18,
        fontWeight: 700,
        marginTop: 20,
        textDecoration: "underline",
      },
      "& .contact-info": {
        textAlign: "center",
        marginTop: 15,
        "& p": {
          fontSize: 14,
          fontWeight: 500,
        },
      },
    },
    "& .section-contact-header": {
      paddingBottom: 40,
    },
    "& .section-header": {
      "& p": {
        color: theme.palette.secondary.main, //theme.palette.primary.violetBlue,

        size: 14,
        fontWeight: 700,
      },
    },
    "& .section-caption": {
      "& p": {
        fontSize: 42,
        fontWeight: 800,
        lineHeight: 1.2,
        color: theme.palette.primary.darkText,
      },
      "&.light-text": {
        color: theme.palette.primary.lightText,
        "& p": {
          color: theme.palette.primary.lightText,
        },
      },
    },
  },
}));
