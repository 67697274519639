import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import ScrollToTopComp from "components/LandingPage/Tickat/ScrollToTopComp";
import AboutUs from "components/LandingPage/Tickat/siteinfo/AboutUsPage";
import ContactUs from "components/LandingPage/Tickat/siteinfo/ContactUsPage";
import TermsAndConditions from "components/LandingPage/Tickat/siteinfo/TermsAndConditions";
import PrivacyPolicy from "components/LandingPage/Tickat/siteinfo/PrivacyPolicy";
import { Route, Switch } from "react-router-dom";
import WebCheckIn from "pages/module/flight/webCheckIn";
import AirlineContact from "pages/module/flight/airlineContact";
import PostLoginNavBar from "components/Navbars/PostLoginNavBar.Tickat";
import CancellationPolicy from "components/LandingPage/Tickat/siteinfo/CancellationPolicy";
import PageFooter from "components/Footer/PageFooter.Tickat";
import RefundAndCancellationPolicy from "components/LandingPage/Tickat/siteinfo/RefundAndCancellationPolicy";
import HomePage from "components/LandingPage/Tickat/HomePage";

const useStyles = makeStyles(styles);

const width = window.innerWidth;
const breakpoint = 980;

export default function Pages(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);
  const theme = useTheme();

  // console.log(window.location.href.split("/"));
  const conditions = [
    "termsandconditions",
    "cancellationVisaPolicy",
    "aboutus",
    "contactus",
    "privacypolicy",
    "webcheckin",
    "airlinecontacts",
    "refundandcancellation"
  ];
  const isInfoPage = conditions.some((el) =>
    window.location.href.split("/").includes(el)
  );
  console.log(isInfoPage);

  return (
    <div className={classes.wrapper}>
      {isInfoPage && <PostLoginNavBar isloading={isLoading} />}

      <Switch>
        <Route path="/aboutus" component={AboutUs} />
        <Route path="/contactus" component={ContactUs} />
        <Route path="/termsandconditions" component={TermsAndConditions} />
        <Route path="/cancellationVisaPolicy" component={CancellationPolicy} />
        <Route path="/privacypolicy" component={PrivacyPolicy} />
        <Route path="/webcheckin" component={WebCheckIn} />
        <Route path="/airlinecontacts" component={AirlineContact} />
        <Route path="/refundandcancellation" component={RefundAndCancellationPolicy} />
        <Route component={HomePage} />
      </Switch>
      <PageFooter />
      <ScrollToTopComp />
    </div>
  );
}
