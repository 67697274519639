import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ContactBanner from "assets/BuzzHolidays/images/contact-banner.jpg";
import { AppConstant } from "appConstant";
import locationIcon from "assets/BuzzHolidays/images/icons/map-pin-light.svg";
import { useHistory } from "react-router-dom";
import Carousel from "react-elastic-carousel";

const HomepageContactUs = () => {
  const classes = useStyles();
  const [partnerEnquiry, setPartnerEnquiry] = useState(false);
  const width = window.innerWidth;
  const breakpoint = 560;

  const history = useHistory();

  const goToUrlLandingpage = () => {
    history.push("/flights");
  };

  return (
    <div className={classes.contactUsRoot}>
      <div className="section-container grid-container justify-center">
        <div className="md-1280">
          <div className="section-caption">
            <p>Our Offices are:</p>
          </div>
          {width > breakpoint && (
            <div className="section-contact-info grid-container ">
              <div
                className="contact-info-item"
                style={{ borderBottom: "10px solid #33B3A6" }}
              >
                <div className="contact-info-title">Mumbai :</div>
                <div className="contact-info">
                  <p>
                    Hallmark Business Plaza 11th Floor,
                    <br /> Office No 1106, Bandra East,
                    <br /> Mumbai, Maharashtra 400051
                    <br />
                    <br />
                    <strong>Phone :</strong> 022-4013 4887 / 8879111166
                    <br />
                    <strong>Email :</strong> info@tickat.com
                  </p>
                </div>
              </div>
              <div
                className="contact-info-item"
                style={{ borderBottom: "10px solid #11263B" }}
              >
                <div className="contact-info-title">Nagpur</div>
                <div className="contact-info">
                  <p>
                    BTP Group,532-A <br />
                    Hanuman Nagar,Main Road,
                    <br />
                    Tukdoji Square,Nagpur ,440009{" "}
                  </p>
                </div>
              </div>
              <div
                className="contact-info-item"
                style={{ borderBottom: "10px solid #D58D47" }}
              >
                <div className="contact-info-title">Dubai</div>
                <div className="contact-info">
                  <p>
                    Fly High Tourism , <br />
                    306 DNI PSC Building,Port Saeed{" "}
                  </p>
                </div>
              </div>
              <div
                className="contact-info-item"
                style={{ borderBottom: "10px solid #938DFC" }}
              >
                <div className="contact-info-title">Thailand</div>
                <div className="contact-info">
                  <p>
                    100/7 Moo 12, <br />
                    Nongprue,Banglamung Chanburi
                  </p>
                </div>
              </div>
            </div>
          )}
          {width < breakpoint && (
            <div className="section-contact-info grid-container ">
              <Carousel
                itemsToShow={1}
                itemPadding={[0, 10, 0, 0]}
                pagination={false}
              >
                <div
                  className="contact-info-item"
                  style={{ borderBottom: "10px solid #33B3A6" }}
                >
                  <div className="contact-info-title">Mumbai :</div>
                  <div className="contact-info">
                    <p>
                      Hallmark Business Plaza 11th Floor,
                      <br /> Office No 1106, Bandra East,
                      <br /> Mumbai, Maharashtra 400051
                      <br />
                      <br />
                      <strong>Phone :</strong> 022-4013 4887 / 8879111166
                      <br />
                      <strong>Email :</strong> info@tickat.com
                    </p>
                  </div>
                </div>
                <div
                  className="contact-info-item"
                  style={{ borderBottom: "10px solid #11263B" }}
                >
                  <div className="contact-info-title">Nagpur</div>
                  <div className="contact-info">
                    <p>
                      BTP Group,532-A <br />
                      Hanuman Nagar,Main Road,
                      <br />
                      Tukdoji Square,Nagpur ,440009{" "}
                    </p>
                  </div>
                </div>
                <div
                  className="contact-info-item"
                  style={{ borderBottom: "10px solid #D58D47" }}
                >
                  <div className="contact-info-title">Dubai</div>
                  <div className="contact-info">
                    <p>
                      Fly High Tourism , <br />
                      306 DNI PSC Building,Port Saeed{" "}
                    </p>
                  </div>
                </div>
                <div
                  className="contact-info-item"
                  style={{ borderBottom: "10px solid #938DFC" }}
                >
                  <div className="contact-info-title">Thailand</div>
                  <div className="contact-info">
                    <p>
                      100/7 Moo 12, <br />
                      Nongprue,Banglamung Chanburi
                    </p>
                  </div>
                </div>
              </Carousel>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomepageContactUs;

const useStyles = makeStyles((theme) => ({
  contactUsRoot: {
    fontFamily: theme.palette.font.primary,
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
    background: "#fafafa",
    marginTop: -1,
    boxSizing: "border-box",
    width: "100%",
    "& .section-container": {
      margin: 0,
      padding: 0,
      width: "100% !important",
    },
    "& .grid-container": {
      display: "flex",
    },
    "& .justify-center": {
      justifyContent: "center",
      padding: "20px",
    },

    "& .md-1280": {
      maxWidth: 1280,
      borderRadius: "10px",
      width: "100%",
      padding: "20px",
      [theme.breakpoints.down(1290)]: {
        padding: "0 20px 50px 20px",
      },
    },

    "& .section-contact-info": {
      [theme.breakpoints.down(959)]: {
        flexWrap: "wrap",
      },
      marginTop: 35,
      [theme.breakpoints.down(560)]: {
        marginTop: 5,

        "& .rec-arrow-left": {
          position: "absolute",
          left: 0,
          zIndex: 5,
          "&:disabled": {
            visibility: "visible",
            background: "rgba(103, 58, 183, 0.1) !important",
            color: `${(prop) =>
              prop.theme.palette.primary.disabled} !important`,
            border: `solid 1px ${(prop) =>
              prop.theme.palette.background.disabled}`,
            borderRadius: 30,
          },
          "&:hover": {
            background: `${(prop) =>
              prop.theme.palette.primary.contrastText} !important`,
            color: `${(prop) => prop.theme.palette.primary.main} !important`,
          },
        },
        "& .rec-arrow-right": {
          position: "absolute",
          right: 0,
          zIndex: 5,
          "& disabled": {
            visibility: "visible",
            background: "rgba(103, 58, 183, 0.1) !important",
            color: `${theme.palette.primary.disabled} !important`,
            border: `solid 1px ${theme.palette.background.disabled}`,
            borderRadius: 30,
          },
          "&:hover": {
            background: `#fff !important`,
            color: `${theme.palette.primary.main} !important`,
          },
        },

        "& .rec-carousel-item": {
          display: "flex",
        },
        // & .rec-arrow-right {
        //   position: absolute;
        //   right: 0;
        //   z-index:5;

        // }
      },

      "& .contact-info-item": {
        //padding: 20,
        backgroundColor: "#F2F1F1",
        width: "25%",
        margin: 5,
        padding: "0 0 0 15px",
        paddingRight: 15,
        border: "1px solid #eee",
        // borderBottom: "15px solid #000",
        // borderRadius: "10px",
        [theme.breakpoints.down(960)]: {
          width: "100%",
          textAlign: "center",

          padding: "0 20px 50px 20px",
        },
        [theme.breakpoints.down(560)]: {
          padding: "0 20px 20px 20px",
        },
      },
      "& .contact-info-title": {
        fontSize: 18,
        fontWeight: 700,
        marginTop: 20,
      },
      "& .contact-info": {
        textAlign: "left",
        marginTop: 15,
        [theme.breakpoints.down(960)]: {
          textAlign: "center",
        },
        "& p": {
          fontSize: 14,
          fontWeight: 500,
        },
      },
    },

    "& .section-caption": {
      borderBottom: "1px solid #eee",
      "& p": {
        fontSize: 30,
        textTransform: "uppercase",
        fontWeight: 800,
        lineHeight: 1.2,
        color: theme.palette.primary.darkText,
        [theme.breakpoints.down(960)]: {
          fontSize: 30,
        },
        [theme.breakpoints.down(560)]: {
          fontSize: 25,
        },
      },
      "&.light-text": {
        color: theme.palette.primary.lightText,
        "& p": {
          color: theme.palette.primary.lightText,
        },
      },
    },
  },
}));
