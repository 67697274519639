import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";
import Button from "components/CustomButtons/Button";
import { Icon } from "@material-ui/core";
import { AppConstant } from "appConstant";
import { useState } from "react";

const selectedRoom = ({
  selectedRooms,
  setSelectedRooms,
  hotelSearch,
  bookRoom,
  roomCombination,
  themeobj,
  ...props
}) => {
  const classes = styles();
  const theme = themeobj;
  const hotelPriceRoundOff = AppConstant.hotelPriceRoundOff;

  const removeRoom = (roomIndex) => {
    let data = [...selectedRooms.room];

    const combinations = getCombinationRoom(roomIndex);

    data = data.filter(function (obj) {
      return combinations && !combinations.includes(obj.roomIndex);
    });

    setSelectedRooms({ room: data });
  };

  const getCombinationRoom = (roomIndex) => {
    if (roomCombination) {
      for (let index = 0; index < roomCombination.length; index++) {
        const element = roomCombination[index];

        for (
          let indexSec = 0;
          indexSec < element.roomIndex.length;
          indexSec++
        ) {
          const roomIndexTemp = element.roomIndex[indexSec];

          if (roomIndexTemp == roomIndex) {
            return element.roomIndex;
          }
        }
      }
    }
  };

  return (
    // <Grid container style={{ paddingLeft: window.innerWidth < 600? 5:15, paddingRight:window.innerWidth < 600? 5:15}}>
    //   <Grid item xs={12}>
    <div className={classes.root}>
      <Card className={classes.cardContainer}>
        <CardBody style={{ overflowY: "auto" }}>
          <Grid item xs={12} md={3}>
            <div
              style={{
                fontSize: "12px",
                color: theme.palette.secondary.sharkGray,
                fontWeight: 500,
              }}
            >
              Selected Rooms
              {selectedRooms.room.length === hotelSearch.noOfRooms ? null : (
                <span
                  style={{ color: "red" }}
                >{`(Please Select ${hotelSearch.noOfRooms} Room)`}</span>
              )}
            </div>
          </Grid>
          <Grid container spacing={2}>
            {selectedRooms.room.map((room, index) => (
              <Grid key={index} item xs={12} md={3}>
                <Card className={classes.card}>
                  <CardBody>
                    <div
                      style={{
                        borderRadius: "50px",
                        background: theme.palette.background.fortressGray,
                        position: "absolute",
                        top: -10,
                        right: -11,
                        height: 25,
                        width: 23,
                      }}
                    >
                      <Icon
                        onClick={() => removeRoom(room.roomIndex)}
                        style={{ cursor: "pointer" }}
                      >
                        close
                      </Icon>
                    </div>
                    <Typography className={classes.typosup}>
                      {room.name}
                    </Typography>
                    <Typography className={classes.data}>
                      {room.countryCode === "INR" ? "₹" : room.countryCode}{" "}
                      {hotelPriceRoundOff
                        ? room.price.toFixed(0)
                        : room.price.toLocaleString('en-IN')}
                    </Typography>
                  </CardBody>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12}>
            {selectedRooms.room.length === hotelSearch.noOfRooms ? (
              <Button className="BooknowBtn" onClick={bookRoom}>
                Book Now
              </Button>
            ) : null}
          </Grid>
        </CardBody>
      </Card>
    </div>
    //   </Grid>
    // </Grid>
  );
};

export default selectedRoom;

const styles = makeStyles((theme) => ({
  root: {
    "& .BooknowBtn": {
      background: theme.palette.buttons.bookNowBtn,
      // cursor: "pointer",
      color: theme.palette.primary.lightText,
    },
  },
  card: {
    height: "auto",
    boxShadow: "none",
    background: theme.palette.primary.lightText,
  },
  typosup: {
    fontSize: "15px",
    color: theme.palette.background.carulean,
    fontWeight: "500",
  },
  data: {
    fontSize: "16px",
    color: theme.palette.primary.peach,
  },
  cardContainer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    height: 215,
    width: "100%",
    zIndex: 1,
    borderTop: "1px solid #b1b1b1",
    boxShadow: `2px 3px 20px 1px ${theme.palette.background.lightFadedGray}`,
    "& .BooknowBtn": {
      background: theme.palette.buttons.tertiary,
    },
  },
}));
