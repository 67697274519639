import React, { useState } from "react";
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  useTheme,
  FormControl,
  Button,
  IconButton,
  Checkbox,
  withWidth,
  useMediaQuery,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import { localforageGetItem } from "oautils/oaForageUtils";

import * as Yup from "yup";
import OaFormAlerts from "pages/components/OaFormAlerts";
import _ from "lodash";
import dateFnsFormat from "date-fns/format";
import FlightItinSection from "./FlightItinSection";
import { connect } from "react-redux";
import { setFlightBookingInitialTime } from "redux/action";
import { allAirlines, gdsAirlines, lccAirlines } from "api/ApiConstants";
import styled from "styled-components";
import FlightFareType from "./formControls/FlightFareType"

const FlightSection = (props) => {
  const formikRef = React.useRef();
  const isMounted = React.useRef(false);
  const arrayHelperRef = React.useRef();

  const [isSaving, setIsSaving] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [infoText, setInfoText] = React.useState("");
  const [userType, setUserType] = React.useState(null);
  const [corporateType, setCorporateType] = React.useState(null);
  const [partnerId, setPartnerId] = React.useState(null);
  const [clientType, setClientType] = React.useState(null);
  const [tripMode, setTripMode] = React.useState("ONE");
  const [alertMessage, setAlertMessage] = React.useState("");
  const [fareType, setFareType] = useState("Regular");
  const [severity, setSeverity] = React.useState({
    issuccess: false,
    iserror: false,
    iswarning: false,
    isinfo: false,
  });

  // console.log();

  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.down(900));
  const history = useHistory();
  

  const initialParams = {
    adultPax: 1,
    childPax: 0,
    infantPax: 0,
    mode: "ONE",
    fareType: "Regular",
    tripMode: tripMode,
    preferredAirline: [allAirlines],
    preferredclass: "E",
    returnDate: dateFnsFormat(new Date(), "yyyy-MM-dd"),
    returnDateDisplay: new Date(),
    sectors: [
      {
        departDate: dateFnsFormat(new Date(), "yyyy-MM-dd"),
        departDateDisplay: new Date(),
        destination: popularCities[1].airportCode,
        destinationObj: popularCities[1],
        origin: popularCities[0].airportCode,
        originObj: popularCities[0],
      },
    ],
  };

  const valSchema = Yup.object().shape({
    preferredclass: Yup.string().required("Mandatory"),
    adultPax: Yup.number().required(),
    childPax: Yup.number().required(),
    infantPax: Yup.number().required(),
    sectors: Yup.array().of(
      Yup.object().shape({
        destination: Yup.string().when("mode", {
          is: (mode) => mode == "ONE" || mode == "ROUND",
          then: Yup.string().required("Mandatory"),
        }),
        origin: Yup.string().when("mode", {
          is: (mode) => mode == "ONE" || mode == "ROUND",
          then: Yup.string().required("Mandatory"),
        }),
      })
    ),
  });

  const validateSectorsForNonMulticity = () => {
    if (formikRef.current) {
      if (
        formikRef.current.values.mode !== "MULTICITY" &&
        formikRef.current.values.sectors.length > 1
      ) {
        formikRef.current.setFieldValue(
          `sectors`,
          formikRef.current.values.sectors.slice(0, 1)
        );
      }
    }
  };

  const onChangeMode = (e) => {
    console.log(e.target.value);
    if (formikRef.current) {
      formikRef.current.setFieldValue(`mode`, e.target.value);
      setTripMode(e.target.value);
    }
  };

  const modifyPassengerCount = (type, val) => {
    if (type === "adult") {
      formikRef.current.setFieldValue(
        "adultPax",
        Number(formikRef.current.values.adultPax) + val
      );
      // special case below = adults number should not be less than infants number
      if (
        formikRef.current.values.adultPax + val <
        formikRef.current.values.infantPax
      ) {
        formikRef.current.setFieldValue(
          "infantPax",
          Number(formikRef.current.values.infantPax) + val
        );
      }
    }
    if (type === "child") {
      formikRef.current.setFieldValue(
        "childPax",
        Number(formikRef.current.values.childPax) + val
      );
    }
    if (type === "infant") {
      if (
        formikRef.current.values.infantPax <=
          formikRef.current.values.adultPax &&
        Number(formikRef.current.values.infantPax) + val <=
          formikRef.current.values.adultPax
      ) {
        formikRef.current.setFieldValue(
          "infantPax",
          Number(formikRef.current.values.infantPax) + val
        );
      }
    }
    // console.log(data.adultCount, data.childCount, data.infantCount);
  };

  const handleChangeDirectFlight = (e, v) => {
    if (formikRef.current) {
      formikRef.current.setFieldValue(
        "isNonStop",
        !formikRef.current.values.isNonStop
      );
    }
  };

  const toggleRoundTrip = () => {
    if (formikRef.current) {
      if (formikRef.current.values.mode === "ONE") {
        formikRef.current.setFieldValue("mode", "ROUND");
      } else {
        formikRef.current.setFieldValue("mode", "ONE");
      }
    }
  };

  const handleSubmitTravelData = (values) => {
    values.clientType = clientType;
    values.partnerId = partnerId;
    window.sessionStorage.removeItem("service");
    window.sessionStorage.setItem("fetching", true);
    var allSectorOk = true;
    if (values.mode == "MULTICITY") {
      for (let i = 0; i < values.sectors.length; i++) {
        if (values.sectors[i].origin === "") {
          setAlertMessage("Please enter Origin");
          setSeverity({
            ...severity,
            iserror: true,
          });
          allSectorOk = false;
        } else if (values.sectors[i].destination === "") {
          setAlertMessage("Please enter Destination");
          setSeverity({
            ...severity,
            iserror: true,
          });
          allSectorOk = false;
        }
      }
    } else {
      if (values.sectors[0].origin === "") {
        setAlertMessage("Please enter Origin");
        setSeverity({
          ...severity,
          iserror: true,
        });
        allSectorOk = false;
      } else if (values.sectors[0].destination === "") {
        setAlertMessage("Please enter Destination");
        setSeverity({
          ...severity,
          iserror: true,
        });
        allSectorOk = false;
      }
    }

    if (allSectorOk) {
      props.setFlightBookingInitialTime(null);
      window.sessionStorage.removeItem("service");
      window.sessionStorage.setItem("fetching", true);
      history.push({
        pathname: "/flight/search-progress",
        state: values,
      });
    }
  };

  const openQuicklink = (quicklinktype) => {
    switch (quicklinktype) {
      case "flights-report": {
        history.push("/admin/booking-report/flight");
        break;
      }

      case "travel-calendar": {
        //don't uncomment --this will cause error window.openPage() is not a function and stop other scripts
        // window.openPage("travel-calendar");
        break;
      }

      case "cancellation": {
        history.push("/admin/booking-report/flight");
        break;
      }

      case "group-booking": {
        history.push("/admin/groupbooking");
        break;
      }

      case "airlines-contact": {
        // window.openPage("airlines-contact");
        history.push("/airlinecontacts");
        break;
      }

      case "web-checkin": {
        // window.openPage("web-checkin");
        history.push("/webcheckin");
        break;
      }
    }
  };

  React.useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    localforageGetItem("user-details", function(err, details) {
      if (isMounted.current && details) {
        setUserType(details.userType);
        setCorporateType(details.corporateType);
        setPartnerId(details.partnerId);

        switch (details.userTypeAbv) {
          case "S": {
            setClientType("Corporate");
            break;
          }
          case "G": {
            setClientType("Normal");
            break;
          }
          default: {
            setClientType("Normal");
            break;
          }
        }
      }
    });
  }, [isMounted.current]);

  React.useEffect(() => {
    validateSectorsForNonMulticity();
  }, [tripMode]);

  return (
    <Container
      // style={{
      //   width: 800,
      // }}
      theme={theme}
    >
      <div className="notification-container">
        <OaFormAlerts
          isSaving={isSaving}
          isSuccess={isSuccess}
          isError={isError}
          infoText={infoText}
          setIsError={setIsError}
          setIsSuccess={setIsSuccess}
        />
      </div>
      <div className="formik-wrapper">
        <Formik
          innerRef={formikRef}
          initialValues={initialParams}
          validationSchema={valSchema}
          onSubmit={handleSubmitTravelData}
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            <Form>
              <div className="form-wrapper">
                <div className="form-group-wrapper radio-group-wrapper radio-header">
                  <RadioGroup
                    className="form-control radio-control"
                    value={values.mode}
                    name={`mode`}
                    onChange={onChangeMode}
                  >
                    {flightWayList.map((a) => (
                      <FormControlLabel
                        key={a.val}
                        value={a.val}
                        control={<Radio color="secondary" />}
                        label={a.text}
                      />
                    ))}
                  </RadioGroup>
                  <ErrorMessage name="mode" component="div" className="error" />
                  <div className="header-text">
                    For International & Domestic Flights
                  </div>
                </div>

                <FieldArray
                  name="sectors"
                  render={(arrayHelpers) => {
                    arrayHelperRef.current = arrayHelpers;
                    return (
                      <FlightItinSection
                        arrayHelpers={arrayHelpers}
                        values={values}
                        setFieldValue={setFieldValue}
                        formikRef={formikRef}
                        setIsError={(val) => setIsError(val)}
                        popularCities={popularCities}
                        setTripmode={setTripMode}
                        togglerOnClick={toggleRoundTrip}
                        modifyPassengerCount={modifyPassengerCount}
                        handleChangeDirectFlight={handleChangeDirectFlight}
                        gdsAirlines={gdsAirlines}
                        lccAirlines={lccAirlines}
                        allAirlines={allAirlines}
                        breakpoint={breakpoint}
                      />
                    );
                  }}
                />

                {values.mode !== "MULTICITY" && !breakpoint && (
                  <div className="bottom-container">
                    {/* <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="method"
                        name="method"
                        value={values.fareType}
                        // value={"Regular"}
                        onChange={
                          (event) => {
                            setFareType(event.target.value);
                            setFieldValue("fareType", event.target.value);

                            // console.log(event.target.value, "VALUE");
                          }
                          // props.setselectedpaymentmethod(event.target.value)
                          // console.log("first")
                        }
                      >
                        <FormControlLabel
                          value="Regular"
                          control={<Radio />}
                          label={`Regular Fare`}
                        />
                        <FormControlLabel
                          value="Student"
                          control={<Radio />}
                          label={`Student Fare`}
                        />
                        <FormControlLabel
                          value="SeniorCitizen"
                          control={<Radio />}
                          label={`SeniorCitizen Fare`}
                        />
                      </RadioGroup>
                    </FormControl> */}
                    <FlightFareType values={values} setFareType = {setFareType} setFieldValue={setFieldValue} />
                    <div className="form-group-wrapper submit-wrapper">
                      <Button type="submit">Search Flights</Button>
                    </div>
                  </div>
                )}
              </div>
              {/* <p style={{wordWrap: "wrap", color: "white", width: "100%", padding: "10px"}}>{JSON.stringify(values)}</p> */}
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
};

function mapStateToProps(state, props) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setFlightBookingInitialTime: (flightBookingInitialTime) =>
      dispatch(setFlightBookingInitialTime(flightBookingInitialTime)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(FlightSection));

const flightWayList = [
  { val: "ONE", text: "One Way" },
  { val: "ROUND", text: "Round Trip" },
  // { val: "ROUNDSP", text: "Round Trip Special" },
  { val: "MULTICITY", text: "Multi City" },
];

const popularCities = [
  {
    airportId: 5669,
    cityName: "Mumbai",
    airportCode: "BOM",
    countryCode: "IN",
    citySearch: "MUMBAI,BOMBAY",
    airportDesc: "Chhatrapati Shivaji International Airport",
  },
  {
    airportId: 5877,
    cityName: "New Delhi",
    airportCode: "DEL",
    countryCode: "IN",
    citySearch: "NEW DELHI,DELHI",
    airportDesc: "Indira Gandhi International Airport",
  },
  {
    airportId: 682,
    cityName: "Bangkok",
    airportCode: "BKK",
    countryCode: "TH",
    citySearch: "",
    airportDesc: "Bangkok",
  },
  {
    airportId: 679,
    cityName: "Bangalore",
    airportCode: "BLR",
    countryCode: "IN",
    citySearch: "BANGALORE,BANGLURU,BANGLORE,BENGALURU",
    airportDesc: "Bengaluru International Airport",
  },
  {
    airportId: 6926,
    cityName: "Pune",
    airportCode: "PNQ",
    countryCode: "IN",
    citySearch: "PUNE,PUNA",
    airportDesc: "Pune Airport",
  },
  {
    airportId: 3454,
    cityName: "Hyderabad",
    airportCode: "HYD",
    countryCode: "IN",
    citySearch: "HYDERABAD,HYDRABAD",
    airportDesc: "Rajiv Gandhi International Airport",
  },
  {
    airportId: 4229,
    cityName: "Kolkata",
    airportCode: "CCU",
    countryCode: "IN",
    citySearch: "KOLKATA,CALCUTTA,CALCUTA",
    airportDesc: "Netaji Subhash Chandra Bose International Airport",
  },
  {
    airportId: 1585,
    cityName: "Chennai",
    airportCode: "MAA",
    countryCode: "IN",
    citySearch: "CHENNAI,MADRAS",
    airportDesc: "Chennai International Airport",
  },
  {
    airportId: 2935,
    cityName: "Goa",
    airportCode: "GOI",
    countryCode: "IN",
    citySearch: "GOA",
    airportDesc: "Dabolim Goa International Airport",
  },
  {
    airportId: 2257,
    cityName: "Dubai",
    airportCode: "DXB",
    countryCode: "AE",
    citySearch: "",
    airportDesc: "Dubai International",
  },
];

const Container = styled.div`
  margin: 10px 0 0 20px;
  border-radius: 18px;
  border-top-left-radius: 0px;
  label {
   font-size: 14px;     
   @media all and (max-width: 1400px) {
     font-size: 11px;   
   }
  }
  // margin-top: 220px;
  width: 800px;
  // background-color: "rgba(255, 255, 255, 0.15)";
  @media all and (max-width: 1400px) {
    width: 700px;
  }
  @media all and (max-width: 1200px) {
    width: 650px;
  }
  @media all and (max-width: 1080px) {
    width: 600px;
  }
  @media all and (max-width: 1020px) {
    width: 570px;
  }
  background: #e9f5f4;
  webkit-box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.6);
  -moz-box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.6);
  box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.6);
  .form-wrapper {
    padding: 10px 15px;
  }
  .flight-itinerary-root {
    & .MuiInput-input {
      color: #838383;
      font-weight: 500;
      @media all and (max-width: 1280px) {
        font-size: 12px;
      }
    }
  }
  .arrow-icon {
    height: 20px;
    @media all and (max-width: 1280px) {
      height: 13px;
    }
  }
  .flight-search-container {
    display: flex;
    gap: 10px;
  }
  .radio-group-wrapper {
    border-bottom: 2px solid #b1b1b1;
  }
  .header-text {
    font-size: 14px;
    font-family: poppins;
    font-weight: 500;
  }
  .radio-header {
    flex-direction: row !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .form-group-wrapper {
    display: flex;
    flex-direction: column;

    gap: 5px;
    margin: 5px 0;

    & label {
      color: ${(prop) => prop.theme.palette.secondary.defaultText};
      font-family: ${(prop) => prop.theme.palette.font.secondary};
      font-weight: 700;
    }
  }
  .service-picker {
    background-color: #fff;
    padding: 10px;
    position: absolute;
    top: -38px;
    margin-left: 20px;
    border-radius: 10px;

    // left: -10px;
  }
  .form-control {
    &.adornment-icon {
      width: 0px;
    }
    &.radio-control {
      flex-direction: row;
      & .MuiRadio-root {
        & .MuiSvgIcon-root {
          color: ${(prop) => prop.theme.palette.secondary.defaultText};
        }
        &.Mui-checked {
          & .MuiSvgIcon-root {
            color: ${(prop) => prop.theme.palette.primary.main};
          }
        }
      }
      & .MuiTypography-root {
        font-size: 14px;
        font-weight: 500;
        color: ${(prop) => prop.theme.palette.text.default};
        @media all and (max-width: 1280px) {
          font-size: 11px;
        }
      }
    }
  }
  .swap-oneway{
    margin-top: 17px;
  }
  .swap-icon-wrapper {
    // position: absolute;
    // top: 33px;
    // left: 307px;
    // margin-top: 32px;
    & .swap-button {
      // background: ${(prop) => prop.theme.palette.primary.main};
      height: 36px;
      width: 36px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      & img {
        height: 40px;
      }
    }
  }
  .flex-row {
    display: flex;
    gap: 50px;
    margin: 13px 0;
  }
  .date-container {
    display: flex;
    gap: 10px;
    width: 30%;
    & .date-form {
      width: 50%;
     
      & .adornment-icon {
        width: 0px;
        display: none;
      }
    }
  }
  .bottom-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
    .MuiFormGroup-root {
      flex-direction: row;
      margin-left: 3px;
    }
    .MuiTypography-root {
      font-weight: 500;
      font-family: poppins;
      font-size: 15px;
      @media all and (max-width: 1280px) {
        font-size: 12px;   
      }
      @media all and (max-width: 1220px) {
        font-size: 10px;   
      }
      color: #838383;
    }
  }
  .fares-container {
    display: flex;

    & .direct-flight-label {
      font-weight: 600;
    }
  }
  .flight-info-multi{
    display: flex;
    align-items:center;
  }
  .flight-info-container {
    width: 100%;

    display: flex;
    gap: 10px;
    & .passenger-preferredClass {
      width: 69%;
      display: flex;
      justify-content: space-between;
    }
    & .passenger-preferredClass-multi {
      width: 50%;
      display: flex;
      justify-content: flex-start;
    }
    & .pax-class {
      width: 45%;
    }
    & .pref-direct {
      width: 45%;
    }
  }
  .location-container {
    display: flex;
    align-items: center;
    width: 70%;
    justify-content: space-between;
    & .adornment-icon {
      width: 0px !important;
    }
  }
  .location-form {
    width: 45%;
  }

  .w50p {
    width: 50%;
    max-width: 300px;
  }
  .pl15 {
    padding-left: 15px;
  }
  .flight-itinerary-root {
    & label {
      font-weight: 700;
    }
  }
  .p-rtv {
    position: relative;
  }
  .input-wrapper {
    position: relative;
  }
  .MuiAutocomplete-popper.MuiAutocomplete-popperDisablePortal {
    width: 300px !important;
    top: 36px;
    left: -46px;
    @media all and (max-width: 1320px) {

      width: 220px !important;
      left: -16px;

    }    
    & .ac-options-list {
      width: 300px;
      overflow-x: hidden;
      padding: 0 2px;
      & span {
        padding: 0;
        display: flex;
        gap: 10px;
        justify-content: space-between;
        width: 100%;
        & .ac-option-right {
          padding: 0;
        }
      }
      & p {
        white-space: nowrap;
      }
    }
  }
  .submit-wrapper {
    & button {
      background: ${(prop) => prop.theme.palette.buttons.primary};
      color: ${(prop) => prop.theme.palette.buttons.primaryContrastText};
      text-transform: uppercase;
      height: 45px;
      border-radius: 8px;
      width: 160px;
      font-weight: 600;
      font-family: poppins;
      text-transform: capitalize;
    }
  }
  .loginIcon {
    position: absolute;

  }
  .multicity-submit-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & .submit-wrapper {
      display: flex;
      flex-direction: row;
      padding-top: 25px;
      gap: 15px;
      & button {
        width: 150px;
        flex-grow: 1;
      }
    }
  }
  .direct-flight-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 15px;
    & .direct-flight-checkbox {
      & .MuiSvgIcon-root {
        color: ${(prop) => prop.theme.palette.text.default};
      }
      &.Mui-checked .MuiSvgIcon-root {
        color: ${(prop) => prop.theme.palette.primary.main};
      }
    }
    & .direct-flight-label {
      color: ${(prop) => prop.theme.palette.text.default};
      font-family: ${(prop) => prop.theme.palette.font.secondary};
      font-weight: 500;
    }
  }
  .align-items-center {
    align-items: center;
  }
  .pref-direct {
    display: flex;
    flex-direction: row;
    width: 45%;
    // align-items: center;
    // margin-top: 25px;
  }
  .pos-rel {
    position: relative;
  }
  .switch-one-way {
    position: absolute;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(72, 72, 72, 1);
    -moz-box-shadow: 0px 0px 3px 0px rgba(72, 72, 72, 1);
    box-shadow: 0px 0px 3px 0px rgba(72, 72, 72, 1);
    top: 34px;
    right: 10px;
    font-size: 16px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
  // @media all and (max-width: 1228px) {
  //   margin: 0;
  //   .submit-wrapper {
  //     padding-top: 25px;
  //     & button {
  //       border-radius: 25px;

  //       width: 160px !important;
  //     }
  //   }
  // }
  @media all and (max-width: 768px) {
    width: 100%;
    .swap-icon-wrapper {
      position: unset;
      padding: 30px 0 0;
    }
    .no-gap-768 {
      gap: 12px;
    }
    .multicity-row {
      & .flex-row {
        flex-direction: column;
        gap: 0;
        & .swap-icon-wrapper {
          padding: 0;
          display: flex;
          justify-content: center;
          position: relative !important;
          left: 0;
          top: 10px;
          & .swap-button img {
            transform: rotate(90deg);
          }
        }
      }
    }
    .multicity-submit-row {
      gap: 10px;
    }
  }
  @media all and (max-width: 630px) {
    .col-630 {
      flex-direction: column;
      & .w50p {
        width: 100%;
        max-width: 100%;
      }
    }
    .swap-icon-wrapper {
      padding: 0 0 0;
      display: flex;
      justify-content: center;
      position: relative;
      left: 0;
      top: 10px;
      & .swap-button img {
        transform: rotate(90deg);
      }
    }
    .no-gap-768 {
      gap: 0;
    }
  }
  @media all and (max-width: 596px) {
    .multicity-submit-row {
      gap: 0;
      flex-direction: column;
    }
    .w50p {
      width: 100%;
      max-width: 100%;
    }
  }
  @media all and (max-width: 490px) {
    .form-wrapper {
      padding: 10px;
    }
    .col-490,
    .no-gap-768 {
      flex-direction: column;
      gap: 0;
      & .w50p {
        width: 100%;
        max-width: 100%;
      }
    }
  }
`;
