import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  useTheme,
  Checkbox,
} from "@material-ui/core";
import moment from "moment";
import { GET_FILE_UPLOAD } from "api/ApiConstants";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PrintIcon from "@material-ui/icons/Print";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import DescriptionIcon from "@material-ui/icons/Description";
import GridContainer from "components/Grid/GridContainer";
import CancelIcon from "@material-ui/icons/Cancel";
import WebApi from "api/ApiConstants";
import { basename } from "api/ApiConstants";
import { useHistory } from "react-router-dom";
import { localforageGetItem } from "oautils/oaForageUtils";
import { useParams } from "react-router-dom";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { apiCall } from "oautils/oaDataUtils";

const FlightReportDetails = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const params = useParams();
  const history = useHistory();
  const [userType, setUserType] = React.useState(null);
  const [flightList, setFlightList] = React.useState(null);
  const [flightData, setFlightData] = React.useState(null);
  const [loggedInUserId, setIsLoggedInUserId] = useState(0);
  const [open, setOpen] = useState(false);

  console.log(flightData, "PARAMS---------");

  const cancelRequest = () => {
    apiCall(
      WebApi.b2CFlightCancellation,
      {
        passenger: flightData?.passengers,
        remark: "sdksksks",
        tid: params.ticketId,
        transType: 1,
        typeOfCancellation: 2,
      },
      (response) => {
        console.log(response);
        // setIsError(!response.success);
        // setIsSuccess(response.success);

        // setIsSaving(false);
        // setInfoText(response.message);
        // window.scrollTo({ top: 0, behavior: "smooth" });
        // setTimeout(() => {
        //   props.history.goBack();
        // }, 3000);
      }
    );
    // setIsCancelled(true)
  };

  useEffect(() => {
    localforageGetItem("user-details", function(err, value) {
      if (err == null && value != null) {
        setUserType(value.userType);
        setIsLoggedInUserId(value.userId);
      }
    });
  }, []);

  useEffect(() => {
    WebApi.getTicketData(
      { transactionId: params.ticketId },
      (response) => {
        console.log(response?.data);
        setFlightData(response?.data);
        console.log(response?.data, "FLIGHTDATA -----------------");
        // if (response !== null && response.success === true) {
        //   resolve(response.data);
        // }
      },
      (error) => {
        // reject([]);
        console.log(error);
      }
    );
  }, []);

  //const [trType, setTrType] = React.useState(null);

  // React.useEffect(() => {
  //     if(props.usertype==="R")
  //     {
  //         // console.log("props.refId gg" , props.location.state.refId);
  //         WebApi.getB2CFlightList({ userType: props.usertype,}, (response) => {
  //             setFlightList(response.data);
  //         }, (error) => { console.log("getB2CFlightList error", error) });
  //     }
  //     else{
  //     // console.log("props.refId " , props.location.state.refId);
  //         WebApi.getB2CFlightList({ userType: props.usertype , refId : props.location.state.refId}, (response) => {
  //             setFlightList(response.data);
  //         }, (error) => { console.log("getB2CFlightList error", error) });
  //     }
  // }, []);

  // React.useEffect(()=>{
  //     if(props.usertype ==="G")
  //     {
  //         WebApi.getB2CFlightList({ userType: props.usertype, refId : props.location.state.refId}, (response) => {
  //             setFlightList(response.data);
  //         }, (error) => { console.log("getB2CFlightList error", error) });
  //     }
  // },[])

  // console.log(props.usertype,"jkasdhjhsgad")
  // console.log(flightList,"jkasdhjhsgad22")

  return (
    <Grid
      item
      md={12}
      sx={12}
      sm={12}
      style={{
        background: "#fff",
        paddingBottom: "6px",
        textAlign: "center",
        // border: "1px solid #bfb6b6",
        padding: isMobile ? 5 : "20px",
        borderRadius: "5px",
      }}
    >
      <Grid
        container
        item
        md={12}
        justifyContent="flex-end"
        alignItems="center"
        style={{ marginBottom: 5 }}
      >
        {/* <Grid item style={{ textAlign: "end" }}>
                      <Typography className={classes.typo10}>
                        Filter/ Sort By
                      </Typography>
                      <select
                        value={value}
                        onChange={(event) => setValue(event.target.value)}
                      >
                        <option value="1">Relevance</option>
                        <option value="2">Booking Date</option>
                      </select>
                    </Grid> */}
      </Grid>
      <Grid
        container
        item
        md={12}
        style={{
          background: "#fff",
          border: "1px solid #dcdcdc",
          borderRadius: "5px",
          boxShadow: "0px 0px 6px -1px #b3b3b3",
          marginBottom: 20,
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{
            background: "#e2e2e2",
            padding: "10px",
          }}
        >
          <Grid item container md={4} xs={6}>
            <Grid item>
              <i
                className="fa fa-plane"
                aria-hidden="true"
                style={{ opacity: 0.5, fontSize: 22 }}
              ></i>
            </Grid>
            <Grid item>
              <Typography className={classes.typo1}>
                Total Passenger(s):{" "}
                {/* {ticket.onwardFlightDetails.summary.adult +
                        ticket.onwardFlightDetails.summary.child +
                        ticket.onwardFlightDetails.summary.infant} */}
                {/* <span style={{ fontSize: 16, color: "red" }}>
                                {index}
                              </span> */}
              </Typography>
              <Typography className={classes.typo1} style={{ opacity: 0.7 }}>
                {`ADULT:${flightData?.flightSummary?.adultNo}  CHILD:${flightData?.flightSummary?.adultNo}  INFANT:${flightData?.flightSummary?.adultNo}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container md={6} xs={6} justifyContent="flex-end">
            <Grid item>
              <Grid container>
                <Typography className={classes.typo2}>
                  Departure Date:{" "}
                  {moment(
                    flightData?.onwardJourney?.[0]?.departureDateTimeRaw
                  ).format("DD MMM'YY")}
                </Typography>
                <Typography className={classes.typo3}>
                  PNR No.
                  {flightData?.pnrNo}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ padding: 15 }}>
          <Grid
            container
            item
            xs={12}
            md={8}
            style={{
              borderRight: isMobile ? "none" : "1px solid #ababab",
              paddingRight: isMobile ? 0 : 25,
              paddingBottom: 25,
            }}
          >
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={6}>
                <Grid container justifyContent="flex-start" alignItems="center">
                  <Grid item xs={6} md={2} style={{ textAlign: "center" }}>
                    <img
                      src={
                        GET_FILE_UPLOAD +
                        "?fileName=" +
                        flightData?.onwardJourney[0].flightName +
                        ".png&type=AIRLINE_IMAGES"
                      }
                      width={28}
                    />
                  </Grid>
                  <Grid item xs={12} md={10} style={{ textAlign: "left" }}>
                    <Typography className={classes.typo4}>
                      {`${flightData?.onwardJourney[0].carrierName}(${flightData?.onwardJourney[0].flightName} - ${flightData?.onwardJourney[0].flightCode})`}
                    </Typography>
                    <Typography className={classes.typo5}>
                      {flightData?.flightSummary.cabinType}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={3} xs={6}>
                {/* <Typography className={classes.typo4}>
                                PNR :{" "}
                                {ticket.onwardFlightDetails.summary.pnrNumber}
                              </Typography> */}
                <Typography className={classes.typo5}>
                  {/* {ticket.onwardFlightDetails.summary.isRefundable} */}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              style={{ marginTop: "15px" }}
            >
              <Grid item xs={5} md={3}>
                <Typography className={classes.typo6}>
                  {flightData?.onwardJourney?.[0]?.originCityName}
                </Typography>
                <Typography className={classes.typo7}>
                  {moment(
                    flightData?.flightSummary?.onwardJourney?.[0]
                      ?.departureDateTimeRaw
                  ).format("DD MMM'YY")}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                {/* <i
                  className="fa fa-long-arrow-right"
                  aria-hidden="true"
                  style={{ fontSize: "35px", color: "#4e4c4c" }}
                ></i> */}
                <ArrowForwardIcon
                  style={{ fontSize: "35px", color: "#4e4c4c" }}
                />
              </Grid>
              <Grid item xs={5} md={3}>
                <Typography className={classes.typo6}>
                  {/* {ticket.onwardFlightDetails.summary.arrAirport !== null &&
                      ticket.onwardFlightDetails.summary.arrAirport.split(
                        ", "
                      )[1]} */}
                  {flightData?.onwardJourney?.[0]?.destinationCityName}
                </Typography>
                <Typography className={classes.typo7}>
                  {moment(
                    flightData?.onwardJourney?.[0]
                      ?.arrivalDateTimeRawarrivalDateTimeRaw
                  ).format("DD MMM'YY")}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              style={{ marginTop: 15 }}
            >
              <Grid item md={3} xs={6}>
                <Typography className={classes.typo8}>
                  {/* {`${ticket.passengerDetails[0].title}. ${ticket.passengerDetails[0].givenname} ${ticket.passengerDetails[0].surname}`} */}
                  {flightData?.passengers?.[0].title}{" "}
                  {flightData?.passengers?.[0].firstName}
                  {flightData?.passengers?.[0].lastName}
                </Typography>
              </Grid>
              <Grid item md={3} xs={6}>
                <Typography className={classes.typo8}>
                  Status{" "}
                  <span style={{ color: "#007bff" }}>
                    {flightData?.bookingStatus}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid continer item xs={12} md={4} style={{ paddingLeft: 15 }}>
            <Grid container justifyContent="space-evenly" spacing={1}>
              <Grid item>
                {/* <Button
                  className={classes.button}
                  onClick={() =>
                    props.history.push("/b2c/faredetails", {
                      // flightTicketDetails: ticket,
                      // index,
                    })
                  }
                >
                  Fare Details
                </Button> */}
              </Grid>
              <Grid item>
                {/* {ticket.status &&
                        (ticket.status != "Pending" &&
                          ticket.status != "SUCCESSCan") && (
                          <Button
                            className={classes.button}
                            onClick={() =>
                              props.history.push({
                                pathname: "/b2c/itinerary",
                                state: {
                                  ticket: ticket,
                                  index: index,
                                  userType: props.usertype,
                                },
                              })
                            }
                          >
                            Itinerary
                          </Button>
                        )} */}

                {/* <Button
                  className={classes.button}
                  onClick={() =>
                    props.history.push({
                      pathname: "/b2c/itinerary",
                      state: {
                        // ticket: ticket,
                        // index: index,
                        userType: props.usertype,
                      },
                    })
                  }
                >
                  Itinerary
                </Button> */}
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20 }}>
              {/* {ticket.status &&
                      (ticket.status != "Pending" &&
                        ticket.status != "SUCCESSCan") && (
                        <Button
                          className={classes.button}
                          style={{ width: "11rem" }}
                          onClick={() =>
                            props.history.push({
                              pathname: "/b2c/itinerary",
                              state: {
                                ticket: ticket,
                                index: index,
                                userType: props.usertype,
                              },
                            })
                          }
                        >
                          Check Refund Status
                        </Button>
                      )} */}

              {/* <Button
                className={classes.button}
                style={{ width: "11rem" }}
                onClick={() =>
                  props.history.push({
                    pathname: "/b2c/itinerary",
                    state: {
                      // ticket: ticket,
                      // index: index,
                      userType: props.usertype,
                    },
                  })
                }
              >
                Check Refund Status
              </Button> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{
            borderTop: "1px solid #9e9e9e",
            margin: "0px 15px",
            padding: "15px 0px",
          }}
        >
          <Grid
            item
            style={{ padding: "0px 10px", cursor: "pointer" }}
            onClick={() => {
              // props.history.push(
              //     "/print/flightticket/" + ticket.tid
              // );
              window.open(
                basename +
                  "/admin/booking-report/flight/ticket/" +
                  params.ticketId,
                "_blank"
              );
            }}
          >
            {/* <i
                            className="fa fa-ticket"
                            aria-hidden="true"
                            style={{
                              textAlign: "center",
                              fontSize: "19px",
                              paddingBottom: "7px",
                              color: "#6f6f6f",
                            }}
                          ></i> */}
            <PrintIcon
              style={{
                textAlign: "center",
                fontSize: "25px",
                paddingBottom: "7px",
                color: "#6f6f6f",
              }}
            />
            <Typography className={classes.typo9}>Print Ticket</Typography>
          </Grid>

          <Grid
            item
            style={{ padding: "0px 10px", cursor: "pointer" }}
            onClick={() => {
              window.open(
                basename +
                  "/admin/booking-report/flight/ticket/" +
                  params.ticketId,
                "_blank"
              );
            }}
          >
            {/* <i
                            className="fa fa-download"
                            aria-hidden="true"
                            style={{
                              textAlign: "center",
                              fontSize: "19px",
                              paddingBottom: "7px",
                              color: "#6f6f6f",
                            }}
                          ></i> */}
            <ArrowDownwardIcon
              style={{
                textAlign: "center",
                fontSize: "25px",
                paddingBottom: "7px",
                color: "#6f6f6f",
              }}
            />
            <Typography className={classes.typo9}>Download Ticket</Typography>
          </Grid>
          <Grid
            item
            style={{ padding: "0px 10px", cursor: "pointer" }}
            onClick={() => {
              // props.history.push(
              //     "/print/voucher/flight-user/" + ticket.tid
              // );
              // window.open(
              //   basename + "/print/voucher/flight-user/" + ticket.tid,
              //   "_blank"
              // );
              console.log(basename);
              // history.push("/admin/voucher/flight-agent/" + params.ticketId);
              window.open(
                basename + "/admin/voucher/flight-agent/" + params.ticketId,
                "_blank"
              );
            }}
          >
            {/* <i
                            className="fa fa-sticky-note-o"
                            aria-hidden="true"
                            style={{
                              textAlign: "center",
                              fontSize: "19px",
                              paddingBottom: "7px",
                              color: "#6f6f6f",
                            }}
                          ></i> */}
            <DescriptionIcon
              style={{
                textAlign: "center",
                fontSize: "25px",
                paddingBottom: "7px",
                color: "#6f6f6f",
              }}
            />
            <Typography className={classes.typo9}>Invoice</Typography>
          </Grid>
          <Grid
            item
            style={{ padding: "0px 10px", cursor: "pointer" }}
            onClick={() => {
              // props.history.push({
              //   pathname: "/b2c/itinerary",
              //   state: {
              //     // ticket: ticket,
              //     // index: index,
              //     userType: props.usertype,
              //   },
              // });
              setOpen(true);

              // history.push({
              //   pathname: "/b2c/mybookings",
              //   state: {
              //     loggedInUserId: loggedInUserId,
              //     transactionId: params.ticketId,
              //     refId: params.ticketId,
              //     userType: "agent",
              //     trType: 1,
              //   },
              // });
            }}
          >
            {/* <i
                            className="fas fa-ban"
                            aria-hidden="true"
                            style={{
                              textAlign: "center",
                              fontSize: "19px",
                              paddingBottom: "7px",
                              color: "#6f6f6f",
                            }}
                          ></i> */}
            <CancelIcon
              style={{
                textAlign: "center",
                fontSize: "25px",
                paddingBottom: "7px",
                color: "#6f6f6f",
              }}
            />
            <Typography className={classes.typo9}>Cancel Ticket</Typography>
          </Grid>
          {/* <Grid item style={{ padding: "0px 10px" }}>
                          <i
                            className="fa fa-commenting-o"
                            aria-hidden="true"
                            style={{
                              textAlign: "center",
                              fontSize: "19px",
                              paddingBottom: "7px",
                              color: "#6f6f6f",
                            }}
                          ></i>
                          <Typography className={classes.typo9}>
                            SMS/Email Ticket
                          </Typography>
                        </Grid> */}
        </Grid>
      </Grid>
      <Dialog
        open={open}
        // onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
      >
        <Grid
          container
          style={{
            marginTop: "15px",
            padding: 20,
            border: `1px solid ${theme.palette.primary.disabled}`,
            borderRadius: "5px",
            boxShadow: `0px 0px 6px -1px ${theme.palette.primary.disabled}`,
            paddingTop: "12px",
            borderTop: `3px solid ${theme.palette.primary.main}`,
          }}
        >
          <Grid item md={12} xs={12}>
            <Typography className={classes.typo4}>
              {/* {`${ticket.onwardFlightDetails.summary.deptAirport !== null &&
                  ticket.onwardFlightDetails.summary.deptAirport.split(
                    ", "
                  )[1]} - ${ticket.onwardFlightDetails.summary.arrAirport !==
                  null &&
                  ticket.onwardFlightDetails.summary.arrAirport.split(
                    ", "
                  )[1]}`} */}
              {flightData?.onwardJourney?.[0]?.originCityName} -{" "}
              {flightData?.onwardJourney?.[0]?.destinationCityName}
            </Typography>
          </Grid>
          <GridContainer justifyContent="space-between" alignItems="center">
            <Grid item style={{ textAlign: "center" }}>
              <Grid container>
                <Grid item>
                  <img
                    src={
                      GET_FILE_UPLOAD +
                      "?fileName=" +
                      flightData?.onwardJourney?.[0]?.flightName +
                      ".png&type=AIRLINE_IMAGES"
                    }
                    alt="flight"
                    className={classes.img}
                  />
                </Grid>
                <Grid item>
                  <Typography className={classes.typo7}>
                    {flightData?.onwardJourney?.[0]?.carrierName}
                  </Typography>
                  <Typography className={classes.typo8}>
                    {`(${flightData?.onwardJourney?.[0]?.flightName}) - ${flightData?.onwardJourney?.[0]?.flightCode}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography className={classes.typo7}>
                {flightData?.flightSummary.cabinType === "Economy"
                  ? "Economy"
                  : "Business"}
              </Typography>
              <Typography className={classes.typo8}>
                PNR : {flightData?.pnrNo}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.typo7}>
                {flightData?.onwardJourney?.[0]?.originCityName}
              </Typography>
              <Typography className={classes.typo8}>
                {
                  flightData?.onwardJourney?.[0]?.departureDateTimeRaw.split(
                    "T"
                  )[0]
                }{" "}
              </Typography>
            </Grid>
            <Grid item>
              <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
            </Grid>
            <Grid item>
              <Typography className={classes.typo7}>
                {flightData?.onwardJourney?.[0]?.destinationCityName}
              </Typography>
              <Typography className={classes.typo8}>
                {
                  flightData?.onwardJourney?.[0]?.arrivalDateTimeRaw.split(
                    "T"
                  )[1]
                }
              </Typography>
            </Grid>
          </GridContainer>
          <Grid container>
            <Grid item xs={12} md={12}>
              {/* <Card xs={12}> */}
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th scope="col">Passenger Name</th>
                    <th scope="col">Status</th>
                    <th scope="col">Seat No.(s)</th>
                    <th scope="col">Baggage</th>
                    <th scope="col">Meal</th>
                    <th scope="col">Others</th>
                    <th scope="col">Cancel</th>
                  </tr>
                </thead>
                <tbody>
                  {flightData?.passengers.map((passenger, index) => (
                    <tr key={index}>
                      <td>{`${passenger.title}. ${passenger.firstName} ${passenger.lastName}`}</td>
                      <td>
                        {flightData?.pnrNo !== "" ? "Success" : "Processing"}
                      </td>
                      <td>{passenger?.onward_seat}</td>
                      <td>{passenger?.onward_baggage}</td>
                      <td>{passenger?.onward_meal}</td>
                      <td>N/A</td>
                      <td>
                        <Checkbox
                          disabled={passenger.isReturnCancelled}
                          checked={passenger.isReturnCancelled}
                          // onChange={() => handleReturnChange(passenger)}
                          name="checkedB"
                          color="primary"
                        />
                        {/* {passenger?.isReturnCancelled ? (
                          <Checkbox
                            disabled={passenger.isReturnCancelled}
                            checked={passenger.isReturnCancelled}
                            onChange={() => handleReturnChange(passenger)}
                            name="checkedB"
                            color="primary"
                          />
                        ) : (
                          <Checkbox
                            onChange={() => handleReturnChange(passenger)}
                            name="checkedB"
                            color="primary"
                          />
                        )} */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* </Card> */}
            </Grid>
            <Grid
              container
              spacing={1}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} md={3}>
                <Typography style={{ color: "red" }}>
                  Type of Cancellation:
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <FormControl component="fieldset" style={{ fontSize: 12 }}>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="1"
                    // value={value}
                    // onChange={handleChangeRadio}
                  >
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="No Show"
                      value="1"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio color="primary" />}
                      label="Flight Cancelled"
                    />
                    <FormControlLabel
                      value="3"
                      control={<Radio color="primary" />}
                      label="Others/Regular Cancellation"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ marginTop: 10 }}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={6} md={3}>
                <Typography>Cancellation Type</Typography>
              </Grid>
              <Grid item xs={6}>
                <textarea
                  style={{ width: "100%", height: 50 }}
                  // onChange={handleChangeCancellation}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.lightText,
                    borderRadius: 10,
                  }}
                  onClick={cancelRequest}
                >
                  Cancel Selected
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </Grid>
  );
};

export default FlightReportDetails;

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    margin: "auto",
    //paddingTop: "40px",
    paddingBottom: "40px",
    //marginTop: 100,
    "& .MuiBox-root": {
      padding: 0,
    },
    "& .MuiTab-textColorInherit": {
      "&.Mui-selected": {
        color: theme.palette.secondary.main,
      },
    },
    "& .PrivateTabIndicator-colorSecondary": {
      backgroundColor: theme.palette.secondary.main,
    },
    "& .b2c-tab-buttons": {
      background: theme.palette.secondary.disabled,
      position: "relative",
      width: "100%",
    },
    "& .b2c-tab-panel": {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  backButton: {
    position: "absolute",
    right: 10,
    top: 10,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.primary.main,
    "& .MuiSvgIcon-root": {
      fontSize: 16,
      marginRight: 5,
    },
    "&:hover": {
      color: theme.palette.primary.sub,
    },
  },
  typo1: {
    fontSize: "11px",
    fontWeight: 500,
    lineHeight: "1.5",
    color: "#212529",
    marginLeft: 5,
    letterSpacing: "0.5px !important",
  },
  typo2: {
    fontSize: "13px",
    paddingRight: "10px",
    fontWeight: 700,
    color: "#212529",
    letterSpacing: "0.5px !important",
    borderRight: "1px solid",
    [theme.breakpoints.down("sm")]: {
      borderRight: "none",
    },
  },
  typo3: {
    fontSize: "13px",
    fontWeight: 700,
    color: "#212529",
    letterSpacing: "0.5px !important",
    paddingLeft: "10px",
  },
  typo4: {
    fontSize: 12,
    color: "#000",
    fontWeight: 500,
  },
  typo5: {
    fontSize: 11,
    color: "#000",
    fontWeight: 300,
    opacity: 0.7,
  },
  typo6: {
    fontSize: 16,
    color: "#000",
    fontWeight: 400,
  },
  typo7: {
    fontSize: 12,
    color: "grey",
    fontWeight: 400,
  },
  typo8: {
    fontSize: 11,
    fontWeight: 300,
  },
  typo9: {
    color: "#6f6f6f",
    fontSize: 12,
  },
  typo10: {
    fontSize: 12,
    color: "#828080",
    fontWeight: 500,
  },
  button: {
    background: theme.palette.secondary.main,
    color: "#fff",
    fontSize: 13,
    textAlign: "center",
    padding: 8,
    borderRadius: 10,
    textTransform: "capitalize",
    "&:hover": {
      background: "#2b5a85",
    },
  },
  table: {
    width: "100%",
    marginBottom: "1rem",
    color: theme.palette.primary.darkText,
    borderCollapse: "collapse",

    "& thead": {
      "& tr": {
        "& th": {
          border: `1px solid ${theme.palette.primary.disabled} !important`,
          fontSize: "12px !important",
          fontWeight: "500 !important",

          padding: ".75rem",
          color: theme.palette.primary.darkText,
          [theme.breakpoints.down("sm")]: {
            padding: "0px",
            fontSize: "7px !important",
          },
        },
      },
    },
    "& tbody": {
      "& tr": {
        "& td": {
          border: `1px solid ${theme.palette.primary.disabled} !important`,
          fontSize: "12px !important",
          fontWeight: "400 !important",
          padding: ".75rem",
          [theme.breakpoints.down("sm")]: {
            padding: "0px",
            fontSize: "7px !important",
          },
        },
      },
    },
  },
}));
