import React from "react";
import { makeStyles } from "@material-ui/styles";
import { ArrowUpward } from "@material-ui/icons";
import { COLORS } from "assets/css/CssConstants";
import { Button } from "react-bootstrap";

const useStyles = makeStyles(theme => ({
    scrollTopRoot: {
        "& .scroll-to-top-button": {
            padding: 10,
            // background: COLORS.BTN_BG_OCT,
            background: theme.palette.secondary.main,//`linear-gradient(to bottom, #f9f0e3 0%,#f99e27 50%,#f9930e 51%,#f9b26b 100%)`,
            cursor: "pointer",
            position: "fixed",
            right: 10,
            bottom: 10,
            boxShadow: "0px 0px 56px -4px rgba(226,190,105,1)",
            border: "none",
            borderRadius: 50,
            width: 50,
            height: 50,
            zIndex: 9,
            "& .MuiSvgIcon-root": {
                color: COLORS.DEFAULT_TEXT_LIGHT,
            },
            "&:hover": {
                background: `linear-gradient(to bottom, #f9f0e3 0%,#d08606 50%,#ce8b04 51%,#f9b26b 100%)`,
            },
        }
    }
}));

const ScrollToTopComp = (props) => {

    const scrollToTopView = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }
    
    const classes = useStyles();
    const [showSTTB, setShowSTTB] = React.useState(false);


    const onScroll = () => {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            setShowSTTB(true);
        } else {
            setShowSTTB(false);
        }
    }

    React.useEffect(() => {
        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, []);
    
    return (
        <div className={classes.scrollTopRoot}>
            <Button className="scroll-to-top-button" 
                style={{display: showSTTB == true ? "block" : "none"}}
                onClick={() => scrollToTopView()}
            >
                <ArrowUpward />
            </Button>
        </div>
    )
}

export default ScrollToTopComp;
