import React, { useEffect, useState } from "react";
import { Button, useTheme, Modal, Box } from "@material-ui/core";
import styled from "styled-components";
import Carousel from "react-elastic-carousel";
import { useHistory } from "react-router-dom";
import offerImg from "assets/Tickat/images/home_pix/offer.png";
import copySelector from "assets/Tickat/images/home_pix/copyselector.png";
import WebApi from "api/ApiConstants";
import dateFnsFormat from "date-fns/format";
import { Link } from "react-router-dom";

const OfferSection = () => {
  const theme = useTheme();
  const history = useHistory();
  const width = window.innerWidth;
  const [selectedOffer, setSelectedOffer] = useState("All");
  const [showModal, setShowModal] = useState(false);
  const [offers, setOffers] = useState();
  const [offersData, setOffersData] = useState([]);

  useEffect(() => {
    WebApi.getExclusiveOffer(
      {
        callFrom: "web",
        offerCategory: "",
      },
      (resp) => {
        console.log(resp.data, "get pop");
        if (resp != null && resp.success) {
          setOffers(resp.data);
          const filteredItems = resp.data?.map(
            (offer) => offer.exclusiveOffers
          );
          const filteredData = filteredItems
            ?.flat()
            .reduce(function(rows, key, index) {
              return (
                (index % 2 == 0
                  ? rows.push([key])
                  : rows[rows.length - 1].push(key)) && rows
              );
            }, []);

          setOffersData(filteredData);
          // setOffersData(filteredItems?.flat());
        } else {
          console.log("error resp null", resp);
        }
      }
    );
  }, []);

  const handleClose = () => {};

  useEffect(() => {
    if (selectedOffer === "All") {
      const filteredItems = offers?.map((offer) => offer.exclusiveOffers);
      // console.log(filteredItems);
      const filteredData = filteredItems
        ?.flat()
        .reduce(function(rows, key, index) {
          return (
            (index % 2 == 0
              ? rows.push([key])
              : rows[rows.length - 1].push(key)) && rows
          );
        }, []);

      setOffersData(filteredData);
    }
    if (selectedOffer === "Flights") {
      const filteredItems = offers
        .filter((offer) => offer.categoryName.trim() === "Flights") // Change 'Jane' to the name you want to filter
        .map((obj) => obj.exclusiveOffers);
      const filteredData = filteredItems
        ?.flat()
        .reduce(function(rows, key, index) {
          return (
            (index % 2 == 0
              ? rows.push([key])
              : rows[rows.length - 1].push(key)) && rows
          );
        }, []);

      setOffersData(filteredData);
      // setOffersData(filteredItems.flat());
    }
    if (selectedOffer === "Hotels") {
      const filteredItems = offers
        .filter((offer) => offer.categoryName.trim() === "Hotels") // Change 'Jane' to the name you want to filter
        .map((obj) => obj.exclusiveOffers);
      const filteredData = filteredItems
        ?.flat()
        .reduce(function(rows, key, index) {
          return (
            (index % 2 == 0
              ? rows.push([key])
              : rows[rows.length - 1].push(key)) && rows
          );
        }, []);

      setOffersData(filteredData);
      // setOffersData(filteredItems.flat());
    }
  }, [selectedOffer]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    borderRadius: 20,
    boxShadow: 24,
    p: 4,
    "& .closebtn": {
      position: "absolute",
      right: 30,
      top: 20,
      cursor: "pointer",
    },
    "& .tc-subcontainer": {
      marginBottom: 15,
    },
    "& .titleheader": {
      fontWeight: 500,
    },
  };

  const OfferContainer = ({ offer }) => {
    // console.log(offer);
    return (
      <div className="offer-container">
        <Modal open={showModal} onClose={handleClose}>
          <Box sx={style}>
            <div className="tc-container">
              <div className="tc-subcontainer">
                <div className="titleheader">Terms & condition: </div>{" "}
                <div>{offer?.termsCondition}</div>
              </div>
              <div className="tc-subcontainer">
                <div className="titleheader">
                  Cancellation Terms & condition:{" "}
                </div>{" "}
                <div>{offer?.cancellationTermsConditions}</div>
              </div>
            </div>
            <div className="closebtn" onClick={() => setShowModal(false)}>
              X
            </div>
          </Box>
        </Modal>
        <div className="offer-subContainer">
          <div className="img-container">
            <img className="img-input" src={offer?.promoImage || offerImg} />
          </div>
          <div className="detail-container">
            <div className="detail-header">{offer?.offerCategory}</div>
            <div className="detail-subheader">₹{offer?.promoValue} Off</div>
            <div className="detail-description">{offer?.description}</div>
            <div className="bottom-container">
              <div
                className="tc-container"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                T & C Apply
              </div>
              <div className="promo-valid-container">
                <div className="promo-container">
                  <div className="promo-input">Promo code</div>
                  <div className="promo-value">
                    {offer?.promoCode}{" "}
                    <img
                      className="promo-copy"
                      onClick={() => {
                        navigator.clipboard.writeText(offer?.promoCode);
                      }}
                      src={copySelector}
                    />
                  </div>
                </div>
                <div className="valid-container">
                  <div className="valid-input">
                    valid till{" "}
                    {dateFnsFormat(new Date(offer?.validTo), "dd MMM, yyyy")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    offersData?.length > 0 && (
      <RootContainer theme={theme}>
        <div className="section-title-container">
          <div className="section-title">
            <div className="title-container">
              <div className="title">Offers</div>
              <div className="category-container">
                <div
                  onClick={() => setSelectedOffer("All")}
                  className={
                    selectedOffer === "All" ? "active offer" : "inactive offer"
                  }
                >
                  All
                </div>
                {offers?.map((offer) => (
                  <div
                    onClick={() => setSelectedOffer(offer?.categoryName.trim())}
                    className={
                      selectedOffer === offer?.categoryName.trim()
                        ? "active offer"
                        : "inactive offer"
                    }
                  >
                    {offer?.categoryName.trim()}
                  </div>
                ))}

                {/* <div
              onClick={() => setSelectedOffer("Hotel")}
              className={
                selectedOffer === "Hotel" ? "active offer" : "inactive offer"
              }
            >
              Hotel
            </div>
            <div
              onClick={() => setSelectedOffer("Holiday")}
              className={
                selectedOffer === "Holiday"
                  ? "active offer"
                  : "inactive offer"
              }
            >
              Holidays
            </div> */}
              </div>
            </div>

            <div
              className="viewAll"
              onClick={() => {
                history.push({
                  pathname: "/exclusive-offers/view-all",
                });
              }}
            >
              View All
            </div>
          </div>
        </div>
        <div className="section-content carousel-container">
          <div className="carousel-containers">
            <Carousel
              itemPadding={[0, 10, 0, 0]}
              itemsToShow={width > 1250 ? 2 : width < 660 ? 1 : 2}
              itemsToScroll={1}
              // verticalMode=
              // itemsToShow={1}
              pagination={false}
            >
              {offersData?.map((row) => (
                <div className="subcontainer">
                  {row?.map((col) => <OfferContainer offer={col} />)}
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </RootContainer>
    )
  );
};

export default OfferSection;

const RootContainer = styled.div`
  background-color: ${(prop) => prop.theme.palette.primary.main};
  background-position: center;
  background-size: cover;
  font-family: poppins;
  // height: 430px;
  background: #fff;
  border-radius: 10px;
  // margin-top: 40px;
  position: relative;
  font-family: poppins;
  .rec-carousel-item {
  //   @media all and (max-width: 700px) {
  //     width: 560px;
  // }
  }
  .subcontainer {
    width: 520px;
    @media all and (max-width: 1280px) {
      width: 500px;
  }
  @media all and (max-width: 1220px) {
    width: 480px;
}



@media all and (max-width: 1180px) {
  width: 450px;
}
@media all and (max-width: 1120px) {
  width: 420px;
};
@media all and (max-width: 1050px) {
  width: 380px;
}
@media all and (max-width: 960px) {
  width: 340px;
}
@media all and (max-width: 875px) {
  width: 320px;
}
@media all and (max-width: 825px) {
  width: 300px;
}
@media all and (max-width: 724px) {
  width: 270px;
}


  }

  .carousel-containers{
    display: flex;
  }
  // .tc-container{
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   height: 100%;
  //   width: 100%;
  //   color: #fff;
  // }

  .closebtn {
    position: absolute;
    right: 30;
    top: 0,
  }
  .detail-header {
    font-size: 14px;
    font-weight: 500;
  }
  .modal-container {
    
  }
  .detail-subheader {
    font-size: 17px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
    color: #585858;
  }
  .title-container{
    display: flex;
    align-items: center;
    @media all and (max-width: 590px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .category-container {
    display: flex;
    align-items: center;
  }
  .detail-description {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 5px;
    height: 75px;
    margin-top: 5px;
    overflow: hidden;
    @media all and (max-width: 875px) {
      font-size: 11px;
    }
  }
  .offer {
    margin-left: 30px;
    cursor: pointer;
    padding: 10px;
    @media all and (max-width: 870px) {
      font-size: 13px;
      padding: 5px;
      margin-left: 10px;
    }
    @media all and (max-width: 590px) {
      flex-direction: column;
      align-items: flex-start;
      margin-left: 0px;
      margin-right: 10px;
    }
  }
  .active {
    // margin-left: 10px;
    background-color: #33B3A6;

    border-radius: 10px;
    color: #fff;
  }
  .tc-container {
    font-weight: 700;
    cursor: pointer;
    @media all and (max-width: 875px) {
      font-size: 10px;
    }
  }
  .promo-container {
    display: flex;
    align-items:center;
  }
  .promo-input{
    font-size: 14px;
    font-weight: 500;
    @media all and (max-width: 960px) {
      font-size: 12px;
    }
    @media all and (max-width: 875px) {
      font-size: 10px;
    }
  }
  .promo-value{
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    margin-left: 10px;
    border: 1px solid black;
    border-style: dotted;
    padding: 3px;
    @media all and (max-width: 870px) {
      font-size: 11px;
      margin-left: 4px;
    }
  }
  .promo-copy {
    margin-left: 6px;
    cursor: pointer;
  }
  .bottom-container {
    display: flex;
    justify-content: space-between;
    align-items:center;
    @media all and (max-width: 725px) {
      flex-direction: column;
      align-items:flex-end;
    }
  }
  .valid-input{
    font-weight: 500;
    font-size: 14px;
    text-align: right;
    @media all and (max-width: 875px) {
      font-size: 10px;
    }
  }
  .detail-container{
    height: 100%;
    width: 300px;
        @media all and (max-width: 1300px) {
      width: 250px;
    };
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  } 
  .img-container {
    height: 200px;
    width: 200px;
    @media all and (max-width: 1120px) {
      width: 180px;
    }
    @media all and (max-width: 1050px) {
      width: 150px;
    }
    @media all and (max-width: 960px) {
      height: 100%;
      width: 120px;
    }
  }
  .img-input {
    height: 200px;
    width: 200px;
    @media all and (max-width: 1120px) {
      width: 180px;
    }
    @media all and (max-width: 1050px) {
      width: 150px;
    }
    @media all and (max-width: 960px) {
      height: 100%;
      width: 120px;
    }
  }
  .offer-container{
    height: 210px;
    @media all and (max-width: 960px) {
      height: 170px;
    }
    background: #FDF4F4;
    margin-top: 10px;
    margin-right: 5px;

    margin-bottom: 10px;
    margin-left: 5px;
    padding: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  .offer-subContainer{
    display: flex;
    height: 100%;
  }
  .section-title-container {
    padding: 30px 56px 5px;
    @media all and (max-width: 740px) {
      padding: 30px 16px 5px;
    }
  }
  .section-title {
    border-bottom: 1px solid #eee;
    display: flex;
    padding-bottom: 5px;
    justify-content: space-between;
    align-items: center;
    & .viewAll {
      margin-right: 100px;
      cursor: pointer;
      font-size: 1.1rem;
      color: ${(prop) => prop.theme.palette.text.default};
      font-family: ${(prop) => prop.theme.palette.font.secondary};
      // text-transform: uppercase;
      @media all and (max-width: 590px) {
        font-size: 14px;
        margin-right: 0px;
      }
    }
    & .title {
      font-size: 1.9rem;
        @media all and (max-width: 590px) {
          font-size: 22px;
        }
      color: ${(prop) => prop.theme.palette.text.default};
      font-family: ${(prop) => prop.theme.palette.font.secondary};
      text-transform: uppercase;
    }
  }
  .section-caption {
    p {
      font-size: 1rem;
      color: ${(prop) => prop.theme.palette.text.light};
      // font-family: ${(prop) => prop.theme.palette.font.secondary};
    }
  }
  .section-content {
    &.flex-row {
      display: flex;
      justify-content: center;
      gap: 25px;
    }
  }
  .carousel-container {
    padding: 10px 36px 0;
    padding-bottom: 10px;

    @media all and (max-width: 740px) {
      padding: 10px 10px 0;
    }
    // margin-top: 20px;
    // margin-bottom: 20px;
    & .rec-swipable {
      // display: grid !important;
      display: flex;
      // grid-template-rows: repeat(2, 1fr);
      // grid-template-columns: repeat(3, 1fr);
      // grid-auto-flow: column;
    }
    & .rec-item-wrapper {
      // height: 230px;
      // width: 100% !important;
      @media all and (max-width: 590px) {
        padding-right: 0px !important;
        // height: 100%;
        // width: unset !important;
        // width: 120px;
      }
    }
    & .rec.rec-arrow {
      position: absolute;
      border-radius: 0;
      height: 35px;
      width: 35px;
      padding-top: 2px;
      min-height: 35px;
      min-width: 35px;
      display: flex;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      background: ${(prop) =>
        prop.theme.palette.primary.contrastText} !important;
      color: ${(prop) => prop.theme.palette.primary.defaultText} !important;
      border: solid 1px ${(prop) =>
        prop.theme.palette.background.defaultContrast};
      &:disabled {
        visibility: visible;
        background: ${(prop) => prop.theme.palette.primary.contrastText} 
        color: ${(prop) => prop.theme.palette.primary.defaultText} !important;
        border: solid 1px ${(prop) =>
          prop.theme.palette.background.defaultContrast};
        border-radius: 20px;
      }
      &:hover {
        background: ${(prop) =>
          prop.theme.palette.primary.contrastText} !important;
        color: ${(prop) => prop.theme.palette.primary.main} !important;
      }
      &.rec-arrow-left {
        top: 35px;
        right: 70px;
      }
      &.rec-arrow-right {
        top: 35px;
        right: 30px;
      }
    }
  }
  .package-thumb-container {
    overflow: clip;
    width: 280px;
    // height: 350px;
    background: ${(prop) => prop.theme.palette.background.default};
    cursor: pointer;
    & .package-image-wrapper {
      width: 100%;
      // padding: 10px;
      & img {
        width: 100%;
        height: 235px;
      }
    }
    & .exploreMorebtn{
      margin-right: 3px;
      margin-bottom: 3px;
    }
    & .package-details {
      padding: 10px;
      // height: 120px;
      text-align: left;
      // height: 85px;
      overflow: hidden;
      & p {
        color: ${(prop) => prop.theme.palette.background.disabledContrast};
      }
      & .package-title {
        font-size: 1rem;
        font-weight: 600;
        height: 40px;
      }
      & .package-destination {
        font-size: .8rem;
        font-weight: 500;
        margin-bottom: 30px;
        // height: 40px;
      }
      & .package-caption {
        color: ${(prop) => prop.theme.palette.primary.main};
        font-size: 0.8rem;
        font-weight: 500;
      }
      & button {
        color: ${(prop) => prop.theme.palette.text.main};
        font-size: 14px;
        text-transform: capitalize;
        // border: solid 1px ${(prop) => prop.theme.palette.background.dark};
        font-family: poppins;
        height: 1.5rem;
        &.MuiButton-text {
          padding: 0;
        }
      }
      & .package-price {
        color: ${(prop) => prop.theme.palette.text.default};
        font-weight: 600;
        font-size: 1.1rem;
        margin: 0;
        & span {
          font-size: 0.8rem;
          font-weight: 500;
        }
      }
    }
  }

  .price-view-more {
    display: flex;
    justify-content: space-between;
  }

  @media all and (max-width: 590px) {
    height: auto;
    padding: 0 0 30px;
    // min-height: 560px;
    .carousel-container {
      & .rec.rec-arrow {
        &.rec-arrow-left {
          top: 60%;
          left: 5px;
          z-index:5;
        }
        &.rec-arrow-right {
          top: 60%;
          right: 5px;
          z-index:5;
        }
      }
    }
  }
`;
