import React from "react";
import styled from "styled-components";
import epkgVectorBg from "assets/Tickat/images/home_pix/explore-package-vectors.png";

import Carousel from "react-elastic-carousel";
import { useHistory } from "react-router-dom";
import exporemore from "assets/Tickat/images/home_pix/packagedetaills_arrow.png";
import { Button, useTheme } from "@material-ui/core";
import { SERVER_URL } from "api/ApiConstants";

const ExplorePackage = ({ data }) => {
  const theme = useTheme();
  const width = window.innerWidth;
  const history = useHistory();

  // console.log(data);

  const goToPackageDetail = (
    name,
    packageType,
    index,
    advertise,
    packageId
  ) => {
    if (advertise === true) {
      history.push("/packages/packageinfo", { data: packageId });
    } else {
      history.push({
        pathname: "/packages/",
        state: { name, packageType, index },
      });
    }
  };

  const PackageThumb = ({
    imgSrc,
    title,
    currency,
    price,
    onClick,
    destination,
  }) => {
    return (
      <div className="package-thumb-container">
        <div className="package-image-wrapper">
          <img src={imgSrc} />
        </div>
        <div className="package-details">
          <p className="package-title">{title}</p>
          <p className="package-destination">{destination || "NA"} </p>
          <div className="price-view-more">
            <p className="package-price">
              {" "}
              {currency === "Rs" ? "₹" : "$"} {price || "NA"}{" "}
              {/*<span>Per Person</span>*/}
            </p>
            <Button className="package-view-btn" onClick={onClick}>
              <img className="exploreMorebtn" src={exporemore} />
              Explore More
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    data?.packagesCategories.length > 0 && (
      <RootContainer theme={theme}>
        <div className="section-title-container">
          <div className="section-title">
            <div className="title">International Package</div>
            <div className="viewAll">View All</div>
          </div>
          <div className="section-caption">
            <p>
              Here's to a world where your desire to travel is unbridled! we
              cordially invite you to travel the world with us on an exciting
              adventure. Discover the undiscovered treasures, accept other
              cultures, and make lifelong experiences. We have an insatiable
              thirst for travel, and we can't wait to show you the wonders of
              the globe.
            </p>
          </div>
        </div>
        <div className="section-content carousel-container">
          {data?.packagesCategories && data?.packagesCategories.length > 0 && (
            <Carousel
              itemsToShow={width > 980 ? 4 : width < 895 ? 1 : 2}
              itemPadding={[0, 10, 0, 0]}
              pagination={false}
            >
              {data.packagesCategories.map(
                (pack, index) =>
                  pack.packageCategoryType === "I" && (
                    <PackageThumb
                      key={index}
                      title={pack.packageCategoryName}
                      imgSrc={
                        SERVER_URL +
                        // "http://oari.co:8080/" +
                        "packageadmin/ImageReaderArticle?path=/" +
                        pack.packageCategoryType +
                        "/" +
                        pack.packageCategoryId +
                        "/" +
                        pack.packageId +
                        "/" +
                        pack.packageCategoryImage
                      }
                      destination={pack?.packageDestinations}
                      currency={pack?.packageCurrency}
                      price={
                        pack?.packageCategoryStartingPrice &&
                        pack.packageCategoryStartingPrice.toLocaleString(
                          "en-IN"
                        )
                      }
                      onClick={() =>
                        goToPackageDetail(
                          pack.packageCategoryId,
                          pack.packageCategoryType,
                          1,
                          true,
                          pack.packageId
                        )
                      }
                    />
                  )
              )}
            </Carousel>
          )}
        </div>
      </RootContainer>
    )
  );
};

export default ExplorePackage;

// background: #33B3A6;

const RootContainer = styled.div`
  background-color: ${(prop) => prop.theme.palette.primary.main};
  background-position: center;
  background-size: cover;
  font-family: poppins;
  height: 600px;
  // background: #33B3A6;
  background: #11263b;
  border-radius: 10px;
  // margin-top: 40px;
  position: relative;
  .section-title-container {
    padding: 30px 56px 5px;
  }
  .section-title {
    border-bottom: 1px solid #eee;
    display: flex;
    padding-bottom: 5px;
    justify-content: space-between;
    align-items: center;
    & .viewAll {
      margin-right: 100px;
      cursor: pointer;
      font-size: 1.1rem;
      color: ${(prop) => prop.theme.palette.text.light};
      font-family: ${(prop) => prop.theme.palette.font.secondary};
      // text-transform: uppercase;
    }
    & .title {
      font-size: 1.9rem;
      color: ${(prop) => prop.theme.palette.text.light};
      font-family: ${(prop) => prop.theme.palette.font.secondary};
      text-transform: uppercase;
    }
  }
  .section-caption {
    p {
      font-size: 14px;
      color: ${(prop) => prop.theme.palette.text.light};
      // font-family: ${(prop) => prop.theme.palette.font.secondary};
    }
  }
  .section-content {
    &.flex-row {
      display: flex;
      justify-content: center;
      gap: 25px;
    }
  }
  .carousel-container {
    padding: 10px 36px 0;
    // margin-top: 20px;
    // margin-bottom: 20px;
    & .rec.rec-arrow {
      position: absolute;
      border-radius: 0;
      height: 35px;
      width: 35px;
      padding-top: 2px;
      min-height: 35px;
      min-width: 35px;
      display: flex;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      background: ${(prop) =>
    prop.theme.palette.primary.contrastText} !important;
      color: ${(prop) => prop.theme.palette.primary.main} !important;
      border: solid 1px ${(prop) => prop.theme.palette.background.disabled};
      &:disabled {
        visibility: visible;
        background: rgba(103, 58, 183, 0.1) !important;
        color: ${(prop) => prop.theme.palette.primary.disabled} !important;
        border: solid 1px ${(prop) => prop.theme.palette.background.disabled};
        border-radius: 20px;
      }
      &:hover {
        background: ${(prop) =>
    prop.theme.palette.primary.contrastText} !important;
        color: ${(prop) => prop.theme.palette.primary.main} !important;
      }
      &.rec-arrow-left {
        top: 35px;
        right: 110px;
      }
      &.rec-arrow-right {
        top: 35px;
        right: 70px;
      }
    }
  }
  .package-thumb-container {
    overflow: clip;
    width: 280px;
    // height: 350px;
    background: ${(prop) => prop.theme.palette.background.default};
    cursor: pointer;
    & .package-image-wrapper {
      width: 100%;
      // padding: 10px;
      & img {
        width: 100%;
        height: 235px;
      }
    }
    & .exploreMorebtn{
      margin-right: 3px;
      margin-bottom: 3px;
    }
    & .package-details {
      padding: 10px;
      // height: 120px;
      text-align: left;
      // height: 85px;
      overflow: hidden;
      & p {
        color: ${(prop) => prop.theme.palette.background.disabledContrast};
      }
      & .package-title {
        font-size: 13px;
        font-weight: 600;
        height: 40px;
      }
      & .package-destination {
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 10px;
        overflow: hidden;
        height: 30px;
      }
      & .package-caption {
        color: ${(prop) => prop.theme.palette.primary.main};
        font-size: 0.8rem;
        font-weight: 500;
      }
      & button {
        color: ${(prop) => prop.theme.palette.text.main};
        font-size: 14px;
        text-transform: capitalize;
        // border: solid 1px ${(prop) => prop.theme.palette.background.dark};
        font-family: poppins;
        height: 1.5rem;
        &.MuiButton-text {
          padding: 0;
        }
      }
      & .package-price {
        color: ${(prop) => prop.theme.palette.text.default};
        font-weight: 600;
        font-size: 1.1rem;
        margin: 0;
        & span {
          font-size: 0.8rem;
          font-weight: 500;
        }
      }
    }
  }
  .price-view-more {
    display: flex;
    justify-content: space-between;
  }
  @media all and (max-width: 959px) {
    height: auto;
    padding: 0 0 30px;
    min-height: 560px;
    .carousel-container {
      & .rec.rec-arrow {
        &.rec-arrow-left {
          top: 60%;
          left: 5px;
        }
        &.rec-arrow-right {
          top: 60%;
          right: 5px;
        }
      }
    }
  }
  @media all and (max-width: 600px) {
    .carousel-container {
      padding: 10px 10px 0;
    }
    .section-title-container {
      padding: 30px 10px 5px;
    }
    .section-title {
      p {
        text-align: center;
      }
    }
    .section-caption {
      p {
        text-align: center;
      }
    }
  }
`;
