import { Popper, useTheme, TextField, IconButton } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import WebApi from "api/ApiConstants";
import ClearIcon from "@material-ui/icons/Clear";
import dateFnsFormat from "date-fns/format";
import LeftAdornedAutoComplete from "./formControls/LeftAdornedAutoComplete";
import LeftAdornedDatePicker from "./formControls/LeftAdornedDatePicker";
import locationPinIcon from "assets/Tickat/images/home_pix/location_pin_icon.png";
import calendarIcon from "assets/Tickat/images/home_pix/calendar_icon.png";
import swapIcon from "assets/Tickat/images/home_pix/swap_icon_horizontal.png";
import FlightLocationInput from "./formControls/FlightLocationInput";
import FlightDateInput from "./formControls/FlightDateInput";

const FlightMultiCitySection = (props) => {
  const theme = useTheme();
  const [airportList, setAirportList] = React.useState(props.popularcities);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchAirports = (searchParams) => {
    setIsLoading(true);
    if (searchParams.length >= 3) {
      WebApi.fetchAirportList(
        {
          airportCode: searchParams,
        },
        (response) => {
          if (response.success == true) {
            setAirportList(response.data.airports);
          }
          setIsLoading(false);
        },
        (error) => {
          console.log("fetchAirportList error", error);
          setIsLoading(false);
        }
      );
    }
  };

  const renderOptionsComp = (optionsData, propes) => {
    return (
      <div selected={propes.selected} className="ac-options-list">
        <span>
          <p className="ac-option-left">
            {optionsData.cityName} ({optionsData.airportCode})
          </p>
          <p className="ac-option-right">{optionsData.countryCode}</p>
        </span>
        <p>{optionsData.airportDesc}</p>
      </div>
    );
  };

  const removeMulticityRow = (indx) => {
    props.arrayHelpers.remove(indx);
  };

  const swapAirports = (indx) => {
    const originTemp = {
      str: props.values.sectors[indx].origin,
      obj: props.values.sectors[indx].originObj,
    };
    props.setFieldValue(
      `sectors[${indx}].originObj`,
      props.values.sectors[indx].destinationObj
    );
    props.setFieldValue(
      `sectors[${indx}].origin`,
      props.values.sectors[indx].destination
    );
    props.setFieldValue(`sectors[${indx}].destinationObj`, originTemp.obj);
    props.setFieldValue(`sectors[${indx}].destination`, originTemp.str);
  };

  React.useEffect(() => {
    if (airportList && airportList > 0) {
      setIsLoading(false);
    }
  }, [airportList]);

  return (
    <RootContainer theme={theme}>
      {props.values.sectors.map((sector, indexs) => (
        <div className="multicity-row" key={indexs}>
          <div className="flex-row p-rtv">
            <div className="form-group-wrapper">
              <label>From</label>
              {/* <LeftAdornedAutoComplete
                loading={isLoading}
                className="form-control airport-input"
                name={`sectors[${indexs}].originObj`}
                options={airportList || []}
                getOptionLabel={(o) => (o ? `${o.cityName} ${o.airportCode}` : "")}
                value={props.values.sectors[indexs].originObj}
                openOnFocus
                getOptionSelected={(option, value) =>
                  option.cityName === value.cityName
                }
                onKeyUp={(event) => {
                  fetchAirports(event.target.value.trim());
                }}
                onChange={(event, newValue, reason) => {
                  if (reason === "clear") {
                    setAirportList(props.popularcities);
                  } else {
                    props.setFieldValue(
                      `sectors[${indexs}].originObj`,
                      newValue
                    );
                    props.setFieldValue(
                      `sectors[${indexs}].origin`,
                      newValue.airportCode
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    color="secondary"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
                renderOption={(optionsData, propes) =>
                  renderOptionsComp(optionsData, propes)
                }
                disablePortal={true}
                adnicon={locationPinIcon}
              /> */}
                <FlightLocationInput props={props} index={indexs} type={"origin"} />
            </div>
            <div className="swap-icon-wrapper">
              <IconButton
                className="swap-button"
                onClick={() => swapAirports(indexs)}
              >
                <img src={swapIcon} />
              </IconButton>
            </div>
            <div className="form-group-wrapper">
              <label>To</label>
              {/* <LeftAdornedAutoComplete
                loading={isLoading}
                className="form-control airport-input"
                name={`sectors[${indexs}].destinationObj`}
                PopperComponent={customPopperFrom}
                options={airportList || []}
                getOptionLabel={(o) => (o ? `${o.cityName} ${o.airportCode}` : "")}
                value={props.values.sectors[indexs].destinationObj || ""}
                openOnFocus
                getOptionSelected={(option, value) =>
                  option.airportCode == value.airportCode
                }
                onKeyUp={(event) => fetchAirports(event.target.value.trim())}
                onChange={(event, newValue, reason) => {
                  if (reason === "clear") {
                    setAirportList(props.popularcities);
                  } else {
                    props.setFieldValue(
                      `sectors[${indexs}].destinationObj`,
                      newValue
                    );
                    props.setFieldValue(
                      `sectors[${indexs}].destination`,
                      newValue.airportCode
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    color="secondary"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
                renderOption={(optionsData, propes) =>
                  renderOptionsComp(optionsData, propes)
                }
                disablePortal={true}
                adnicon={locationPinIcon}
              /> */}
                  <FlightLocationInput props={props} index={indexs} type={"destination"} />
            </div>
            <div className="form-group-wrapper">
              <label>Departure</label>
              <LeftAdornedDatePicker
                showDisabledMonthNavigation
                className="date-picker"
                name={`sectors[${indexs}].departDateDisplay`}
                minDate={ indexs == 0
                  ? new Date()
                  : props.values.sectors[indexs - 1].departDateDisplay}
                customInput={
                  <TextField id="standard-basic" variant="standard" fullWidth />
                }
                // selected={props.values.sectors[indexs].departDateDisplay}
                selected={
                  props.values.sectors[indexs].departDateDisplay
                    ? props.values.sectors[indexs].departDateDisplay
                    : props.values.sectors[0].departDateDisplay
                }
                onChange={(e, v) => {
                  //   console.log("props.values.mode " + props.values.mode);
                  if (props.values.mode === "MULTICITY") {
                    for (let i = indexs; i < props.values.sectors.length; i++) {
                      const t1 = new Date(e).getTime();
                      const t2 = new Date(
                        props.values.sectors[i].departDateDisplay
                      ).getTime();
                      if (t2 < t1) {
                        props.setFieldValue(
                          `sectors[${i}].departDateDisplay`,
                          e
                        );
                        props.setFieldValue(
                          `sectors[${i}].departDate`,
                          dateFnsFormat(e, "yyyy-MM-dd")
                        );
                      }
                    }
                  } else {
                    props.setFieldValue(
                      `sectors[${indexs}].departDateDisplay`,
                      e
                    );
                    props.setFieldValue(
                      `sectors[${indexs}].departDate`,
                      dateFnsFormat(e, "yyyy-MM-dd")
                    );
                  }
                }}
                monthsShown={2}
                dateFormat="dd MMM yyyy"
                adnicon={calendarIcon}
              />
                    {/* <FlightDateInput  props={props} index={indexs} type={"depart"} /> */}
            </div>
            <div className="remove-row-wrapper">
              {indexs > 1 && (
                <IconButton
                  className="remove-row"
                  onClick={() => removeMulticityRow(indexs)}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </div>
          </div>
          <div className="multicity-bottom-border"></div>
        </div>
      ))}
    </RootContainer>
  );
};

export default FlightMultiCitySection;

const customPopperFrom = function(props) {
  // console.log("props", props);
  return (
    <Popper
      {...props}
      // anchorEl={departAutocompleteEl.current.children[0]}
      placement="bottom"
      style={{}}
    />
  );
};

const customPopperTo = function(props) {
  return <Popper {...props} placement="bottom" style={{}} />;
};

const RootContainer = styled.div`
  & .flex-row {
    gap: 15px !important;
  }
  & .adornment-icon {
    width: 0px !important;
    display: none;
  }

  & .form-group-wrapper {
    width: 160px;
    @media all and (max-width: 1280px) {
      width: 140px;
    }
  }
  & .swap-icon-wrapper {
    position: unset !important;
    padding-top: 35px;
  }
  & .MuiAutocomplete-clearIndicator {
    display: none !important;
  }
  & .MuiInputBase-input {
    width: 100px !important;
  }
  &
    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
    .MuiAutocomplete-inputRoot {
    padding-right: 20px !important;
  }
  .remove-row-wrapper {
    padding-top: 30px;
    width: 45px;
    & .remove-row {
      cursor: pointer;
      height: 45px;
      width: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${(prop) => prop.theme.palette.primary.main};
      border-radius: 8px;
      & .MuiSvgIcon-root {
        color: ${(prop) => prop.theme.palette.primary.contrastText};
        font-size: 35px;
      }
    }
  }
  .multicity-bottom-border {
    border-bottom: solid 3px ${(prop) => prop.theme.palette.primary.main};
    margin-right: 25px;
  }
`;
