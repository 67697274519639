import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  getQueryVar,
  getCurrentTab,
  getAgencyId,
} from "oautils/oaCommonUtils.js";
import PostLoginNavBar from "components/Navbars/PostLoginNavBar.Tickat";
import PageFooter from "components/Footer/PageFooter.Tickat";
import FlightSearch from "pages/module/flight/compact/FlightSearch";
import FlightSearchProgress from "pages/module/flight/compact/FlightSearchProgress";
import WebCheckIn from "pages/module/flight/webCheckIn";
import AirlineContact from "pages/module/flight/airlineContact";
import FlightBookingProcess from "pages/module/flight/compact/FlightBookingProcess";
import FlightSearchComRes from "pages/module/flight/compact/FlightSearchComRes";
import FlightPaymentStatus from "pages/module/flight/compact/FlightPaymentStatus";
import FlAvLoader from "assets/Tickat/images/loaders/flight_avail_loader.gif";
import ComLoader from "assets/Tickat/images/loaders/clock_loader.gif";
import TermsAndConditions from "components/LandingPage/Tickat/siteinfo/TermsAndConditions";
import PricingPolicy from "components/LandingPage/Tickat/siteinfo/PricingPolicy";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
//import PaymentFailed from "pages/module/flight/compact/checkinReview/PaymentFailed";

export default function FlightLayout(props) {
  const { ...rest } = props;
  const classes = useStyles();
  const history = useHistory();

  React.useEffect(() => {
    document.body.style.overflow = "unset";
    let qAgencyId = getAgencyId(props);
    setAgencyId(qAgencyId);
    return function cleanup() { };
  }, [agencyId, props]);

  const [agencyId, setAgencyId] = useState(undefined);

  let defaultTab = "hotel";
  let queryVar = getQueryVar({ ...props, q: "priTab" });
  let currentTab = getCurrentTab(queryVar, defaultTab);
  const sessionStartTime = useSelector(
    (state) => state.hotel.flightBookingInitialTime
  );

  useEffect(() => {
    if (sessionStartTime) {
      let now = new Date().getTime();
      let distance = sessionStartTime - now;

      let minute = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let second = Math.floor((distance % (1000 * 60)) / 1000);
      if (minute <= 0 && second <= 0) {
        history.push("/");
      }
    }
  });

  return (
    <>
      <PostLoginNavBar
        {...rest}
        isSearchPage={true}
        showServices={true}
        isTimerShow={true}
      />
      <div className={classes.root}>
        <Switch>
          <Route
            path="/flight/search-progress"
            render={(prop) => (
              <FlightSearchProgress {...prop} availoader={FlAvLoader} />
            )}
          />
          <Route
            path="/flight/search-result"
            render={(prop) => <FlightSearch {...prop} comloader={ComLoader} />}
          />
          <Route path="/flight/webcheckin" component={WebCheckIn} />
          <Route path="/flight/booking">
            <FlightBookingProcess
              {...props}
              comloader={ComLoader}
              tnc={TermsAndConditions}
              ppc={PricingPolicy}
            />
          </Route>
          {/* <Route
            path="/flight/booking/payment-failed"
            render={(prop) => <PaymentFailed />}
          /> */}
          <Route path="/flight/airlinecontacts" component={AirlineContact} />
          <Route
            path="/flight/international/search-result"
            render={(prop) => (
              <FlightSearchComRes {...prop} comloader={ComLoader} />
            )}
          />
          <Route
            path="/flight/payment-status/:tid"
            component={FlightPaymentStatus}
          />
        </Switch>
      </div>
      {/* <PageFooter white {...props} /> */}
      <PageFooter />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -60,
    width: "100%",
    //backgroundColor: theme.palette.background.lightGrayDefault,
    color: theme.palette.background.lightGrayDefaultContrast,
    //  background: "rgb(99 99 149 / 10%)",
    minHeight: "75vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& ::-webkit-scrollbar": {
      width: 8,
    },
    "& ::-webkit-scrollbar-thumb": {
      background: "#b1b1b1",
      borderRadius: 8,
      transition: "ease-in-out 1s",
      "&:hover": {
        background: "#b1b1b1",
      },
    },
    "& ::-webkit-scrollbar-track": {
      width: 10,
    },
  },
}));
