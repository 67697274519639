import React from "react";
import {
    Grid,
    Typography,
    makeStyles,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import { AppConstant } from "appConstant";
import { useHistory } from "react-router-dom";

const RefundAndCancellationPolicy = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles();
    const history = useHistory();

    const goToHomePage = () => {
        history.push("/");
    };

    return (
        <div
            className={classes.root}
            style={{
                marginBottom: 60,
                paddingLeft: isMobile ? 10 : "6%",
                paddingRight: isMobile && 10,
            }}
        >
            <Grid
                container
                spacing={isMobile ? 0 : 3}
                style={{ width: "100%", marginTop: 30 }}
                justifyContent="center"
            >
                <Grid item style={{ width: "100%" }}>
                    <div className={classes.titleHeader}>Refund And Cancellation Policy:</div>
                </Grid>
                <Grid item md={12} className={classes.sectionTextGrid}>
                    <div className="table-scroll-x">
                        <table className="refund-and-cancellation-policy-table">
                            <thead>
                                <tr>
                                    <th><p className="header-1">{""}</p></th>
                                    <th><p className="header-1">International Tours</p></th>
                                    <th><p className="header-1">Indian Tours</p></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><p className="header-2">Number of days before departure from the date of receipt of your cancellation request</p></td>
                                    <td><p className="header-2">Amount of cancellation charges in % on total tourprice must be paid by the Guest/s</p></td>
                                    <td><p className="header-2">Amount of cancellation charges in % on total tour price must be paid by the Guest/s</p></td>
                                </tr>
                                <tr>
                                    <td><p>More than 90 days</p></td>
                                    <td><p>Registration Amount of 20% of tour cost whichever is higher</p></td>
                                    <td><p>Registration Amount of 20% of tour cost whichever is higher</p></td>
                                </tr>
                                <tr>
                                    <td><p>90 - 61 days</p></td>
                                    <td><p>30%</p></td>
                                    <td><p>30%</p></td>
                                </tr>
                                <tr>
                                    <td><p>60 - 46 days</p></td>
                                    <td><p>50%</p></td>
                                    <td><p>50%</p></td>
                                </tr>
                                <tr>
                                    <td><p>45 - 31 days</p></td>
                                    <td><p>75%</p></td>
                                    <td><p>75%</p></td>
                                </tr>
                                <tr>
                                    <td><p>30 - 16 days</p></td>
                                    <td><p>90%</p></td>
                                    <td><p>90%</p></td>
                                </tr>
                                <tr>
                                    <td><p>15 - 01 days</p></td>
                                    <td><p>100%</p></td>
                                    <td><p>100%</p></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default RefundAndCancellationPolicy;

const Gap10 = () => {
    return <div className="gap-10" style={{ margin: 10, width: "100%" }}></div>;
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        "& .highlight": {
            color: theme.palette.primary.darkOrange,
        },
        "& .table-scroll-x": {
            [theme.breakpoints.down(600)]: {
                overflowX: "scroll",
                width: "100%"
            }
        },
        "& .refund-and-cancellation-policy-table": {
            background: theme.palette.background.disabled,
            [theme.breakpoints.down(600)]: {
                width: 600
            },
            "& thead tr": {
                background: theme.palette.background.darkerFadedGray,
                "& th": {
                    padding: "20px 5px 5px",
                    border: `solid 1px ${theme.palette.background.disabled}`,
                    "& p.header-1": {
                        color: theme.palette.text.light,
                        fontWeight: 600
                    }
                }
            },
            "& tbody tr": {
                background: theme.palette.background.default,
                "& td": {
                    padding: "5px",
                    border: `solid 1px ${theme.palette.background.disabled}`,
                    "& p": {
                        color: theme.palette.text.default,
                        fontWeight: 500,
                        "&.header-2": {
                            fontWeight: 600
                        }
                    }
                }
            }
            
        }
    },
    titleHeader: {
        fontSize: 40,
        fontWeight: 500,
        textDecoration: "underline",
    },
    Grid: {
        background: theme.palette.background.default,
        marginTop: "20px",
        boxShadow: "0px 53px 99px -81px rgba(0,0,0,0.75)",
        padding: 30,
        borderRadius: 20,
    },
    sectionHeader: {
        color: `${theme.palette.primary.darkFadedText} !important`,
        fontWeight: 500,
        margin: "5px 0 30px",
    },
    sectionSubhead: {
        color: `${theme.palette.primary.defaultText} !important`,
        fontWeight: 400,
    },
    sectionText: {
        color: `${theme.palette.primary.davyGray} !important`,
        fontWeight: 400,
        lineHeight: "27px",
    },
    sectionTextGrid: {
        margin: "15px 0",
        flexDirection: "column",
    },
    listText: {
        "& li": {
            color: theme.palette.primary.davyGray,
            fontWeight: 400,
            lineHeight: "27px",
        },
    },
    sectionTextGrid: {
        margin: "15px 0",
        flexDirection: "column",
    },
    listText: {
        "& li": {
            color: theme.palette.primary.darkFadedText,
            fontWeight: 400,
            lineHeight: "27px",
        },
    },
    breadcrumbLink: {
        color: `${theme.palette.primary.active} !important`,
        cursor: "pointer",
        "&:hover": {
            color: `${theme.palette.primary.pomegranate} !important`,
        },
    },
}));
