import { useTheme } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import wcuHomeVector from "assets/Tickat/images/home_pix/wcu_home_vector.png";
import wcuGearVector from "assets/Tickat/images/home_pix/wcu_gear_vector.png";
import wcuBestPriceVector from "assets/Tickat/images/home_pix/wcu_best_price_vector.png";
import clock from "assets/Tickat/images/home_pix/clock.png";
import legitemacy from "assets/Tickat/images/home_pix/legitemacy.png";
import customersupport from "assets/Tickat/images/home_pix/customersupport.png";
import calendar from "assets/Tickat/images/home_pix/calendar.png";
import Carousel from "react-elastic-carousel";
import checkmark from "assets/Tickat/images/home_pix/checkmark.png";
import localexpense from "assets/Tickat/images/home_pix/localexpense.png";
import friendly from "assets/Tickat/images/home_pix/friendly.png";
import Login from "assets/Tickat/images/home_pix/Login.png";
import personaltouch from "assets/Tickat/images/home_pix/personaltouch.png";
import qualityService from "assets/Tickat/images/home_pix/qualityService.png";

const WhyChooseUs = (props) => {
  const theme = useTheme();
  const width = window.innerWidth;
  const breakpoint = 560;

  const WcuItem = ({ imgSrc, title, caption }) => {
    return (
      <div className="wcu-item">
        <img src={imgSrc} />
        <p className="wcu-title">{title}</p>
        <p className="wcu-caption">{caption}</p>
      </div>
    );
  };

  return (
    <RootContainer theme={theme}>
      <div className="wcu-container">
        {/* <div className="caption-title-top">
                    <p>3 steps for the perfect trip</p>
                </div> */}
        <div className="section-title-container">
          <p>Why Choose Us</p>
        </div>
        {/* <div className="caption-title-bottom">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</p>
                </div> */}
        {width > breakpoint && (
          <div className="section-content wcu-content">
            <div className="wcu-item">
              <div className="mg_services">
                <img src={qualityService} className="wcu-image" />
                <p className="wcu-title">QUALITY AND SERVICE</p>
                <p className="wcu-caption">
                  At Tickat.com, quality and service go hand-in-hand. We go
                  above and beyond to ensure these two hallmarks are met at
                  every step of your journey. To help achieve these core values,
                  we closely monitor client satisfaction and are consistently
                  seeking new ways to exceed our clients’ expectations.
                </p>
              </div>
            </div>
            <div className="wcu-item">
              <div className="mg_services">
                <img src={personaltouch} className="wcu-image" />
                <p className="wcu-title">A PERSONAL TOUCH</p>
                <p className="wcu-caption">
                  From first contact, through the duration of your stay, and
                  until you are safely home again, we are with you. You will
                  know your personal travel consultant and they will be
                  connected to you through the entire trip.
                </p>
              </div>
            </div>
            <div className="wcu-item">
              <div className="mg_services">
                <img src={friendly} className="wcu-image" />
                <p className="wcu-title">FRIENDLY AND KNOWLEDGEABLE STAFF</p>
                <p className="wcu-caption">
                  Since we began in 2019, we’ve created unforgettable travel
                  experiences for thousands of clients from all over the world.
                  That’s down to the quality of our people. Our staff members
                  have extensive and first-hand knowledge of the region and are
                  passionate about what they do.
                </p>
              </div>
            </div>

            <div className="wcu-item">
              <div className="mg_services">
                <img src={localexpense} className="wcu-image" />
                <p className="wcu-title">LOCAL EXPERTISE</p>
                <p className="wcu-caption">
                  It is vital to us that you have a memorable and authentic
                  experience with our holidays. In line with the expectations we
                  have for our staff, we select only local suppliers that are
                  friendly, reputable and have in-depth local knowledge.
                </p>
              </div>
            </div>

            <div className="wcu-item">
              <div className="mg_services">
                <img src={checkmark} className="wcu-image" />
                <p className="wcu-title">ACCREDITATIONS</p>
                <p className="wcu-caption">
                  Approved by Tourism Authority of Thailand
                  <br />
                  Approved by Tourism Authority of UAE
                  <br />
                  Member of TAAI ( Travel Agents Association of India)
                  <br />
                  Approved by IATA
                </p>
              </div>
            </div>

            <div className="wcu-item">
              <div className="mg_services">
                <img src={calendar} className="wcu-image" />
                <p className="wcu-title">TAILOR-MADE HOLIDAYS</p>
                <p className="wcu-caption">
                  Designed with you in mind, our packages can be tailored or
                  built completely from scratch to suit your needs. Our
                  experienced travel experts have the resources at hand to make
                  your dream holiday a reality. Why not contact us with your
                  interests?
                </p>
              </div>
            </div>
            <div className="wcu-item">
              <div className="mg_services">
                <img src={customersupport} className="wcu-image" />
                <p className="wcu-title">YOU’RE IN GOOD HANDS</p>
                <p className="wcu-caption">
                  While on your journey, help is never out of reach. There is
                  always a staff member on call 24/7 to handle any unforeseen
                  situations, and we do our best to respond to emails and voice
                  calls as soon as possible during our regular office hours.
                </p>
              </div>
            </div>
            <div className="wcu-item">
              <div className="mg_services">
                <img src={legitemacy} className="wcu-image" />
                <p className="wcu-title">LEGITIMACY</p>
                <p className="wcu-caption">
                  We are fully licensed travel Company approved by the local
                  authorities. And as members of various travel industry
                  associations, we are held up to a high level of standards and
                  practices.
                </p>
              </div>
            </div>

            <div className="wcu-item">
              <div className="mg_services">
                <img src={clock} className="wcu-image" />
                <p className="wcu-title">SAVE TIME AND EFFORT</p>
                <p className="wcu-caption">
                  Organizing your own holiday can be a stressful task. Let us do
                  the work for you! By choosing us, you can save time and hassle
                  by booking all of your itinerary services directly with a
                  specialized Travel Company.
                </p>
              </div>
            </div>
          </div>
        )}
        {width < breakpoint && (
          <div className="section-content wcu-content">
            <Carousel
              itemsToShow={1}
              itemPadding={[0, 10, 0, 0]}
              pagination={false}
            >
              <div className="wcu-item">
                <div className="mg_services">
                  <img src={qualityService} className="wcu-image" />
                  <p className="wcu-title">QUALITY AND SERVICE</p>
                  <p className="wcu-caption">
                    At Tickat.com, quality and service go hand-in-hand. We go
                    above and beyond to ensure these two hallmarks are met at
                    every step of your journey. To help achieve these core
                    values, we closely monitor client satisfaction and are
                    consistently seeking new ways to exceed our clients’
                    expectations.
                  </p>
                </div>
              </div>
              <div className="wcu-item">
                <div className="mg_services">
                  <img src={personaltouch} className="wcu-image" />
                  <p className="wcu-title">A PERSONAL TOUCH</p>
                  <p className="wcu-caption">
                    From first contact, through the duration of your stay, and
                    until you are safely home again, we are with you. You will
                    know your personal travel consultant and they will be
                    connected to you through the entire trip.
                  </p>
                </div>
              </div>
              <div className="wcu-item">
                <div className="mg_services">
                  <img src={friendly} className="wcu-image" />
                  <p className="wcu-title">FRIENDLY AND KNOWLEDGEABLE STAFF</p>
                  <p className="wcu-caption">
                    Since we began in 2019, we’ve created unforgettable travel
                    experiences for thousands of clients from all over the
                    world. That’s down to the quality of our people. Our staff
                    members have extensive and first-hand knowledge of the
                    region and are passionate about what they do.
                  </p>
                </div>
              </div>

              <div className="wcu-item">
                <div className="mg_services">
                  <img src={localexpense} className="wcu-image" />
                  <p className="wcu-title">LOCAL EXPERTISE</p>
                  <p className="wcu-caption">
                    It is vital to us that you have a memorable and authentic
                    experience with our holidays. In line with the expectations
                    we have for our staff, we select only local suppliers that
                    are friendly, reputable and have in-depth local knowledge.
                  </p>
                </div>
              </div>

              <div className="wcu-item">
                <div className="mg_services">
                  <img src={checkmark} className="wcu-image" />
                  <p className="wcu-title">ACCREDITATIONS</p>
                  <p className="wcu-caption">
                    Approved by Tourism Authority of Thailand
                    <br />
                    Approved by Tourism Authority of UAE
                    <br />
                    Member of TAAI ( Travel Agents Association of India)
                    <br />
                    Approved by IATA
                  </p>
                </div>
              </div>

              <div className="wcu-item">
                <div className="mg_services">
                  <img src={calendar} className="wcu-image" />
                  <p className="wcu-title">TAILOR-MADE HOLIDAYS</p>
                  <p className="wcu-caption">
                    Designed with you in mind, our packages can be tailored or
                    built completely from scratch to suit your needs. Our
                    experienced travel experts have the resources at hand to
                    make your dream holiday a reality. Why not contact us with
                    your interests?
                  </p>
                </div>
              </div>
              <div className="wcu-item">
                <div className="mg_services">
                  <img src={customersupport} className="wcu-image" />
                  <p className="wcu-title">YOU’RE IN GOOD HANDS</p>
                  <p className="wcu-caption">
                    While on your journey, help is never out of reach. There is
                    always a staff member on call 24/7 to handle any unforeseen
                    situations, and we do our best to respond to emails and
                    voice calls as soon as possible during our regular office
                    hours.
                  </p>
                </div>
              </div>
              <div className="wcu-item">
                <div className="mg_services">
                  <img src={legitemacy} className="wcu-image" />
                  <p className="wcu-title">LEGITIMACY</p>
                  <p className="wcu-caption">
                    We are fully licensed travel Company approved by the local
                    authorities. And as members of various travel industry
                    associations, we are held up to a high level of standards
                    and practices.
                  </p>
                </div>
              </div>

              <div className="wcu-item">
                <div className="mg_services">
                  <img src={clock} className="wcu-image" />
                  <p className="wcu-title">SAVE TIME AND EFFORT</p>
                  <p className="wcu-caption">
                    Organizing your own holiday can be a stressful task. Let us
                    do the work for you! By choosing us, you can save time and
                    hassle by booking all of your itinerary services directly
                    with a specialized Travel Company.
                  </p>
                </div>
              </div>
            </Carousel>
          </div>
        )}
      </div>
    </RootContainer>
  );
};

export default WhyChooseUs;

const RootContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 30px 0 40px;
    padding-top: 0px;
    // background: ${(prop) => prop.theme.palette.background.default};
    .wcu-container {
        /*width: 80%;
   
        border-radius: 120px;
        background: ${(prop) => prop.theme.palette.background.disabled};*/
        // background: red;
        display: flex;
        flex-direction: column;
        // align-items: center;

        @media all and (max-width: 560px) {
            width: 100%;
    
            & .rec-arrow-left {
              position: absolute;
              left: 0;
              z-index:5;
              border-radius: 30px;
              border: solid 1px ${(prop) =>
                prop.theme.palette.background.disabled};
              &:disabled {
                visibility: visible;
                background: rgba(103, 58, 183, 0.1) !important;
                color: ${(prop) =>
                  prop.theme.palette.primary.disabled} !important;
                border: solid 1px ${(prop) =>
                  prop.theme.palette.background.disabled};
                  border-radius: 30px;
              }
              &:hover {
                background: ${(prop) =>
                  prop.theme.palette.primary.contrastText} !important;
                color: ${(prop) => prop.theme.palette.primary.main} !important;
              }
            }
            & .rec-arrow-right {
              position: absolute;
              right: 0;
              z-index:5;
              border-radius: 30px;
              border: solid 1px ${(prop) =>
                prop.theme.palette.background.disabled};
              &:disabled {
                visibility: visible;
                background: rgba(103, 58, 183, 0.1) !important;
                color: ${(prop) =>
                  prop.theme.palette.primary.disabled} !important;
                border: solid 1px ${(prop) =>
                  prop.theme.palette.background.disabled};
                  border-radius: 30px;
              }
              &:hover {
                background: ${(prop) =>
                  prop.theme.palette.primary.contrastText} !important;
                color: ${(prop) => prop.theme.palette.primary.main} !important;
              }

            }
          }
        & .caption-title-top p {
            color: ${(prop) => prop.theme.palette.text.main};
            text-transform: uppercase;
            font-weight: 600;
            font-family: ${(prop) => prop.theme.palette.font.secondary};
            font-size: 14px;
        }
        & .section-title-container {
            & p {
                color: ${(prop) => prop.theme.palette.text.default};
                font-weight: 700;
                font-family: ${(prop) => prop.theme.palette.font.secondary};
                font-size: 30px;

                @media all and (max-width: 560px) {
                  font-size: 20px;
                  text-align: center;

              }
            }
        }
        & .caption-title-bottom {
            width: 380px;
            text-align: center;
            p {
                color: ${(prop) => prop.theme.palette.text.dark};
                font-weight: 500;
                font-family: ${(prop) => prop.theme.palette.font.secondary};
                font-size: 12px;
            }
        }
    }
    .wcu-content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        // padding: 5px 44px;
        .mg_services {
          height: 200px;
            border-radius: 5px;
            float: left;
            width: 100%;
            text-align: center;
            padding: 40px 45px;
            padding-bottom: 20px;
            background: #fff;
            // height: 240px;
            box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
            &:hover {
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
            }
        }
        .wcu-item {
            width:32%;
            text-align: center;
            & .wcu-image{
              height: 80px;
              width: 80px;
              background-color: white;
              box-shadow: 0px 2px 2px 1px rgba(0,0,0,0.5);
              padding: 12px;
              border-radius: 50%;
            }
            // padding: 10px 15px;
            margin-bottom: 10px;
            & img {
                height: 60px;
            }
            & .wcu-title {
                color: ${(prop) => prop.theme.palette.text.default};
                font-weight: 600;
                font-size: 14px;
                margin-bottom: 15px;
                margin-top: 10px;
                height: 22px;
            }
            & .wcu-caption {
                color: ${(prop) => prop.theme.palette.text.dark};
                font-weight: 500;
                font-family: ${(prop) => prop.theme.palette.font.secondary};
                font-size: 12px;
                display: none;
            }
            
        }
    }
    @media all and (max-width: 1200px) {
        .mg_services {
            height: auto !important;
            padding: 20px 25px !important;
        }
    }
    // @media all and (max-width: 959px) {
    //     .wcu-content {
    //         padding: 30px 14px;
    //     }
    //     .wcu-item {
    //         width: 50% !important;
    //     }
    // }
    @media all and (max-width: 600px) {
        .wcu-item {
            width: 100% !important;
            padding: 10px !important;
        }
        .mg_services {
            padding: 20px 15px !important;
        }
    }
`;
