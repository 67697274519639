import React from "react";
import WebApi from "api/ApiConstants";
import dateFnsFormat from "date-fns/format";
import FLightMultiCitySection from "./FlightMultiCitySection";
import {
  TextField,
  IconButton,
  Checkbox,
  Button,
  Popper,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import LeftAdornedAutoComplete from "./formControls/LeftAdornedAutoComplete";
import LeftAdornedDatePicker from "./formControls/LeftAdornedDatePicker";
import locationPinIcon from "assets/Tickat/images/home_pix/location_pin_icon.png";
import calendarIcon from "assets/Tickat/images/home_pix/calendar_icon.png";
import arrowDown from "assets/Tickat/images/home_pix/arrow-down.png";
import swapIcon from "assets/Tickat/images/home_pix/swap_icon_horizontal.png";
import PassengerClass from "./formControls/PassengerClass";
import PreferredAirline from "./formControls/PreferredAirline";
import cx from "classnames";
import { Radio } from "@material-ui/icons";
import FlightLocationInput from "./formControls/FlightLocationInput";
import FlightDateInput from "./formControls/FlightDateInput";

const FlightItinSection = (props) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = React.useState(true);
  const [airportList, setAirportList] = React.useState(props.popularCities);
  const [fetchingAirports, setFetchingAirports] = React.useState(false);
  const [addMultiCityRow, setAddMultiCityRow] = React.useState(false);

  const renderOptionsComp = (optionsData, propes) => {
    return (
      <div selected={propes.selected} className="ac-options-list">
        <span>
          <p className="ac-option-left">
            {optionsData.cityName} ({optionsData.airportCode})
          </p>
          <p className="ac-option-right">{optionsData.countryCode}</p>
        </span>
        <p>{optionsData.airportDesc}</p>
      </div>
    );
  };

  const fetchAirports = (searchParams) => {
    setFetchingAirports(true);
    if (searchParams.length >= 3) {
      WebApi.fetchAirportList(
        {
          airportCode: searchParams.trim(),
        },
        (response) => {
          if (response.success == true) {
            setAirportList(response.data.airports);
          }
        },
        (error) => console.log("fetchAirportList error", error)
      );
    }
  };

  const swapAirports = () => {
    const originTemp = {
      str: props.values.sectors[0].origin,
      obj: props.values.sectors[0].originObj,
    };
    props.setFieldValue(
      `sectors[0].originObj`,
      props.values.sectors[0].destinationObj
    );
    props.setFieldValue(
      `sectors[0].origin`,
      props.values.sectors[0].destination
    );
    props.setFieldValue(`sectors[0].destinationObj`, originTemp.obj);
    props.setFieldValue(`sectors[0].destination`, originTemp.str);
  };

  const pushBtn = (initFlag) => {
    let index = props.values.sectors.length - 1;
    if (initFlag) {
      index = 0;
    }
    const initObj = {
      origin: props.values.sectors[index].destination,
      originObj: props.values.sectors[index].destinationObj,
      destination: "",
      destinationObj: null,
      departDate: dateFnsFormat(
        props.values.sectors[index].departDateDisplay,
        "yyyy-MM-dd"
      ),
      departDateDisplay: props.values.sectors[index].departDateDisplay,
    };
    if (props.values.sectors.length < 4) props.arrayHelpers.push(initObj);
  };

  const onChangeReturnDateDisplay = (e, v) => {
    props.setFieldValue(`returnDateDisplay`, e);
    props.setFieldValue(`returnDate`, dateFnsFormat(e, "yyyy-MM-dd"));
  };

  const toggleRoundTrip = () => {
    if (props.values.mode === "ONE") {
      props.setFieldValue("mode", "ROUND");
      props.setTripmode("ROUND");
    } else {
      props.setFieldValue("mode", "ONE");
      props.setTripmode("ONE");
    }
  };

  const addMulticityOrgDes = () => {
    setAddMultiCityRow(true);
  };

  React.useEffect(() => {
    if (props.values.mode == "MULTICITY" && props.values.sectors.length === 1) {
      pushBtn(true);
    }
  }, [props.values.mode]);

  React.useEffect(() => {
    // console.log("fetchingAirports",fetchingAirports)
    // console.log("airportList",airportList)
    if (airportList && airportList.length > 0) {
      setFetchingAirports(false);
    }
  }, [airportList]);

  return (
    <div className="flight-itinerary-root">
      {props.values.mode !== "MULTICITY" ? (
        <div className="flight-search-container">
          <div className="location-container">
            <div className="form-group-wrapper location-form">
              <label>From</label>
              {/* <LeftAdornedAutoComplete
                loading={fetchingAirports}
                className="form-control airport-input"
                name={`sectors[0].originObj`}
                PopperComponent={customPopperTo}
                options={airportList || []}
                getOptionLabel={(o) => (o ? `${o.cityName}` : "")}
                value={props.values.sectors[0].originObj}
                openOnFocus
                getOptionSelected={(option, value) =>
                  option.cityName === value.cityName
                }
                onKeyUp={(event) => {
                  fetchAirports(event.target.value.trim());
                }}
                onChange={(event, newValue, reason) => {
                  if (reason === "clear") {
                    setAirportList(props.popularcities);
                  } else {
                    props.setFieldValue(`sectors[0].originObj`, newValue);
                    props.setFieldValue(
                      `sectors[0].origin`,
                      newValue.airportCode
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    color="secondary"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
                renderOption={(optionsData, propes) =>
                  renderOptionsComp(optionsData, propes)
                }
                disablePortal={true}
                // adnicon={locationPinIcon}
              /> */}
                   <FlightLocationInput props={props} index={0} type={"origin"} />
            </div>
            <div className="swap-icon-wrapper swap-oneway">
              <IconButton className="swap-button" onClick={swapAirports}>
                <img src={swapIcon} />
              </IconButton>
            </div>
            <div className="form-group-wrapper location-form">
              <label>To</label>
              {/* <LeftAdornedAutoComplete
                loading={fetchingAirports}
                className="form-control airport-input"
                name={`sectors[0].originObj`}
                PopperComponent={customPopperTo}
                options={airportList || []}
                getOptionLabel={(o) => (o ? `${o.cityName}` : "")}
                value={props.values.sectors[0].originObj}
                openOnFocus
                getOptionSelected={(option, value) =>
                  option.cityName === value.cityName
                }
                onKeyUp={(event) => {
                  fetchAirports(event.target.value.trim());
                }}
                onChange={(event, newValue, reason) => {
                  if (reason === "clear") {
                    setAirportList(props.popularcities);
                  } else {
                    props.setFieldValue(`sectors[0].originObj`, newValue);
                    props.setFieldValue(
                      `sectors[0].origin`,
                      newValue.airportCode
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    color="secondary"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
                renderOption={(optionsData, propes) =>
                  renderOptionsComp(optionsData, propes)
                }
                disablePortal={true}
                // adnicon={locationPinIcon}
              /> */}
              <FlightLocationInput props={props} index={0} type={"destination"} />
            </div>
          </div>

          <div className="date-container">
            <div className="form-group-wrapper date-form">
              <label>
                Departure <img className="arrow-icon" src={arrowDown} />
              </label>
              {/* <LeftAdornedDatePicker
                showDisabledMonthNavigation
                className="date-picker"
                name={`sectors[0].departDateDisplay`}
                minDate={new Date()}
                customInput={
                  <TextField id="standard-basic" variant="standard" fullWidth />
                }
                selected={props.values.sectors[0].departDateDisplay}
                onChange={(e, v) => {
                  //   console.log("props.values.mode " + props.values.mode);
                  if (props.values.mode === "MULTICITY") {
                    for (let i = 0; i < props.values.sectors.length; i++) {
                      const t1 = new Date(e).getTime();
                      const t2 = new Date(
                        props.values.sectors[i].departDateDisplay
                      ).getTime();
                      if (t2 < t1) {
                        props.setFieldValue(
                          `sectors[${i}].departDateDisplay`,
                          e
                        );
                        props.setFieldValue(
                          `sectors[${i}].departDate`,
                          dateFnsFormat(e, "yyyy-MM-dd")
                        );
                      }
                    }
                  } else {
                    props.setFieldValue(`sectors[0].departDateDisplay`, e);
                    props.setFieldValue(
                      `sectors[0].departDate`,
                      dateFnsFormat(e, "yyyy-MM-dd")
                    );
                  }
                  if (Math.abs(props.values.returnDateDisplay) < Math.abs(e)) {
                    props.setFieldValue(`returnDateDisplay`, e);
                    props.setFieldValue(
                      `returnDate`,
                      dateFnsFormat(e, "yyyy-MM-dd")
                    );
                  }
                }}
                monthsShown={2}
                dateFormat="dd MMM yy"
                adnicon={calendarIcon}
              /> */}
              <FlightDateInput  props={props} index={0} type={"depart"} />
            </div>
            <div className="form-group-wrapper date-form">
              <label>
                Return <img className="arrow-icon" src={arrowDown} />
              </label>
              <FlightDateInput  props={props} index={0} type={"return"} />
              {/* <LeftAdornedDatePicker
                showDisabledMonthNavigation
                className="date-picker"
                name={`returnDateDisplay`}
                minDate={props.values.sectors[0].departDateDisplay}
                customInput={
                  <TextField id="standard-basic" variant="standard" fullWidth />
                }
                selected={props.values.returnDateDisplay}
                onChange={onChangeReturnDateDisplay}
                monthsShown={2}
                dateFormat="dd MMM yy"
                adnicon={calendarIcon}
                hidedate={props.values.mode !== "ROUND"}
                togglerOnClick={props.togglerOnClick}
                toggleTitle="switch to round trip"
              /> */}
              {props.values.mode === "ROUND" && (
                <IconButton
                  className="switch-one-way"
                  onClick={props.togglerOnClick}
                  title="switch to one way"
                >
                  x
                </IconButton>
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          <FLightMultiCitySection
            arrayHelpers={props.arrayHelpers}
            setFieldValue={props.setFieldValue}
            values={props.values}
            popularcities={props.popularCities}
            ismobilescreen={isMobileScreen}
            days={days}
            addMultiCityRow={addMultiCityRow}
            setAddMultiCityRow={setAddMultiCityRow}
          />
        </>
      )}

      {props.values.mode === "MULTICITY" && !props.breakpoint && (
        <div className="flight-info-container flight-info-multi">
          <div className="passenger-preferredClass-multi">
            {props.values.mode === "MULTICITY" && !props.breakpoint && (
              <div className="form-group-wrapper  pref-direct">
                <PreferredAirline
                  values={props.values}
                  setFieldValue={props.setFieldValue}
                  allairlines={props.allAirlines}
                  gdsairlines={props.gdsAirlines}
                  lccairlines={props.lccAirlines}
                />
              </div>
            )}
            <div className="direct-flight-wrapper">
              <Checkbox
                className="direct-flight-checkbox"
                onChange={props.handleChangeDirectFlight}
                checked={props.values.isNonStop}
              />
              <span className="direct-flight-label">Direct Flight</span>
            </div>
          </div>

          {props.values.mode === "MULTICITY" && (
            <div className="form-group-wrapper submit-wrapper">
              <Button onClick={pushBtn}>Add More</Button>
              {/* <Button type="submit">Submit</Button> */}
            </div>
          )}
        </div>
      )}

      {(props.breakpoint || props.values.mode === "MULTICITY") && (
        <div
          className={cx(
            "flex-row col-490",
            props.values.mode === "MULTICITY" && "multicity-submit-row"
          )}
        >
          <div className="form-group-wrapper w50p">
            <label className="pl15">
              Passenger <img className="arrow-icon" src={arrowDown} />
            </label>
            <PassengerClass
              values={props.values}
              modifyPassengerCount={props.modifyPassengerCount}
              setFieldValue={props.setFieldValue}
              totalPax={
                Number(props.values.adultPax) +
                  Number(props.values.childPax) +
                  Number(props.values.infantPax) || 1
              }
            />
          </div>
          {props.values.mode === "MULTICITY" && (
            <div className="form-group-wrapper submit-wrapper">
              {/* <Button onClick={pushBtn}>Add More</Button> */}
              <Button type="submit">Submit</Button>
            </div>
          )}
          {/* {props.breakpoint && props.values.mode !== "MULTICITY" && (
            <div className="form-group-wrapper submit-wrapper w50p">
              <Button type="submit">Book Now</Button>
            </div>
          )} */}
        </div>
      )}

      {props.values.mode !== "MULTICITY" && !props.breakpoint && (
        <div className="flight-info-container">
          <div className="passenger-preferredClass">
            {props.values.mode !== "MULTICITY" && !props.breakpoint && (
              <div className="form-group-wrapper pax-class">
                <label>
                  Passenger <img className="arrow-icon" src={arrowDown} />
                </label>
                <PassengerClass
                  values={props.values}
                  modifyPassengerCount={props.modifyPassengerCount}
                  setFieldValue={props.setFieldValue}
                  totalPax={
                    Number(props.values.adultPax) +
                      Number(props.values.childPax) +
                      Number(props.values.infantPax) || 1
                  }
                />
              </div>
            )}
            {props.values.mode !== "MULTICITY" && !props.breakpoint && (
              <div className="form-group-wrapper  pref-direct">
                <PreferredAirline
                  values={props.values}
                  setFieldValue={props.setFieldValue}
                  allairlines={props.allAirlines}
                  gdsairlines={props.gdsAirlines}
                  lccairlines={props.lccAirlines}
                />
              </div>
            )}
          </div>
          <div className="direct-flight-wrapper">
            <Checkbox
              className="direct-flight-checkbox"
              onChange={props.handleChangeDirectFlight}
              checked={props.values.isNonStop}
            />
            <span className="direct-flight-label">Direct Flight</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default FlightItinSection;

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const customPopperFrom = function(props) {
  // console.log("props", props);
  return (
    <Popper
      {...props}
      // anchorEl={departAutocompleteEl.current.children[0]}
      placement="bottom"
      style={{}}
    />
  );
};

const customPopperTo = function(props) {
  return <Popper {...props} placement="bottom" style={{}} />;
};
