import React from 'react'
import WebApi from "api/ApiConstants";
import LeftAdornedAutoComplete from "./LeftAdornedAutoComplete";
import { TextField } from '@material-ui/core';

const HotelLocationCountryInput = ({props, setFieldValue, values, formikRef}) => {


  const cityHotelChangeHandler = (searchType, obj) => {
    let countryCode = "";
    if (obj != null) {
      countryCode = obj.code;
    } else {
      const countryCodeObj = formikRef.current.values.countryCode;
      countryCode = countryCodeObj?.code;
    }
  };

  return (
    <LeftAdornedAutoComplete
                      className="form-control"
                      name={`countryCodeObj`}
                      options={props.hotelCountry || []}
                      getOptionLabel={(a) => a.name || ""}
                      value={values.countryCodeObj}
                      getOptionSelected={(option, value) =>
                        option.value == value.code
                      }
                      disableClearable
                      onChange={
                        (_, newValue) => {
                          setFieldValue("countryCodeObj", newValue);
                          setFieldValue("countryCode", newValue.code);
                          cityHotelChangeHandler(1, newValue);
                          props.setHotelCity([]);
                          setFieldValue("cityId", "");
                          //   setFieldValue("guestNationalityObj", newValue);
                          //   setFieldValue("guestNationality", newValue.code);

                          WebApi.getHotelCityListForCountry(
                            {
                              clientId: "",
                              countryCode: newValue.code,
                              endUserIp: "",
                              hotelCity: "",
                              searchType: "",
                              tokenId: "",
                            },

                            (response) => {
                              if (response.success === true) {
                                // console.log(response.data.destinations);
                                props.setHotelCity(
                                  response?.data?.destinations ?? []
                                );
                                // console.log(
                                //   response.data.destinations[0].destinationId
                                // );
                                setFieldValue(
                                  "cityObj",
                                  response.data.destinations[0]
                                );
                                setFieldValue(
                                  "cityId",
                                  response.data.destinations[0].destinationId
                                );
                                // setIsLoading(false);
                              } else {
                              }
                            },
                            (error) =>
                              console.log("getHotelCountry error", error)
                          );
                        }
                        // setData((prevState) => ({
                        //   ...prevState,
                        //   country: newValue,
                        // }))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          color="secondary"
                          // label="Country"
                        />
                      )}
                      disablePortal={true}
                      // adnicon={locationPinIcon}
                    />
  )
}

export default HotelLocationCountryInput
