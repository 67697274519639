import React from 'react'
import WebApi from "api/ApiConstants";
import LeftAdornedAutoComplete from "./LeftAdornedAutoComplete";
import { TextField } from '@material-ui/core';

const HotelLocationCityInput = ({props, setFieldValue, values, locationAutocompleteRef}) => {


    const fetchMatchingCities = (searchType, searchParam, countryCode) => {
        // console.log("hotel city search");
    
        if (searchParam.length < 3) {
          return;
        }
    
        // setCallingApi(true);
        WebApi.getMatchingHotelCity(
          {
            searchType: searchType,
            countryCode: countryCode || "IN",
            hotelCity: searchParam,
          },
          (response) => {
            if (response.success === true) {
              // console.log("hotel city search", searchParam);
              // console.log("matching cities response", response);
              props.setHotelCity(response.data.destinations);
            } else {
            //   setIsError(!response.success);
            //   setInfoText(response.message);
            }
            // setCallingApi(false);
          },
          (error) => {
            // setCallingApi(false);
            console.log("getMatchingHotelCity error", error);
          }
        );
      };

  return (
    <LeftAdornedAutoComplete
    className="form-control"
    name={`cityObj`}
    options={props.hotelCity || []}
    getOptionLabel={(a) => (a && a.cityName) || ""}
    value={values.cityObj}
    // loading={callingApi}
    getOptionSelected={(option, value) =>
      option.value == value.destinationId
    }
    onKeyUp={(event) =>
      fetchMatchingCities(
        1,
        event.target.value,
        values.countryCode
      )
    }
    onChange={(_, newValue) => {
      setFieldValue("cityObj", newValue);
      setFieldValue("cityId", newValue.destinationId);
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        fullWidth
        color="secondary"
        // label="Location"
        inputRef={locationAutocompleteRef}
        inputProps={{
          ...params.inputProps,
          autoComplete: "new-password",
        }}
        placeholder="Enter City"
      />
    )}
    // adnicon={locationPinIcon}
  />
  )
}

export default HotelLocationCityInput
