import { Button, useTheme } from "@material-ui/core";
import React, { useMemo } from "react";
import styled from "styled-components";
import bgCover from "assets/Tickat/images/home_pix/bg_cover.png";
import bgImage2 from "assets/Tickat/images/home_pix/europe.jpg";
import bgImage3 from "assets/Tickat/images/home_pix/flight-3.jpg";
// import visaServicesImage from "assets/Tickat/images/home_pix/visa_services.png";
import dmcPackagesImage from "assets/Tickat/images/home_pix/dmc_packages.png";
import { Carousel } from "react-responsive-carousel";

const SuitServices = () => {
  const theme = useTheme();

  const carouselImages = useMemo(
    () => [dmcPackagesImage, bgImage2, bgImage3],
    []
  );
  const [currentBgImage, setCurrentBgImage] = React.useState(carouselImages[0]);

  // React.useEffect(() => {
  //   setCurrentBgImage(carouselImages[Math.floor(Math.random() * 3)]);
  // }, []);

  return (
    <RootContainer theme={theme}>
      <div className="suit-bg-image">
        {/* <img src={currentBgImage} /> */}
        <Carousel
          autoPlay
          infiniteLoop
          useKeyboardArrows
          showThumbs={false}
          showIndicators={false}
          showArrows={false}
          showStatus={false}
          stopOnHover={true}
        >
          {carouselImages &&
            carouselImages.map((value, index) => {
              return <img src={value} />;
            })}
        </Carousel>
      </div>
      {/* <div className="background-overlay"></div> */}
      {/* <div className="section-title-container">
                <p>Our Suit of Services</p>
            </div> */}
      <div className="section-content">
        <div className="suit-service-container dmc-packages">
          <div className="service-details">
            <p className="service-title">DMC Packages</p>
            <div className="service-descriptionContainer">
              <div className="service-description">
                An excellent destination management company ( Dubai, South East
                Asia, Europe ) is tickat.com. We collaborate with you to arrange
                every aspect of your trip in an economical, ethical, and timely
                manner. Whether our clients are traveling for business or
                pleasure, we are dedicated to changing the world's scenery. We
                are committed to providing our customers with a luxurious
                vacation experience by meeting their every need and making even
                the little things memorable.
                <br />
                Our offices are situated in Thailand, Dubai, and India (Mumbai &
                Nagpur), and we take great satisfaction in being the top DMC.
                <br />
                We know that your vacation is a moment to be cherished, so we
                only provide the best by using the greatest accommodations and
                eco-friendly transportation, along with the best service and
                hospitality, to make sure you have a special and unforgettable
                time. We never cut corners when it comes to service standards.
              </div>
            </div>
          </div>
        </div>
      </div>
    </RootContainer>
  );
};

export default SuitServices;

const RootContainer = styled.div`
  // background-color: ${(prop) => prop.theme.palette.background.default};
  padding: 0 0 25px;
  border-radius: 10px;
  position: relative;
  .carousel-root{
    height: 100%;
  }
  .carousel-slider {
    height: 100%;
  }
  .slider-wrapper{
    height: 100%;
  }
  .slider-animated{
    height: 100%;
  }
  .axis-horizontal {
    height: 100%;
  }
  .slider {
    height: 100%;
  }
  .suit-bg-image {
    position: absolute;
    border-radius: 10px;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    & img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
    }
  }
  .background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
  }
  .section-title-container {
    padding: 30px 56px 5px;
    max-width: 600px;
    z-index: 2;
    position: relative;
    & p {
      font-size: 1.9rem;
      color: ${(prop) => prop.theme.palette.background.defaultContrast};
      font-weight: 600;
      font-family: ${(prop) => prop.theme.palette.font.secondary};
    }
  }
  .section-content {
    padding: 0 56px;
    z-index: 2;
    position: relative;
    @media all and (max-width: 500px) {
      padding: 0 16px;
    }
  }
  .suit-service-container {
    display: flex;
    justify-content: center;
    padding: 15px 0;
    &.dmc-packages .service-details {
      & .btn-visit-now {
        float: right;
      }
    }
  }
  .service-image {
    background: ${(prop) => prop.theme.palette.background.disabled};
    padding: 10px;
    & img {
      height: 258px;
    }
  }
  .service-details {
    width: 100%;
    // padding: 10px 30px;
    // margin-bottom: 130px;
    & p {
      margin: 10px 0;
    }
    & .service-title {
      font-weight: 600;
      font-size: 2rem;
      font-family: ${(prop) => prop.theme.palette.font.secondary};
      border-bottom: 1px solid #fff;
      width: 100%;
      text-transform: uppercase;
      z-index: 2;
      position: relative;
      &.right {
        text-align: right;
      }
    }
    & .service-subheading {
      font-weight: 600;
      font-family: ${(prop) => prop.theme.palette.font.secondary};
    }
    & .service-descriptionContainer {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        
    }
    & .service-description {
      font-weight: 500;
      font-size: 14px;
      font-family: ${(prop) => prop.theme.palette.font.secondary};
      background-color: ${(prop) => prop.theme.palette.background.default};
      width: 450px;
      padding: 25px;
      opacity: 80%;
        border-radius: 10px;
      line-height: 1.9;
    //   padding: 0 0 0 300px;
    @media all and (max-width: 500px) {
      padding: 15px;
      font-size: 12px;
    }
      &.left {
        text-align: left;
      }
    }
    & .btn-visit-now {
      display: block;
      width: 100px;
      cursor: pointer;
      border-radius: 5px;
      border: solid 1px ${(prop) => prop.theme.palette.buttons.primary};
      background: ${(prop) => prop.theme.palette.buttons.primaryContrast};
      color: ${(prop) => prop.theme.palette.buttons.primary};
      padding: 8px 18px;
      font-family: ${(prop) => prop.theme.palette.font.secondary};
      font-size: 13px;
      font-weight: 500;
    }
  }
  @media all and (max-width: 1280px) {
    // padding: 0;
    .service-details {
      margin-bottom: 10px;
      & .service-description {
        // padding: 0;
      }
    }
  }
  @media all and (max-width: 959px) {
    .suit-service-container {
      width: 100%;
      flex-direction: column;
    }
    .service-title.right {
      text-align: left !important;
    }
  }
  @media all and (max-width: 600px) {
    .service-details {
      padding: 10px;
    }
    .section-title-container {
      p {
        text-align: center;
      }
    }
    .service-title,
    .service-title.right {
      text-align: center !important;
    }
    .service-description {
      text-align: center;
      background-color: 
      max-width: 100% !important;
      &.left {
        text-align: center !important;
      }
    }
  }
`;
