import React from "react";
import { makeStyles, Button, useTheme, useMediaQuery } from "@material-ui/core";
import ContactBanner from "assets/triumphTravel/images/contact-banner.jpg";
import pic1 from "assets/triumphTravel/images/pic1.png";
import pic2 from "assets/triumphTravel/images/pic2.png";
import pic3 from "assets/triumphTravel/images/pic3.png";
import slideImage from "assets/triumphTravel/images/slideImage.png";
import { useHistory } from "react-router-dom";

const AboutUs = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  const goToUrlLandingpage = () => {
    history.push("/flights");
  };

  return (
    <div className={classes.root}>
      {/* <div className="section-container">
        <div className="banner-section">
          <div className="section-title-container">
            <div className="section-title">
              <h2>About Us</h2>
            </div>
            <div className="section-bread-crumbs">
              <a onClick={goToUrlLandingpage}>Home</a> | <a href="">About Us</a>
            </div>
          </div>
        </div>
      </div> */}

      <div className="section-container grid-container justify-center">
        <div className="grid-container md-1280 padding-tb100">
          <div className="content-container md-100">
            <div className="section-caption">
              <p>ABOUT US</p>
            </div>
            <div className="content-text">
              <p>
                Our company was built upon our founder's visionary travel concept of truly living for the moment and experiencing every activity to its fullest. We seek to only offer king-sized adventures and king-sized fun! The crux of our tours revolves around encouraging travellers to do it big and experience everything to its fullest - eat, play, explore and party as hard as one possibly can!
              </p>
              <p>
                If you're looking for an incredible travel experience and a chance to make memories that will last a lifetime, Bharat Travel Point Pvt. Ltd. is for you. We've specialized in providing escorted touring holidays for years and love to share our passion for travel.
              </p>
              <p>
                Wherever you choose to explore, however you choose to travel, we believe it's the people you're with that make all the difference to your precious time away. From fascinating history to awe-inspiring natural wonders and the chance to learn something new, shared experiences in good company are something you'll never forget. All our award-winning tour itineraries have been carefully crafted so you can take in a destination's most iconic sights, its famous traditions, culture and cuisine, while still discovering its hidden treasures. We love to share the journey of discovery, exploring new lands and experiences with you.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section-container grid-container justify-center">
        <div className="grid-container md-1280 padding-tb100">
          <div className="content-container md-100">
            <div className="section-caption">
              <p>Going The Extra Mile for Real Smiles</p>
            </div>
            <div className="content-text">
              <p>
                We pack so much into your holiday price, from flights, transfers and accommodation, to world-class Tour Managers and many excursions. Many tours also include a selection of meals, to give you a flavour of the local cuisine. You'll have free time to explore on every holiday and because your idea of the perfect trip will be different to the next persons, we offer freedom and flexibility with our range of optional excursions, add-on and the chance to stay longer.
              </p>
              <p>
                For a hassle-free experience, we'll take care of everything and if your holiday requires a visa, our specialist team can even process your application for you.
              </p>
              <p>
                There's someone available in our headquarters 24 hours a day, seven days a week, 365 days a year. That means if you need assistance when you're on holiday, help is just a phone call away.
              </p>
              <p>
                <b>
                  Returning customers are rewarded with a loyalty discount - all you have to do is book again within six months of your last holiday.
                </b>
              </p>
              <p>
                We're committed to making your holiday extra special. Our Rock-Solid Guarantees are one more thing to smile about, offering you complete peace of mind. Free private chauffeur transfers are provided on worldwide tours**, and you can often fly from your local airport. Plus, keep an eye out for our fantastic discounts and offers!!!!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section-container grid-container justify-center">
        <div className="grid-container md-1280 padding-tb100">
          <div className="content-container md-100">
            <div className="section-caption">
              <p>SIRAJ SHEIKH (CHAIRMAN)</p>
            </div>
            <div className="content-text">
              <ul>
                <li>
                  <p>
                    Siraj Sheikh was born in 1973 at Nagpur, as fourth child to Ramzan Sheikh, in middle class family but this didn't stop him from winning in the race. he had set his ambition on something bigger. He dreams big and made that happen. He establishes the foundation of some of the biggest companies in Central India Bharat Travel Point Pvt. Ltd., Bava Travel point pvt .ltd, BCN Cable, Bharat Agency, Lotto world and several companies. If there's one thing you can say about Siraj Sheikh it's that he doesn't back down.
                  </p>
                  <p>
                    The story of something always start with nothing and there are sayings if you Want something in your life you must work for it & put the extra efforts which you think you will be needing to achieve the desired result, this is a story of child who sold figure chips at the age of 13 by lending it from known person and made a profit in it without investing any money. He has witnessed the lack of money in early age of his life, His father worked in Empress Mill who is the only breadwinner in family. He has faced some tough time along with his other siblings to survive, he has made his mind to overcome the same situation anyhow and he started to focus his vision on business which he wanted to turn it on his favour. He packed popcorn packets wherein to pack 500 packets he used to get Rs.5. He saw a dream to own the fleets of Buses, Taxis & Luxury cars, he knows the price of his dreams & efforts to make it reality. At the age of 13 he entered into selling of Lottery on petrol pump by lending lottery tickets from his friend and started earning commission on every sale of its lottery tickets. He developed interest in the same field and opened his first shop of lottery in early age of 15 in 1989 on Medical Square at Nagpur. he was always incredibly ambitious and worked actively to achieve his goals.
                  </p>
                  <p>Early Life</p>
                  <p>
                    So many in the middle class set their sights on what it takes to simply get by, instead of thrive and prosper, Siraj Sheikh is one of the man who always thrive and to do more out of his capacity, Though it took a little while for his product to catch on, he didn't give up on his dream or his vision for what it could become and that gamble has paid off handsomely for him. In 1993 he opened his new retail shop at Tukdoji Putla, at Nagpur of lottery tickets and started his retail counter, he has made fortune to overcome the obstruction coming in way, it paves the way for his upcoming joint venture of TV cable in Nagpur, In 1996 he opened a new company by the name of BCN Cable in partnership with his brothers in central India to cater for cable Tv connections which has revolutionized the Television in Nagpur.
                  </p>
                  <p>
                    Lao Tzu said "He who conquers others is strong; He who conquers himself is mighty", Working hard on your dreams always pays its best interest. In 1997 he got opportunity of Lottery wholesale Agency for Vidarbha, His work ethic and positive attitude never failed him and he diversified his income almost as soon as he started making it. In 1998 he added 1 more venture in his business of Media industry (NEWS) by the Name of BCN News Channel, Nagpur cities first of its kind News channel which cover local news of the city. Being finger chips seller & now he has become one of the renowned faces of the Nagpur.
                  </p>
                  <p>
                    The best years of your life are the ones in which you decide your problems are your own. You do not blame them on your mother, the ecology, or any event. You realize that you control your own destiny and you must work hard it's the only way. When nature starts to unfold its glory, you will get what you desired.
                  </p>
                  <p>
                    Once again, a new feather is waiting for him to put on, he got opportunity for Online Lottery sale in 2001, the new era has begun with New hope & New area to explore, he has sought to cultivate a different approach to running a business. He says the ethos of his businesses is to build from the bottom up – taking into account the feedback from all staff, and not just top-down hierarchy.
                  </p>
                  <p>
                    When businesses of his going on in good direction and started paying off he again started to multiply his business. In 2007 he started printing newspaper which is basically a Lottery based paper by the name of "Aap Ki Qismat", where he got overwhelming response from the readers. He has very much contributed in Social activities like Dahihandi, Garbha and Cultural Events for various season. His birthday now celebrates by the various organization as Festival in Nagpur they arrange for Blood donation camp, Eye check-up camp, Health check-up camp at various place for social cause. He truly loves the Mother Nature whenever he got a chance to relax from work, he used to visit the forest and spend the quality time with nature. That was the year which come for breakthrough in his life In 2012 he expanded his wings again and now he went for an international launch, He opened a New Venture i.e. Bharat Travel Point Pvt Ltd. & Brand New office at United Arab Emirates - Dubai i.e. Destination Management Company, which takes care of the local tourist requirement for UAE. He started to extending his offices in across Maharashtra, like in Mumbai, Pune, Amravati, multiple branches in Nagpur at Mahalgi Nagar, Sitabuldi & Hanuman Nagar. He has added multiple Taxi & Bus services. To satisfied guest now they are operating 24 Hours call centre to resolve any query and to help the customer when they needed first of its kind in Central India. It's like someone always there to extend you help When you needed the most.
                  </p>
                  <p>
                    One more feather is in the way for him in this year he launched his dream project of Bus fleet which will be operating from Nagpur to Pune and on many more routs to come, with vision of launching more than 100 buses & multiple offices in across India his passion for work is growing more & more and to convert his Dreams into reality he works hard. He is now extending its wings into Hospitality industry as its first project he has opened BTP Inn, 15 rooms property at Chikhaldara, which is just 500 meters away from the Dream Project of Maharashtra government i.e. Sky Walk which will be the world's longest Sky walk that will help for the boost of tourism in Chikhaldara. Other projects like Destination Wedding Management is his dream to operate and cater to needs of customer which will be added in his list of companies soon.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="section-container grid-container justify-center">
        <div className="grid-container md-1280 padding-tb100">
          <div className="content-container md-100">
            <div className="section-caption">
              <p>MR. FAIZAN SHEIKH (MANAGING DIRECTOR)</p>
            </div>
            <div className="content-text">
              <ul>
                <li>
                  <p>
                    Entrepreneurship is the constant hunger for making things better and the idea that you are never satisfied with how things are with this attitude which is in mind of our young entrepreneur Mr. Faizan Siraj Sheikh, at the age of 22 he has wide range of portfolio to offer. Having ample of knowledge in Tourism Industry which he is utilizing to run the business, he has Graduated in Bachelor of Business Administration. Since childhood he has witness the amount of effort put by his father to run the businesses and to achieve something desired by individual. He considers his father as the Ideological person which he follows.
                  </p>
                  <p>
                    He does believe in the saying that "Time is Everything", this is the only force which will decide the fate of the efforts. He says Being open to change and improvements provides an opportunity to complete work assignments in a more efficient manner while offering additional benefits to the organization. It is the responsibility of each person to use their own individual sense of moral and ethical behaviour when working with and serving others within the scope of their job.
                  </p>
                  <p>
                    Earlier he went for Job in one of the prestigious Tour & Travel company for experience, while having things in hand, but as per him the business ethics & continue innovation in service is very much needed to satisfy the client. He always looks on to learn new things which can be utilize for the betterment of company and individual. He is very skilful in handling the team of Professional and knows the way about how to get it the work done.
                  </p>
                  <p>
                    He is now oversees the operations of Faizan Media Services, Faizan Islamic Tours, Bharat Travel Point Pvt. Ltd. & Btpbuses.com, wherein customer have a one point of contact for tourism & Media related requirement which can be offer through his services, He says Listening to your clients and meeting their needs is essential, to understand what they exactly needed and to deliver with same enthusiasm is need of an hour, If you're ready to help your customers fall in love with your business by surpassing the expectation of client with personal touch it can indicate how well the organization is performing currently, client satisfaction is perhaps the best indicator of how likely it is that the client will make further purchases or give reference to other in future.
                  </p>
                  <p>
                    He is coming up with outdoor Digital hoardings to deliver marketing solutions to the companies, to reach out diverse market in one place. Photographic information builds a company reputation & product image in the market with huge and eye-catching digital screens & its high reach capacity. In an increasingly technological world and with an increasing number of new brands and products being born, the attention of consumers must be drawn. Brands and companies need to adapt to a new, more demanding and digital customer.
                  </p>
                  <p>
                    With Vision of to becoming most trusted and client-oriented company in future, He is working hard to reach the desired results and passion to make it happen nothing seems impossible for him to achieve. He always looks to empower his employee through various ways to take the on-spot decision rather than having every decision go through the management. He understands that the Employee empowerment should be centred on the needs of the customer. If you manage other people, the first thing you need to understand is that your success depends on their success. The more you empower your employees, the more they will grow and thrive. Everything in life including people changes. If you don't give people room to grow, you will force them to either leave the business or grow stagnant. As he has witness some of his employees are there in the company when it has started by his Father and the way his father had handles them and they are still the part of the Team, which makes the Ratan Tata saying come True i.e. "If you want to walk fast, Walk Alone. But if You want to walk far, Walk Together."
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: theme.palette.font.primary,
    "& .section-container": {
      margin: 0,
      padding: 0,
      width: "100%",
    },
    "& .grid-container": {
      display: "flex",
      [theme.breakpoints.down(600)]: {
        flexWrap: "wrap",
      },
    },
    "& .justify-center": {
      justifyContent: "center",
    },
    "& .padding-tb100": {
      padding: "25px 0",
    },
    "& .md-1280": {
      maxWidth: 1280,
      width: "100%",
    },

    "& .section-title-container": {
      padding: "150px 0 0 60px",
      [theme.breakpoints.down(600)]: {
        padding: "90px 0 0 60px",
      },
    },
    "& .section-title": {
      "& h2": {
        fontWeight: 700,
        color: theme.palette.primary.lightText,
        fontSize: 42,
        margin: 0,
      },
    },
    "& .section-bread-crumbs": {
      color: theme.palette.primary.lightText,
      "& a": {
        color: theme.palette.primary.lightText,
        cursor: "pointer",
      },
    },
    "& .banner-section": {
      padding: 20,
      height: 380,
      backgroundImage: `url(${ContactBanner})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "norepeat",
      [theme.breakpoints.down(600)]: {
        height: 250,
      },
    },
    "& .content-container": {
      "& button": {
        background: theme.palette.buttons.tertiary,
        color: theme.palette.buttons.tertiaryContrastText,
        fontSize: 14,
        padding: "10px 20px",
        textTransform: "lowercase",
        "& .MuiSvgIcon-root": {
          marginLeft: 4,
          fontSize: 16,
        },
      },
      "&.md-50": {
        width: "50%",
        [theme.breakpoints.down(960)]: {
          width: "100%",
        },
      },
      "&.md-100": {
        width: "100%",
      },
      [theme.breakpoints.down(1330)]: {
        padding: "0 20px",
      },
      [theme.breakpoints.down(960)]: {
        padding: "0 30px",
      },
      [theme.breakpoints.down(600)]: {
        textAlign: "center",
        marginBottom: 10,
      },
      [theme.breakpoints.down(390)]: {
        padding: "0 15px",
      },
    },

    "& .testimonial-container": {
      margin: "20px 0 0 0",
      "& .content-text-name": {
        fontSize: 18,
        fontWeight: 600,
        fontFamily: theme.palette.font.primary,
        margin: 0,
        lineHeight: 1.5,
      },
      "& .content-text-occupation": {
        fontSize: 12,
        fontWeight: 600,
        fontFamily: theme.palette.font.primary,
        margin: 0,
        lineHeight: 1.5,
      },
      "& .content-image": {
        margin: "0 15px 0 0",
      },
      "& .testimonial-text": {
        width: 342,
        margin: "20px 0 0",
        "& .content-text-caption": {
          fontSize: 14,
          fontWeight: 500,
          fontFamily: theme.palette.font.primary,
        },
      },
      [theme.breakpoints.down(390)]: {
        padding: "0 15px",
      },
    },
    "& .section-header": {
      "& p": {
        color: theme.palette.secondary.main,
        paddingTop: 20,
        size: 14,
        fontWeight: 700,
      },
    },
    "& .section-caption": {
      "& p": {
        fontSize: 22,
        fontWeight: 800,
        lineHeight: 1.2,
        fontFamily: theme.palette.font.primary,
        color: theme.palette.primary.darkText,
        [theme.breakpoints.down(1330)]: {
          fontSize: 32,
        },
      },
      "&.light-text": {
        color: theme.palette.primary.lightText,
        "& p": {
          color: theme.palette.primary.lightText,
          fontFamily: theme.palette.font.primary,
        },
      },
    },
    "& .content-text": {
      margin: "30px 0",
      "& p": {
        fontWeight: 500,
        fontSize: 15,
        lineHeight: 1.3,
        fontFamily: theme.palette.font.primary,
      },
      "& a": {
        cursor: "pointer",
        color: theme.palette.primary.info,
      },
    },
    "& .content-Images": {
      textAlign: "center",
      "& img": {
        margin: 10,
        [theme.breakpoints.down(960)]: {
          width: "100%",
        },
        [theme.breakpoints.down(390)]: {
          width: "100%",
        },
      },
    },
    "& .section-bg": {
      minHeight: 320,
      backgroundImage: `url(${slideImage})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "norepeat",
    },
    "& .inner-container": {
      maxWidth: 1280,
      width: "100%",
      "&.max-1440": {
        maxWidth: 1440,
      },
      "&.padding-tb100": {
        padding: "100px 0",
      },
    },
    "& .header-container": {
      padding: "0 0 0 70px",
      marginBottom: 30,
    },
    "& .section-carousel-wrapper": {
      width: "100%",
    },
    "& .rec-carousel": {
      [theme.breakpoints.down(600)]: {
        position: "relative",
      },
    },
    "& .arrow-crouselPackage": {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down(600)]: {
        position: "absolute",
        top: "35%",
      },
      "& img": {
        cursor: "pointer",
      },
      "&.left": {
        left: 0,
        zIndex: 1,
      },
      "&.right": {
        right: 0,
      },
    },
    "& .service-item": {
      background: theme.palette.background.light,
      width: "100%",
      textAlign: "center",
      padding: "30px 5px",
      color: theme.palette.primary.darkText,
    },
    "& .service-image": {
      width: "100%",
      margin: "0 0 20px",
      "& img": {
        width: 59,
        height: 59,
      },
    },
    "& .service-text-title": {
      fontSize: 16,
      fontWeight: 500,
      padding: "0 45px",
      fontFamily: theme.palette.font.primary,
    },
    "& .service-text": {
      color: theme.palette.primary.darkText,
      fontSize: 13.5,
      marginTop: 15,
    },
    "& .grid-column": {
      "&.grow-1": {
        flexGrow: 1,
      },
      "&.testimonial-grid": {
        padding: "60px 0 30px",
      },
      "&.image-column": {
        position: "relative",
      },
    },
    "& .content-navImage": {
      position: "absolute",
      bottom: -10,
      left: -60,
      "& a": {
        cursor: "pointer",
      },
    },
  },
}));
