import React from "react";
import { Grid, Divider, Collapse } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AppConstant } from "appConstant";

import ContactCard from "components/HotelPage/HotelComponents/ContactCard/ContactCard";
import WebApi from "api/ApiConstants";
import cx from "classnames";
import OaDiscountCouponSearch from "oahoc/OaDiscountCouponSearch";
import Coupons from "pages/components/Coupons";

const HotelBookingFormPage = ({ location, ...props }) => {console.log("location", location); console.log("props", props)
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const hotelPriceRoundOff = AppConstant.hotelPriceRoundOff;
  const [isLoading, setIsLoading] = React.useState(true);
  const [discountCoupons, setDiscountCoupons] = React.useState(null);
  const [couponObj, setCouponObj] = React.useState(null);
  const [addDisCoup, setAddDisCoup] = React.useState(null);
  const [couponStatus, setCouponStatus] = React.useState({
    error: false,
    success: false,
    message: ""
  });
  const [openPromo, setOpenPromo] = React.useState(true);
  const [userId, setUserId] = React.useState(null);
  const [totalPayable, setTotalPayable] = React.useState(0);

  const TitleComp = (props) => {
    return (
      <div className="section-title-wrapper">
        <span className="heading-icon">
          <i className={props.fa} />
        </span>
        <Typography className="heading-text">{props.title}</Typography>
      </div>
    );
  };

  const { params } = location;

  const totalCost = (selectedRooms) => {
    let total = 0;
    selectedRooms.map((room) => {
      // let roomPrice = room.price + "";
      // roomPrice = roomPrice.replace(',', '');
      // console.log("roomPrice", roomPrice);
      total = total + Number(room.price);
    });
    console.log("total", total)
    // return hotelPriceRoundOff ? total.toFixed(2) : total.toLocaleString('en-IN');
    return hotelPriceRoundOff ? total.toFixed(2) : total;
    //return total.toFixed(2);
  };

  const getDiscountCoupons = (userId) => {
    WebApi.getCoupons(
      {
        allVoucher: false,
        loggedInUserId: userId,
        voucherCode: "",
        onwardFareType: "",
        returnFareType: "",
        voucherUID: 0,
        service: "Hotel"
      },
      (resp) => {
        if (resp && resp.success) {
          setDiscountCoupons(resp.data);
        }
      },
      (error) => {
        console.error("hotel discount coupon error", error);
      }
    );
  };

  const applyCoupon = (val) => {
    if (val) {
      setIsLoading(true);
      WebApi.validateDiscountVoucherUsage({ voucherCode: val.voucher_code }, response => {
        if (response.success) {
          if (response.data.usageLimitExceeded) {
            console.error(`Discount coupon ${val.voucher_code} usage limit exceeded! Please try another.`);
            setCouponStatus({
              ...couponStatus,
              error: true,
              message: `Discount coupon ${val.voucher_code} usage limit exceeded! Please try another.`
            });
          } else {
            setCouponObj(val);
            setAddDisCoup(true);
            // console.log("discount coupon applied successfully");
            setCouponStatus({
              ...couponStatus,
              success: true,
              message: "discount coupon applied successfully"
            });
          }
        }
        setIsLoading(false)
      }, error => {
        console.error("error validating discount coupon usage", error);
        setIsLoading(false);
      });
    }
  };

  const removeCoupon = () => {
    setAddDisCoup(false);
    setTotalPayable(parseFloat(totalCost(location.state.selectedRooms)).toFixed(2));
    // setSelectedRooms(prevState => {
    //   let temp = prevState;
    //   for(let room of temp) {
    //     room.roomInfo.price.discount = 0;
    //   }
    //   return temp;
    // });
  };

  React.useEffect(() => {
    if(userId) {
      getDiscountCoupons(userId);
    }
    if(couponStatus?.success && addDisCoup && totalPayable) {
      setTotalPayable((parseFloat(totalPayable) - parseFloat(couponObj.voucher_value)).toFixed(2));
      // setSelectedRooms(prevState => {
      //   let temp = prevState;
      //   for(let room of temp) {
      //     room.roomInfo.price.discount = parseFloat(couponObj.voucher_value).toFixed(2);
      //   }
      //   return temp;
      // });
    }
  }, [userId, couponStatus, couponObj, addDisCoup]);

  React.useEffect(() => {
    let totalFareCalculated = totalCost(location.state.selectedRooms);
    console.log("totalFareCalculated", totalFareCalculated)
    setTotalPayable(parseFloat(totalFareCalculated).toFixed(2));
  }, []);

  React.useEffect(() => {console.log("totalPayable", totalPayable)}, [totalPayable])

  return (
    <div style={{ backgroundColor: "white" }}>
      <Grid container spacing={3}>
        <Grid
          item
          md={8}
          style={{
            padding: "0 13px",
            background: "#eee",
          }}
        >
          <ContactCard
            locationProps={location}
            hotelInfoProps={props}
            traceId={props.history.location.state.traceId} //trace ID added
            hotelInfo={location.state.hotelInfo}
            hotelSearch={props.hotelSearch}
            selectedRooms={location.state.selectedRooms}
            totalFare={totalCost(location.state.selectedRooms)}
            hotelCode={location.state.hotelCode}
            tnc={props.tnc}
            ppc={props.ppc}
            setUserId={setUserId}
            couponObj={couponObj}
            totalPayable={totalPayable}
            discountApplied={addDisCoup}
          />
        </Grid>
        <Grid item md={4} sm={12} xs={12} className={classes.infoGrid}>
          <Grid
            container
            className={cx(classes.sectionContainer, classes.mobileHide)}
          >
            <Grid item md={12} sm={12} xs={12}>

              <div
                className="section-header"
              >
                <TitleComp fa="fa fa-building" title={`Hotel Details`} />
              </div>
              <Typography
                className={classes.hotelName}
                style={{ padding: 15, paddingBottom: 0 }}
              >
                {" "}
                {location.state.hotelInfo.hotelDetails.hotelName}
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              style={{ padding: "0px 15px" }}
            >
              <Grid item>
                <Typography style={{ color: theme.palette.primary.blackPearl }}>
                  {" "}
                  Check-In:
                </Typography>
                <Typography style={{ color: theme.palette.primary.blackPearl }}>
                  {props.hotelSearch.checkInDate}
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: theme.palette.primary.blackPearl }}>
                  {" "}
                  Check-Out:
                </Typography>
                <Typography style={{ color: theme.palette.primary.blackPearl }}>
                  {props.hotelSearch.checkOutDate}
                </Typography>
              </Grid>
            </Grid>
            {addDisCoup && 
              <Grid
                container
                justifyContent="space-between"
                style={{ padding: "0px 15px" }}
              >
                <Grid item>
                  <Typography style={{ color: theme.palette.primary.blackPearl }}>
                    {" "}
                    Discount:
                  </Typography>
                </Grid>
                <Grid item style={{display: "flex", alignItems: "center"}}>
                  <Typography style={{ color: theme.palette.primary.blackPearl }}>
                    {parseFloat(couponObj?.voucher_value).toFixed(2)}
                  </Typography>
                  <span style={{cursor: "pointer", color: "red", marginLeft: 5, paddingBottom: 3.5}}
                    onClick={removeCoupon}
                    title="remove discount"
                  >
                    x
                  </span>
                </Grid>
              </Grid>
            }
            <Grid item md={12} sm={12} xs={12} style={{ marginTop: 10 }}>
              <Divider />
            </Grid>
            <Grid
              container
              style={{
                background: theme.palette.background.disabled,
                padding: "10px 15px",
                borderBottom: `none`,
                flexDirection: "row",
                display: "flex",
                width: "100%",
              }}
            >
              <Grid item md={6} sm={6} xs={6}>
                <Typography className={classes.hotelName}>
                  Total Costs
                </Typography>
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                <Typography className={classes.hotelName} align="right">{`${props.hotelSearch.preferredCurrency === "INR"
                  ? "₹"
                  : props.hotelSearch.preferredCurrency
                  } ${totalPayable}`}</Typography>
              </Grid>
            </Grid>
          </Grid>
          {discountCoupons && discountCoupons.length > 0 &&
            <Grid
              container
              className={cx(classes.sectionContainer)}
            >
              <Grid item md={12} sm={12} xs={12}>
                <div
                  className="section-header"
                >
                  <TitleComp fa="fa fa-gift" title={`Offers`} />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12} className="offer-coupons-search">
                <OaDiscountCouponSearch
                  applyDiscountCoupon={applyCoupon}
                  couponStatus={couponStatus}
                  setCouponStatus={setCouponStatus}
                  service="Hotel"
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12} className="offer-coupons-section" style={{marginBottom: openPromo ? 0 : 5}}>
                <div
                  className={classes.promoCollapseToggle}
                  onClick={() => setOpenPromo(!openPromo)}
                >
                  <Typography
                    style={{ fontSize: 15, marginRight: 10 }}
                  >
                    PROMO CODE
                  </Typography>
                  <span>
                    <i
                      className={
                        openPromo
                          ? "fas fa-chevron-up"
                          : "fas fa-chevron-down"
                      }
                      style={{ marginTop: 6, marginLeft: 10 }}
                    ></i>
                  </span>
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12} className="offer-coupons-section">
                <Collapse in={openPromo}>
                  {discountCoupons?.map((item, index) => (item.couponDisplay === "auto" &&
                    <Coupons
                      item={item}
                      key={index}
                      applycoupon={(val) => applyCoupon(val)}
                      selectedcoupon={couponObj}
                      {...props}
                    />
                  ))}
                </Collapse>
              </Grid>
            </Grid>
          }
        </Grid>
      </Grid>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    hotelSearch: state.hotel.hotelSearch,
  };
}

export default withRouter(connect(mapStateToProps)(HotelBookingFormPage));

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    background: theme.palette.background.light,
    borderRadius: "0.25rem",
    border: "1px solid rgba(0,0,0,0.125)",
    boxShadow: "0 .125rem .25rem rgba(0,0,0,0.075)!important",
    marginBottom: 25,
    "& .section-header": {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.125)",
      marginBottom: 5,
    },
    "& .section-title-wrapper": {
      display: "flex",
      alignItems: "center",
    },
    "& .heading-icon": {
      borderRadius: "50%",
      background: theme.palette.background.disabled,
      width: 30,
      height: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: 10,
      "& i": {
        fontSize: 16,
        color: theme.palette.text.secondary,
      },
    },
    "& .heading-text": {
      textTransform: "uppercase",
      fontWeight: 500,
      color: theme.palette.text.secondary,
    },
  },
  "& .section-title-wrapper": {
    display: "flex",
    alignItems: "center",
  },
  "& .heading-icon": {
    borderRadius: "50%",
    background: theme.palette.background.disabled,
    width: 30,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
    "& i": {
      fontSize: 16,
      color: theme.palette.text.secondary,
    },
  },
  "& .heading-text": {
    textTransform: "uppercase",
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  root: {
    padding: theme.spacing(0, 0, 3),
    backgroundColor: theme.palette.background.selago,
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.primary.lightText,
    },
    [theme.breakpoints.down(960)]: {
      "& .divider": {
        display: "none",
      },
    },
    "& .section-container": {
      background: theme.palette.background.light,
      borderRadius: "0.25rem",
      border: "1px solid rgba(0,0,0,0.125)",
      boxShadow: "0 .125rem .25rem rgba(0,0,0,0.075)!important",
      marginBottom: 25,
    },
  },

  header: {
    backgroundColor: theme.palette.background.carulean,
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.primary.lightText,
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: "500",
    letterSpacing: theme.typography.pxToRem(1.33),
    textTransform: "uppercase",
    color: theme.palette.primary.lightText,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(0),
      fontSize: theme.typography.pxToRem(20),
      textTransform: "capitalize",
      color: theme.palette.primary.defaultText,
    },
  },
  // [theme.breakpoints.down(960)]: {
  divider: {
    display: "none",
  },
  // },
  //
  heading: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: "500",
    letterSpacing: theme.typography.pxToRem(1.33),
    textTransform: "uppercase",
    color: theme.palette.primary.lightText,
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(15),
      textTransform: "capitalize",
      color: theme.palette.primary.defaultText,
    },
  },
  subHeading: {
    fontWeight: "500",
    fontSize: theme.typography.pxToRem(12),
    letterSpacing: theme.typography.pxToRem(0.83),
    lineHeight: theme.typography.pxToRem(10),
    textTransform: "uppercase",
    color: theme.palette.secondary.aegean,
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.up("md")]: {
      display: "block",
      marginBottom: theme.spacing(1),
      color: theme.palette.secondary.sharkGray,
    },
  },
  heading__container: {
    margin: theme.spacing(1, 0, 1),
  },
  costContainer: {
    backgroundColor: theme.palette.background.peach,
    width: "100%",
    textAlign: "center",
    padding: theme.spacing(0),
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.primary.lightText,
      textAlign: "right",
    },
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      textAlign: "left",
    },
  },
  totalCost: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: theme.typography.pxToRem(43),
    letterSpacing: theme.typography.pxToRem(2),
    color: theme.palette.primary.lightText,
    borderRadius: theme.typography.pxToRem(2),
    marginRight: theme.spacing(0.8),
    [theme.breakpoints.up("md")]: {
      display: "block",
      color: theme.palette.secondary.charcoal,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  cost: {
    fontWeight: 500,
    fontSize: 22,
    textAlign: "center",
    lineHeight: theme.typography.pxToRem(43),
    letterSpacing: theme.typography.pxToRem(1.9),
    color: theme.palette.primary.lightText,
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(30),
      letterSpacing: theme.typography.pxToRem(1.9),
      color: theme.palette.background.peach,
    },
  },
  sectionHeading: {
    fontWeight: "500",
    fontSize: theme.typography.pxToRem(18),
    letterSpacing: theme.typography.pxToRem(1.2366),
    color: theme.palette.background.peach,
    textTransform: "uppercase",
    marginBottom: theme.typography.pxToRem(4),
  },
  inputLabel: {
    fontSize: theme.typography.pxToRem(11),
    letterSpacing: theme.typography.pxToRem(0.97),
    color: theme.palette.secondary.charcoal,
    textTransform: "uppercase",
  },
  caption: {
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.secondary.charcoal,
  },

  agent__label: {
    fontSize: theme.typography.pxToRem(11),
    letterSpacing: theme.typography.pxToRem(0.8833),
    color: theme.palette.secondary.sharkGray,
    marginBottom: theme.spacing(0.5),
    textTransform: "uppercase",
  },
  agent__value: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: theme.typography.pxToRem(1.2366),
    textTransform: "uppercase",
    color: theme.palette.secondary.charcoal,
    marginBottom: theme.spacing(3),
  },
  hotelInfo: {
    fontSize: 15,
    color: theme.palette.primary.blackPearl,
    // marginBottom: 5,
  },
  hotelName: {
    fontSize: 18,
    color: theme.palette.primary.blackPearl,
    // marginBottom: 10,
    fontWeight: 500,
  },
  infoGrid: {
    padding: "0 30px",
    background: "#eee",
    "& .offer-coupons-search": {
      padding: "10px 10px 0"
    },
    "& .offer-coupons-section": {
      padding: "5px 20px 0"
    }
  },
  mobileHide: {
    [theme.breakpoints.down(960)]: {
      display: "none",
    }
  },
  promoCollapseToggle: { 
    display: "flex", 
    flexWrap: "nowrap", 
    width: "100%", 
    justifyContent: "space-between", 
    cursor: "pointer" 
  }
}));
