import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import company_logo_white from "assets/Tickat/images/logo/white_logo.png";
import company_logo from "assets/Tickat/images/logo/logo.png";
import flightIcon from "assets/Tickat/images/home_pix/flight_icon.png";
import blackflightIcon from "assets/Tickat/images/home_pix/flight_black_icon.png";
import hotelIcon from "assets/Tickat/images/home_pix/hotel_icon.png";
import blackhotelIcon from "assets/Tickat/images/home_pix/hotel_black_icon.png";
import busIcon from "assets/Tickat/images/home_pix/bus_icon.png";
import packageIcon from "assets/Tickat/images/home_pix/package_icon.png";
import loginIcon from "assets/Tickat/images/home_pix/Login.png";
import blackpackageIcon from "assets/Tickat/images/home_pix/package_black_icon.png";
import { MenuOutlined } from "@material-ui/icons";
import visaIcon from "assets/Tickat/images/home_pix/visa_icon.png";
import dmcIcon from "assets/Tickat/images/home_pix/dmc_icon.png";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { AppStateContext } from "layouts/AppStateProvider";
import { apiCall } from "oautils/oaDataUtils";
import {
  localforageSetItem,
  localforageGetItem,
  localforageClear,
} from "oautils/oaForageUtils";
import WebApi from "api/ApiConstants";
import cx from "classnames";
import OaLoading from "pages/components/OaLoading";
import { RefreshRounded, ExpandMore, ExpandLess } from "@material-ui/icons";
import { currencyFormatter } from "oautils/oaCommonUtils";

const HomePageNavbar = (props) => {
  const history = useHistory();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showMobileMenu, setshowMobileMenu] = React.useState(false);
  const [accountAnchorEl, setAccountAnchorEl] = React.useState(null);
  const [corpName, setCorpName] = React.useState("");
  const [corpIdentity, setCorpIdentity] = React.useState("");
  const [accountBalance, setAccountBalance] = React.useState("");
  const [showServices, setShowServices] = React.useState(
    props.hideServices ? props.hideServices : props.showServices
  );
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const { secLogo } = React.useContext(AppStateContext);
  const [serviceOptions, setServiceOptions] = React.useState([]);
  const isMenuOpen = Boolean(anchorEl);
  const isAccountMenuOpen = Boolean(accountAnchorEl);
  const [showSupport, setShowSupport] = React.useState(false);
  const [anchorEll, setAnchorEll] = React.useState(null);
  const [userType, setUserType] = React.useState(null);
  const [anchorElLog, setAnchorElLog] = React.useState(null);
  const openLog = Boolean(anchorElLog);
  const { setSecLogo } = React.useContext(AppStateContext);
  const idLog = openLog ? "simple-popover" : undefined;
  const { setUserTypeb2c } = React.useContext(AppStateContext);
  const [corporateType, setCorporateType] = React.useState(null);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  const [mobile24Support, setMobile24Support] = React.useState(false);
  const [profileOpen, setProfileOpen] = React.useState(false);
  const oaNavBarRootRef = React.useRef();
  const oaNavBarBgRef = React.useRef();
  const oaNavMenuRef = React.useRef();
  const isMounted = React.useRef(false);
  const breakpoint = 960;
  const width = window.innerWidth;
  const [openForexQueryForm, setOpenForexQueryForm] = React.useState(false);

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const goToHome = () => {
    history.push("/");
  };

  const refreshBalance = () => {
    setAccountBalance(0);
    apiCall(WebApi.getDashboardData, {}, function(response) {
      let mainAccountBalance = response.data.mainAccountBalance;
      setAccountBalance(mainAccountBalance);
      localforageGetItem("user-details", function(err, value) {
        localforageSetItem("user-details", {
          ...value,
          cashBalance: mainAccountBalance,
        });
      });
    });
  };

  const logOut = (event) => {
    localforageClear(() => {
      WebApi.getAgentInfo({ userType: "G" }, (response) => {
        if (response != null && response.success === true) {
          localforageClear(() => {
            localforageSetItem("user-id", 0);

            const loginPagePath =
              corporateType && corporateType != "corporate"
                ? "/auth/login-page"
                : "/corporate_login";

            localforageSetItem("user-details", response.data, () => {
              localforageSetItem("access-key", response.accesskey, () => {
                history.push("/flights");
                window.location.reload();
              });
            });
          });
        }
      });
    });
  };

  const logOutB2C = () => {
    localforageClear(() => {
      WebApi.getAgentInfo({ userType: "G" }, (response) => {
        if (response != null && response.success === true) {
          localforageClear(() => {
            localforageSetItem("user-id", 0);

            localforageSetItem("user-details", response.data, () => {
              localforageSetItem("access-key", response.accesskey, () => {
                history.push("/flights");
                window.location.reload();
              });
            });
          });
        }
      });
    });
  };

  const openDashboard = (event) => {
    history.push("/admin/dashboard");
  };

  const openDashboardB2c = () => {
    history.push("/b2c/mybookings");
  };

  const getUserDetails = (mountStatus) => {
    localforageGetItem("user-details", function(err, value) {
      if (mountStatus && value) {
        if (value.userTypeAbv == "R") {
          setIsLoggedIn(true);
        }

        setShowServices(
          value.userType == "distributor"
            ? false
            : showServices != null
            ? showServices
            : true
        );
        setCorpName(value.corpname);
        setCorpIdentity(value.userIdentity);
        setAccountBalance(value.cashBalance);
        setServiceOptions(value.serviceOption);

        // console.log("serviceOptions", value.serviceOption);

        setUserType(value.userType);
        setUserTypeb2c(value.userType);
        // setCorporateType(value.corporateType);
      }
    });
  };

  const toggleMobile24Support = () => {
    setMobile24Support(!mobile24Support);
  };
  const toggleProfile = () => {
    setProfileOpen(!profileOpen);
  };

  const toggleVisibilityForex = () => {
    setOpenForexQueryForm(!openForexQueryForm);
  };

  const printEticket = () => {
    history.push("/b2c/printeticket");
  };

  React.useEffect(() => {
    if (mobileMenuOpen) {
      oaNavMenuRef &&
        oaNavMenuRef.current &&
        oaNavMenuRef.current.classList.add("oa-mobile-menu-show");
    } else {
      oaNavMenuRef &&
        oaNavMenuRef.current &&
        oaNavMenuRef.current.classList.remove("oa-mobile-menu-show");
    }
  }, [mobileMenuOpen]);

  React.useEffect(() => {
    isMounted.current = true;
    getUserDetails(isMounted.current);

    if (userType === "agent" || userType === "direct_agent") {
      refreshBalance();
    }

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <NavContainer theme={theme}>
      <div
        className={
          isSticky
            ? "navbar-wrappercontainer-sticky"
            : "navbar-wrappercontainer"
        }
      >
        <div className={isSticky ? "navbar-wrapper-sticky" : "navbar-wrapper"}>
          {showMobileMenu && (
            <div className="mobile-menu-container">
              {width < breakpoint && (
                <div className="navbar-links-wrapper">
                  <ul className="navbar-links">
                    <li className="navbar-link-li">
                      <NavbarLinkItem
                        isSticky={isSticky}
                        icon={
                          (isSticky && (history.location.pathname !== "/flights" && history.location.pathname !== "/")) ? blackflightIcon : flightIcon //prettier-ignore
                        }
                        serviceLabel="Flights"
                        isActive={
                          history.location.pathname === "/" ||
                          history.location.pathname === "/flights"
                        }
                        onClick={() => {
                          history.push("/flights");
                        }}
                      />
                    </li>
                    <li className="navbar-link-li">
                      <NavbarLinkItem
                        icon={
                          isSticky && history.location.pathname !== "/hotels"
                            ? blackhotelIcon
                            : hotelIcon
                        }
                        isSticky={isSticky}
                        serviceLabel="Hotels"
                        isActive={history.location.pathname === "/hotels"}
                        onClick={() => {
                          history.push("/hotels");
                        }}
                      />
                    </li>

                    <li className="navbar-link-li">
                      <NavbarLinkItem
                        isSticky={isSticky}
                        icon={
                          isSticky && history.location.pathname !== "/packages"
                            ? blackpackageIcon
                            : packageIcon
                        }
                        serviceLabel="Packages"
                        isActive={history.location.pathname === "/packages"}
                        onClick={() => {
                          history.push("/packages");
                        }}
                      />
                    </li>
                  </ul>
                </div>
              )}
              {width < breakpoint && (
                <div className="navbar-links-wrapper">
                  <ul className="navbar-links rightside-links">
                    {/* <li className="navbar-link-li">
                      <NavbarLinkItem
                          icon={visaIcon}
                          serviceLabel="Visas"
                          isActive={false}
                          onClick={() => { }}
                      />
                  </li> */}
                    {/* <li className="navbar-link-li">
                      <NavbarLinkItem
                          icon={dmcIcon}
                          serviceLabel="DMC"
                          isActive={false}
                          onClick={() => { }}
                      />
                  </li> */}
                    {/* {breakpoint &&
      userType !== "agent" &&
      userType !== "direct_agent" && (
        <>
          <li className="navbar-link-li">
            <a className="navbar-link-item" onClick={printEticket}>
              Print E-ticket
            </a>
          </li>
        </>
      )} */}

                    {userType !== "b2c_user" &&
                      userType !== "agent" &&
                      userType !== "direct_agent" && (
                        <>
                          <li className="navbar-link-li bg-secondary">
                            <a
                              className="navbar-link-item"
                              onClick={printEticket}
                            >
                              Print E-ticket
                            </a>
                          </li>
                          <li className="navbar-link-li bg-secondary">
                            <a
                              className="navbar-link-item"
                              onClick={() => history.push("/auth/login-page")}
                            >
                              Agent Login
                            </a>
                          </li>

                          <li className="navbar-link-li bg-secondary">
                            <a
                              className="navbar-link-item "
                              onClick={() => history.push("/b2c/register")}
                            >
                              Sign up
                            </a>
                          </li>
                          <li className="navbar-link-li bg-secondary">
                            <a
                              className="navbar-link-item login-item"
                              onClick={() => history.push("/b2c/signin")}
                            >
                              Login{"          "}{" "}
                              <img className="loginIcon" src={loginIcon} />
                            </a>
                          </li>
                        </>
                      )}
                    {(userType === "agent" || userType === "direct_agent") && (
                      <div className="agent-navinfo">
                        <li className="navbar-link-li bg-secondary">
                          <a
                            className="navbar-link-item"
                            onClick={openDashboard}
                          >
                            Dashboard
                          </a>
                        </li>
                        <li className="navbar-link-li bg-secondary">
                          <a
                            className="navbar-link-item flex-item"
                            onClick={refreshBalance}
                          >
                            <span className="navbar-link-label">Bal:</span>
                            <RefreshRounded />
                            <span className="navbar-link-label">
                              {currencyFormatter(accountBalance)}
                            </span>
                          </a>
                        </li>
                        <li className="navbar-link-li bg-secondary">
                          <a
                            className="navbar-link-item"
                            onClick={openDashboard}
                          >
                            {corpName} ({corpIdentity})
                          </a>
                        </li>
                        <li className="navbar-link-li bg-secondary">
                          <a className="navbar-link-item" onClick={logOut}>
                            Log out
                          </a>
                        </li>
                      </div>
                    )}
                    {userType == "b2c_user" && isLoggedIn && (
                      <div className="agent-navinfo">
                        <li className="navbar-link-li bg-secondary">
                          <a
                            className="navbar-link-item"
                            onClick={openDashboardB2c}
                          >
                            Dashboard
                          </a>
                        </li>
                        <li className="navbar-link-li bg-secondary">
                          <a
                            className="navbar-link-item"
                            onClick={openDashboardB2c}
                          >
                            {corpName} ({corpIdentity})
                          </a>
                        </li>
                        <li className="navbar-link-li bg-secondary">
                          <a className="navbar-link-item" onClick={logOutB2C}>
                            Log out
                          </a>
                        </li>
                      </div>
                    )}
                  </ul>

                  {/* {breakpoint && <div className="navbar-links-bottom-border"></div>} */}
                </div>
              )}
            </div>
          )}
          <div className="agent-navcontainer"></div>

          <div className="navbar-container">
            <div className="navbar-brand-wrapper">
              <a onClick={goToHome}>
                {width > breakpoint ? (
                  <img src={isSticky ? company_logo : company_logo_white} />
                ) : (
                  <img src={company_logo} />
                )}
              </a>
            </div>
            {width > breakpoint && (
              <div className="navbar-section-right">
                {/* <div className="navbar-links-wrapper post-login-links">
  <ul className="navbar-links">
    {(userType === "agent" || userType === "direct_agent") && (
      <>
        <li className="navbar-link-li bg-secondary">
          <a className="navbar-link-item" onClick={openDashboard}>
            Dashboard
          </a>
        </li>
        <li className="navbar-link-li bg-secondary">
          <a
            className="navbar-link-item flex-item"
            onClick={refreshBalance}
          >
            <span className="navbar-link-label">Cash Balance:</span>
            <RefreshRounded />
            <span className="navbar-link-label">
              {currencyFormatter(accountBalance)}
            </span>
          </a>
        </li>
        <li className="navbar-link-li bg-secondary">
          <a className="navbar-link-item" onClick={openDashboard}>
            {corpName} ({corpIdentity})
          </a>
        </li>
        <li className="navbar-link-li bg-secondary">
          <a className="navbar-link-item btn" onClick={logOut}>
            Log out
          </a>
        </li>
      </>
    )}
    {userType == "b2c_user" && isLoggedIn && (
      <>
        <li className="navbar-link-li bg-secondary">
          <a className="navbar-link-item" onClick={openDashboardB2c}>
            Dashboard
          </a>
        </li>
        <li className="navbar-link-li bg-secondary">
          <a className="navbar-link-item" onClick={openDashboardB2c}>
            {corpName} ({corpIdentity})
          </a>
        </li>
        <li className="navbar-link-li bg-secondary">
          <a className="navbar-link-item btn" onClick={logOutB2C}>
            Log out
          </a>
        </li>
      </>
    )}
    {userType !== "b2c_user" &&
      userType !== "agent" &&
      userType !== "direct_agent" && (
        <>
          <li className="navbar-link-li bg-secondary">
            <a
              className="navbar-link-item btn"
              onClick={() => history.push("/b2c/signin")}
            >
              Log in
            </a>
          </li>
          <li className="navbar-link-li bg-secondary">
            <a
              className="navbar-link-item btn"
              onClick={() => history.push("/b2c/register")}
            >
              Sign up
            </a>
          </li>
          <li className="navbar-link-li bg-secondary">
            <a
              className="navbar-link-item"
              onClick={() => history.push("/auth/login-page")}
            >
              Agent Login
            </a>
          </li>
        </>
      )}
  </ul>
</div> */}
                {width > breakpoint && (
                  <div className="navbar-links-wrapper">
                    <ul className="navbar-links">
                      <li className="navbar-link-li">
                        <NavbarLinkItem
                          isSticky={isSticky}
                          icon={
                            (isSticky && (history.location.pathname !== "/flights" && history.location.pathname !== "/")) ? blackflightIcon : flightIcon //prettier-ignore
                          }
                          serviceLabel="Flights"
                          isActive={
                            history.location.pathname === "/" ||
                            history.location.pathname === "/flights"
                          }
                          onClick={() => {
                            history.push("/flights");
                          }}
                        />
                      </li>
                      <li className="navbar-link-li">
                        <NavbarLinkItem
                          icon={
                            isSticky && history.location.pathname !== "/hotels"
                              ? blackhotelIcon
                              : hotelIcon
                          }
                          isSticky={isSticky}
                          serviceLabel="Hotels"
                          isActive={history.location.pathname === "/hotels"}
                          onClick={() => {
                            history.push("/hotels");
                          }}
                        />
                      </li>
                      {/* <li className="navbar-link-li">
                      <NavbarLinkItem
                          icon={busIcon}
                          serviceLabel="Bus"
                          isActive={history.location.pathname === "/buses"}
                          onClick={() => { history.push('/buses') }}
                      />
                  </li> */}
                      <li className="navbar-link-li">
                        <NavbarLinkItem
                          isSticky={isSticky}
                          icon={
                            isSticky &&
                            history.location.pathname !== "/packages"
                              ? blackpackageIcon
                              : packageIcon
                          }
                          serviceLabel="Packages"
                          isActive={history.location.pathname === "/packages"}
                          onClick={() => {
                            history.push("/packages");
                          }}
                        />
                      </li>
                      {/* <li className="navbar-link-li">
                      <NavbarLinkItem
                          icon={visaIcon}
                          serviceLabel="Visas"
                          isActive={false}
                          onClick={() => { }}
                      />
                  </li> */}
                      {/* <li className="navbar-link-li">
                      <NavbarLinkItem
                          icon={dmcIcon}
                          serviceLabel="DMC"
                          isActive={false}
                          onClick={() => { }}
                      />
                  </li> */}
                      {/* {breakpoint &&
      userType !== "agent" &&
      userType !== "direct_agent" && (
        <>
          <li className="navbar-link-li">
            <a className="navbar-link-item" onClick={printEticket}>
              Print E-ticket
            </a>
          </li>
        </>
      )} */}
                    </ul>
                    {/* {!breakpoint &&
    userType !== "agent" &&
    userType !== "direct_agent" && (
      <ul className="navbar-links btn-links">
        <li className="navbar-link-li">
          <a className="navbar-link-item" onClick={printEticket}>
            Print E-ticket
          </a>
        </li>
      </ul>
    )} */}
                    {/* {breakpoint && <div className="navbar-links-bottom-border"></div>} */}
                  </div>
                )}
                {width > breakpoint && (
                  <div className="navbar-links-wrapper">
                    <ul className="navbar-links rightside-links">
                      {/* <li className="navbar-link-li">
                      <NavbarLinkItem
                          icon={visaIcon}
                          serviceLabel="Visas"
                          isActive={false}
                          onClick={() => { }}
                      />
                  </li> */}
                      {/* <li className="navbar-link-li">
                      <NavbarLinkItem
                          icon={dmcIcon}
                          serviceLabel="DMC"
                          isActive={false}
                          onClick={() => { }}
                      />
                  </li> */}
                      {/* {breakpoint &&
      userType !== "agent" &&
      userType !== "direct_agent" && (
        <>
          <li className="navbar-link-li">
            <a className="navbar-link-item" onClick={printEticket}>
              Print E-ticket
            </a>
          </li>
        </>
      )} */}

                      {userType !== "b2c_user" &&
                        userType !== "agent" &&
                        userType !== "direct_agent" && (
                          <>
                            <li className="navbar-link-li bg-secondary">
                              <a
                                className="navbar-link-item"
                                onClick={printEticket}
                              >
                                Print E-ticket
                              </a>
                            </li>
                            <li className="navbar-link-li bg-secondary">
                              <a
                                className="navbar-link-item"
                                onClick={() => history.push("/auth/login-page")}
                              >
                                Agent Login
                              </a>
                            </li>

                            <li className="navbar-link-li bg-secondary">
                              <a
                                className="navbar-link-item "
                                onClick={() => history.push("/b2c/register")}
                              >
                                Sign up
                              </a>
                            </li>
                            <li className="navbar-link-li bg-secondary">
                              <a
                                className="navbar-link-item login-item"
                                onClick={() => history.push("/b2c/signin")}
                              >
                                Login{"          "}{" "}
                                <img className="loginIcon" src={loginIcon} />
                              </a>
                            </li>
                          </>
                        )}
                      {(userType === "agent" ||
                        userType === "direct_agent") && (
                        <div className="agent-navinfo">
                          <li className="navbar-link-li bg-secondary">
                            <a
                              className="navbar-link-item"
                              onClick={openDashboard}
                            >
                              Dashboard
                            </a>
                          </li>
                          <li className="navbar-link-li bg-secondary">
                            <a
                              className="navbar-link-item flex-item"
                              onClick={refreshBalance}
                            >
                              <span className="navbar-link-label">Bal:</span>
                              <RefreshRounded />
                              <span className="navbar-link-label">
                                {currencyFormatter(accountBalance)}
                              </span>
                            </a>
                          </li>
                          <li className="navbar-link-li bg-secondary">
                            <a
                              className="navbar-link-item"
                              onClick={openDashboard}
                            >
                              {corpName} ({corpIdentity})
                            </a>
                          </li>
                          <li className="navbar-link-li bg-secondary">
                            <a className="navbar-link-item" onClick={logOut}>
                              Log out
                            </a>
                          </li>
                        </div>
                      )}
                      {userType == "b2c_user" && isLoggedIn && (
                        <div className="agent-navinfo">
                          <li className="navbar-link-li bg-secondary">
                            <a
                              className="navbar-link-item"
                              onClick={openDashboardB2c}
                            >
                              Dashboard
                            </a>
                          </li>
                          <li className="navbar-link-li bg-secondary">
                            <a
                              className="navbar-link-item"
                              onClick={openDashboardB2c}
                            >
                              {corpName} ({corpIdentity})
                            </a>
                          </li>
                          <li className="navbar-link-li bg-secondary">
                            <a className="navbar-link-item" onClick={logOutB2C}>
                              Log out
                            </a>
                          </li>
                        </div>
                      )}
                    </ul>

                    {/* {breakpoint && <div className="navbar-links-bottom-border"></div>} */}
                  </div>
                )}
              </div>
            )}

            {width < breakpoint && (
              <div className="mobile-rightside">
                <div
                  className=""
                  onClick={() => setshowMobileMenu(!showMobileMenu)}
                >
                  <MenuOutlined />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </NavContainer>
  );
};

export default HomePageNavbar;

const NavbarLinkItem = (props) => {
  return (
    <a
      className={cx(
        "navbar-link-item",
        props.isActive && "active",
        props.isSticky && "sticky-navbar-link-item"
      )}
      onClick={props.onClick}
    >
      <img src={props.icon} />
      <span>{props.serviceLabel}</span>
    </a>
  );
};

const NavContainer = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 5;
  .navbar-wrappercontainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .navbar-wrappercontainer-sticky {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #fff;
    height: 82px;
    position: fixed;
    box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.5);
  }
  .mobile-menu-container{
    position: absolute;
    width: 100%;
    top: 66px;
    box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.5);

    background-color: #fff;
    & .navbar-links-wrapper{
      width: 100%;
      justify-content: center;
    }
    & .navbar-links {
      padding-left: 0px;
      align-items: center;
      gap: 6px;
      margin-top: 3px;
      margin-bottom: 7px;
    }
    & .rightside-links {
      & .navbar-link-item {
        padding: 0px;
        padding-left: 3px;
        padding-right: 3px;
      }
    }
    & .agent-navinfo {
      @media all and (max-width: 960px) {
        flex-direction: column !important;
        justify-content: space-between;
        height: 100%;
        gap: 4px;
      }
    }
    & .navbar-link-item{
      padding: 0px; 

      & span {
        color: #000;
      }
      & img {
        width: 0px;
          margin: 0px;
      }
      &.active {
        background: #33b3a6;
        border-radius: 10px;
        padding: 4px;
        & img {
          width: 0px;
          margin: 0px;
        }
        & span {
          color: ${(prop) => prop.theme.palette.buttons.primaryContrastText};
        }
      }
    }
    & .navbar-links {
      flex-direction: column;
    }
  }
  .navbar-container {
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
    justify-content: space-between;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
    @media all and (max-width: 960px) {
      align-items: center;
    }
  }
  .agent-navcontainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: #fff;
    padding-left: 25px;
    padding-right: 25px;
  }
  .agent-navinfo {
    // width: 800px;
    display: flex;
    justify-content: flex-end;

    height: 35px;
    align-items: center;
  }
  .navbar-wrapper {
    display: flex;
    flex-direction: column;
    // gap: 20px;
    position: absolute;
    width: 100%;
  max-width: 1440px;
    // padding: 15px 45px 0;
    // padding-top: 5px;
    padding-bottom: 5px;
  }
  .navbar-wrapper-sticky {
    // box-shadow: 0 0 10px 0px rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    // gap: 20px;

    max-width: 1440px;
    position: fixed;
    background-color: #fff;
    width: 100%;
    // padding: 15px 45px 0;
    padding-top: 0px;
  }
  .navbar-brand-wrapper {
    width: 200px;
    @media all and (max-width: 1050px) {
      width: 130px;
    }
    // padding: 0px 0 0;
    & a {
      cursor: pointer;
    }
    & img {
      height: 70px;
      @media all and (max-width: 1050px) {
        margin-top: 10px;
        height: 45px;
      }
    }
  }
  .navbar-section-right {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
  }
   .rightside-links {
    // .navbar-links {
    //   background-color: #eee;
    //   border-radius: 10px;
    // }
    .login-item{
      padding-right: 40px;
    }
    .agent-navinfo{
      display: flex;
    }
  
    .loginIcon {
      position: absolute;
      right: -9px;
      height: 27px;
    }

    .navbar-link-item {
      & span {
        color: ${(prop) => prop.theme.palette.text.default};
        color:  #555555;
      }
      position: relative;
      background-color: #D9D9D9;
      border-radius: 30px;
      font-family:  ${(prop) => prop.theme.palette.font.secondary};
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      // color: #000;
      color:  #555555;
      @media all and (max-width: 1235px) {
        font-size: 10px;
      }
      padding-top: 5px;
      padding-bottom: 5px;
    }
    // padding: 20px;
  }
  .navbar-links {
    display: flex;
    gap: 10px;
    padding-left: 20px;

    & .bg-secondary {
      // background: ${(prop) => prop.theme.palette.background.secondary};
    }
  }
  li {
    list-style: none;
    margin: 0 5px;
  }
  .post-login-links {
    display: block;
    height: 40px;
    & ul {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-block-start: 5px;
      margin-block-end: 5px;
    }
    & .navbar-link-label {
      margin-right: 5px;
    }
    & .MuiSvgIcon-root {
      font-size: 15px;
    }
    & .navbar-link-label {
      margin-left: 5px;
    }
  }
  .navbar-links-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .sticky-navbar-link-item {

    & span {
      flex-grow: 1;
      font-size: 11px;
      font-weight: 700;
      font-family: ${(prop) => prop.theme.palette.font.primary};
      // color: ${(prop) => prop.theme.palette.text.daar};
      color:  #555555  !important;
    }
    &.active {
      // background: linear-gradient(
      //   0deg,
      //   rgba(112, 246, 234, 1) 0%,
      //   rgba(0, 182, 166, 1) 1%,
      //   rgba(76, 226, 212, 1) 95%,
      //   rgba(112, 246, 234, 1) 100%
      // );
      background: #33b3a6;
      border-radius: 10px;
      padding: 10px 20px;
 
      & span {
        color: ${(prop) =>
          prop.theme.palette.buttons.primaryContrastText} !important;
      }
    }
  }
  .navbar-link-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    // padding: 3px 8px;
    
    padding: 10px 20px;
    @media all and (max-width: 1235px) {
      padding: 10px 10px;
    }
 
    color: ${(prop) => prop.theme.palette.text.default};
    & .MuiSvgIcon-root {
      height: 15px;
      width: 15px;
    }
    & img {
      max-width: 32px;
      margin-right: 8px;
      height: 1rem;
    }
    & span {
      flex-grow: 1;
      font-size: 11px;
      font-weight: 700;
      font-family: ${(prop) => prop.theme.palette.font.primary};
      color: ${(prop) => prop.theme.palette.text.light};
      @media all and (max-width: 1235px) {
        font-size: 10px;
      }
    }
    &.active {
      // background: linear-gradient(
      //   0deg,
      //   rgba(112, 246, 234, 1) 0%,
      //   rgba(0, 182, 166, 1) 1%,
      //   rgba(76, 226, 212, 1) 95%,
      //   rgba(112, 246, 234, 1) 100%
      // );
      background: #33b3a6;
      border-radius: 10px;
      padding: 10px 20px;
      @media all and (max-width: 1235px) {
        padding: 10px 10px;
      }
      & span {
        color: ${(prop) => prop.theme.palette.buttons.primaryContrastText};
      }
    }
    &.btn {
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(229, 230, 229, 1) 1%,
        rgba(229, 230, 229, 1) 95%,
        rgba(255, 255, 255, 1) 100%
      );
      border-radius: 20px;
      color: ${(prop) => prop.theme.palette.buttons.defaultContrast};
      font-weight: 500;
      font-size: 11px;
      padding: 3px 15px;
    }
  }
  .navbar-links-bottom-border {
    position: absolute;
    height: 2px;
    background: ${(prop) => prop.theme.palette.background.primary};
    bottom: 0;
    left: 20px;
    right: 20px;
  }


  // @media all and (max-width: 1279px) {
  //   background: ${(prop) => prop.theme.palette.background.default};
  //   .navbar-wrapper {
  //     flex-direction: column;
  //     padding: 5px 0 0;
  //   }
  //   .navbar-brand-wrapper {
  //     width: 100%;
  //     & a {
  //       display: block;
  //       text-align: center;
  //     }
  //   }
  //   .navbar-section-right {
  //     background: ${(prop) => prop.theme.palette.background.secondary};
  //     display: flex;
  //     flex-direction: column-reverse;
  //     width: 100%;
  //   }
  //   .navbar-links {
  //     padding-inline-start: 30px;
  //     padding-inline-end: 30px;
  //     flex-wrap: wrap;
  //     width: 100%;
  //   }
  //   .navbar-links-wrapper {
  //     position: relative;
  //     flex-direction: column;
  //     & ul {
  //       justify-content: center;
  //     }
  //   }
  //   .post-login-links {
  //     margin: 10px 0 5px;
  //     & ul {
  //       justify-content: center;
  //     }
  //   }
  // }
`;
